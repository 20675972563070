import {
  OreButton,
  OreMessageBar,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreText,
  OreStack,
  OreTableContainer,
  OreTable,
  OreTableHead,
  OreTableRow,
  OreTableCell,
  OreTableCaption,
  OreTableBody,
  OreCardContainer,
  OreCardTitle,
  SvgExternalHealth,
  OreIcon,
} from '@edenredespana/oreneta'
import { MouseEvent, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { forms } from 'src/domain/translations'
import {
  ConfigureModalActions,
  EmployeeContractAlert,
  EmployeeContractMessage,
} from 'src/Flex/Products/ui/product-configuration'
import { FormProvider } from 'react-hook-form'
import { FlexExternalHealthCollectiveRow } from 'src/Flex/Collectives/ui/collective-detail/molecules/FlexExternalHealthCollectiveRow'
import { HeadersModel } from 'src/domain/models/tables/Headers'
import { ExternalHealthCollectiveModel } from 'src/Flex/Collectives/domain'
import {
  ExternalHealthInsuranceForm,
  useConfigureExternalHealthCollectiveInsuranceController,
} from 'src/Flex/Collectives/ui/collective-detail'
import { Divider } from 'src/presentation/layout'
import { edenredProducts } from 'src/domain/enum'
import { useProducts } from 'src/Flex/Products/ui/product-list'

export const CollectivesConfigureExternalHealthInsuranceModalForm =
  forwardRef<ConfigureModalActions>((_, ref) => {
    const { t } = useTranslation()
    const { collective } = useProducts()
    const [isOpen, setOpen] = useState(false)
    const {
      model,
      load,
      form,
      save,
      employeesAffected,
      setEmployeesAffected,
      getProduct,
      validateEmployeeAffected,
      confirmEmployeeAffected,
    } = useConfigureExternalHealthCollectiveInsuranceController()
    const policyWatch = form.watch('policies')
    const close = (): void => {
      setEmployeesAffected(undefined)
      setOpen(false)
    }

    const saveAndClose = (event: MouseEvent): void => {
      event.preventDefault()

      form.handleSubmit(async (data: ExternalHealthInsuranceForm) => {
        const result = await save(data)

        if (result) {
          setOpen(false)
        }
      })()
    }

    const validate = (event: MouseEvent): void => {
      event.preventDefault()
      form.handleSubmit(async (data: ExternalHealthInsuranceForm) => {
        const result = await validateEmployeeAffected(edenredProducts.saludExterno, data)
      })()
    }

    const confirm = (event: MouseEvent): void => {
      event.preventDefault()

      form.handleSubmit(async (data: ExternalHealthInsuranceForm) => {
        const result = await confirmEmployeeAffected(edenredProducts.saludExterno, data)

        if (result) {
          setEmployeesAffected(undefined)
          setOpen(false)
        }
      })()
    }

    useImperativeHandle<ConfigureModalActions, ConfigureModalActions>(ref, () => ({
      open: (): void => {
        load().then(() => {
          setOpen(true)
        })
      },
      close,
    }))

    const productHeaders: HeadersModel[] = [
      {
        key: 'state',
      },
      {
        key: 'company',
        label: t(
          flexCollectivesTranslations.configure.edit.products.healthExternal.table.header
            .company
        ),
      },
      {
        key: 'policyNumber',
        label: t(
          flexCollectivesTranslations.configure.edit.products.healthExternal.table.header
            .policyName
        ),
      },
      {
        key: 'monthlyPrice',
        label: t(
          flexCollectivesTranslations.configure.edit.products.healthExternal.table.header
            .monthlyImport
        ),
      },
    ]
    useEffect(() => {
      setEmployeesAffected(undefined)
    }, [policyWatch])
    return (
      <OreModal open={isOpen}>
        <OreModalBox
          size="large"
          handleOnClose={() => {
            setEmployeesAffected(undefined)
            setOpen(false)
          }}>
          <form>
            <OreModalBoxBody noGap>
              <FormProvider {...form}>
                <OreCardContainer hasShadow={false}>
                  <OreCardTitle
                    icon={
                      <OreIcon
                        size="large"
                        icon={<SvgExternalHealth />}
                        tone="flex-products"
                      />
                    }
                    title={t(flexProductsTranslations.configure.healthExternal.title)}
                  />
                  <Divider marginTop="1rem" marginBottom="2rem" />
                  <OreStack>
                    {model &&
                    model.contractStateInfo &&
                    (model.contractStateInfo.isInProgress > 0 ||
                      model.contractStateInfo.isPendingSignature +
                        model.contractStateInfo.isInProcess >
                        0) ? (
                      <EmployeeContractMessage
                        activeContracts={model.contractStateInfo.isInProgress}
                        pendingContracts={
                          model.contractStateInfo.isPendingSignature +
                          model.contractStateInfo.isInProcess
                        }
                        isDefault={false}
                      />
                    ) : (
                      <></>
                    )}
                    <OreText>
                      {t(flexProductsTranslations.configure.health.table.legend)}
                    </OreText>
                  </OreStack>
                  <OreStack placeContent="stretch" space="xlarge">
                    <OreStack placeContent="stretch" space="medium">
                      <OreTableContainer>
                        <OreTable>
                          <OreTableCaption
                            title={t(flexProductsTranslations.configure.health.subtitle)}
                          />
                          <OreTableHead valign="middle">
                            <OreTableRow>
                              {productHeaders.map((header: HeadersModel) => (
                                <OreTableCell as="th" key={header.key}>
                                  {header.label}
                                </OreTableCell>
                              ))}
                            </OreTableRow>
                          </OreTableHead>

                          <OreTableBody valign="top">
                            {model?.policies.length &&
                              model.policies.map(
                                (policy: ExternalHealthCollectiveModel) => (
                                  <FlexExternalHealthCollectiveRow
                                    key={policy.id}
                                    policy={policy}
                                    form={form}
                                  />
                                )
                              )}
                          </OreTableBody>
                        </OreTable>
                      </OreTableContainer>
                    </OreStack>

                    {employeesAffected !== undefined && employeesAffected > 0 ? (
                      <EmployeeContractAlert
                        activeChange={false}
                        deleteChange={false}
                        numberOfContracts={employeesAffected}
                        productType={edenredProducts.saludExterno}
                        product={getProduct()}
                        collectiveId={collective?.id}
                      />
                    ) : null}
                    {employeesAffected !== undefined && employeesAffected === 0 ? (
                      <EmployeeContractAlert
                        activeChange={false}
                        deleteChange={false}
                        numberOfContracts={employeesAffected}
                        productType={edenredProducts.saludExterno}
                        product={getProduct()}
                      />
                    ) : null}
                  </OreStack>
                </OreCardContainer>
              </FormProvider>
            </OreModalBoxBody>
            <OreModalBoxFooter>
              <OreButton onClick={close} size="small" category="secondary">
                {t(forms.buttons.cancel)}
              </OreButton>
              {employeesAffected !== undefined ? (
                <OreButton
                  type="submit"
                  onClick={confirm}
                  size="small"
                  category={'danger'}>
                  {t(flexProductsTranslations.configure.common.submit)}
                </OreButton>
              ) : (
                <OreButton
                  type="submit"
                  onClick={validate}
                  size="small"
                  category={'primary'}>
                  {t(flexProductsTranslations.configure.common.submit)}
                </OreButton>
              )}
            </OreModalBoxFooter>
          </form>
        </OreModalBox>
      </OreModal>
    )
  })
