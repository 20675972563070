import { OreHeading } from '@edenredespana/oreneta'
import { stepper } from '../../domain/enum'
import { OrderAndDuplicateStepsData } from '../components/Edenred/stepper'
import Stepper from '../components/Edenred/stepper/Stepper'

interface SimpleHeaderProps {
  title: string
  organisms: React.ReactNode
}

export const SimpleHeader = ({ title, organisms }: SimpleHeaderProps) => (
  <div className="template-layout">
    <header className="width-max">
      <OreHeading as="h1" size="title-sm">
        {title}
      </OreHeading>
      <Stepper currentStep={stepper.thirdStep} stepsFor={OrderAndDuplicateStepsData} />
    </header>
    {organisms}
  </div>
)
