import { useEffect, useState } from 'react'
import type {
  CaptionModel,
  FooterModel,
  HeaderModel,
  RowModel,
} from '../../../../../domain/customComponents/table'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useTranslation } from 'react-i18next'
import { cardOrderTranslation, forms } from '../../../../../domain/translations'
import { OrePill, SvgAlert, SvgWarning } from '@edenredespana/oreneta'
import {
  edenredProducts,
  excelAlert,
  excelRequestType,
  orderType,
  Repositories,
} from '../../../../../domain/enum'
import { useNavigate } from 'react-router'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import { useModalController } from '../../../Edenred'
import type { PopupButtonModel } from '../../../../../domain/customComponents/Popup'
import type { validateOrderExcelRequest } from '../../../../../domain/bodyRequest/ExcelOrders'
import { RepositoryFactory } from '../../../../../core/factories'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import type {
  HttpModel,
  MessagesModel,
  ValidateExcelCheckoutResponse,
} from '../../../../../domain/models'
import { apiExceptions } from '../../../../../domain/definitions'

export const useExcelAlerts = () => {
  const {
    orderExcelAlerts,
    base64FileAlerts,
    base64File,
    orderExcel,
    setOrderExcel,
    setOrderExcelAlerts,
    setCardOrderCheckout,
    clearAll,
    setBase64File,
    setBase64FileAlerts,
    orderContext,
  } = useCardOrder()

  const [page, setPage] = useState(1)
  const pageSize = 8
  const isServerSide = false
  const [hasStoppers, setHasStoppers] = useState(false)
  const { startLoading, stopLoading } = useLoader()
  const [hasValidationError, setHasValidationError] = useState<boolean>(false)
  const [rows, setRows] = useState<RowModel[]>([])
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { show: showCancelModal, setShow: setShowCancelModal } = useModalController()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(cardOrderTranslation.excelAlerts.table.rowNumber) },
      { label: t(cardOrderTranslation.excelAlerts.table.errorType) },
      { label: t(cardOrderTranslation.excelAlerts.table.errorDescription) },
    ],
  }

  const caption: CaptionModel = {
    title: t(cardOrderTranslation.excelAlerts.table.title),
    description: t(cardOrderTranslation.excelAlerts.table.totalAlerts),
  }

  const GetRows = () => {
    const alertRows: RowModel[] = []
    orderExcelAlerts.forEach((alert: MessagesModel, index) => {
      const orderRow: RowModel = {
        rowId: index,
        cells: [
          {
            type: 'text',
            cellText: [
              {
                text: alert.value,
                name: 'rowNumber',
                tone: 'neutral-900',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'element',
            cellElement: (
              <div className="whitespace-nowrap">{getAlertLevel(alert.level)}</div>
            ),
          },
          {
            type: 'text',
            cellText: [
              {
                text: alert.description,
                name: 'message',
                tone: 'neutral-900',
                size: 'text-sm',
              },
            ],
          },
        ],
      }
      alertRows.push(orderRow)
    })
    setRows(alertRows)
  }

  const getAlertLevel = (alertLevel: string) => {
    if (alertLevel === excelAlert.error) {
      return (
        <OrePill icon={<SvgAlert />} tone="error-600">
          {t(cardOrderTranslation.excelAlerts.table.errorLabel)}
        </OrePill>
      )
    }
    if (alertLevel === excelAlert.warning) {
      return (
        <OrePill icon={<SvgWarning />} tone="warning-600">
          {t(cardOrderTranslation.excelAlerts.table.warningLabel)}
        </OrePill>
      )
    }
  }

  const footer: FooterModel = {
    pagination: {
      onPageChange: currentPage => setPage(currentPage),
      labelNextPage: t(forms.pagination.next),
      labelPreviousPage: t(forms.pagination.back),
      rowsPerPage: pageSize,
      text: t(forms.pagination.of),
      page: page,
      isServerSide: isServerSide,
    },
  }

  const checkContainStoppers = () => {
    const containsStoppers =
      orderExcelAlerts.some(alert => alert.level === excelAlert.error) ||
      apiExceptions.some(error => error === orderExcelAlerts[0]?.code) // Check if error code to disable Button
    if (containsStoppers) {
      setHasStoppers(true)
    }
  }

  const hasStoppersOrWarnings = () => {
    return orderExcelAlerts.length > 0
  }

  const changeFileHandle = () => {
    navigate(-1)
  }

  const downloadListHandle = async () => {
    const link = document.createElement('a')
    link.href = `data:application/octet-stream;base64,${base64FileAlerts}`
    link.download = 'listado-errores.xlsx'
    link.click()
    link.remove()
  }

  function onMainButtonClick() {
    if (hasStoppersOrWarnings()) {
      Validate()
    } else {
      navigate(navigationRoutes.cardOrderExcelHome)
    }
  }

  function Validate() {
    startLoading() // show spinner
    setHasValidationError(false) // clear errors
    const orderTypeRequest =
      orderContext.orderType == orderType.Card
        ? excelRequestType.CardsOptionalRecharge
        : excelRequestType.Recharge
    const uploadExcelRequest: validateOrderExcelRequest = {
      orderExcelType: orderTypeRequest,
      productCode: edenredProducts.ticketRestaurant,
      excel: base64File,
      stopOnWarnings: false,
    }

    const repository = RepositoryFactory(Repositories.excel)
    repository
      .validateOrder(uploadExcelRequest)
      .then((response: HttpModel<ValidateExcelCheckoutResponse>) =>
        HandleResponse(response, base64File)
      )
      .catch(error => HandleErrorUpload(error))
  }

  function HandleResponse(
    response: HttpModel<ValidateExcelCheckoutResponse>,
    base64File: string
  ) {
    if (response.meta) {
      setOrderExcel(response.data.summary)
      setBase64FileAlerts(
        response.data.additionalData ? response.data.additionalData : ''
      )
      setCardOrderCheckout(response.data.summary)
      setOrderExcelAlerts(response.meta.messages)
      setBase64File(base64File)
      stopLoading() //spinner hide
      navigate(navigationRoutes.cardOrderExcelHome)
    }
  }

  function HandleErrorUpload(error: any) {
    stopLoading() // spinner hide
    setHasValidationError(true) // show errors bar
  }

  const orderCancelConfirmed = () => {
    clearAll()
    setShowCancelModal(false)
    navigate(navigationRoutes.myOrders)
  }

  function Cancel() {
    setShowCancelModal(true)
    return undefined
  }

  const getCancelButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: orderCancelConfirmed,
        size: 'large',
      },
    ]
  }

  useEffect(() => {
    GetRows()
    checkContainStoppers()
  }, [])

  return {
    rows,
    setRows,
    header,
    caption,
    footer,
    hasStoppers,
    changeFileHandle,
    downloadListHandle,
    onMainButtonClick,
    getCancelButtons,
    showCancelModal,
    setShowCancelModal,
    Cancel,
    hasValidationError,
  }
}
