import { OreText } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { cardOrderTranslation } from '../../../../../domain/translations'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { hasUnloadOrders } from '../../../../../core/services'
import { orderType } from 'src/domain/enum'

export const CardOrderDatesParagraph = (): JSX.Element => {
  const { t } = useTranslation()
  const { orders, orderContext } = useCardOrder()

  if (orderContext.orderType === orderType.Unload || hasUnloadOrders(orders)) {
    return (
      <OreText size="text-md">
        {t(cardOrderTranslation.cardOrderHome.formSubTitleUnload)}
      </OreText>
    )
  }
  return (
    <OreText size="text-md">{t(cardOrderTranslation.cardOrderHome.formSubTitle)}</OreText>
  )
}
