import { OreButton, OreDivider, OreStack, SvgSave } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { useTTOrder } from '../../context/TTOrderProvider'

import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { TTRechargeSummaryState } from '../hooks/useTTRechargeSummaryController'

interface Props {
  ttProps: TTRechargeSummaryState
}

export const TTRechargeSummaryButtons = ({ ttProps }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { ttOrders } = useTTOrder()
  const { form, onClickCancel, onCheckoutOrder, setShowSaveCloseModal } = ttProps

  return (
    <div style={{ width: '100%' }}>
      <OreDivider space="medium" />

      <OreStack direction="row" placeContent="space-between" placeItems="normal">
        {ttOrders && ttOrders.length > 0 ? (
          <OreButton
            category="tertiary"
            icon={<SvgSave />}
            onClick={() => setShowSaveCloseModal(true)}>
            {t(forms.buttons.saveAndClose)}
          </OreButton>
        ) : (
          <div> </div>
        )}
        <OreStack direction="row" space="medium">
          <OreButton onClick={() => onClickCancel()}>{t(forms.buttons.cancel)}</OreButton>
          <OreButton
            category="primary"
            onClick={form.handleSubmit(onCheckoutOrder)}
            disabled={ttOrders && ttOrders.length <= 0}>
            {t(TTOrderTranslation.summary.checkoutButton)}
          </OreButton>
        </OreStack>
      </OreStack>
    </div>
  )
}
