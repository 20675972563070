import { OreButton, OreStack, OreText, SvgDownload } from '@edenredespana/oreneta'
import { DownloadTemplateModel } from 'src/Flex/CommunicationsTemplates/domain'
import { LegalDocumentType } from 'src/domain/enum'
import { useDownloader } from 'src/Flex/Employees/ui/employee-detail'

interface TemplateDownloadProps {
  downloadModel: DownloadTemplateModel
  name: string | undefined
}

export const TemplateDownload = ({
  downloadModel,
  name,
}: TemplateDownloadProps): JSX.Element => {
  const { downloadLegalDocument } = useDownloader()

  return (
    <OreStack placeItems="start">
      {downloadModel.description ? <OreText>{downloadModel.description}</OreText> : null}
      {downloadModel.buttonText ? (
        <OreButton
          icon={<SvgDownload />}
          category="secondary"
          onClick={() =>
            downloadLegalDocument(
              LegalDocumentType.flexTemplate,
              name?.replace(/ /g, '_')
            )
          }>
          {downloadModel.buttonText}
        </OreButton>
      ) : null}
    </OreStack>
  )
}
