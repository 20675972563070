import { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'
import { forms } from 'src/domain/translations'
import {
  useOrders,
  orderImputationsTranslations,
} from 'src/Flex/Orders/ui/order-imputation'

export type DeleteModalActions = {
  open: (id: string) => void
}

export const DeleteImputationModal = forwardRef<DeleteModalActions>(
  (_, ref): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false)
    const [id, setId] = useState<string>('')
    const { t } = useTranslation()
    const { deleteOrder } = useOrders()

    useImperativeHandle(ref, () => ({
      open: async (id: string): Promise<void> => {
        setId(id)
        setOpen(true)
      },
    }))

    const saveAndClose = async (): Promise<void> => {
      deleteOrder(id).then((response: boolean) => {
        if (response) {
          setOpen(false)
        }
      })
    }

    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="medium" handleOnClose={() => setOpen(false)}>
          <OreModalBoxBody>
            <OreHeading align="left" size="headline-md">
              {t(orderImputationsTranslations.configure.delete.title)}
            </OreHeading>
            <OreStack space="large">
              <OreText align="left">
                {t(orderImputationsTranslations.configure.delete.description)}
              </OreText>
            </OreStack>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={() => setOpen(false)} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton onClick={saveAndClose} size="small" category="danger">
              {t(forms.buttons.delete)}
            </OreButton>
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
