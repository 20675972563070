import { useNavigate } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { PageWrapper } from 'src/presentation/layout'
import {
  OreHeading,
  OreDivider,
  OreStack,
  OreDistributor,
  SvgAddEmployee,
  SvgAddEmployees,
  OreText,
} from '@edenredespana/oreneta'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { EmployeeProvider, FlexEmployeeList } from 'src/Flex/Employees/ui/employee-list'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { Steps, stepSlug } from 'src/Flex/Employees/ui/employee-excel'

import { useUserData } from 'src/Flex/User/ui/context'
import { RestrictedComponent } from 'src/presentation/components/Edenred/permissions'
import { actionTag } from 'src/domain/enum/permissionTags'
import { VideoTutorial } from 'src/Flex/Shared/ui/VideoTutorial/VideoTutorial'

export const FlexEmployeesPage = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { flexData } = useUserData()

  return (
    <PageWrapper>
      <OreHeading as="h1" size="title-md">
        {t(flexEmployeesTranslations.title)}
      </OreHeading>
      <OreDivider space="larger-bottom" />
      <EmployeeProvider>
        <OreStack space="large" placeContent="stretch">
          {!flexData?.isOnboardingDone ? (
            <OreText>
              <Trans t={t}>{t(flexEmployeesTranslations.onboarding)}</Trans>
            </OreText>
          ) : null}

          <OreStack direction="row" sameSize>
            <RestrictedComponent by={actionTag.ACTION_FL_EMPLOYEES_ADDFROMFORM}>
              <OreDistributor
                title={t(flexEmployeesTranslations.excel.button1.label)}
                subtitle={t(flexEmployeesTranslations.excel.button1.subtitle)}
                icon={<SvgAddEmployee aria-hidden />}
                handleClick={(): void =>
                  navigate(flexNavigationRoutes.flexEmployeesCreate)
                }
              />
            </RestrictedComponent>
            <RestrictedComponent by={actionTag.ACTION_FL_EMPLOYEES_ADDEDITFROMEXCEL}>
              <OreDistributor
                title={t(flexEmployeesTranslations.excel.button2.label)}
                subtitle={t(flexEmployeesTranslations.excel.button2.subtitle)}
                icon={<SvgAddEmployees aria-hidden />}
                handleClick={(): void =>
                  navigate(
                    flexNavigationRoutes.flexEmployeesExcel.replace(
                      ':step',
                      stepSlug(Steps.Upload)
                    )
                  )
                }
              />
            </RestrictedComponent>
          </OreStack>

          <FlexEmployeeList />
          <OreStack placeContent="stretch">
            <RestrictedComponent by={actionTag.ACTION_FL_EMPLOYEES_ADDFROMFORM}>
              <VideoTutorial
                title={t(flexEmployeesTranslations.videotutorial.byForm)}
                youtubeUrl="https://www.youtube.com/embed/AFi9baWMDmo?si=aMK6armjb884Delp"
              />
            </RestrictedComponent>
            <RestrictedComponent by={actionTag.ACTION_FL_EMPLOYEES_ADDEDITFROMEXCEL}>
              <VideoTutorial
                title={t(flexEmployeesTranslations.videotutorial.byExcel)}
                youtubeUrl="https://www.youtube.com/embed/4_RHi36FYL8?si=60Xh8ZKedtHhvPS2"
              />
            </RestrictedComponent>
            <VideoTutorial
              title={t(flexEmployeesTranslations.videotutorial.editMultiple)}
              youtubeUrl="https://www.youtube.com/embed/sq8cFtfRpAg?si=dO2opsLcJwh6QG-f"
            />
          </OreStack>
        </OreStack>
      </EmployeeProvider>
    </PageWrapper>
  )
}
