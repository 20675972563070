import { OreMessageBar, OreStack } from '@edenredespana/oreneta'
import { flexProductsTranslations } from '../../translations'
import { useTranslation } from 'react-i18next'

export const ProductWarningMessage = (props: {
  deleteChange: boolean | undefined
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      {!props.deleteChange ? (
        <OreMessageBar color="warning">
          <OreStack>
            <strong>{t(flexProductsTranslations.employeeContractAlert.title)}</strong>
            {t(flexProductsTranslations.employeeContractAlert.warning.description)}
          </OreStack>
        </OreMessageBar>
      ) : (
        <OreMessageBar color="warning" icon>
          {t(
            flexProductsTranslations.configure.healthExternal.force_delete_policy_message6
          )}
        </OreMessageBar>
      )}
    </>
  )
}
