import {
  OreIconButton,
  OreTableCell,
  OreTableRow,
  OreText,
  SvgEdit,
  OreStack,
  OrePill,
  SvgDownload,
  OreButton,
  OreTooltip,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import type { ProductModel } from 'src/Flex/Products/domain'
import type { ComponentType, RefAttributes } from 'react'
import { useRef } from 'react'
import type { ConfigureModalActions } from 'src/Flex/Products/ui/product-configuration'
import { edenredProducts } from 'src/domain/enum'
import { forms } from 'src/domain/translations'

interface IProps {
  product: ProductModel
  productIcon: JSX.Element | null
  Modal?: ComponentType<RefAttributes<ConfigureModalActions>>
  ModalIcon?: ComponentType
}

export const FlexProductRow = ({
  product,
  productIcon,
  Modal,
  ModalIcon = SvgEdit,
}: IProps): JSX.Element => {
  const { name, description, productType } = product
  const ref = useRef<ConfigureModalActions>(null)
  const { t } = useTranslation()

  const handleClick = (): void => {
    ref?.current?.open()
  }

  const getProductStatus = (status?: boolean): JSX.Element => {
    if (status === undefined) return <OreText>-</OreText>
    if (status)
      return (
        <OrePill tone="success-600">
          {t(flexProductsTranslations.table.row.status.active)}
        </OrePill>
      )
    else
      return (
        <OrePill tone="grey">
          {t(flexProductsTranslations.table.row.status.inactive)}
        </OrePill>
      )
  }

  const productTypeToFile = {
    [edenredProducts.ticketRestaurant]: '6_DocumentoPlataformaFlex_TR.pdf',
    [edenredProducts.ticketGuarderia]: '6_DocumentoPlataformaFlex_Guarderia.pdf',
    [edenredProducts.ticketTransporte]: '6_DocumentoPlataformaFlex_Movilidad.pdf',
    [edenredProducts.formacion]: '6_DocumentoPlataformaFlex_Formacion.pdf',
    [edenredProducts.saludExterno]: '6_DocumentoPlataformaFlex_SeguroSalud.pdf',
    [edenredProducts.salud]: '',
  }

  const getFilename = (): string => {
    return productTypeToFile[productType] || '6_DocumentoPlataformaFlex_TR.pdf'
  }

  const downloadDocument = () => {
    const link = document.createElement('a')
    const filename = getFilename()
    link.href = `/FlexDocuments/${filename}`
    link.download = filename
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <OreTableRow valign="middle">
      <OreTableCell size="quarter">
        <div className="flex">
          <OreStack
            space="xsmall"
            placeContent="center"
            placeItems="center"
            direction="row">
            {productIcon}
            <OreText size="text-sm" tone="neutral-900" bold={true}>
              {name}
            </OreText>
          </OreStack>
        </div>
      </OreTableCell>
      <OreTableCell>
        <OreStack space="small">
          <OreText size="text-sm">
            {description}
            {product.productType === edenredProducts.salud && '*'}
          </OreText>
        </OreStack>
      </OreTableCell>
      <OreTableCell>{getProductStatus(product.active)}</OreTableCell>
      <OreTableCell>
        <OreStack space="2xsmall" direction="row">
          {productType !== edenredProducts.salud && (
            <OreTooltip
              text={t(flexProductsTranslations.table.row.actions.download_tooltip)}
              placement="top">
              <OreIconButton icon={<SvgDownload />} onClick={downloadDocument} />
            </OreTooltip>
          )}
          {Modal && (
            <>
              {product.configurated && (
                <OreTooltip
                  text={t(flexProductsTranslations.table.row.actions.edit)}
                  placement="top">
                  <OreIconButton icon={<ModalIcon />} onClick={handleClick} />
                </OreTooltip>
              )}
              {!product.configurated && (
                <OreButton size="small" onClick={handleClick}>
                  {product.productType === edenredProducts.salud
                    ? t(forms.buttons.consult)
                    : t(forms.buttons.configure)}
                </OreButton>
              )}
              <Modal ref={ref} />
            </>
          )}
        </OreStack>
      </OreTableCell>
    </OreTableRow>
  )
}
