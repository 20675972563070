import {
  OreHeading,
  OrePagination,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'

import {
  employeesAdherenceTranslations,
  useEmployeesAdherence,
} from 'src/Flex/Orders/ui/order-adherence'
import { tableSelector } from 'src/domain/translations'
import { HeadersModel } from 'src/domain/models/tables/Headers'
import { EmployeesLastAdherenceModel } from 'src/Flex/Orders/domain/EmployeesLastAdherenceModel'
import { FlexEmployeeLastAdherenceRow } from '../molecules/FlexEmployeeLastAdherenceRow'

export const FlexEmployeesLastAdherenceList = (): JSX.Element => {
  const { t } = useTranslation()

  const handlePageChange = (page: number): void => changePage(page - 1)

  const { lastEmployeesAdherence, changePage, numberOfRecordsPerPage, page, totalRows } =
    useEmployeesAdherence()

  const employeesLastAdherenceHeaders: HeadersModel[] = [
    {
      key: 'name',
      label: t(employeesAdherenceTranslations.lastAdherenceTable.header.name),
    },

    {
      key: 'date',
      label: t(employeesAdherenceTranslations.lastAdherenceTable.header.adherenceDate),
    },
    {
      key: 'products',
      label: t(employeesAdherenceTranslations.lastAdherenceTable.header.products),
    },
  ]

  return (
    <>
      <OreTableContainer>
        <div className="bg-white p-2">
          <OreStack direction="row" placeItems="center">
            <OreHeading size="headline-md">
              {t(employeesAdherenceTranslations.lastAdherenceTable.title)}
            </OreHeading>
            <OreText size="text-sm">
              {totalRows +
                ' ' +
                t(employeesAdherenceTranslations.pendingAdherenceTable.subtitle)}
            </OreText>
          </OreStack>
        </div>
        <OreTable>
          {lastEmployeesAdherence.length > 0 && (
            <OreTableHead valign="middle">
              <OreTableRow hover={false}>
                {employeesLastAdherenceHeaders.map((header: HeadersModel) => (
                  <OreTableCell as="th" key={header.key} size={header.size}>
                    {header.label}
                  </OreTableCell>
                ))}
              </OreTableRow>
            </OreTableHead>
          )}

          <OreTableBody valign="top">
            {lastEmployeesAdherence.length
              ? lastEmployeesAdherence.map((employee: EmployeesLastAdherenceModel) => {
                  return (
                    <FlexEmployeeLastAdherenceRow
                      key={employee.employeeDocument}
                      employee={employee}
                    />
                  )
                })
              : null}
          </OreTableBody>
        </OreTable>
        {lastEmployeesAdherence.length === 0 && (
          <OreTableActions>
            <OreText size="text-sm" tone="neutral-600">
              {t(employeesAdherenceTranslations.lastAdherenceTable.empty)}
            </OreText>
          </OreTableActions>
        )}
        {lastEmployeesAdherence.length > 0 && (
          <OreTableActions>
            <OrePagination
              displayPageButtons
              count={totalRows}
              labelNextPage={t(tableSelector.nextPageLabel)}
              labelPreviousPage={t(tableSelector.previousPageLabel)}
              onPageChange={handlePageChange}
              page={page}
              rowsPerPage={numberOfRecordsPerPage}
              text={t(tableSelector.pageOf)}
              legend={t(tableSelector.rowsShowing)}
            />
          </OreTableActions>
        )}
      </OreTableContainer>
    </>
  )
}
