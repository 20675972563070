import { OreButton, OreHeading, OreStack, OreText } from '@edenredespana/oreneta'
import { orderValidationsTranslations } from '../orderValidationsTranslations'
import { useTranslation } from 'react-i18next'
import { orderImputationsTranslations } from '../../order-imputation'

export const ForceUpdateOrder = (props: {
  refresh: () => Promise<void>
  date: string | undefined
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <OreStack>
      <OreHeading size="headline-md">
        {t(orderValidationsTranslations.forceUpdate.title)}
      </OreHeading>
      <OreText>{t(orderValidationsTranslations.forceUpdate.description)}</OreText>
      {props.date && (
        <OreText tone="neutral-400">
          {t(orderImputationsTranslations.forceUpdate.updateDate)} {props.date}
        </OreText>
      )}
      <OreStack placeContent="start">
        <OreButton category="primary" onClick={props.refresh}>
          {t(orderValidationsTranslations.forceUpdate.button)}
        </OreButton>
      </OreStack>
    </OreStack>
  )
}
