import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreTableContainer,
  OreTable,
  OreTableHead,
  OreTableRow,
  OreTableCell,
  OreTableBody,
  OrePagination,
  OreTableActions,
  OreText,
  OreMessageBar,
} from '@edenredespana/oreneta'
import {
  FlexEmployeeRow,
  ContractReceivedModal,
  ContractReceivedModalActions,
  useEmployees,
  StatusModalActions,
  StatusEmployeeModal,
} from 'src/Flex/Employees/ui/employee-list'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { tableSelector } from 'src/domain/translations/tables/tableSelector'
import type { HeadersModel } from 'src/domain/models/tables/Headers'
import {
  active,
  collectiveId,
  isWelcomeEmailSent,
  type EmployeeModel,
  flexRetributionPlanRegisterStep,
} from 'src/Flex/Employees/domain'
import {
  TableSearcher,
  tableSearcherTranslations,
} from 'src/Flex/Shared/ui/TableSearcher'
import { FilterModel } from 'src/Flex/Shared/domain'
import { useCollectiveController } from 'src/Flex/Collectives/ui/collective-list'
import {
  SendEmailModal,
  SendEmailModalActions,
} from 'src/Flex/CommunicationsTemplates/ui/templates-send/organisms'
import { TemplateTargetEnum } from 'src/Flex/CommunicationsTemplates/domain'
import { EmployeePRFEnum } from 'src/Flex/Employees/domain/EmployeePRFEnum'
import { forms } from 'src/domain/translations'
import { FlexEmployeeDeleteToolbar } from '../molecules/FlexEmployeeDeleteToolbar'
import { DeleteMultipleEmployeesModal } from '../atoms/DeleteMultipleEmployeesModal'
import {
  DeleteEmployeedModalActions,
  DeleteEmployeeModal,
} from '../atoms/DeleteEmployeeModal'

export const FlexEmployeeList = (): JSX.Element => {
  const { t } = useTranslation()
  const ref = useRef<StatusModalActions>(null)
  const sendEmailRef = useRef<SendEmailModalActions>(null)
  const contractReceivedRef = useRef<ContractReceivedModalActions>(null)
  const deleteEmployeeRef = useRef<DeleteEmployeedModalActions>(null)
  const [showDeleteMultipleModal, setShowDeleteMultipleModal] = useState(false)
  const [deleteHasError, setDeleteHasError] = useState(false)

  const {
    numberOfRecordsPerPage,
    page,
    count,
    onPageChange,
    search,
    searchParameters,
    employees,
    toggleState,
    loadEmployees,
    selectedEmployees,
    setSelectedEmployees,
    deleteEmployees,
  } = useEmployees()

  const { collectives } = useCollectiveController(null)

  const handleSubscribe = (employee: EmployeeModel): void => {
    if (!employee) return

    if (employee?.active) {
      ref?.current?.open(employee)
    } else {
      toggleState(employee)
      loadEmployees()
    }
  }

  const handleSendEmail = (id: string): void => {
    sendEmailRef?.current?.open(id, TemplateTargetEnum.employee)
  }

  const handleContractReceived = (employee: EmployeeModel): void => {
    contractReceivedRef?.current?.open(employee)
  }

  const handleDeleteEmployee = (employee: EmployeeModel): void => {
    setSelectedEmployees([])
    deleteEmployeeRef?.current?.open(employee)
  }

  const handleDeleteMultipleEmployees = (): void => {
    setDeleteHasError(false)
    setShowDeleteMultipleModal(false)
    deleteEmployees(selectedEmployees).then(result => {
      if (result) {
        setSelectedEmployees([])
        setDeleteHasError(false)
      } else {
        setDeleteHasError(true)
      }
    })
  }

  const employeesHeaders: HeadersModel[] = [
    {
      key: 'name',
      label: t(flexEmployeesTranslations.table.header.name),
    },
    {
      key: 'collective',
      label: t(flexEmployeesTranslations.table.header.collective),
    },
    {
      key: 'communicationDate',
      label: t(flexEmployeesTranslations.table.header.communicationDate),
    },
    {
      key: 'state',
      label: t(flexEmployeesTranslations.table.header.state),
    },
    {
      key: 'prf',
      label: t(flexEmployeesTranslations.table.header.prfShort),
    },
    {
      key: 'actions',
      label: t(flexEmployeesTranslations.table.header.actions),
    },
  ]

  const filters: FilterModel[] = [
    {
      id: collectiveId,
      label: t(flexEmployeesTranslations.table.header.collective),
      type: 'select',
      placeholder: `${t(tableSearcherTranslations.selectPrefix)} ${t(
        flexEmployeesTranslations.table.header.collective
      ).toLowerCase()}`,
      options: collectives.map(collective => ({
        label: collective.name,
        value: collective.id,
      })),
    },
    {
      id: active,
      label: t(flexEmployeesTranslations.table.header.state),
      type: 'select',
      placeholder: `${t(tableSearcherTranslations.selectPrefix)} ${t(
        flexEmployeesTranslations.table.header.state
      ).toLowerCase()}`,
      options: [
        { label: t(flexEmployeesTranslations.table.body.active), value: 'true' },
        { label: t(flexEmployeesTranslations.table.body.inactive), value: 'false' },
      ],
    },
    {
      id: flexRetributionPlanRegisterStep,
      label: t(flexEmployeesTranslations.table.header.prfLong),
      type: 'select',
      placeholder: t(flexEmployeesTranslations.table.filters.placeholders.prf),
      options: [
        {
          label: t(flexEmployeesTranslations.table.body.notStartedPrf),
          value: EmployeePRFEnum.notStarted.toString(),
        },
        {
          label: t(flexEmployeesTranslations.table.body.pendingPrf),
          value: EmployeePRFEnum.pending.toString(),
        },
        {
          label: t(flexEmployeesTranslations.table.body.cancelledPrf),
          value: EmployeePRFEnum.cancelled.toString(),
        },
        {
          label: t(flexEmployeesTranslations.table.body.donePrf),
          value: EmployeePRFEnum.done.toString(),
        },
      ],
    },
    {
      id: isWelcomeEmailSent,
      label: t(flexEmployeesTranslations.table.header.communicationSent),
      type: 'select',
      placeholder: t(forms.buttons.select),
      options: [
        { label: t(flexEmployeesTranslations.table.header.commSent), value: 'true' },
        { label: t(flexEmployeesTranslations.table.header.commPending), value: 'false' },
      ],
    },
  ]

  return (
    <>
      <OreTableContainer>
        <TableSearcher
          handleSearch={search}
          filters={filters}
          searchPlaceholder={t(flexEmployeesTranslations.searchPlaceholder)}
        />
        <OreTable>
          <OreTableHead valign="middle">
            <OreTableRow hover={false}>
              {employeesHeaders.map(
                (header: HeadersModel): JSX.Element => (
                  <OreTableCell as="th" key={header.key}>
                    <span style={{ whiteSpace: 'pre-line' }}>{header.label}</span>
                  </OreTableCell>
                )
              )}
            </OreTableRow>
          </OreTableHead>
          <OreTableBody valign="top">
            {employees.map(
              (employee: EmployeeModel): JSX.Element => (
                <FlexEmployeeRow
                  key={employee.id}
                  employee={employee}
                  handleSubscribe={handleSubscribe}
                  sendEmail={handleSendEmail}
                  contractReceived={handleContractReceived}
                  selectedEmployees={selectedEmployees}
                  setSelectedEmployees={setSelectedEmployees}
                  handleDeleteEmployee={handleDeleteEmployee}
                />
              )
            )}
          </OreTableBody>
        </OreTable>
        {employees.length === 0 ? (
          <OreTableActions>
            <OreText size="text-sm" tone="neutral-900">
              {searchParameters.filters.some(sf => sf.value) ||
              searchParameters.search !== ''
                ? t(flexEmployeesTranslations.table.noEmployeesWithFilters)
                : t(flexEmployeesTranslations.table.noEmployees)}
            </OreText>
          </OreTableActions>
        ) : null}
        {count > 0 ? (
          <OreTableActions>
            <OrePagination
              displayPageButtons
              count={count}
              labelNextPage={t(tableSelector.nextPageLabel)}
              labelPreviousPage={t(tableSelector.previousPageLabel)}
              onPageChange={onPageChange}
              page={page}
              rowsPerPage={numberOfRecordsPerPage}
              text={t(tableSelector.pageOf)}
              legend={t(tableSelector.rowsShowing)}
            />
          </OreTableActions>
        ) : null}
      </OreTableContainer>
      {deleteHasError && (
        <OreMessageBar color="error" icon>
          {t(flexEmployeesTranslations.deleteToolbar.modal.errorMessage1)}
          <span className="underline">
            {selectedEmployees.length}
            {t(flexEmployeesTranslations.deleteToolbar.modal.errorMessage2)}
          </span>
          {t(flexEmployeesTranslations.deleteToolbar.modal.errorMessage3)}
        </OreMessageBar>
      )}
      <StatusEmployeeModal ref={ref} />
      <SendEmailModal
        ref={sendEmailRef}
        target={TemplateTargetEnum.employee}
        actionOnClose={loadEmployees}
      />
      <ContractReceivedModal ref={contractReceivedRef} />
      <DeleteEmployeeModal ref={deleteEmployeeRef} />
      {selectedEmployees.length > 1 && (
        <FlexEmployeeDeleteToolbar
          selectedEmployees={selectedEmployees}
          setShowDeleteMultipleModal={setShowDeleteMultipleModal}
        />
      )}
      <DeleteMultipleEmployeesModal
        deleteEmployees={handleDeleteMultipleEmployees}
        showDeleteMultipleModal={showDeleteMultipleModal}
        setShowDeleteMultipleModal={setShowDeleteMultipleModal}
        totalEmployees={selectedEmployees.length}
      />
    </>
  )
}
