import { useTranslation } from 'react-i18next'
import {
  OreTableContainer,
  OreTable,
  OreTableCaption,
  OreTableHead,
  OreTableRow,
  OreTableCell,
  OreTableBody,
  OreText,
  OreTableActions,
  OrePagination,
} from '@edenredespana/oreneta'
import { getProductIconByProductType } from 'src/Flex/Products/ui/shared'
import type { HeadersModel } from 'src/domain/models/tables/Headers'
import {
  FlexProductAdherenceRow,
  productAdherenceTranslations,
  useProductsAdherence,
} from 'src/Flex/Orders/ui/order-adherence'
import { ProductAdherenceModel } from 'src/Flex/Orders/domain'
import { flexOrdersTranslations } from 'src/Flex/Orders/ui/translations'
import { tableSelector } from 'src/domain/translations'

export const FlexProductsAdherenceList = (): JSX.Element => {
  const { t } = useTranslation()

  const { productsAdherence, numberOfRecordsPerPage, totalRows, page, changePage } =
    useProductsAdherence()

  const handlePageChange = (page: number): void => changePage(page - 1)

  const productHeaders: HeadersModel[] = [
    {
      key: 'name',
      label: t(flexOrdersTranslations.adherence.table.header.name),
    },

    {
      key: 'description',
      label: t(flexOrdersTranslations.adherence.table.header.description),
    },
    {
      key: 'totalAmount',
      label: t(flexOrdersTranslations.adherence.table.header.totalAmount),
    },
    {
      key: 'date',
      label: t(flexOrdersTranslations.adherence.table.header.applicationDate),
    },
    {
      key: 'actions',
      label: t(flexOrdersTranslations.adherence.table.header.action),
    },
  ]

  return (
    <>
      <OreTableContainer>
        <OreTable>
          {<OreTableCaption title={t(productAdherenceTranslations.table.title)} />}
          <OreTableHead valign="middle">
            <OreTableRow hover={false}>
              {productHeaders.map((header: HeadersModel) => (
                <OreTableCell as="th" key={header.key} size={header.size}>
                  {header.label}
                </OreTableCell>
              ))}
            </OreTableRow>
          </OreTableHead>
          <OreTableBody valign="top">
            {productsAdherence.length
              ? productsAdherence.map((product: ProductAdherenceModel) => {
                  return (
                    <FlexProductAdherenceRow
                      key={product.id}
                      product={product}
                      productIcon={getProductIconByProductType(product.productType)}
                    />
                  )
                })
              : null}
          </OreTableBody>
        </OreTable>
        {productsAdherence.length === 0 && (
          <OreTableActions>
            <OreText size="text-sm" tone="neutral-600">
              {t(flexOrdersTranslations.adherence.table.noProducts)}
            </OreText>
          </OreTableActions>
        )}
        {productsAdherence.length > 0 && (
          <OreTableActions>
            <OrePagination
              displayPageButtons
              count={totalRows}
              labelNextPage={t(tableSelector.nextPageLabel)}
              labelPreviousPage={t(tableSelector.previousPageLabel)}
              onPageChange={handlePageChange}
              page={page}
              rowsPerPage={numberOfRecordsPerPage}
              text={t(tableSelector.pageOf)}
              legend={t(tableSelector.rowsShowing)}
            />
          </OreTableActions>
        )}
      </OreTableContainer>
    </>
  )
}
