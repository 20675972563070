import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreText,
  OreStack,
} from '@edenredespana/oreneta'
import { forwardRef, useState, useImperativeHandle } from 'react'
import { ConfigureModalActions } from 'src/Flex/Products/ui/product-configuration'
import { useTranslation } from 'react-i18next'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { forms } from 'src/domain/translations'
import { useStatusToggleProduct } from 'src/Flex/Products/ui/product-toggle-status'
import { edenredProducts } from 'src/domain/enum'

export type ToggleModalActions = {
  evaluate: () => void
}

interface IProps {
  productType: edenredProducts
  contractsNumber: number
  current: boolean
  toggleButton: () => void
  ref: React.Ref<ConfigureModalActions>
  hasModifiedLimits: boolean | undefined
}

export const StatusToggleModal = forwardRef<ToggleModalActions, IProps>(
  (
    { productType, current, toggleButton, hasModifiedLimits, contractsNumber },
    ref
  ): JSX.Element => {
    const { t } = useTranslation()
    const [open, setOpen] = useState(false)
    const { getEmployeesAffectedExcel, enableDisable } = useStatusToggleProduct()

    const isCollective = hasModifiedLimits !== undefined
    const isOpenISalud =
      productType === edenredProducts.salud && isCollective && !hasModifiedLimits
    const isOpenExternal =
      productType === edenredProducts.saludExterno && isCollective && !hasModifiedLimits

    useImperativeHandle(ref, () => ({
      evaluate: async (): Promise<void> => {
        const shouldOpen = contractsNumber > 0
        if ((current && shouldOpen) || (current && isCollective)) {
          setOpen(true)
        } else {
          if (isOpenISalud || isOpenExternal) {
            setOpen(true)
          } else {
            const saved = await enableDisable(productType, current)
            saved && toggleButton()
          }
        }
      },
    }))

    const close = (): void => {
      setOpen(false)
    }

    const saveAndClose = async (): Promise<void> => {
      const result = await enableDisable(productType, current)

      if (result) {
        toggleButton()
      }

      setOpen(false)
    }

    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="small" handleOnClose={() => setOpen(false)}>
          <OreModalBoxBody>
            <OreHeading align="left" size="headline-md">
              {(isOpenISalud && !current) || (isOpenExternal && !current)
                ? t(flexProductsTranslations.configure.health.toggle.open.title)
                : t(flexProductsTranslations.activeModal.title) +
                  t(flexProductsTranslations.ids[productType]) +
                  t(flexProductsTranslations.activeModal.title2)}
            </OreHeading>
            <OreStack space="small">
              <OreText align="left">
                <>
                  {t(flexProductsTranslations.activeModal.description)}
                  <a
                    className="pointer"
                    onClick={() => getEmployeesAffectedExcel(productType)}>
                    {contractsNumber +
                      t(flexProductsTranslations.activeModal.description1)}
                  </a>
                  {t(flexProductsTranslations.activeModal.description2)}
                </>
              </OreText>
              {(productType === edenredProducts.ticketRestaurant ||
                productType === edenredProducts.ticketTransporte ||
                productType === edenredProducts.ticketGuarderia) && (
                <>
                  <OreText>{t(flexProductsTranslations.activeModal.item)}</OreText>
                  <OreText>{t(flexProductsTranslations.activeModal.item2)}</OreText>
                  <OreText>{t(flexProductsTranslations.activeModal.item3)}</OreText>
                </>
              )}
              {productType === edenredProducts.formacion && (
                <>
                  <OreText>
                    {t(flexProductsTranslations.activeModal.formacion_item1)}
                  </OreText>
                  <OreText>
                    {t(flexProductsTranslations.activeModal.formacion_item2)}
                  </OreText>
                  <OreText>
                    {t(flexProductsTranslations.activeModal.formacion_item3)}
                  </OreText>
                  <OreText>{t(flexProductsTranslations.activeModal.item3)}</OreText>
                </>
              )}
              {productType === edenredProducts.salud && (
                <>
                  <OreText>{t(flexProductsTranslations.activeModal.salud_item1)}</OreText>
                  <OreText>{t(flexProductsTranslations.activeModal.salud_item2)}</OreText>
                  <OreText>{t(flexProductsTranslations.activeModal.salud_item3)}</OreText>
                  <OreText>{t(flexProductsTranslations.activeModal.item3)}</OreText>
                </>
              )}
              {productType === edenredProducts.saludExterno && (
                <>
                  <OreText>
                    {t(flexProductsTranslations.activeModal.saludExterno_item1)}
                  </OreText>
                  <OreText>
                    {t(flexProductsTranslations.activeModal.saludExterno_item2)}
                  </OreText>
                  <OreText>{t(flexProductsTranslations.activeModal.item3)}</OreText>
                </>
              )}
            </OreStack>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={close} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton onClick={saveAndClose} size="small" category="danger">
              {t(flexProductsTranslations.configure.common.submit)}
            </OreButton>
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
