import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { array, object } from 'yup'
import { edenredProducts } from 'src/domain/enum'
import {
  ExternalHealthCollectiveConfigurationModel,
  ExternalHealthCollectiveModel,
} from 'src/Flex/Collectives/domain'
import { useConfigureProduct } from 'src/Flex/Products/ui/product-configuration'
import { stringSchema } from 'src/Flex/Shared/ui/Form'
import {
  ExternalHealthInsuranceConfigurationModel,
  ProductConfigurationModels,
} from 'src/Flex/Products/domain'
import { Dispatch, SetStateAction } from 'react'

export interface ExternalHealthInsuranceForm {
  policies: string[]
}

interface IUseConfigureExternalHealthCollectiveInsuranceController {
  load: () => Promise<void>
  save: (data: ExternalHealthInsuranceForm) => Promise<boolean>
  form: UseFormReturn<ExternalHealthInsuranceForm>
  model: ExternalHealthCollectiveConfigurationModel | undefined
  isGlobal: boolean
  validateEmployeeAffected: (
    productType: edenredProducts,
    data: ExternalHealthInsuranceForm
  ) => Promise<void>
  employeesAffected: number | undefined
  setEmployeesAffected: Dispatch<SetStateAction<number | undefined>>
  confirmEmployeeAffected: (
    productType: edenredProducts,
    data: ExternalHealthInsuranceForm
  ) => Promise<boolean>
  getProduct: () =>
    | ExternalHealthCollectiveConfigurationModel
    | ExternalHealthInsuranceConfigurationModel
}

export const useConfigureExternalHealthCollectiveInsuranceController =
  (): IUseConfigureExternalHealthCollectiveInsuranceController => {
    const { t } = useTranslation()
    const { model, getProduct, ...rest } = useConfigureProduct<
      ExternalHealthInsuranceForm,
      edenredProducts.saludExterno
    >({
      productType: edenredProducts.saludExterno,
      formProps: {},
      formValidationScheme: () =>
        object({
          policies: array().of(stringSchema(t)),
        }),
      convertFromAPIToForm: data => {
        const activeArray: string[] = []
        const _data = data as ExternalHealthCollectiveConfigurationModel
        _data.policies.length &&
          _data.policies.forEach((policy: ExternalHealthCollectiveModel) => {
            policy.active && activeArray.push(policy.id)
          })

        return {
          policies: activeArray,
        }
      },
    })

    const _model = model as ExternalHealthCollectiveConfigurationModel

    return { model: _model, getProduct, ...rest }
  }
