import { OreHeading, OreStack } from '@edenredespana/oreneta'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useTranslation } from 'react-i18next'
import { TGDOrderProvider } from './context/TGDOrderProvider'
import { Stepper, TGDOrderStepsData } from '../Edenred/stepper'
import { stepper } from 'src/domain/enum'
import { Outlet } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

export const TGDOrderPageTemplate = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <TGDOrderProvider>
      <OreStack placeContent="normal" space="xlarge">
        <header className="width-max">
          <OreStack placeContent="normal" space="large">
            <OreHeading as="h1" size="title-md">
              {t(TGDOrderTranslation.title)}
            </OreHeading>
            <Stepper
              paddingTop={0}
              paddingBottom={0}
              currentStep={stepper.firstStep}
              stepsFor={TGDOrderStepsData}
              error={window.location.pathname === navigationRoutes.tgdOrderCompleteError}
            />
          </OreStack>
        </header>
        <Outlet />
      </OreStack>
    </TGDOrderProvider>
  )
}
