import { OreText } from '@edenredespana/oreneta'
import React from 'react'

interface Props {
  label: string
  value: string
}

export const ConfigureEmployeeProductRowItem = ({ label, value }: Props) => {
  return (
    <li>
      <OreText size="text-sm">
        {label}
        <span className="bold">{value}</span>
      </OreText>
    </li>
  )
}
