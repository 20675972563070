import { useEffect, useState } from 'react'
import { edenredProducts, queryParam } from '../../../domain/enum'
import { SvgKindergarten, SvgRestaurant, SvgTransport } from '@edenredespana/oreneta'
import { useUser } from 'src/presentation/context/user/UserProvider'
import { useSearchParams } from 'react-router-dom'

interface ContainerState {
  handleTabClick: (index: number) => void
  selectedTab: edenredProducts
  getIconByTab(product: number): JSX.Element
  hasTR: boolean
  hasTT: boolean
  hasTGD: boolean
}

export const useProductTabsController = (
  navigateValue?: edenredProducts
): ContainerState => {
  const { contracts } = useUser()
  const [searchParams, setSearchParams] = useSearchParams()

  const hasTR = !!contracts?.find(
    contract => contract.productCode === edenredProducts.ticketRestaurant
  )
  const hasTT = !!contracts?.find(
    contract => contract.productCode === edenredProducts.ticketTransporte
  )
  const hasTGD = !!contracts?.find(
    contract => contract.productCode === edenredProducts.ticketGuarderia
  )

  const getSelectedByUserContract = (): number => {
    // Check for each product in the desired order and return the first match
    const existTR = contracts?.some(
      contract => contract.productCode === edenredProducts.ticketRestaurant
    )
    if (existTR) return edenredProducts.ticketRestaurant

    const existTT = contracts?.some(
      contract => contract.productCode === edenredProducts.ticketTransporte
    )
    if (existTT) return edenredProducts.ticketTransporte

    const existTGD = contracts?.some(
      contract => contract.productCode === edenredProducts.ticketGuarderia
    )
    if (existTGD) return edenredProducts.ticketGuarderia

    // Default case if none of the products are found
    // You can return a default value or handle as needed
    return edenredProducts.ticketRestaurant // Assuming Ticket Restaurant is the default
  }

  const [selectedTab, setSelectedTab] = useState(getSelectedByUserContract())

  const handleTabClick = (selected: number): void => selectTab(selected)

  const selectTab = (selected: number): void => {
    setSelectedTab(selected)
    searchParams.set(queryParam.product, selected.toString())
    setSearchParams(searchParams)
  }

  const getIconByTab = (product: number): JSX.Element => {
    switch (product) {
      case edenredProducts.ticketTransporte:
        return <SvgTransport />
      case edenredProducts.ticketRestaurant:
        return <SvgRestaurant />
      case edenredProducts.ticketGuarderia:
        return <SvgKindergarten />
      default:
        return <SvgRestaurant />
    }
  }

  const updateQueryParam = (): void => {
    const productParam = searchParams.get(queryParam.product)
    if (productParam) {
      const product = Number(productParam)
      switch (product) {
        case edenredProducts.ticketRestaurant:
          setSelectedTab(edenredProducts.ticketRestaurant)
          break
        case edenredProducts.ticketTransporte:
          setSelectedTab(edenredProducts.ticketTransporte)
          break
        case edenredProducts.ticketGuarderia:
          setSelectedTab(edenredProducts.ticketGuarderia)
          break
        default:
          selectTab(getSelectedByUserContract())
          break
      }
    }
    if (!productParam) {
      selectTab(getSelectedByUserContract())
    }
  }

  useEffect(() => {
    if (navigateValue) setSelectedTab(navigateValue)
  }, [navigateValue])

  useEffect(() => {
    if (contracts && contracts.length > 0) {
      const productParam = searchParams.get(queryParam.product)
      if (!productParam) {
        selectTab(getSelectedByUserContract())
        return
      }
      updateQueryParam()
    }
  }, [contracts])

  return { handleTabClick, selectedTab, getIconByTab, hasTR, hasTT, hasTGD }
}
