import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Dispatch, SetStateAction, useEffect, useState, KeyboardEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderModel } from 'src/domain/customComponents/table'
import { browserStorageKeys, edenredProducts } from 'src/domain/enum'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { EmployeesService } from 'src/core/services/employeesService'
import { EmployeesNurseryModel, EmployeesNurseryRequest } from 'src/domain/models'
import { useNavigate } from 'react-router-dom'
import {
  TGDChildModel,
  TGDEmployeeAndChildsModel,
} from 'src/domain/models/TGDOrder/TGDOrderModel'
import { tgdOrderType } from 'src/domain/enum/tgdOrder'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { createOrderId } from 'src/core/helpers'
import { useTGDOrder } from '../../context/TGDOrderProvider'

export interface TGDSingleEmployeeState {
  header: HeaderModel
  totalRows: number
  employeesTGD: EmployeesNurseryModel[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  search(filter: EmployeesNurseryRequest): void
  onEnter: (event: KeyboardEvent<HTMLInputElement>) => void
  form: UseFormReturn<EmployeesNurseryRequest, any>
  onSearch: () => void
  selectedEmployee: EmployeesNurseryModel | undefined
  setSelectedEmployee: Dispatch<SetStateAction<EmployeesNurseryModel | undefined>>
  addChild(employee?: EmployeesNurseryModel): void
}

export const useTGDOrderSelectSingleEmployee = (): TGDSingleEmployeeState => {
  const { t } = useTranslation()
  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState(1)
  const pageSize = 8
  const { startLoading, stopLoading } = useLoader()
  const [employeesTGD, setEmployeesTGD] = useState<EmployeesNurseryModel[]>([])
  const [selectedEmployee, setSelectedEmployee] = useState<EmployeesNurseryModel>()
  const navigate = useNavigate()
  const { tgdOrders } = useTGDOrder()

  const defaultValues: EmployeesNurseryRequest = {
    productCode: edenredProducts.ticketGuarderia,
    userNameOrDNIFilter: '',
    paginationModel: {
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    },
  }

  const [query, setQuery] = useState<EmployeesNurseryRequest>({ ...defaultValues })

  const header: HeaderModel = {
    headerLabel: [
      { label: t(employeesTranslation.table.header.titular) },
      { label: t(employeesTranslation.table.header.employeeNumber) },
      { label: t(employeesTranslation.table.header.childs) },
      { label: t(employeesTranslation.table.header.kindergartenAssigned) },
    ],
  }

  const getEmployees = (): void => {
    startLoading()
    EmployeesService()
      .GetEmployeesNursery(query)
      .then(response => {
        const employeesNotAddedToOrder = response.data.rows.filter(emp => {
          return !tgdOrders.some(order => order.userId === emp.userId)
        })
        setEmployeesTGD(employeesNotAddedToOrder)
        setTotalRows(response.data.totalRows - tgdOrders.length)
      })
      .finally(() => stopLoading())
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...query,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
    setPage(page)
  }

  const search = (filters: EmployeesNurseryRequest): void => {
    setQuery({
      ...filters,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: 0,
      },
    })
    setPage(1)
  }

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSearch()
    }
  }

  const formSchema = Yup.object({
    productCode: Yup.number(),
    userNameOrDNIFilter: Yup.string(),
  })

  const form = useForm<EmployeesNurseryRequest>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      productCode: edenredProducts.ticketGuarderia,
      userNameOrDNIFilter: '',
    },
  })

  const onSearch = (): void => {
    const filters: EmployeesNurseryRequest = {
      productCode: edenredProducts.ticketGuarderia,
      userNameOrDNIFilter: form.getValues('userNameOrDNIFilter'),
    }
    search(filters)
  }

  const addChild = (employee?: EmployeesNurseryModel): void => {
    if (!employee) return

    const childs: TGDChildModel[] = []
    employee.userSons?.forEach(child => {
      childs.push({
        firstName: child.userFirstName,
        lastName: child.userLastName,
        lastName2: child.userLastName2,
        birthDate: child.userBirthdayDate,
        kindergartenId: child.lastNurseryId,
        firstName2: '',
      })
    })
    const employeeData: TGDEmployeeAndChildsModel = {
      userId: employee.userId,
      birthDate: employee.userBirthdayDate,
      costCenter: employee.costCenter,
      document: employee.userDni,
      documentTypeId: employee.documentTypeId.toString(),
      email: employee.userEmail,
      employeeNumber: employee.employeeNumber,
      firstSurname: employee.userLastName,
      secondSurname: employee.userLastName2,
      name: employee.userFirstName,
      telephone: employee.userTelephone,
      existingChilds: childs,
      childs: [],
      companyId: 0,
      tgdOrderType: tgdOrderType.childOnly,
      id: createOrderId(),
    }

    sessionStorage.setItem(browserStorageKeys.tgdOrder, JSON.stringify(employeeData))

    navigate(navigationRoutes.tgdOrderChildForm)
  }

  useEffect(() => {
    getEmployees()
  }, [query])

  return {
    header,
    totalRows,
    employeesTGD,
    page,
    setPage,
    pageSize,
    onPageChange,
    search,
    onEnter,
    form,
    onSearch,
    selectedEmployee,
    setSelectedEmployee,
    addChild,
  }
}
