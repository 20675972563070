import { ChangeEvent, forwardRef, useState } from 'react'
import { ChangeHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { OreDropZone, OreText } from '@edenredespana/oreneta'
import { readableFileTypes, dropZoneTranslations } from 'src/Flex/Shared/ui/DropZone'

type DropZoneProps = {
  validFileFormats: string | string[]
  hasError?: boolean
  errorMessage?: string
  onChange?: ChangeHandler
  onBlur?: ChangeHandler
  name?: string
}

export const DropZone = forwardRef<HTMLInputElement, DropZoneProps>(
  (
    { validFileFormats, hasError, errorMessage, onChange, ...rest }: DropZoneProps,
    ref
  ): JSX.Element => {
    const { t } = useTranslation()
    const [hasFile, setHasFile] = useState<boolean>(false)

    const handleOnRemove = () => setHasFile(false)

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
      setHasFile(event.target.value !== '')

      onChange && onChange(event)
    }

    const helpText = !hasFile ? (
      <>
        <OreText as="span" tone="cobalt">
          {t(dropZoneTranslations.select)}
        </OreText>
        <OreText as="span">{t(dropZoneTranslations.drag)}</OreText>
      </>
    ) : (
      <>
        <OreText as="span" tone="cobalt">
          {t(dropZoneTranslations.change)}
        </OreText>
        <OreText as="span">{t(dropZoneTranslations.dragNew)}</OreText>
      </>
    )

    const accept = Array.isArray(validFileFormats)
      ? validFileFormats.join(',')
      : validFileFormats

    return (
      <OreDropZone
        {...rest}
        onChange={handleOnChange}
        ref={ref}
        accept={accept}
        onRemove={handleOnRemove}
        dropText={helpText}
        hasError={hasError}
        errorMessage={errorMessage}
        deleteFileText={t(dropZoneTranslations.delete)}
        errorUploadMessage={t(dropZoneTranslations.error)}
        supportsText={t(dropZoneTranslations.format, {
          formato: readableFileTypes(validFileFormats).join(
            t(dropZoneTranslations.formatOr)
          ),
        })}
        validMessage={t(dropZoneTranslations.valid)}
      />
    )
  }
)
