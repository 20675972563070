import {
  OreIcon,
  SvgKindergarten,
  SvgRestaurant,
  SvgTransport,
} from '@edenredespana/oreneta'
import { edenredProducts } from 'src/domain/enum'

interface State {
  GetProductIconByCode(code: number): JSX.Element
}

export const useInvoiceSharedController = (): State => {
  const GetProductIconByCode = (code: number): JSX.Element => {
    let productIcon: JSX.Element = (
      <OreIcon size="extra-small" icon={<SvgRestaurant />} tone="restaurant" />
    )
    switch (code) {
      case edenredProducts.ticketRestaurant:
        productIcon = (
          <OreIcon size="extra-small" icon={<SvgRestaurant />} tone="restaurant" />
        )
        break
      case edenredProducts.ticketTransporte:
        productIcon = (
          <OreIcon size="extra-small" icon={<SvgTransport />} tone="transport" />
        )
        break
      case edenredProducts.ticketGuarderia:
        productIcon = (
          <OreIcon size="extra-small" icon={<SvgKindergarten />} tone="kindergarten" />
        )
        break
      default:
        productIcon
        break
    }
    return productIcon
  }

  return { GetProductIconByCode }
}
