import { Trans, useTranslation } from 'react-i18next'
import {
  OreButton,
  OreStack,
  OreTableCell,
  OreTableRow,
  OreWysiwyg,
  SvgDownload,
  SvgRepeat,
} from '@edenredespana/oreneta'
import {
  useExcelUpload,
  ErrorKindPill,
  ExcelLayout,
} from 'src/Flex/Employees/ui/employee-excel'
import { SimpleTable } from 'src/Flex/Shared/ui/Table'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'

export const ExcelUploaderErrors = (): JSX.Element => {
  const { t } = useTranslation()
  const { uploadResponse, goToResumee, downloadErrorsFile, clearErrors, goToEmployees } =
    useExcelUpload()

  return (
    <ExcelLayout
      onCancel={goToEmployees}
      onContinue={goToResumee}
      continueLabel={t(flexEmployeesTranslations.excel.upload.errors.label)}
      continueDisabled={uploadResponse?.hasErrors}>
      <OreStack space="medium" placeItems="start">
        <OreWysiwyg>
          <p>
            <strong>{t(flexEmployeesTranslations.excel.upload.errors.title)}</strong>
          </p>

          <p>{t(flexEmployeesTranslations.excel.upload.errors.subtitle)}</p>

          <ul>
            <li>
              <Trans t={t}>{flexEmployeesTranslations.excel.upload.errors.list1}</Trans>
            </li>
            <li>
              <Trans t={t}>{flexEmployeesTranslations.excel.upload.errors.list2}</Trans>
            </li>
          </ul>
        </OreWysiwyg>
      </OreStack>
      <SimpleTable
        tableHeaders={[
          {
            key: 'title',
            label: t(flexEmployeesTranslations.excel.upload.errors.table.header.title),
          },
          {
            key: 'kind',
            label: t(flexEmployeesTranslations.excel.upload.errors.table.header.kind),
            size: 'quarter',
          },
          {
            key: 'description',
            label: t(
              flexEmployeesTranslations.excel.upload.errors.table.header.description
            ),
            size: 'half',
          },
        ]}
        rows={uploadResponse?.errors ?? []}
        renderRow={(row, index) => (
          <OreTableRow key={index}>
            <OreTableCell>{row.row}</OreTableCell>
            <OreTableCell>
              <ErrorKindPill kind={row.kind} />
            </OreTableCell>
            <OreTableCell>{row.message}</OreTableCell>
          </OreTableRow>
        )}
        title={t(flexEmployeesTranslations.excel.upload.errors.table.title)}
        withPagination
      />
      <OreStack direction="row" space="medium">
        {uploadResponse?.excelWithErrors ? (
          <OreButton
            icon={<SvgDownload />}
            category="secondary"
            onClick={downloadErrorsFile}>
            {t(flexEmployeesTranslations.excel.upload.errors.button1)}
          </OreButton>
        ) : null}
        <OreButton icon={<SvgRepeat />} category="secondary" onClick={clearErrors}>
          {t(flexEmployeesTranslations.excel.upload.errors.button2)}
        </OreButton>
      </OreStack>
    </ExcelLayout>
  )
}
