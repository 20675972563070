import { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { OreButton, OreSelect, OreStack, SvgPlus } from '@edenredespana/oreneta'
import { ComboBoxOptionModel } from 'src/domain/customComponents'
import { cardOrderFormTranslation } from 'src/domain/translations'
import { Modal } from 'src/presentation/components/Edenred'
import {
  useFormController,
  useShipmentDetailsController,
} from 'src/presentation/components/cardOrder/cardOrderForm/hooks'
import { Form } from 'src/presentation/components/deliverySite/deliverySiteForm/organism/Form'
import { DeliverySiteSelected } from 'src/presentation/components/cardOrder/cardOrderForm/molecules/DeliverySiteSelected'
import { EmployeeForm } from 'src/Flex/Employees/ui/employee-detail'
import { handleErrors } from 'src/presentation/sharedForms/helpers'

export const ConfigureEmployeesDelivery = (): JSX.Element => {
  const { register, formState, getValues } = useFormContext<EmployeeForm>()
  const { errors } = handleErrors(formState)
  const {
    deliverySitesOptions,
    t,
    onDeliverySiteChange,
    deliverySiteSelected,
    cardOrderModalShow,
    setCardOrderModalShow,
    addDeliverySiteLabel,
    deliverySiteFormParameters,
    onClickDeliverySiteModal,
    setNewDeliverySite,
    changeDeliverySite,
    removeDeliverySiteSelected,
  } = useShipmentDetailsController(true)

  useEffect(() => {
    if (
      deliverySitesOptions.length > 0 &&
      getValues('deliverySiteId') !== 0 &&
      deliverySitesOptions.some((option: ComboBoxOptionModel) =>
        option.options.some(
          (opt: [string, number]) => opt[1] === getValues('deliverySiteId')
        )
      )
    ) {
      changeDeliverySite(getValues('deliverySiteId'))
    }
  }, [deliverySitesOptions])

  return (
    <OreStack space="medium" placeContent="stretch">
      <OreSelect
        {...register('deliverySiteId')}
        {...errors('deliverySiteId')}
        required
        id="deliverySiteId"
        label={t(cardOrderFormTranslation.shipment.deliverySiteSelectLabel)}
        onInput={onDeliverySiteChange}
        value={deliverySiteSelected?.deliveryPointId}>
        {deliverySitesOptions.map((option: ComboBoxOptionModel, index: number) => (
          <optgroup
            key={'optgroup_' + index}
            label={t(option.groupName ? option.groupName : '')}>
            {option.options.map((opt: [string, number], index: number) => (
              <option key={'option_' + index} label={opt[0]} value={opt[1]} />
            ))}
          </optgroup>
        ))}
      </OreSelect>

      <DeliverySiteSelected
        deliverySite={deliverySiteSelected}
        handleDelete={removeDeliverySiteSelected}
        handleEdit={event =>
          onClickDeliverySiteModal(event, 'edit', deliverySiteSelected?.productTypeId)
        }
      />

      <OreStack space="medium" placeContent="start">
        <OreButton
          size="large"
          category="highlight"
          icon={<SvgPlus />}
          onClick={event =>
            onClickDeliverySiteModal(event, 'new', deliverySiteSelected?.productTypeId)
          }>
          {addDeliverySiteLabel.current}
        </OreButton>
      </OreStack>

      <Modal
        handleOnClose={setCardOrderModalShow}
        open={cardOrderModalShow}
        children={
          <Form
            formParameters={deliverySiteFormParameters}
            setShowModal={setCardOrderModalShow}
            formMode="modal"
            setNewDeliverySite={setNewDeliverySite}
          />
        }
        size="large"
      />
    </OreStack>
  )
}
