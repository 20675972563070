import { OreCardContainer, OreHeading } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import type { GeneralTermsServiceModel } from '../../../../domain/models/generalTerms/GeneralTermsModel'
import { generalTermsTranslation } from '../../../../domain/translations/myProducts/generalTerms/generalTermsTranslation'
import { Divider } from '../../../layout'
import { EconomicTermItem } from '../atoms/EconomicTermItem'
import { useEconomicTermsController } from '../hooks/useEconomicTermsController'

interface Props {
  services: GeneralTermsServiceModel[]
}

export const EconomicsTerms = ({ services }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { servicesAlias, translateAlias } = useEconomicTermsController()

  return (
    <OreCardContainer>
      <div className="card-title">
        <OreHeading as="h1" size="headline-md">
          {t(generalTermsTranslation.economicTerms.title)}
        </OreHeading>
      </div>
      <Divider marginTop="1.5rem" marginBottom="1rem" />
      <div className="card-container">
        {servicesAlias.map((alias: string, index: number) => (
          <React.Fragment key={alias + index}>
            <div className="mb-1 mt-105">
              <OreHeading as="h1" size="headline-sm">
                {translateAlias(alias)}
              </OreHeading>
            </div>
            {services.map(
              (service: GeneralTermsServiceModel, index: number) =>
                alias === service.alias && (
                  <EconomicTermItem service={service} key={'services_' + index} />
                )
            )}
          </React.Fragment>
        ))}
      </div>
    </OreCardContainer>
  )
}
