import { OreText } from '@edenredespana/oreneta'
import { Seq } from 'immutable'
import type { TextSectionModel } from '../../../../../domain/models'

export const Fail = (props: { sectionText: TextSectionModel[] }): JSX.Element => {
  return (
    <section className="width-max">
      {Seq(props.sectionText).map((text: TextSectionModel, index: number) => (
        <OreText align="center" key={'fail_' + index} className="pt-05">
          {!text.isBold ? (
            text.text
          ) : (
            <strong key={'strong_' + index}>{text.text}</strong>
          )}
        </OreText>
      ))}
    </section>
  )
}
