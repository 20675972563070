import {
  OreCardContainer,
  OreCardWrapper,
  OreDivider,
  OreHeading,
  OreText,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { edenredProducts } from '../../../../../domain/enum'
import { accountSettingsTranslation } from '../../../../../domain/translations/myAccount/accountSettings'
import { useUser } from '../../../../context/user/UserProvider'
import { Grid } from '../../../Edenred/layout'
import { CompanyDataGridItem } from '../atom/CompanyDataItem'

export const CompanyData = (): JSX.Element => {
  const { t } = useTranslation()
  const { contracts } = useUser()
  const currentContract = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketRestaurant
  )
  return (
    <OreCardContainer>
      <OreCardWrapper space="large">
        <OreHeading as="h1" size="headline-sm">
          {t(accountSettingsTranslation.companyData.title)}
        </OreHeading>

        <OreDivider space="medium" />

        <div className="card-container">
          <Grid columnGap="3rem">
            <CompanyDataGridItem
              title={t(accountSettingsTranslation.companyData.company)}
              description={currentContract?.clientName}
            />
            <CompanyDataGridItem
              title={t(accountSettingsTranslation.companyData.code)}
              description={currentContract?.clientCode}
            />
            <CompanyDataGridItem
              title={t(accountSettingsTranslation.companyData.CIF)}
              description={currentContract?.clientCIF}
            />
            <CompanyDataGridItem
              title={t(accountSettingsTranslation.companyData.address)}
              description={currentContract?.clientStreetNameAddress.concat(
                ' ' + currentContract?.clientStreetNumberAddress
              )}
            />
            <CompanyDataGridItem
              title={t(accountSettingsTranslation.companyData.phone)}
              description={currentContract?.clientTelephone}
            />
          </Grid>
        </div>
        <div className="ml-105 mb-3 mt-105">
          <OreText size="text-md" tone="neutral-500">
            {t(accountSettingsTranslation.companyData.description)}
            <span className="underline">
              {t(accountSettingsTranslation.companyData.orderEmail)}
            </span>
          </OreText>
        </div>
      </OreCardWrapper>
    </OreCardContainer>
  )
}
