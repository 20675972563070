import {
  OrePagination,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTableSortLabel,
  OreText,
  OreTooltip,
} from '@edenredespana/oreneta'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { duplicatedTranslation, forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { TTMultipleEmployeeState } from '../hooks/useTTUnloadSelectMultipleEmployees'
import { EmployeesWithLastCard } from 'src/domain/models'
import { useTTOrder } from '../../context/TTOrderProvider'
import { TTUnloadSelectMultipleEmployeesRow } from '../atoms/TTUnloadSelectMultipleEmployeesRow'
import { TTUnloadSelectMultipleEmployeesCaption } from '../atoms/TTUnloadSelectMultipleEmployeesCaption'
import { Grid } from 'src/presentation/components/Edenred/layout'

interface Props {
  employeesTTProps: TTMultipleEmployeeState
}

export const TTUnloadSelectMultipleEmployeesList = ({
  employeesTTProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    totalRows,
    header,
    employeesTT,
    onPageChange,
    page,
    pageSize,
    selectedEmployees,
    setSelectedEmployees,
    onSelectEmployee,
    isSelected,
    showSelected,
    isInContext,
  } = employeesTTProps

  const { ttOrders } = useTTOrder()

  return (
    <>
      <OreTableContainer>
        <div className="bg-white">
          <TTUnloadSelectMultipleEmployeesCaption employeeTTProps={employeesTTProps} />
        </div>
        <OreTable>
          <OreTableHead>
            <OreTableRow>
              {header.headerElement && (
                <OreTableCell as="th">{header.headerElement}</OreTableCell>
              )}
              {header.headerLabel.map((header, index) =>
                header.isSortable && !showSelected ? (
                  <OreTableCell as="th" key={'lbl' + index}>
                    <OreTableSortLabel
                      active
                      direction={header.sortDirection}
                      onClick={header.onClick}>
                      {header.label}&nbsp;
                      {header.tooltip && (
                        <OreTooltip
                          placement="top"
                          separation="small"
                          text={header.tooltip}>
                          <span>{header.tooltipIcon}</span>
                        </OreTooltip>
                      )}
                    </OreTableSortLabel>
                  </OreTableCell>
                ) : (
                  <OreTableCell as="th" key={'lbl' + index}>
                    {header.label}&nbsp;
                    {header.tooltip && (
                      <OreTooltip
                        placement="top"
                        separation="small"
                        text={header.tooltip}>
                        <span>{header.tooltipIcon}</span>
                      </OreTooltip>
                    )}
                  </OreTableCell>
                )
              )}
            </OreTableRow>
          </OreTableHead>
          <OreTableBody as="tbody" valign="top">
            {employeesTT && employeesTT.length <= 0 ? (
              <TableEmpty colSpan={7} firstText={t(forms.table.emptyMessage)} />
            ) : showSelected && selectedEmployees.length > 0 ? (
              selectedEmployees
                .slice((page - 1) * pageSize, page * pageSize)
                .map((employeeTT: EmployeesWithLastCard, index: number) => {
                  if (
                    !ttOrders.find(emp => emp.userId.toString() === employeeTT.userId)
                  ) {
                    return (
                      <TTUnloadSelectMultipleEmployeesRow
                        key={'rows' + index}
                        employeeTT={employeeTT}
                        selectedEmployees={selectedEmployees}
                        setSelectedEmployees={setSelectedEmployees}
                        isSelected={isSelected}
                        onSelectEmployee={onSelectEmployee}
                        isInContext={isInContext}
                      />
                    )
                  }
                })
            ) : (
              employeesTT.map((employeeTT: EmployeesWithLastCard, index: number) => {
                return (
                  <TTUnloadSelectMultipleEmployeesRow
                    key={'rows' + index}
                    employeeTT={employeeTT}
                    selectedEmployees={selectedEmployees}
                    setSelectedEmployees={setSelectedEmployees}
                    isSelected={isSelected}
                    onSelectEmployee={onSelectEmployee}
                    isInContext={isInContext}
                  />
                )
              })
            )}
          </OreTableBody>
        </OreTable>
        <OreTableActions>
          <Grid gap="2rem">
            <OreText as="span" size="text-sm" tone="neutral">
              {selectedEmployees.length}{' '}
              {selectedEmployees.length == 1
                ? t(duplicatedTranslation.selectedRowsDescriptionOne)
                : t(duplicatedTranslation.selectedRowsDescriptionOthers)}
            </OreText>
          </Grid>
          <OrePagination
            count={showSelected ? selectedEmployees.length : totalRows}
            labelNextPage={t(forms.pagination.next)}
            labelPreviousPage={t(forms.pagination.back)}
            onPageChange={onPageChange}
            page={page}
            rowsPerPage={pageSize}
            text={t(forms.pagination.of)}
          />
        </OreTableActions>
      </OreTableContainer>
    </>
  )
}
