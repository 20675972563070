import {
  OreButton,
  OreHeading,
  OreInput,
  OreSwitch,
  OreText,
} from '@edenredespana/oreneta'
import { FormProvider } from 'react-hook-form'
import { duplicatedTranslation, forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { TTMultipleEmployeeState } from '../hooks/useTTUnloadSelectMultipleEmployees'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'

interface Props {
  employeeTTProps: TTMultipleEmployeeState
}

export const TTUnloadSelectMultipleEmployeesCaption = ({
  employeeTTProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    form,
    onEnter,
    onSearch,
    setPage,
    setShowSelected,
    totalRows,
    showSelected,
    selectedEmployees,
  } = employeeTTProps

  return (
    <FormProvider {...form}>
      <form autoComplete="off" onSubmit={e => e.preventDefault()}>
        <div className="bg-white px-1 pb-2">
          <Grid items="center" columnGap="1rem" className="py-105">
            <OreHeading size="headline-md">
              {t(TTOrderTranslation.selectMultipleEmployees.title)}
            </OreHeading>
            <OreText tone="neutral-500" size="text-sm">
              {totalRows + ' ' + t(duplicatedTranslation.rowsTotalDescription)}
            </OreText>
          </Grid>
          <Grid justify="space-between" items="center" gap="1rem">
            <Grid items="center" gap="1.5rem" className="width-60">
              <OreInput
                {...form.register('userNameOrDNIFilter')}
                id="userNameOrDNIFilter"
                placeholder={t(employeesTranslation.filter.textBox)}
                name="userNameOrDNIFilter"
                type="search"
                onKeyUp={e => onEnter(e)}
              />
              <OreButton as="button" category="primary" onClick={onSearch} size="small">
                {t(forms.buttons.search)}
              </OreButton>
            </Grid>
            <OreSwitch
              label={t(duplicatedTranslation.showSelected)}
              name="showSelected"
              onChange={event => {
                setShowSelected(event.target.checked)
                setPage(1)
                if (!event.target.checked) onSearch()
              }}
              checked={showSelected}
              disabled={selectedEmployees.length <= 0}
            />
          </Grid>
        </div>
      </form>
    </FormProvider>
  )
}
