import {
  OreIconButton,
  OreInput,
  OreStack,
  OreText,
  SvgOut,
} from '@edenredespana/oreneta'
import React, { SetStateAction } from 'react'
import DragDots from '../../../../../presentation/assets/img/dragDots.svg'
import {
  PayrollColumnEnum,
  PayrollColumnNameEnum,
  PayrollCustomModel,
} from 'src/Flex/Payrolls/domain/PayrollCustomModel'
import { forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { flexPayrollsTranslations } from '../../translations'

interface Props {
  index: number
  item: PayrollCustomModel | undefined
  isDragging: boolean
  columnName: number
  setItemsList: (value: SetStateAction<PayrollCustomModel[]>) => void
  removeNewItem: (itemId: string) => void
}

export const DropItem = ({
  index,
  item,
  isDragging,
  columnName,
  setItemsList,
  removeNewItem,
}: Props) => {
  const { t } = useTranslation()
  const handleInputChange = (e: any) => {
    setItemsList(prevItems =>
      prevItems.map(prevItem =>
        prevItem.id === item?.id ? { ...prevItem, customName: e.target.value } : prevItem
      )
    )
  }
  return (
    <div style={{ margin: '0 1rem 1rem 1rem' }}>
      <OreStack direction="row" space="xsmall" placeContent="normal">
        {columnName === PayrollColumnNameEnum.MY_PAYROLL && !isDragging && (
          <OreStack placeItems="center" placeContent="center">
            <OreText>{index + 1 <= 9 ? `0${index + 1}` : index + 1}</OreText>
          </OreStack>
        )}

        <div
          style={{
            fontSize: '15px',
            backgroundColor: '#fff',
            boxShadow:
              columnName === PayrollColumnNameEnum.MY_PAYROLL
                ? '0px 0px 0px 0px #cbcbcbcc, 3px 3px 7px -2px #cbcbcbcc'
                : '',
            color: '#000',
            padding: '10px',
            cursor: 'grab',
            borderRadius: '0.5rem',
            wordWrap: 'break-word',
            WebkitUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            opacity: columnName === PayrollColumnNameEnum.HIDE_ITEMS ? 0.8 : 1,
          }}>
          <OreStack direction="row" placeItems="center" placeContent="stretch">
            <img src={DragDots} alt="" />
            <OreStack
              direction="column"
              placeItems="normal"
              space="xsmall"
              placeContent="normal">
              {item?.standardId === PayrollColumnEnum.STANDARD_ID ? (
                <OreStack direction="row" placeContent="space-between">
                  <OreText>
                    {t(flexPayrollsTranslations.customPayroll.newItemName)}
                  </OreText>
                  <OreIconButton
                    icon={<SvgOut />}
                    onClick={() => removeNewItem(item.id)}
                  />
                </OreStack>
              ) : (
                <OreText>{item?.name}</OreText>
              )}
              <OreInput
                id={item?.id}
                name={item?.id}
                value={item?.customName}
                placeholder={t(forms.placeholders.rename)}
                disabled={columnName === PayrollColumnNameEnum.HIDE_ITEMS}
                onChange={handleInputChange}
                maxLength={50}
              />
            </OreStack>
          </OreStack>
        </div>
      </OreStack>
    </div>
  )
}
