import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreDivider,
  OreHeading,
  OreMessageBar,
  OreStack,
  OreStepper,
} from '@edenredespana/oreneta'
import { useExcelUpload } from 'src/Flex/Employees/ui/employee-excel'
import { PageWrapper } from 'src/presentation/layout'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'

type ExcelLayoutProps = PropsWithChildren & {
  onCancel?: () => void
  onContinue?: () => void
  continueDisabled?: boolean
  continueLabel?: string
}

export const ExcelLayout = ({
  onCancel,
  onContinue,
  continueDisabled,
  continueLabel,
  children,
}: ExcelLayoutProps): JSX.Element => {
  const { t } = useTranslation()
  const { step, error } = useExcelUpload()

  if (!step) return <></>

  return (
    <PageWrapper>
      <OreStack space="xlarge" placeContent="stretch">
        <OreStack space="large" placeContent="stretch">
          <OreHeading as="h1" size="title-md">
            {t(flexEmployeesTranslations.excel.layout.title)}
          </OreHeading>
          <OreStepper
            activeStep={step}
            steps={[
              t(flexEmployeesTranslations.excel.layout.step1),
              t(flexEmployeesTranslations.excel.layout.step2),
              t(flexEmployeesTranslations.excel.layout.step3),
            ]}
          />
        </OreStack>
        {children}
      </OreStack>
      {onCancel || onContinue ? (
        <>
          <OreDivider space="larger-top" />
          <OreStack space="xlarge" placeContent="stretch">
            {error && (
              <div>
                <OreMessageBar color="error" icon>
                  {error.description}
                </OreMessageBar>
              </div>
            )}
            <OreStack space="medium" direction="row" placeContent="end">
              {onCancel ? (
                <OreButton type="submit" category="secondary" onClick={onCancel}>
                  {t(flexEmployeesTranslations.excel.layout.button.cancel)}
                </OreButton>
              ) : null}
              {onContinue ? (
                <OreButton
                  type="submit"
                  category="primary"
                  disabled={continueDisabled}
                  onClick={onContinue}>
                  {continueLabel}
                </OreButton>
              ) : null}
            </OreStack>
          </OreStack>
        </>
      ) : null}
    </PageWrapper>
  )
}
