import { Modal } from 'src/presentation/components/Edenred'
import { useTGDOrderImportExcel } from '../hooks/useTGDOrderImportExcel'
import { TGDOrderImportForm } from '../molecules/TGDOrderImportForm'
import { TGDOrderStoppersAndWarnings } from '../molecules/TGDOrderStoppersAndWarnings'
import { OreHeading, OreSpinner, OreText } from '@edenredespana/oreneta'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useTranslation } from 'react-i18next'
import { cardOrderTranslation } from 'src/domain/translations'

export const TGDOrderImportExcelContainer = (): JSX.Element => {
  const props = useTGDOrderImportExcel()
  const { t } = useTranslation()

  return (
    <>
      {props.hasStoppersOrWarnings ? (
        <TGDOrderStoppersAndWarnings props={props} />
      ) : (
        <TGDOrderImportForm props={props} />
      )}
      <Modal
        handleOnClose={() => true}
        closeIcon={false}
        open={props.showWaitingModal}
        topChildren={
          <div className="flex justify-center">
            <OreSpinner />
          </div>
        }>
        <>
          <OreHeading size="headline-lg" align="center">
            {t(cardOrderTranslation.excel.validationTitle)}
          </OreHeading>
          <OreText align="center">{t(cardOrderTranslation.excel.validationBody)}</OreText>
        </>
      </Modal>
      <Modal
        handleOnClose={props.setShowCancelModal}
        open={props.showCancelModal}
        buttons={props.cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">
            {t(TGDOrderTranslation.form.child.cancelModalTitle)}
          </OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {t(TGDOrderTranslation.form.child.cancelModalDescription)}
          </OreText>
        </>
      </Modal>
    </>
  )
}
