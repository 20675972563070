import { yupResolver } from '@hookform/resolvers/yup'

import { UseFormReturn, useForm } from 'react-hook-form'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useModalController } from 'src/presentation/components/Edenred'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import {
  useExcelFileValidation,
  useValidationModal,
} from 'src/presentation/components/cardOrder/cardOrderFormExcel'
import { forms } from 'src/domain/translations'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  MetaStatusCodes,
  edenredProducts,
  excelRequestType,
  orderType,
} from 'src/domain/enum'
import { excelService } from 'src/core/services/excelService'
import { MessagesModel } from 'src/domain/models'
import { CardOrderResumeDTO } from 'src/domain/dto'
import { useTTOrder } from '../../../context/TTOrderProvider'
import { TTOrderService } from 'src/core/services/ttOrderService'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'

export type FormTypes = {
  file: FileList
}

export interface TTOrderExcelState {
  showCancelModal: boolean
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel: () => void
  showWaitingModal: boolean
  downloadTemplate: (empty: boolean) => void
  form: UseFormReturn<FormTypes, any>
  checkExcelData: () => Promise<void>
  fileIsLoaded: boolean
  hasStoppersOrWarnings: boolean
  setHasStoppersOrWarnings: Dispatch<SetStateAction<boolean>>
  errorsFile: string
  setErrorsFile: Dispatch<SetStateAction<string>>
  orderExcelAlerts: MessagesModel[]
  setOrderExcelAlerts: Dispatch<SetStateAction<MessagesModel[]>>
  totalRows: number
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  warningsContinue: () => Promise<void>
  getDescriptionHtmlText: (textType: 'text1' | 'text2') => string
}

export const useTTOrderImportExcel = (): TTOrderExcelState => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { startLoading, stopLoading } = useLoader()
  const { schema } = useExcelFileValidation()
  const { showWaitingModal, setShowWaitingModal } = useValidationModal()
  const [fileIsLoaded, setFileIsLoaded] = useState<boolean>(false)
  const [orderExcelAlerts, setOrderExcelAlerts] = useState<MessagesModel[]>([])
  const [errorsFile, setErrorsFile] = useState<string>('')
  const [hasStoppersOrWarnings, setHasStoppersOrWarnings] = useState<boolean>(false)
  const { addTTOrderCheckout, orderContext } = useTTOrder()
  const {
    show: showCancelModal,
    buttons: cancelPopupButtons,
    setShow: setShowCancelModal,
    setButtons: setCancelModalButtons,
  } = useModalController()

  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState(1)
  const pageSize = 8

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const form = useForm<FormTypes>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const onClickCancel = (): void => {
    setShowCancelModal(true)
    setCancelModalButtons(getCancelModalButtons())
  }

  const getDescriptionHtmlText = (textType: 'text1' | 'text2'): string => {
    const translations = {
      [orderType.Recharge]: {
        text1: TTOrderTranslation.excel.import.download.recharge.textHtml1,
        text2: TTOrderTranslation.excel.import.download.recharge.textHtml2,
      },
      [orderType.Unload]: {
        text1: TTOrderTranslation.excel.import.download.unload.textHtml1,
        text2: TTOrderTranslation.excel.import.download.unload.textHtml2,
      },
    }

    const translation = translations[orderContext]?.[textType]
    return translation ? t(translation) : ''
  }

  const getCancelModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => {
          setShowCancelModal(false)
          navigate(-1)
        },
        size: 'large',
      },
    ]
  }

  const downloadTemplate = (empty: boolean): void => {
    let fileName = ''
    switch (orderContext) {
      case orderType.Card:
        fileName = t(TTOrderTranslation.excel.import.download.fileNameNewCard)
        break
      case orderType.Recharge:
        fileName = t(TTOrderTranslation.excel.import.download.fileNameRecharge)
        break
      case orderType.Unload:
        fileName = t(TTOrderTranslation.excel.import.download.fileNameUnload)
        break
      default:
        fileName = t(TTOrderTranslation.excel.import.download.fileNameNewCard)
        break
    }
    startLoading()
    TTOrderService()
      .downloadTTExcelTemplate(orderContext, empty)
      .then(response => {
        const link = document.createElement('a')
        link.href = `data:application/pdf;base64,${response.data}`
        link.download = `${fileName} ${!empty ? 'precargado' : ''}.xlsx`
        link.click()
        link.remove()
      })
      .finally(() => {
        stopLoading()
      })
  }

  const file2Base64 = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result?.toString() || '')
      reader.onerror = error => reject(error)
    })
  }

  const getFile = (fileList: FileList): File | undefined => {
    if (fileList.length === 0) return undefined
    const file = fileList[0]
    return file
  }

  const checkExcelData = async (): Promise<void> => {
    const isFormValid = await form.trigger('file')
    if (isFormValid) {
      const fileList = getFile(form.getValues('file'))
      if (fileList) {
        file2Base64(fileList).then(base64 => {
          base64 = base64.toString().replace(/^data:(.*,)?/, '')
          validate(base64)
        })
      }
    }
    return undefined
  }

  const getExcelSummaryURL = (): string => {
    switch (orderContext) {
      case orderType.Card:
        return navigationRoutes.ttOrderExcelSummary
      case orderType.Recharge:
        return navigationRoutes.ttRechargeSummaryExcel
      case orderType.Unload:
        return navigationRoutes.ttUnloadSummaryExcel
      default:
        return navigationRoutes.ttOrderExcelSummary
    }
  }

  const getExcelRequestType = (): number => {
    switch (orderContext) {
      case orderType.Card:
        return excelRequestType.CardsOptionalRecharge
      case orderType.Recharge:
        return excelRequestType.Recharge
      case orderType.Unload:
        return excelRequestType.Unload
      default:
        return excelRequestType.none
    }
  }

  const warningsContinue = async (): Promise<void> => {
    const fileList = getFile(form.getValues('file'))
    if (fileList) {
      file2Base64(fileList).then(base64 => {
        base64 = base64.toString().replace(/^data:(.*,)?/, '')
        validate(base64, false) // Don't stop on warnings
      })
    }
  }

  const validate = (base64: string, stoppers = true) => {
    setErrorsFile('')
    setOrderExcelAlerts([])
    setShowWaitingModal(true)
    const uploadExcelRequest = {
      orderExcelType: getExcelRequestType(),
      productCode: edenredProducts.ticketTransporte,
      excel: base64,
      stopOnWarnings: stoppers,
    }
    excelService()
      .validateOrder(uploadExcelRequest)
      .then(response => {
        setErrorsFile(response.data.additionalData ? response.data.additionalData : '')
        setOrderExcelAlerts(response.meta.messages)
        const containsStoppers = response.meta.messages?.length > 0
        setHasStoppersOrWarnings(containsStoppers)
        if (response.meta.status === MetaStatusCodes.SUCCESS || !stoppers) {
          if (!containsStoppers || !stoppers) {
            const checkout: CardOrderResumeDTO = {
              data: {
                ...response.data.summary,
                additionalData: uploadExcelRequest.excel,
              },
              meta: { ...response.meta },
            }
            addTTOrderCheckout(checkout)
            navigate(getExcelSummaryURL(), {
              state: {
                isExcel: true,
              },
            })
          }
        } else {
          setShowWaitingModal(false)
        }
      })
      .finally(() => setShowWaitingModal(false))
  }

  useEffect(() => {
    setFileIsLoaded(form.formState.isValid)
  }, [form.watch()])

  return {
    showCancelModal,
    setShowCancelModal,
    cancelPopupButtons,
    onClickCancel,
    downloadTemplate,
    form,
    showWaitingModal,
    checkExcelData,
    fileIsLoaded,
    errorsFile,
    orderExcelAlerts,
    totalRows,
    page,
    setPage,
    pageSize,
    onPageChange,
    hasStoppersOrWarnings,
    setHasStoppersOrWarnings,
    setErrorsFile,
    setOrderExcelAlerts,
    warningsContinue,
    getDescriptionHtmlText,
  }
}
