import { OreButton, OreHeading, OreStack, OreText } from '@edenredespana/oreneta'

import Error from '../../../../assets/img/Warning.svg'
import { LogaltyResponseStatus } from '../../domain'

interface LogaltyErrorProps {
  title: string
  description: string
  description2?: React.ReactNode
  buttonLabel?: string
  buttonIcon?: React.ReactElement
  signStatus?: string
  returnRoute?: string
  returnCallback?: () => void
}

export const LogaltyError = ({
  title,
  description,
  description2,
  buttonLabel,
  buttonIcon,
  signStatus,
  returnRoute,
  returnCallback,
}: LogaltyErrorProps): JSX.Element => (
  <OreStack placeContent="center" space="2xlarge">
    <OreStack placeItems="center" space="large">
      <img src={Error} alt="Error" />
      <OreStack space="medium" placeItems="center">
        <OreHeading size="title-sm">{title}</OreHeading>
        <OreStack space="small" placeItems="center">
          <div style={{ maxWidth: '550px' }}>
            <OreText align="center" tone="neutral-900" style={{ whiteSpace: 'pre-line' }}>
              {description}
            </OreText>
          </div>
          {description2 && (
            <div style={{ maxWidth: '600px' }}>
              <OreText
                align="center"
                tone="neutral-900"
                style={{ whiteSpace: 'pre-line' }}>
                {description2}
              </OreText>
            </div>
          )}
        </OreStack>
      </OreStack>
      {buttonLabel ? (
        <OreStack placeItems="center" placeContent="center">
          <OreButton
            category={'primary'}
            icon={buttonIcon}
            onClick={
              returnCallback
                ? (): void => returnCallback()
                : returnRoute
                ? (): string => (window.location.href = returnRoute)
                : (): string => (window.location.href = '/')
            }
            disabled={signStatus === LogaltyResponseStatus.Pending}>
            {buttonLabel}
          </OreButton>
        </OreStack>
      ) : null}
    </OreStack>
  </OreStack>
)
