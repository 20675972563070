import { OreSelect, OreSelectPlaceholder } from '@edenredespana/oreneta'
import { t } from 'i18next'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { memo } from 'react'
import type { ComboBoxOptionModel } from '../../../../../domain/customComponents'
import type { CellControlModel } from '../../../../../domain/customComponents/table/TableModel'
import { useSelectorComboBox } from '../../hooks/table/useSelectorComboBox'

export const SelectorComboBox = memo(
  (props: {
    comboBox: CellControlModel
    selectorState: [string, string][]
    setSelectorState: Dispatch<SetStateAction<[string, string][]>>
  }): JSX.Element => {
    const { onChangeSelector } = useSelectorComboBox(
      props.selectorState,
      props.setSelectorState
    )
    return (
      <OreSelect
        name={props.comboBox.name}
        disabled={props.comboBox.disabled}
        defaultValue={props.comboBox.defaultValue}
        onChange={event => onChangeSelector(event, props.comboBox.name)}
        value={
          Seq(props.selectorState)
            .filter(state => state[0] === props.comboBox.name)
            .get(0)?.[1]
        }
        hasError={props.comboBox.hasError}
        errorMessage={props.comboBox.errorMassage}>
        {props.comboBox.placeHolder && (
          <OreSelectPlaceholder label={props.comboBox.placeHolder} />
        )}
        {props.comboBox.options &&
          Seq(props.comboBox.options).map((option: ComboBoxOptionModel, index: number) =>
            props.comboBox.group ? (
              <optgroup
                key={'optgroup_' + index}
                label={t(option.groupName ? option.groupName : '')}>
                {Seq(option.options).map((option: [string, number], index: number) => (
                  <option
                    key={'option_' + index}
                    label={t(option[0])}
                    value={option[1]}
                  />
                ))}
              </optgroup>
            ) : (
              Seq(option.options).map((option: [string, number], index: number) => (
                <option key={'option_' + index} label={t(option[0])} value={option[1]} />
              ))
            )
          )}
      </OreSelect>
    )
  }
)
