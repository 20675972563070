import { OreButton, OreInput, OreStack } from '@edenredespana/oreneta'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'

const SsoAmazon = (): JSX.Element => {
  interface IFormInput {
    username: string
  }
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<IFormInput>()
  const onSubmit: SubmitHandler<IFormInput> = data => console.log(data)
  const { t } = useTranslation()
  return (
    <>
      <div className="soo-login-container">
        <div className="soo-login">
          <form className="soo-login" onSubmit={handleSubmit(onSubmit)}>
            <OreStack direction="column" placeContent="stretch">
              <h2>{t(forms.titles.login)}</h2>
              <OreInput
                id="collectiveName"
                placeholder={t(forms.placeholders.username)}
                hasError={errors.username ? true : false}
                errorMessage={errors.username?.message}
                {...register('username', {
                  required: t(forms.errors.fieldRequired),
                  maxLength: {
                    value: 50,
                    message: t(forms.errors.usernameMaxLength, { max: '50' }),
                  },
                })}
              />
              <OreButton category="primary" type="submit">
                {t(forms.buttons.loginSoo)}
              </OreButton>
            </OreStack>
          </form>
        </div>
      </div>
    </>
  )
}

export default SsoAmazon
