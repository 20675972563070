import { OreDistributorListItem } from '@edenredespana/oreneta'
import { useNavigate } from 'react-router-dom'

interface DistributorLinkProps {
  title: string
  icon?: JSX.Element
  link?: string
}

const DistributorLink = (props: DistributorLinkProps): JSX.Element => {
  const navigate = useNavigate()

  const handleClick = () => {
    if (props.link) {
      navigate(props.link)
    }
  }

  return (
    <OreDistributorListItem
      title={props.title}
      icon={props.icon}
      handleClick={handleClick}
    />
  )
}

export default DistributorLink
