import {
  OrePagination,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTooltip,
} from '@edenredespana/oreneta'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { EmployeesNurseryModel } from 'src/domain/models'
import { TGDSingleEmployeeState } from '../hooks/useTGDOrderSelectSingleEmployee'
import { TGDSelectSingleEmployeeCaption } from './TGDSelectSingleEmployeeCaption'
import { TGDSelectSingleEmployeeRow } from './TGDSelectSingleEmployeeRow'
import { useState } from 'react'

interface Props {
  employeesTGDProps: TGDSingleEmployeeState
}

export const TGDSelectSingleEmployeeList = ({
  employeesTGDProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    totalRows,
    header,
    employeesTGD,
    onPageChange,
    page,
    pageSize,
    selectedEmployee,
    setSelectedEmployee,
  } = employeesTGDProps

  return (
    <>
      <OreTableContainer>
        <div className="bg-white">
          <TGDSelectSingleEmployeeCaption employeesTGDProps={employeesTGDProps} />
        </div>
        <OreTable>
          <OreTableHead>
            <OreTableRow>
              {header.headerElement && (
                <OreTableCell as="th">{header.headerElement}</OreTableCell>
              )}
              {header.headerLabel.map((header, index) =>
                !header.tooltip ? (
                  <OreTableCell as="th" key={'lbl' + index}>
                    {header.label}
                  </OreTableCell>
                ) : (
                  <OreTableCell as="th" key={'lbl' + index}>
                    {header.label}&nbsp;
                    <OreTooltip placement="top" separation="small" text={header.tooltip}>
                      <span>{header.tooltipIcon}</span>
                    </OreTooltip>
                  </OreTableCell>
                )
              )}
            </OreTableRow>
          </OreTableHead>
          <OreTableBody as="tbody" valign="top">
            {employeesTGD && employeesTGD.length <= 0 ? (
              <TableEmpty colSpan={7} firstText={t(forms.table.emptyMessage)} />
            ) : (
              employeesTGD.map((employeeTGD: EmployeesNurseryModel, index: number) => {
                return (
                  <TGDSelectSingleEmployeeRow
                    key={'rows' + index}
                    employeeTGD={employeeTGD}
                    selectedEmployee={selectedEmployee}
                    setSelectedEmployee={setSelectedEmployee}
                  />
                )
              })
            )}
          </OreTableBody>
        </OreTable>
        <OreTableActions>
          <OrePagination
            count={totalRows}
            labelNextPage={t(forms.pagination.next)}
            labelPreviousPage={t(forms.pagination.back)}
            onPageChange={onPageChange}
            page={page}
            rowsPerPage={pageSize}
            text={t(forms.pagination.of)}
          />
        </OreTableActions>
      </OreTableContainer>
    </>
  )
}
