import { SvgKindergarten, SvgRestaurant, SvgTransport } from '@edenredespana/oreneta'
import { edenredProducts } from '../../../domain/enum'
import { t } from 'i18next'
import { productsTranslate } from '../../../domain/translations/productsTranslate'

interface ProductSharedState {
  GetProductNameByCode(code: number): string
  GetProductIconByCode(code: number): JSX.Element
}

export const useProductsShared = (): ProductSharedState => {
  const GetProductIconByCode = (code: number): JSX.Element => {
    switch (code) {
      case edenredProducts.ticketRestaurant:
        return <SvgRestaurant />
      case edenredProducts.ticketTransporte:
        return <SvgTransport />
      case edenredProducts.ticketGuarderia:
        return <SvgKindergarten />
      default:
        return <SvgRestaurant />
    }
  }

  const GetProductNameByCode = (code: number): string => {
    switch (code) {
      case edenredProducts.ticketRestaurant:
        return t(productsTranslate.ticketRestaurant)
      case edenredProducts.ticketGuarderia:
        return t(productsTranslate.ticketGuarderia)
      case edenredProducts.ticketTransporte:
        return t(productsTranslate.ticketTransporte)
      default:
        return t(productsTranslate.ticketRestaurant)
    }
  }

  return { GetProductIconByCode, GetProductNameByCode }
}
