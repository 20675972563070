import { useTranslation } from 'react-i18next'
import { Table } from '../../../Edenred'
import { useHistorySpending } from '../hooks/useHistorySpending'
import { OreButton, SvgDownload } from '@edenredespana/oreneta'
import { forms } from 'src/domain/translations'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { edenredProducts } from 'src/domain/enum'

interface Props {
  productCode: edenredProducts
}

export const SpendingContainer = ({ productCode }: Props): JSX.Element => {
  const {
    header,
    rows,
    setRows,
    footer,
    tableEmpty,
    filter,
    setFilter,
    onClickFilter,
    downloadExcel,
  } = useHistorySpending({ pageSize: 8, showFilter: true, productCode })

  const { t } = useTranslation()

  return (
    <div>
      <div role="tabpanel" style={{ marginTop: '2rem' }}>
        <Table
          header={header}
          rows={rows}
          setRows={setRows}
          footer={footer}
          tableBodyValign="middle"
          filter={filter}
          setFilter={setFilter}
          onClickFilter={onClickFilter}
          tableEmpty={tableEmpty}
        />
        <div className="mt-2">
          <Grid justify="flex-end">
            <OreButton
              as="button"
              category="primary"
              size="large"
              icon={<SvgDownload />}
              onClick={downloadExcel}>
              {t(forms.buttons.downloadExcel)}
            </OreButton>
          </Grid>
        </div>
      </div>
    </div>
  )
}
