import { OreText } from '@edenredespana/oreneta'
import React from 'react'
import { edenredProducts } from '../../../../domain/enum'
import { Grid } from '../../Edenred/layout'
import { useProductsShared } from '../../hooks/useProductsShared'
import { useGeneralTermsController } from '../hooks/useGeneralTermsController'
import { ContactsTable } from '../molecules/ContactsTable'
import { EconomicsTerms } from '../molecules/EconomicsTerms'
import { GeneralInformationTable } from '../molecules/GeneralInformationTable'
import { PaymentAndInvoiceDataTable } from '../molecules/PaymentAndInvoiceDataTable'

interface Props {
  productCode: number
}

export const GeneralTermsContainer = ({ productCode }: Props): JSX.Element => {
  const { generalTerms } = useGeneralTermsController(productCode)
  return generalTerms ? (
    <>
      <hr className="divider" />
      <Grid columns={2} columnGap="3rem" rowGap="4rem" className="mt-2">
        <PaymentAndInvoiceDataTable generalTerms={generalTerms} />
        <GeneralInformationTable generalTerms={generalTerms} />
      </Grid>
      {generalTerms.contacts && (
        <Grid className="mt-2">
          <ContactsTable generalTerms={generalTerms} />
        </Grid>
      )}
      <Grid className="mt-2">
        <EconomicsTerms services={generalTerms.services} />
      </Grid>
    </>
  ) : (
    <></>
  )
}
