import { Trans, useTranslation } from 'react-i18next'
import {
  OreIconButton,
  OreMessageBar,
  OreStack,
  OreTable,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
  OreText,
  OreWysiwyg,
  SvgDuplicate,
} from '@edenredespana/oreneta'
import {
  OrderValidationSuccess,
  orderValidationsTranslations,
  useOrderValidation,
  OrderValidationError,
} from 'src/Flex/Orders/ui/order-validation'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { ControlSteps } from '../atoms/Steps'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { PaymentModes } from 'src/domain/enum'

export const OrderValidationStepThree = (props: {
  setStepError: Dispatch<SetStateAction<number>>
}): JSX.Element => {
  const { successData, errorData, backToStartFromError, backToHome, excelError } =
    useOrderValidation()
  const { t } = useTranslation()

  useEffect(() => {
    if (errorData) {
      props.setStepError(ControlSteps.StepError)
    } else {
      props.setStepError(ControlSteps.StepSucces)
    }
  }, [errorData, successData])

  return errorData ? (
    <OrderValidationError
      errors={errorData}
      back={backToStartFromError}
      backToHome={backToHome}
      excelError={excelError}
    />
  ) : successData ? (
    <OrderValidationSuccess
      title={t(orderValidationsTranslations.step3.success.title)}
      body={
        <>
          <OreWysiwyg>
            <p style={{ textAlign: 'center' }}>
              <Trans t={t} values={{ email: successData.email }}>
                {orderValidationsTranslations.step3.description_1}
              </Trans>
            </p>
            {successData.paymentMode?.toString() === PaymentModes.wireTransfer &&
              successData.ordersNeedPrepayment && (
                <>
                  <br />
                  <OreMessageBar color="warning" icon>
                    <Trans t={t}>
                      {orderValidationsTranslations.step3.description_transfer_2}
                    </Trans>
                  </OreMessageBar>
                  <br />
                </>
              )}
            <p style={{ textAlign: 'center' }}>
              {t(orderValidationsTranslations.step3.description_2)}
            </p>
          </OreWysiwyg>
        </>
      }
      ending={
        <>
          <OreWysiwyg>
            <ul style={{ listStyle: 'none', textAlign: 'center' }}>
              {successData.deliveryDate ? (
                <li>
                  <Trans
                    t={t}
                    values={{
                      value: successData.deliveryDate.split('-').reverse().join('/'),
                    }}>
                    {orderValidationsTranslations.step3.bullets.first}
                  </Trans>
                </li>
              ) : null}
              <li style={{ marginTop: '.5rem' }}>
                <Trans t={t} values={{ value: successData.totalAmount }}>
                  {orderValidationsTranslations.step3.bullets.second}
                </Trans>
              </li>
              {successData.cardOrderId ? (
                <li style={{ marginTop: '.5rem' }}>
                  <Trans t={t} values={{ value: '#' + successData.cardOrderId }}>
                    {orderValidationsTranslations.step3.bullets.third}
                  </Trans>
                </li>
              ) : null}
              {successData.chargeOrderId ? (
                <li style={{ marginTop: '.5rem' }}>
                  <Trans t={t} values={{ value: '#' + successData.chargeOrderId }}>
                    {orderValidationsTranslations.step3.bullets.fourth}
                  </Trans>
                </li>
              ) : null}
            </ul>
          </OreWysiwyg>
          {successData.iban !== '' &&
          successData.paymentMode?.toString() === PaymentModes.wireTransfer ? (
            <OreStack space="xlarge">
              <OreWysiwyg>
                <OreText align="center">
                  {t(TGDOrderTranslation.complete.successMessage3)}
                </OreText>
              </OreWysiwyg>
              <OreTableContainer>
                <OreTable>
                  <OreTableBody>
                    <OreTableRow hover={false} noBorder={true}>
                      <OreTableCell>
                        <OreStack
                          placeContent="space-between"
                          space="large"
                          direction="row"
                          placeItems="center">
                          <OreText>{t(TGDOrderTranslation.complete.iban)}</OreText>
                          <OreStack
                            placeContent="space-between"
                            space="xsmall"
                            placeItems="center"
                            direction="row">
                            <OreText bold={true}>{successData.iban}</OreText>
                            <OreIconButton
                              as="a"
                              icon={<SvgDuplicate />}
                              onClick={() =>
                                navigator.clipboard.writeText(successData.iban)
                              }
                            />
                          </OreStack>
                        </OreStack>
                      </OreTableCell>
                    </OreTableRow>
                    <OreTableRow hover={false} valign="middle">
                      <OreTableCell>
                        <OreStack
                          placeContent="space-between"
                          space="large"
                          direction="row"
                          placeItems="center">
                          <OreText>{t(TGDOrderTranslation.complete.concept)}</OreText>
                          <OreStack
                            placeContent="space-between"
                            space="xsmall"
                            placeItems="center"
                            direction="row">
                            <OreText bold={true}>{successData.concept}</OreText>
                            <OreIconButton
                              as="a"
                              icon={<SvgDuplicate />}
                              onClick={() =>
                                navigator.clipboard.writeText(successData.concept)
                              }
                            />
                          </OreStack>
                        </OreStack>
                      </OreTableCell>
                    </OreTableRow>
                    <OreTableRow hover={false}>
                      <OreTableCell>
                        <OreStack
                          placeContent="space-between"
                          space="large"
                          direction="row">
                          <OreText>{t(TGDOrderTranslation.complete.totalAmount)}</OreText>
                          <OreText bold>{successData.totalAmount}</OreText>
                        </OreStack>
                      </OreTableCell>
                    </OreTableRow>
                  </OreTableBody>
                </OreTable>
              </OreTableContainer>
              {successData.paymentMode?.toString() === PaymentModes.wireTransfer &&
                !successData.ordersNeedPrepayment && (
                  <OreMessageBar color="warning" icon>
                    <Trans t={t}>{orderValidationsTranslations.step3.prepayment}</Trans>
                  </OreMessageBar>
                )}
            </OreStack>
          ) : null}
        </>
      }
    />
  ) : (
    <></>
  )
}
