import {
  OreButton,
  OreCheckbox,
  OreInput,
  OreTableCell,
  OreTableRow,
  OreText,
  SvgOut,
} from '@edenredespana/oreneta'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { currency } from 'src/domain/enum'
import { forms, unloadsConfigurationTranslations } from 'src/domain/translations'
import { UseFormReturn } from 'react-hook-form'
import { EmployeesTROrderFormModel } from '../hooks/useUnloadConfigurationEmployees'
import { CardOrderModel } from 'src/domain/models'
import { useCardOrder } from 'src/presentation/context/cardOrder/CardOrderProvider'
import { addCurrency } from 'src/core/services'

interface Props {
  employeeTR: CardOrderModel
  onSelectEmployee(event: ChangeEvent<HTMLInputElement>, employee: CardOrderModel): void
  isSelected(employee: CardOrderModel): boolean
  form: UseFormReturn<EmployeesTROrderFormModel, any>
  rowId: number
  page: number
  pageSize: number
  updateDataRowToTROrderEmployee: (
    userId: number,
    initialAmount: string,
    costCenter: string
  ) => void
  unloadAllBalance: (userId?: number) => void
}

export const UnloadConfigurationEmployeesRow = ({
  employeeTR,
  updateDataRowToTROrderEmployee,
  onSelectEmployee,
  isSelected,
  form,
  rowId,
  page,
  pageSize,
  unloadAllBalance,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { getTREmployeesToConfig } = useCardOrder()

  const GetCurrentIndex = (index: number): number => {
    return index + (page - 1) * pageSize
  }

  const setInitialAmountValue = (index: number, amount: number) => {
    form.setValue(`employeesTR.${index}.cardData.initialAmount`, amount)
    employeeTR.cardData.initialAmount = amount
  }
  const setCostCenterValue = (index: number, costCenter: string) => {
    form.setValue(`employeesTR.${index}.employeeData.costCenter`, costCenter)
    employeeTR.employeeData.costCenter = costCenter
  }

  const index = GetCurrentIndex(rowId)

  return (
    <>
      <OreTableRow valign="top">
        {getTREmployeesToConfig().length > 1 && (
          <OreTableCell align="left" as="td" size="auto">
            <OreCheckbox
              name="check"
              onChange={event => onSelectEmployee(event, employeeTR)}
              role="checkbox"
              aria-checked={isSelected(employeeTR)}
              checked={isSelected(employeeTR)}
            />
          </OreTableCell>
        )}
        <OreTableCell align="left" as="td" size="auto">
          <OreText bold size="text-sm" tone="neutral-900">
            {employeeTR.employeeData.name}
          </OreText>
          <OreText size="text-sm" tone="neutral-500">
            {employeeTR.employeeData.document}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText>{addCurrency(employeeTR.cardData.balance)}</OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreInput
            {...form.register(`employeesTR.${index}.cardData.initialAmount`)}
            placeholder={'200.00'}
            required
            startAdornment={currency.euro}
            type="number"
            autoComplete="off"
            hasError={
              !!form.formState.errors?.employeesTR?.[index]?.cardData?.initialAmount
            }
            errorMessage={
              form.formState.errors?.employeesTR?.[index]?.cardData?.initialAmount
                ?.message
            }
            onChange={e => setInitialAmountValue(index, +e.target.value)}
          />
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreInput
            {...form.register(`employeesTR.${index}.employeeData.costCenter`)}
            placeholder={t(forms.placeholders.employee.costCenter)}
            required
            maxLength={50}
            type="text"
            autoComplete="off"
            hasError={
              !!form.formState.errors?.employeesTR?.[index]?.employeeData?.costCenter
            }
            errorMessage={
              form.formState.errors?.employeesTR?.[index]?.employeeData?.costCenter
                ?.message
            }
            onChange={e => setCostCenterValue(index, e.target.value)}
          />
        </OreTableCell>
        <OreTableCell
          align="left"
          as="td"
          size="auto"
          style={{ verticalAlign: 'middle' }}>
          <OreButton
            category="tertiary"
            size="small"
            icon={<SvgOut />}
            onClick={() => unloadAllBalance(employeeTR.employeeData.userId)}>
            {t(unloadsConfigurationTranslations.table.actions.unloadAllBalance)}
          </OreButton>
        </OreTableCell>
      </OreTableRow>
    </>
  )
}
