import { OreInput } from '@edenredespana/oreneta'
import type { Dispatch, SetStateAction } from 'react'
import { memo } from 'react'
import type { CellControlModel } from '../../../../../domain/customComponents/table/TableModel'
import { useSelectorTextBox } from '../../hooks/table/useSelectorTextBox'

export const SelectorTextBox = memo(
  (props: {
    textBox: CellControlModel
    selectorState: [string, string][]
    setSelectorState: Dispatch<SetStateAction<[string, string][]>>
  }) => {
    const { onChangeSelector, GetTextBoxValue } = useSelectorTextBox(
      props.selectorState,
      props.setSelectorState
    )
    return (
      <OreInput
        name={props.textBox.name}
        disabled={props.textBox.disabled}
        defaultValue={props.textBox.defaultValue}
        onChange={event => onChangeSelector(event, props.textBox.name)}
        value={GetTextBoxValue(props.textBox.name)}
        hasError={props.textBox.hasError}
        errorMessage={props.textBox.errorMassage}
        maxLength={props.textBox.maxLength}
        placeholder={props.textBox.placeHolder}
        label={props.textBox.label}
        type={props.textBox.type}
        startAdornment={props.textBox.startAdornment}
      />
    )
  }
)
