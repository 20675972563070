// interface SpendingRulesStripeProps {
//   stripe: ITimeZones
// }

import {
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreCheckbox,
  OreFormGroup,
  OreHeading,
  OreInput,
  OreStack,
  OreText,
  OreTimePicker,
  SvgBin,
} from '@edenredespana/oreneta'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { currency } from 'src/domain/enum'
import { ITimeZones, SpendingRuleModel } from 'src/domain/models'
import { spendingRuleFormTranslation } from 'src/domain/translations'

interface SpendingRulesStripeProps {
  stripeIndex: number
  totalStripes: number
}

export const SpendingRulesStripe = ({
  stripeIndex,
  totalStripes,
}: SpendingRulesStripeProps): JSX.Element => {
  const form = useFormContext<SpendingRuleModel>()
  const formValues = form.watch()
  const { t } = useTranslation()

  const handleDeleteStripe = (): void => {
    const updatedArray = [...formValues.timeZones]
    updatedArray.splice(stripeIndex, 1)
    form.setValue('timeZones', updatedArray)
    form.clearErrors('timeZones')
    form.trigger('timeZones')
  }

  return (
    <OreCardContainer tone="neutral-100" hasShadow={false}>
      <OreCardWrapper>
        <OreStack placeItems="stretch" placeContent="stretch" space="medium">
          <OreHeading size="headline-md">
            {`${t(spendingRuleFormTranslation.form.diet.stripeTitle)} ${stripeIndex + 1}`}
          </OreHeading>

          <OreText>{t(spendingRuleFormTranslation.form.restrictionsLabel)}</OreText>

          <OreFormGroup
            direction="column"
            hasError={
              !!(
                form.formState.errors.timeZones &&
                Array.isArray(form.formState.errors.timeZones) &&
                form.formState.errors.timeZones[stripeIndex]?.weekDays
              )
            }
            errorMessage={
              form.formState.errors.timeZones &&
              Array.isArray(form.formState.errors.timeZones) &&
              form.formState.errors.timeZones[stripeIndex]?.weekDays?.message
            }
            label={t(spendingRuleFormTranslation.form.restrictionsPerDay)}
            required>
            <OreStack direction="row" space="medium">
              <OreCheckbox
                label={t(spendingRuleFormTranslation.form.day.monday)}
                labelCheckIcon={`${t(
                  spendingRuleFormTranslation.form.day.monday
                )} Check Icon`}
                {...form.register(`timeZones.${stripeIndex}.weekDays.monday`)}
              />
              <OreCheckbox
                label={t(spendingRuleFormTranslation.form.day.tuesday)}
                labelCheckIcon={`${t(
                  spendingRuleFormTranslation.form.day.tuesday
                )} Check Icon`}
                {...form.register(`timeZones.${stripeIndex}.weekDays.tuesday`)}
              />
              <OreCheckbox
                label={t(spendingRuleFormTranslation.form.day.wednesday)}
                labelCheckIcon={`${t(
                  spendingRuleFormTranslation.form.day.wednesday
                )} Check Icon`}
                {...form.register(`timeZones.${stripeIndex}.weekDays.wednesday`)}
              />
              <OreCheckbox
                label={t(spendingRuleFormTranslation.form.day.thursday)}
                labelCheckIcon={`${t(
                  spendingRuleFormTranslation.form.day.thursday
                )} Check Icon`}
                {...form.register(`timeZones.${stripeIndex}.weekDays.thursday`)}
              />
              <OreCheckbox
                label={t(spendingRuleFormTranslation.form.day.friday)}
                labelCheckIcon={`${t(
                  spendingRuleFormTranslation.form.day.friday
                )} Check Icon`}
                {...form.register(`timeZones.${stripeIndex}.weekDays.friday`)}
              />
              <OreCheckbox
                label={t(spendingRuleFormTranslation.form.day.saturday)}
                labelCheckIcon={`${t(
                  spendingRuleFormTranslation.form.day.saturday
                )} Check Icon`}
                {...form.register(`timeZones.${stripeIndex}.weekDays.saturday`)}
              />
              <OreCheckbox
                label={t(spendingRuleFormTranslation.form.day.sunday)}
                labelCheckIcon={`${t(
                  spendingRuleFormTranslation.form.day.sunday
                )} Check Icon`}
                {...form.register(`timeZones.${stripeIndex}.weekDays.sunday`)}
              />
            </OreStack>
          </OreFormGroup>

          <OreFormGroup
            direction="column"
            label={t(spendingRuleFormTranslation.form.timeRestrictionLabel)}
            hasError={
              !!(
                form.formState.errors.timeZones &&
                Array.isArray(form.formState.errors.timeZones) &&
                form.formState.errors.timeZones[stripeIndex]?.timeZoneTimeGroup
              )
            }
            errorMessage={
              form.formState.errors.timeZones &&
              Array.isArray(form.formState.errors.timeZones) &&
              form.formState.errors.timeZones[stripeIndex]?.timeZoneTimeGroup?.message
            }>
            <OreStack direction="row" sameSize space="small">
              <OreTimePicker
                id="timeZoneFrom"
                label={t(spendingRuleFormTranslation.form.from)}
                required
                {...form.register(`timeZones.${stripeIndex}.timeZoneFrom`)}
                hasError={
                  !!(
                    form.formState.errors.timeZones &&
                    Array.isArray(form.formState.errors.timeZones) &&
                    form.formState.errors.timeZones[stripeIndex]?.timeZoneFrom
                  )
                }
                errorMessage={
                  form.formState.errors.timeZones &&
                  Array.isArray(form.formState.errors.timeZones) &&
                  form.formState.errors.timeZones[stripeIndex]?.timeZoneFrom?.message
                }
              />
              <OreTimePicker
                id="timeZoneTo"
                label={t(spendingRuleFormTranslation.form.to)}
                required
                {...form.register(`timeZones.${stripeIndex}.timeZoneTo`)}
                hasError={
                  !!(
                    form.formState.errors.timeZones &&
                    Array.isArray(form.formState.errors.timeZones) &&
                    Array.isArray(form.formState.errors.timeZones) &&
                    form.formState.errors.timeZones[stripeIndex]?.timeZoneTo
                  )
                }
                errorMessage={
                  form.formState.errors.timeZones &&
                  Array.isArray(form.formState.errors.timeZones) &&
                  Array.isArray(form.formState.errors.timeZones) &&
                  form.formState.errors.timeZones[stripeIndex]?.timeZoneTo?.message
                }
              />
            </OreStack>
          </OreFormGroup>
          <OreFormGroup direction="row">
            <OreInput
              {...form.register(`timeZones.${stripeIndex}.transactionAmount`)}
              label={t(spendingRuleFormTranslation.form.diet.maxPerStripe)}
              id="yearAmount"
              placeholder="20,00"
              type="number"
              autoComplete="off"
              startAdornment={currency.euro}
              required
              hasError={
                !!(
                  form.formState.errors.timeZones &&
                  Array.isArray(form.formState.errors.timeZones) &&
                  Array.isArray(form.formState.errors.timeZones) &&
                  form.formState.errors.timeZones[stripeIndex]?.transactionAmount
                )
              }
              errorMessage={
                form.formState.errors.timeZones &&
                Array.isArray(form.formState.errors.timeZones) &&
                Array.isArray(form.formState.errors.timeZones) &&
                form.formState.errors.timeZones[stripeIndex]?.transactionAmount?.message
              }
            />
          </OreFormGroup>

          {totalStripes > 1 && (
            <div>
              <OreButton
                category="tertiary"
                icon={<SvgBin />}
                onClick={handleDeleteStripe}>
                {t(spendingRuleFormTranslation.form.diet.deleteStripeButton)}
              </OreButton>
            </div>
          )}
        </OreStack>
      </OreCardWrapper>
    </OreCardContainer>
  )
}
