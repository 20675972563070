import { OreText } from '@edenredespana/oreneta'
import React from 'react'
import { GridItem } from '../../../Edenred/layout'

interface Props {
  title: string
  description?: string | number | undefined
}

export const CompanyDataGridItem = ({ title, description }: Props): JSX.Element => {
  return (
    <GridItem>
      <OreText bold={true} tone="neutral-800" size="text-md">
        {title}
      </OreText>
      <OreText tone="neutral-800" size="text-md">
        {description}
      </OreText>
    </GridItem>
  )
}
