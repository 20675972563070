import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import {
  OreHeading,
  OreInput,
  OreSelect,
  OreSelectPlaceholder,
  OreStack,
} from '@edenredespana/oreneta'
import { CollectiveModel } from 'src/Flex/Collectives/domain'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { EmployeeForm } from 'src/Flex/Employees/ui/employee-detail'
import { EmployeeConfigurationModel } from 'src/Flex/Employees/domain'
import { currency } from 'src/domain/enum'
import { handleErrors } from 'src/presentation/sharedForms/helpers'

interface IProps {
  model?: EmployeeConfigurationModel
  collectives?: CollectiveModel[]
}

export const ConfigureEmployeesRetributionForm = ({ model, collectives }: IProps) => {
  const { t } = useTranslation()
  const { register, formState } = useFormContext<EmployeeForm>()
  const { errors } = handleErrors(formState)

  let collectivesList: {
    label: string
    value: string
  }[] = []

  if (collectives) {
    collectivesList = collectives
      .filter(collective => collective.products.length > 0)
      .map(collective => ({
        value: collective.id,
        label: collective.name,
      }))
  }

  if (model && !collectivesList.find(({ value }) => value === model.collectiveId)) {
    collectivesList.push({
      value: model.collectiveId,
      label: model.collective ?? '',
    })
  }

  return (
    <OreStack placeContent="stretch" space="medium">
      <OreHeading as="h2" size="headline-md">
        {t(flexEmployeesTranslations.configure.common.form.title_retribution)}
      </OreHeading>
      <OreStack space="medium" direction="row" sameSize>
        <OreInput
          {...register('workingPercentage')}
          {...errors('workingPercentage')}
          startAdornment="%"
          id="workingPercentage"
          type="number"
          label={t(
            flexEmployeesTranslations.configure.common.form.workingPercentage.label
          )}
          labelTooltip={t(
            flexEmployeesTranslations.configure.common.form.workingPercentage.tooltip
          )}
          placeholder={t(
            flexEmployeesTranslations.configure.common.form.workingPercentage.placeholder
          )}
          required
        />
        <OreInput
          {...register('annualGrossSalary')}
          {...errors('annualGrossSalary')}
          startAdornment={currency.euro}
          id="annualGrossSalary"
          type="number"
          label={t(flexEmployeesTranslations.configure.common.form.grossSalary.label)}
          labelTooltip={t(
            flexEmployeesTranslations.configure.common.form.grossSalary.tooltip
          )}
          placeholder={t(
            flexEmployeesTranslations.configure.common.form.grossSalary.placeholder
          )}
          required
        />
      </OreStack>
      <OreStack space="medium" direction="row" sameSize>
        <OreInput
          {...register('salaryInKind')}
          {...errors('salaryInKind')}
          startAdornment={currency.euro}
          id="salaryInKind"
          type="number"
          label={t(flexEmployeesTranslations.configure.common.form.kindSalary.label)}
          labelTooltip={t(
            flexEmployeesTranslations.configure.common.form.kindSalary.tooltip
          )}
          placeholder={t(
            flexEmployeesTranslations.configure.common.form.kindSalary.placeholder
          )}
        />
        <OreSelect
          {...register('collectiveId')}
          {...errors('collectiveId')}
          id="collectiveId"
          label={t(flexEmployeesTranslations.configure.common.form.collective.label)}
          legend={t(flexEmployeesTranslations.configure.common.form.collective.legend)}
          required>
          <OreSelectPlaceholder
            label={t(
              flexEmployeesTranslations.configure.common.form.collective.placeholder
            )}
          />
          {collectivesList.map(collective => (
            <option key={collective.value} value={collective.value}>
              {collective.label}
            </option>
          ))}
        </OreSelect>
      </OreStack>
    </OreStack>
  )
}
