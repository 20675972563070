import { Fragment } from 'react'
import type { FieldValues } from 'react-hook-form'
import { OreFormGroup, OreRadioButton, OreTooltip } from '@edenredespana/oreneta'
import { Seq } from 'immutable'
import { get } from 'lodash'
import type {
  RadioButtonGroupModel,
  RadioButtonModel,
} from '../../../domain/customComponents'

export const RadioButton = <TFormValues extends FieldValues>(
  props: RadioButtonGroupModel<TFormValues>
): JSX.Element => {
  const errorMessages = get(props.errors, props.name)
  const hasError = !!(props.errors && errorMessages)
  return (
    <>
      <OreFormGroup
        direction={props.direction}
        label={props.label}
        required={props.required}
        hasError={hasError}
        errorMessage={errorMessages?.message}>
        <div className="flex flex--row">
          {Seq(props.radioButtons).map((radio: RadioButtonModel, index: number) => {
            return (
              <Fragment key={'fragment_' + index}>
                <OreRadioButton
                  {...(props.register && props.register(props.name))}
                  name={props.name}
                  label={radio.label}
                  value={radio.value}
                  onChange={props.onValueChange}
                  checked={props.selectedValue?.toString() === radio.value}
                  disabled={radio.disabled}
                  onKeyDown={radio.onKeyDown}
                />
                {radio.tooltip ? (
                  <OreTooltip
                    text={radio.tooltipText ? radio.tooltipText : ''}
                    placement="top">
                    <span>{radio.tooltipIcon}</span>
                  </OreTooltip>
                ) : (
                  <></>
                )}
              </Fragment>
            )
          })}
        </div>
      </OreFormGroup>
    </>
  )
}
