import { createContext } from 'react'

export interface User {
  cif: string | undefined
  city: string | undefined
  code: string | undefined
  fax: string | undefined
  name: string | undefined
  province: string | undefined
  streetDescription: string | undefined
  streetName: string | undefined
  streetNumber: string | undefined
  streetType: string | undefined
  telephone: string | undefined
  zipCode: string | undefined
  dataControllerAcceptancePending: boolean
}

type AuthContextProps = {
  user: User | undefined
  permissionTags: string[]
  userHomePage: string
  token: string | undefined
  oldToken: string
  isLogged: boolean
  logIn: () => void
  logOut: () => void
}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps)
