import {
  OreDivider,
  OreHeading,
  OreStack,
  OreStepper,
  OreText,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { roleStandarizationTranslation } from 'src/domain/translations/autoenrollment/autoEnrollmentTranslate'
import { useRoleStandarizationController } from '../hooks/useRoleStandarizationController'
import { RoleStandarizationStep1 } from '../molecules/RoleStandarizationStep1'
import { RoleStandarizationStep2 } from '../molecules/RoleStandarizationStep2'
import { RoleStandarizationSteps } from 'src/domain/enum/autoenrollment'
import { useRoleStandarization } from 'src/presentation/context/roleStandarization/RoleStandarizationContext'

const renderCurrentStep = (step: number): JSX.Element => {
  switch (step) {
    case RoleStandarizationSteps.SIGNATURE:
      return <RoleStandarizationStep1 />
    case RoleStandarizationSteps.CONFIRMATION:
      return <RoleStandarizationStep2 />
    default:
      return <RoleStandarizationStep1 />
  }
}

export const RoleStandarizationContainer = (): JSX.Element => {
  const { roleStandarizationStep } = useRoleStandarization()

  console.log('roleStandarizationStep', roleStandarizationStep)

  const { t } = useTranslation()

  return (
    <OreStack placeContent="normal" space="xlarge">
      <OreStack placeContent="normal" space="medium">
        <OreHeading as="h1" size="title-md" tone="neutral">
          {t(roleStandarizationTranslation.acceptancePage.title)}
        </OreHeading>

        {/* <button onClick={() => nextStep()}>Next Step</button> */}

        <OreDivider />

        <OreText align="left" as="p" tone="neutral">
          {t(roleStandarizationTranslation.acceptancePage.description)}
        </OreText>
      </OreStack>

      <OreStepper
        activeStep={roleStandarizationStep}
        steps={[
          t(roleStandarizationTranslation.acceptancePage.stepper.step1),
          t(roleStandarizationTranslation.acceptancePage.stepper.step2),
        ]}
      />

      {renderCurrentStep(roleStandarizationStep)}
    </OreStack>
  )
}
