import {
  OreButton,
  OreHeading,
  OreIconButton,
  OreMessageBar,
  OreStack,
  OreTable,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
  OreText,
  SvgDuplicate,
} from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import SuccessLogo from '../../../../assets/img/Success.svg'
import { cardOrderTranslation, forms } from 'src/domain/translations'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { GetDateFormatted } from 'src/core/helpers'
import { PaymentModes, currency, edenredProducts } from 'src/domain/enum'
import { TTOrderCompleteModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'

interface Props {
  orderCompleteData: TTOrderCompleteModel
}

export const TTOrderCompleteSuccess = ({ orderCompleteData }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const initialChargeDate = orderCompleteData.initialChargeDate
    ? GetDateFormatted(orderCompleteData.initialChargeDate)
    : ''

  const location = useLocation()
  const isUnload: boolean = location.pathname === navigationRoutes.ttUnloadCompleteSuccess

  return (
    <div style={{ margin: '3rem 17% 0 17%' }}>
      <OreStack
        direction="column"
        space="large"
        placeContent="space-between"
        placeItems="center">
        <div>
          <figure>
            <img src={SuccessLogo} alt="" />
          </figure>
        </div>
        <OreStack direction="column" space="medium">
          <header className="width-max">
            <OreHeading as="h1" size="title-sm" align="center">
              {t(cardOrderTranslation.cardOrderComplete.titleSuccess)}
            </OreHeading>
          </header>
          <div>
            <OreText align="center">
              {t(TTOrderTranslation.complete.successMessage1)}
              <b>{orderCompleteData.email}</b>
              {t(TTOrderTranslation.complete.successMessage2)}
            </OreText>
          </div>
          <div>
            <OreText align="center">
              {t(TTOrderTranslation.complete.successMessage3)}
            </OreText>
          </div>
          <div>
            <OreText align="center">
              {t(
                isUnload
                  ? TTOrderTranslation.complete.unloadDate
                  : TTOrderTranslation.complete.orderDate
              )}
              <b>{initialChargeDate}</b>
            </OreText>
            <OreText align="center">
              {t(TTOrderTranslation.complete.totalAmount)}
              <b>{orderCompleteData.totalAmount + currency.euro}</b>
            </OreText>
            {orderCompleteData.cardOrderId && (
              <OreText align="center">
                {t(TTOrderTranslation.complete.totalCards)}
                <b>{`#${orderCompleteData.cardOrderId}`}</b>
              </OreText>
            )}
            {orderCompleteData.chargeOrderId && !isUnload && (
              <OreText align="center">
                {t(TTOrderTranslation.complete.totalRecharges)}
                <b>{`#${orderCompleteData.chargeOrderId}`}</b>
              </OreText>
            )}
            {orderCompleteData.chargeOrderId && isUnload && (
              <OreText align="center">
                {t(TTOrderTranslation.complete.totalUnloads)}
                <b>{`#${orderCompleteData.chargeOrderId}`}</b>
              </OreText>
            )}
          </div>

          {orderCompleteData.chargeOrderId && isUnload && (
            <div>
              <OreText align="center">
                {t(TTOrderTranslation.complete.needDeleteCardsMessage1)}{' '}
                <NavLink
                  to={
                    navigationRoutes.employeesHome +
                    '?product=' +
                    edenredProducts.ticketTransporte
                  }>
                  {t(TTOrderTranslation.complete.needDeleteCardsMessage2)}
                </NavLink>
              </OreText>
            </div>
          )}

          {!isUnload && (
            <OreMessageBar color="warning" icon>
              {t(TTOrderTranslation.complete.important)}
            </OreMessageBar>
          )}

          {orderCompleteData.paymentMode === +PaymentModes.wireTransfer && (
            <div>
              <OreText align="center">
                {t(TTOrderTranslation.complete.successMessage4)}
              </OreText>
            </div>
          )}
        </OreStack>
        {orderCompleteData.paymentMode === +PaymentModes.wireTransfer && (
          <>
            <OreStack>
              <OreTableContainer>
                <OreTable>
                  <OreTableBody>
                    <OreTableRow hover={false} noBorder={true}>
                      <OreTableCell>
                        <OreStack
                          placeContent="space-between"
                          space="large"
                          direction="row"
                          placeItems="center">
                          <OreText>{t(TTOrderTranslation.complete.iban)}</OreText>
                          <OreStack
                            placeContent="space-between"
                            space="xsmall"
                            placeItems="center"
                            direction="row">
                            <OreText bold={true}>{orderCompleteData.iban}</OreText>
                            <OreIconButton
                              as="a"
                              icon={<SvgDuplicate />}
                              onClick={() =>
                                navigator.clipboard.writeText(orderCompleteData.iban)
                              }
                            />
                          </OreStack>
                        </OreStack>
                      </OreTableCell>
                    </OreTableRow>
                    <OreTableRow hover={false} valign="middle">
                      <OreTableCell>
                        <OreStack
                          placeContent="space-between"
                          space="large"
                          direction="row"
                          placeItems="center">
                          <OreText>{t(TTOrderTranslation.complete.concept)}</OreText>
                          <OreStack
                            placeContent="space-between"
                            space="xsmall"
                            placeItems="center"
                            direction="row">
                            <OreText bold={true}>{orderCompleteData.concept}</OreText>
                            <OreIconButton
                              as="a"
                              icon={<SvgDuplicate />}
                              onClick={() =>
                                navigator.clipboard.writeText(orderCompleteData.concept)
                              }
                            />
                          </OreStack>
                        </OreStack>
                      </OreTableCell>
                    </OreTableRow>
                    <OreTableRow hover={false}>
                      <OreTableCell>
                        <OreStack
                          placeContent="space-between"
                          space="large"
                          direction="row">
                          <OreText>{t(TTOrderTranslation.complete.totalAmount)}</OreText>
                          <OreText bold>
                            {orderCompleteData.totalAmount} {currency.euro}
                          </OreText>
                        </OreStack>
                      </OreTableCell>
                    </OreTableRow>
                  </OreTableBody>
                </OreTable>
              </OreTableContainer>
            </OreStack>
          </>
        )}

        <OreStack
          direction="column"
          space="small"
          placeContent="space-between"
          placeItems="center">
          <div className="mt-1">
            <OreButton
              as="button"
              category="primary"
              onClick={() => navigate(navigationRoutes.myOrdersHistory)}
              size="large">
              {t(cardOrderTranslation.cardOrderComplete.checkStatus)}
            </OreButton>
          </div>
          <OreButton
            as="button"
            category="highlight"
            onClick={() => navigate(navigationRoutes.clientsHome)}
            size="large"
            style={{ color: '#0F172A' }}>
            {t(forms.buttons.goHome)}
          </OreButton>
        </OreStack>
      </OreStack>
    </div>
  )
}
