import { OreTabs, OreTab } from '@edenredespana/oreneta'
import { t } from 'i18next'
import { myOrdersTabs } from '../../../../../domain/enum'
import { LastOrdersContainer } from '../lastOrders/organism/LastOrdersContainer'
import { IncompleteOrders } from '../incompleteOrders/organism/IncompleteOrders'
import { useContainerController } from '../hooks/useContainerController'
import { myOrderTabs } from '../../../../../domain/constants'

interface ContainerProps {
  navigateValue?: myOrdersTabs
}

export const Container = (props: ContainerProps): JSX.Element => {
  const { handleTabClick, value } = useContainerController(props.navigateValue)
  return (
    <>
      <OreTabs>
        {myOrderTabs.map((tab, index) => {
          return (
            <OreTab
              key={'tab_' + index}
              handleClick={() => handleTabClick(index)}
              active={value === index && true}
              label={t(tab)}
            />
          )
        })}
      </OreTabs>
      {value === myOrdersTabs.lastOrders && <LastOrdersContainer />}
      {value === myOrdersTabs.incompleteOrders && (
        <IncompleteOrders
          pageSize={8}
          showFilter={true}
          showCaption={false}
          isExtended={true}
        />
      )}
    </>
  )
}
