import {
  OreCardContainer,
  OreCardWrapper,
  OreStack,
  SvgNavBarNotificactions,
} from '@edenredespana/oreneta'
import { PropsWithChildren } from 'react'
import { flexProductsTranslations } from '../../translations'
import { useTranslation } from 'react-i18next'

interface EmployeeContractMessageProps {
  isDefault: boolean
  activeContracts: number
  pendingContracts: number
}

export const EmployeeContractMessage = ({
  isDefault,
  activeContracts,
  pendingContracts,
}: EmployeeContractMessageProps): JSX.Element => {
  const { t } = useTranslation()
  return (
    <OreCardContainer hasBorder hasShadow={false} tone="neutral-100">
      <div style={{ padding: '1rem' }}>
        <OreStack direction="row" placeItems="center">
          <SvgNavBarNotificactions />
          <div style={{ color: 'var(--color-neutral-600)' }}>
            {t(flexProductsTranslations.employeeContractMessage.message1)}
            <strong>{activeContracts}</strong>
            <strong>
              {t(flexProductsTranslations.employeeContractMessage.message2)}
            </strong>
            {t(flexProductsTranslations.employeeContractMessage.message3)}
            {isDefault ? (
              <>
                {pendingContracts}
                {t(flexProductsTranslations.employeeContractMessage.message4)}
              </>
            ) : (
              <>
                {pendingContracts}
                {t(flexProductsTranslations.employeeContractMessage.message5)}
              </>
            )}
          </div>
        </OreStack>
      </div>
    </OreCardContainer>
  )
}
