import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import DropZone from '../molecules/DropZone'
import { usePayrollCustomController } from '../hooks/usePayrollCustomController'
import {
  OreButton,
  OreDivider,
  OreStack,
  SvgArrowLeft,
  SvgPayback,
} from '@edenredespana/oreneta'
import { forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { PayrollColumnNameEnum } from 'src/Flex/Payrolls/domain/PayrollCustomModel'
import { flexPayrollsTranslations } from '../../translations'

export const DropContainer = () => {
  const {
    itemsList,
    columns,
    addNewItem,
    onDragEnd,
    setItemsList,
    savePayrollConfiguration,
    restorePayrollConfiguration,
    removeNewItem,
  } = usePayrollCustomController()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const myPayrollColumn = columns.find(
    column => column.columnName === PayrollColumnNameEnum.MY_PAYROLL
  )

  return (
    <>
      <div
        style={{
          margin: 0,
          minHeight: 280,
          paddingTop: '2rem',
        }}>
        <DragDropContext onDragEnd={onDragEnd}>
          {columns.map(({ id, title, columnName, itemsId }, index) => (
            <DropZone
              key={id + 'DropContainer' + index}
              id={id}
              title={title}
              allItems={itemsList}
              setItemsList={setItemsList}
              itemsId={itemsId}
              columnName={columnName}
              addNewItem={addNewItem}
              removeNewItem={removeNewItem}
            />
          ))}
        </DragDropContext>
      </div>
      <OreStack placeItems="start">
        <div style={{ paddingTop: '2rem' }}>
          <OreButton
            as="a"
            category="tertiary"
            icon={<SvgPayback />}
            onClick={restorePayrollConfiguration}>
            {t(flexPayrollsTranslations.customPayroll.recoverItems)}
          </OreButton>
        </div>
      </OreStack>
      <OreDivider space="medium" />
      <OreStack direction="row" placeContent="space-between">
        <OreButton
          as="a"
          category="tertiary"
          icon={<SvgArrowLeft />}
          onClick={() => navigate(-1)}>
          {t(forms.buttons.goBack)}
        </OreButton>
        <OreButton
          disabled={myPayrollColumn && myPayrollColumn.itemsId.length <= 0}
          category="primary"
          onClick={savePayrollConfiguration}>
          {t(forms.buttons.save)}
        </OreButton>
      </OreStack>
    </>
  )
}
