import {
  OreCheckbox,
  OreDatePicker,
  OreHeading,
  OreInput,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { FormProvider } from 'react-hook-form'
import {
  getDaysAfter,
  getLastDayOfMonthOneYearLater,
  getLastMilesimaDate,
  getTomorrow,
} from 'src/core/helpers'
import { useUser } from 'src/presentation/context/user/UserProvider'
import { edenredProducts } from 'src/domain/enum'
import { cardOrderTranslation } from 'src/domain/translations'
import { useTTOrder } from '../../context/TTOrderProvider'
import { TTOrderSummaryState } from '../hooks/useTTOrderSummaryController'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { isUnloadTTOrder } from 'src/core/services/ttOrderService'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { useEffect } from 'react'
import { TTOrderSummaryNoOrderDescription } from './TTOrderSummaryNoOrderDescription'

interface Props {
  ttProps: TTOrderSummaryState
}

export const TTOrderSummaryForm = ({ ttProps }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { form } = ttProps
  const { contracts } = useUser()
  const currentContract = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketTransporte
  )
  const { ttOrders, requiresRechargeDate } = useTTOrder()

  useEffect(() => {
    if (currentContract?.invoiceComment)
      form.setValue('billReference', currentContract.invoiceComment)
  }, [currentContract])

  return (
    <FormProvider {...form}>
      <div style={{ width: '100%' }}>
        {ttOrders && ttOrders.length > 0 && (
          <>
            <OreStack placeContent="normal">
              <OreStack>
                <OreHeading size="headline-md">
                  {t(TTOrderTranslation.summary.completeOrder.title)}
                </OreHeading>
              </OreStack>
              <OreStack space="large" placeContent="normal">
                {/* Dates */}
                {requiresRechargeDate() && (
                  <OreStack space="small" placeContent="normal">
                    <OreText>
                      {t(TTOrderTranslation.summary.completeOrder.dateDescription)}
                    </OreText>
                    <Grid columns={2} columnGap="1.5rem">
                      <OreDatePicker
                        {...form.register('initialChargeDate')}
                        label={t(TTOrderTranslation.summary.completeOrder.dateRecharge)}
                        labelTooltip={t(
                          TTOrderTranslation.summary.completeOrder
                            .dateRechargeLabelTooltip
                        )}
                        id="initialChargeDate"
                        min={getTomorrow()}
                        max={
                          currentContract?.allowMilesima
                            ? getLastMilesimaDate()
                            : getLastDayOfMonthOneYearLater()
                        }
                        hasError={!!form.formState.errors?.initialChargeDate}
                        errorMessage={form.formState.errors?.initialChargeDate?.message}
                        required
                      />
                      {currentContract?.allowLoadExpiredDate &&
                        !isUnloadTTOrder(ttOrders) && (
                          <OreDatePicker
                            {...form.register('expiredDate')}
                            label={t(
                              TTOrderTranslation.summary.completeOrder.expiredDate
                            )}
                            labelTooltip={t(
                              TTOrderTranslation.summary.completeOrder
                                .expiredDateLabelTooltip
                            )}
                            id="expiredDate"
                            min={getDaysAfter(2)}
                            hasError={!!form.formState.errors?.initialChargeDate}
                            errorMessage={
                              form.formState.errors?.initialChargeDate?.message
                            }
                            required
                          />
                        )}
                    </Grid>
                  </OreStack>
                )}

                {/* Bill Reference */}
                <OreStack space="small" placeContent="normal">
                  <OreText>
                    {t(TTOrderTranslation.summary.completeOrder.billReferenceDescription)}
                  </OreText>
                  <Grid columns={2} columnGap="1.5rem">
                    <OreInput
                      {...form.register('billReference')}
                      name="billReference"
                      label={t(TTOrderTranslation.summary.completeOrder.billReference)}
                      id="billReference"
                      placeholder="Ej: FC98568"
                      maxLength={400}
                      required={currentContract?.isCommentRequiredFromClient}
                      hasError={!!form.formState.errors?.billReference}
                      errorMessage={form.formState.errors?.billReference?.message}
                    />
                    <div></div>
                  </Grid>
                </OreStack>
                <OreText tone="neutral-600">
                  {t(TTOrderTranslation.summary.completeOrder.fieldsRequired)}
                </OreText>
              </OreStack>
            </OreStack>
            <div className="form-section float-left mt-3 inline-flex">
              <OreCheckbox
                {...form.register('acceptConditions')}
                label={<CheckboxLabel />}
                name="acceptConditions"
                hasError={!!form.formState.errors?.acceptConditions}
                errorMessage={form.formState.errors?.acceptConditions?.message}
              />
            </div>
          </>
        )}
        {ttOrders && ttOrders.length <= 0 && <TTOrderSummaryNoOrderDescription />}
      </div>
    </FormProvider>
  )
}

const CheckboxLabel = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div>
      <OreText size="text-md">
        {t(cardOrderTranslation.cardOrderHome.termsAcceptanceExcel1)}
      </OreText>
      <br />
      <OreText size="text-md">
        {t(cardOrderTranslation.cardOrderHome.termsAcceptanceExcel2)}
      </OreText>
    </div>
  )
}
