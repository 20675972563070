import {
  OrePill,
  OreStack,
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
  OreText,
  SvgBankTransfer,
  SvgDomiciliaryReceipt,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import type { CardOrderHeaderDetailModel } from '../../../../../domain/models'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import React from 'react'
import { PaymentModes, orderType } from '../../../../../domain/enum'
import { paymentMethodsTranslation } from 'src/domain/translations'
import { Grid, GridItem } from 'src/presentation/components/Edenred/layout'
import { useTTOrder } from '../../context/TTOrderProvider'
import { GetDateFormatted } from 'src/core/helpers'

interface Props {
  detail: CardOrderHeaderDetailModel
}

export const TTOrderCheckoutHeader = ({ detail }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { orderContext } = useTTOrder()
  const initialChargeLabel =
    orderContext === orderType.Unload
      ? t(cardOrderTranslation.cardOrderResume.initialDischargeDate)
      : t(cardOrderTranslation.cardOrderResume.initialChargeDate)

  const generalDataHeader: JSX.Element = (
    <OreTableContainer>
      <OreTable>
        <OreTableCaption title={t(cardOrderTranslation.cardOrderResume.headerTitle)} />
        <OreTableBody>
          {detail.initialChargeDate && (
            <OreTableRow>
              <OreTableCell>
                <OreText>{initialChargeLabel}</OreText>
              </OreTableCell>
              <OreTableCell>
                <OreText align="right" bold>
                  {GetDateFormatted(detail.initialChargeDate)}
                </OreText>
              </OreTableCell>
            </OreTableRow>
          )}
          {detail.expiredDate && (
            <OreTableRow>
              <OreTableCell>
                <OreText>{t(cardOrderTranslation.cardOrderResume.expiredDate)}</OreText>
              </OreTableCell>
              <OreTableCell>
                <OreText align="right" bold>
                  {GetDateFormatted(detail.expiredDate)}
                </OreText>
              </OreTableCell>
            </OreTableRow>
          )}
          {detail.billReference && (
            <OreTableRow>
              <OreTableCell>
                <OreText>{t(cardOrderTranslation.cardOrderResume.billReference)}</OreText>
              </OreTableCell>
              <OreTableCell>
                <OreText align="right" bold>
                  {detail.billReference}
                </OreText>
              </OreTableCell>
            </OreTableRow>
          )}
          {detail?.paymentMode && orderContext !== orderType.Unload && (
            <OreTableRow>
              <OreTableCell>
                <OreText>{t(cardOrderTranslation.cardOrderResume.paymentMode)}</OreText>
              </OreTableCell>
              <OreTableCell align="right">
                {detail.paymentMode === PaymentModes.wireTransfer && (
                  <div style={{ display: 'inline-block' }}>
                    <OrePill icon={<SvgBankTransfer aria-hidden />} tone="cobalt-600">
                      {t(paymentMethodsTranslation.wireTransfer.name)}
                    </OrePill>
                  </div>
                )}
                {detail.paymentMode === PaymentModes.directDebit && (
                  <div style={{ display: 'inline-block' }}>
                    <OrePill
                      icon={<SvgDomiciliaryReceipt aria-hidden />}
                      tone="cobalt-600">
                      {t(paymentMethodsTranslation.directDebit.name)}
                    </OrePill>
                  </div>
                )}
              </OreTableCell>
            </OreTableRow>
          )}
          {orderContext === orderType.Recharge && (
            <OreTableRow>
              <OreTableCell>
                <OreStack direction="row">
                  <OreText>
                    {t(cardOrderTranslation.cardOrderResume.deliveryTermsHeaderTitle)}
                  </OreText>
                </OreStack>
              </OreTableCell>
              <OreTableCell>
                <OreText align="right">
                  {t(cardOrderTranslation.cardOrderResume.in)}
                  <span className="bold">
                    {t(cardOrderTranslation.cardOrderResume.rateHours)}
                  </span>
                  {t(cardOrderTranslation.cardOrderResume.oncePaymentRecieved)}
                </OreText>
              </OreTableCell>
            </OreTableRow>
          )}
        </OreTableBody>
      </OreTable>
    </OreTableContainer>
  )
  const deliveryTermsHeader: JSX.Element = (
    <OreTableContainer>
      <OreTable>
        <OreTableCaption
          title={t(cardOrderTranslation.cardOrderResume.deliveryTermsHeaderTitle)}
        />
        <OreTableBody>
          <OreTableRow>
            <OreTableCell>
              <OreText>{t(cardOrderTranslation.cardOrderResume.plasticCards)}</OreText>
            </OreTableCell>
            <OreTableCell>
              <OreText align="right">
                {t(cardOrderTranslation.cardOrderResume.in)}
                <span className="bold">
                  {t(cardOrderTranslation.cardOrderResume.workingDays)}
                </span>
              </OreText>
            </OreTableCell>
          </OreTableRow>
          <OreTableRow>
            <OreTableCell>
              <OreText>{t(cardOrderTranslation.cardOrderResume.recharges)}</OreText>
            </OreTableCell>
            <OreTableCell>
              <OreText align="right">
                {t(cardOrderTranslation.cardOrderResume.in)}
                <span className="bold">
                  {t(cardOrderTranslation.cardOrderResume.rateHours)}
                </span>
                {t(cardOrderTranslation.cardOrderResume.oncePaymentRecieved)}
              </OreText>
            </OreTableCell>
          </OreTableRow>
        </OreTableBody>
      </OreTable>
    </OreTableContainer>
  )
  if (orderContext) {
    switch (orderContext) {
      case orderType.Card:
        return (
          <div style={{ width: '100%' }}>
            <Grid columns={2} columnGap="3rem" className="home-tables-grid">
              <GridItem>
                <div className="incomplete-order-home--wrapper">{generalDataHeader}</div>
              </GridItem>
              <GridItem>
                <div className="incomplete-order-home--wrapper">
                  {deliveryTermsHeader}
                </div>
              </GridItem>
            </Grid>
          </div>
        )
      case orderType.Recharge:
      case orderType.Unload:
        return <>{generalDataHeader}</>
      default:
        return <></>
    }
  } else return <></>
}
