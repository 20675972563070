import { Seq } from 'immutable'
import type { ChangeEvent } from 'react'
import { useEffect, useState } from 'react'
import type { UseFormRegister } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import type {
  ComboBoxOptionModel,
  RadioButtonModel,
} from '../../../../../domain/customComponents'
import { cardType, edenredProducts } from '../../../../../domain/enum'
import type { CardOrderModel, SpendingRuleModel } from '../../../../../domain/models'
import { cardOrderFormTranslation } from '../../../../../domain/translations'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useAsync } from '../../../../hooks'
import { spendingRuleService } from '../../../../../core/services'
import {
  GetDefaultSpendingRule,
  GetSpendingRulesRequestBody,
} from '../../../../../core/controllers/cardOrder/services'
import { useUser } from '../../../../context/user/UserProvider'
import { SvgInfo } from '@edenredespana/oreneta'

export interface CardDetailState {
  GetCardDetailsRadioButtons: () => RadioButtonModel[]
  spendingRulesOptions: ComboBoxOptionModel[]
  register: UseFormRegister<CardOrderModel>
  errors: any
  t: TFunction<'translation', undefined>
  radioCardTypeState: string | undefined
  onChangeRadioCardType: (event: ChangeEvent<HTMLInputElement>) => void
  spendingRuleDisabled: boolean
  disableEnterKey: (event: React.KeyboardEvent<HTMLInputElement>) => void
}

export const useCardDetailController = (): CardDetailState => {
  const {
    register,
    formState: { errors },
    setValue,
  } = useFormContext<CardOrderModel>()
  const { t } = useTranslation()
  const [spendingRulesOptions, setSpendingRulesOptions] = useState<ComboBoxOptionModel[]>(
    [
      {
        options: [[t(cardOrderFormTranslation.cardDetail.spendingRulesPlaceholder), 0]],
      },
    ]
  )
  const [radioCardTypeState, setRadioCardTypeState] = useState<string | undefined>()
  const { formParameters } = useCardOrder()
  const [spendingRuleDisabled, setSpendingRuleDisabled] = useState<boolean>(false)

  const { state: spendingRulesResponse } = useAsync<any>(() => {
    return spendingRuleService().getAll(GetSpendingRulesRequestBody())
  })

  const { contracts } = useUser()
  const currencContractTR = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketRestaurant
  )

  const GetCardDetailsRadioButtons = (): RadioButtonModel[] => {
    return [
      {
        label: t(cardOrderFormTranslation.cardDetail.physical),
        value: cardType.Plastic.toString(),
        tooltip: false,
        onKeyDown: disableEnterKey,
      },
      {
        label: t(cardOrderFormTranslation.cardDetail.virtual),
        value: cardType.Virtual.toString(),
        tooltip: !currencContractTR?.allowPlasticless,
        tooltipIcon: <SvgInfo />,
        tooltipText: t(cardOrderFormTranslation.cardDetail.allowPlasticlessTooltip),
        disabled: !currencContractTR?.allowPlasticless,
        onKeyDown: disableEnterKey,
      },
    ]
  }

  const GetCardSpendingRulesOptions = (spendingRules: SpendingRuleModel[]) => {
    let options: ComboBoxOptionModel[] = [...spendingRulesOptions]
    if (spendingRules) {
      options = [
        {
          options: [[t(cardOrderFormTranslation.cardDetail.spendingRulesPlaceholder), 0]],
        },
      ]
      Seq(spendingRules).forEach((spendingRule: SpendingRuleModel) => {
        options[0].options.push([spendingRule.profileName, spendingRule.profileId])
      })
    }
    setSpendingRulesOptions(options)
  }

  const onChangeRadioCardType = (event: ChangeEvent<HTMLInputElement>) => {
    setRadioCardTypeState(event.target.value)
  }

  //set options
  useEffect(() => {
    if (spendingRulesResponse?.usageProfiles?.rows)
      GetCardSpendingRulesOptions(spendingRulesResponse.usageProfiles.rows)
  }, [spendingRulesResponse])

  // set default value
  useEffect(() => {
    if (spendingRulesOptions?.length > 0) {
      let spendingRulesDefault: number | undefined
      if (formParameters?.editMode)
        spendingRulesDefault = formParameters.entity?.cardData.spendingRuleId
      else
        spendingRulesDefault = GetDefaultSpendingRule(
          spendingRulesResponse?.usageProfiles?.rows
        )

      if (spendingRulesDefault) {
        if (spendingRulesResponse?.usageProfiles?.rows.length === 1)
          setSpendingRuleDisabled(true)
        else setSpendingRuleDisabled(false)
        setValue('cardData.spendingRuleId', spendingRulesDefault)
      }
    }
  }, [spendingRulesOptions, currencContractTR])

  const disableEnterKey = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
    }
  }

  useEffect(() => {
    if (currencContractTR) {
      if (formParameters?.editMode)
        setRadioCardTypeState(formParameters.entity?.cardData.cardTypeId.toString())
      else {
        const optionValue: string = currencContractTR?.allowPlasticless
          ? cardType.Virtual.toString()
          : cardType.Plastic.toString()
        setValue('cardData.cardTypeId', Number(optionValue))
        setRadioCardTypeState(optionValue)
      }
    }
  }, [currencContractTR])

  return {
    GetCardDetailsRadioButtons,
    spendingRulesOptions,
    register,
    errors,
    t,
    radioCardTypeState,
    onChangeRadioCardType,
    spendingRuleDisabled,
    disableEnterKey,
  }
}
