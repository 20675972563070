import { OreHeading, OreInput, OreDatePicker, OreSelect } from '@edenredespana/oreneta'
import type { CardOrderModel } from '../../../../../domain/models/'
import { useTranslation } from 'react-i18next'
import { cardOrderFormTranslation } from '../../../../../domain/translations/'
import React from 'react'
import { minAge, maxAge } from '../../../../../core/helpers'
import { Textbox } from '../../../Edenred'
import type {
  ComboBoxOptionModel,
  TextBoxRowModel,
} from '../../../../../domain/customComponents'
import { useEmployeeDetailController } from '../hooks'

export const EmployeeDetail = ({ title }: { title?: string }): JSX.Element => {
  const { t } = useTranslation()
  const {
    GetEmployeeDetailFirstSectionTextBoxes,
    GetEmployeeDetailSecondtSectionTextBoxes,
    GetEmployeeDetailDocumentTypeOptions,
    register,
    errors,
    disableEnterKey,
  } = useEmployeeDetailController()
  const textBoxesFirstSection: TextBoxRowModel<CardOrderModel>[] =
    GetEmployeeDetailFirstSectionTextBoxes()
  const textBoxesSecondSection: TextBoxRowModel<CardOrderModel>[] =
    GetEmployeeDetailSecondtSectionTextBoxes()
  const documentTypeOptions: ComboBoxOptionModel[] =
    GetEmployeeDetailDocumentTypeOptions()

  return (
    <>
      <section className="form-molecule mt-1">
        <div className="pl-05">
          <OreHeading as="h2" size="headline-md">
            {title}
          </OreHeading>
        </div>

        <Textbox
          errors={errors}
          register={register}
          textBoxList={textBoxesFirstSection}
        />

        <div className="form-row">
          <div className="form-atom form-atom--half">
            <OreSelect
              {...register('employeeData.documentTypeId')}
              required={true}
              id="documentTypeId"
              name="employeeData.documentTypeId"
              label={t(cardOrderFormTranslation.employeeDetail.documentType)}
              legend={t(cardOrderFormTranslation.employeeDetail.documentTypeLegend)}
              hasError={!!errors?.employeeData?.documentTypeId}
              errorMessage={errors.employeeData?.documentTypeId?.message}>
              {documentTypeOptions.map((option: ComboBoxOptionModel) =>
                option.options.map((opt: [string, number], index: number) => (
                  <option key={'option_' + index} label={opt[0]} value={opt[1]} />
                ))
              )}
            </OreSelect>
          </div>
          <div className="form-atom form-atom--half">
            <OreInput
              {...register('employeeData.document')}
              name="employeeData.document"
              id="document"
              label={t(cardOrderFormTranslation.employeeDetail.document)}
              placeholder={t(cardOrderFormTranslation.employeeDetail.documentPlaceHolder)}
              type="text"
              required
              maxLength={14}
              hasError={!!errors?.employeeData?.document}
              errorMessage={errors.employeeData?.document?.message}
              onKeyDown={disableEnterKey}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-atom">
            <OreDatePicker
              {...register('employeeData.birthDate')}
              name="employeeData.birthDate"
              id="birthDate"
              label={t(cardOrderFormTranslation.employeeDetail.birthDate)}
              placeholder={t(
                cardOrderFormTranslation.employeeDetail.birthDatePlaceHolder
              )}
              legend={t(cardOrderFormTranslation.employeeDetail.birthDateLegend)}
              min={maxAge()}
              max={minAge()}
              required
              hasError={!!errors?.employeeData?.birthDate}
              errorMessage={errors.employeeData?.birthDate?.message}
              onKeyDown={disableEnterKey}
            />
          </div>
        </div>
        <Textbox
          errors={errors}
          register={register}
          textBoxList={textBoxesSecondSection}
        />
      </section>
    </>
  )
}
