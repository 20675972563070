import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderModel } from 'src/domain/customComponents/table'
import {
  MetaStatusCodes,
  NotificationSeverity,
  browserStorageKeys,
  orderType,
} from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { UseFormReturn, useForm } from 'react-hook-form'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useModalController } from 'src/presentation/components/Edenred'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'
import { TTOrderService } from 'src/core/services/ttOrderService'
import {
  CardOrderResumeModel,
  IncompleteOrderModel,
  MessagesModel,
  MetaModel,
} from 'src/domain/models'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useModal } from 'src/presentation/hooks'
import { useTTRechargeSummarySchema } from '../validations/useTTRechargeSummarySchema'
import { useTTOrder } from '../../context/TTOrderProvider'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { TTOrderModel, TTOrderSummaryForm } from 'src/domain/models/TTOrder/TTOrderModel'
import { SvgInfo } from '@edenredespana/oreneta'
import { CardOrderResumeDTO } from 'src/domain/dto'
import { singleGetRechargeCheckout } from 'src/config/constants/endpoints'

export interface TTRechargeSummaryState {
  form: UseFormReturn<TTOrderSummaryForm, any>
  header: HeaderModel
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>
  showDeleteModal: boolean
  deleteModalTitle: string
  deleteModalDescription: string
  deletePopupButtons: PopupButtonModel[]
  onClickDelete(id: string): void
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  showCancelModal: boolean
  cancelModalTitle: string
  cancelModalDescription: string
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  onClickEdit(order: TTOrderModel): void
  onCheckoutOrder: (summary: TTOrderSummaryForm) => Promise<void>
  showSaveCloseModal: boolean
  setShowSaveCloseModal: Dispatch<SetStateAction<boolean>>
  errorData: MessagesModel[] | undefined
  excelError: string
  backToSummary: () => void
  showSpinner: boolean
  // setTTEmployeesToConfig: (employees: EmployeesTTOrderModel[]) => void
}

export const useTTRechargeSummaryController = (): TTRechargeSummaryState => {
  const { t } = useTranslation()
  const { startLoading, stopLoading } = useLoader()
  const navigate = useNavigate()
  const {
    ttOrders,
    addTTOrders,
    removeTTOrder,
    clearTTOrders,
    addTTOrderCheckout,
    addTTSummaryForm,
    setOrderContext,
    setTTEmployeesToConfig,
    setTTSelectedEmployees,
  } = useTTOrder()
  const [errorData, setErrorData] = useState<MessagesModel[]>()
  const [excelError, setExcelError] = useState<string>('')
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const { TTRechargeSummarySchema } = useTTRechargeSummarySchema()
  const { addNotification } = useNotification()
  const { showModal: showSaveCloseModal, setShowModal: setShowSaveCloseModal } =
    useModal()

  const form = useForm<TTOrderSummaryForm>({
    resolver: yupResolver(TTRechargeSummarySchema),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      initialChargeDate: '',
      billReference: '',
      acceptConditions: false,
      orderTypeId: orderType.Recharge,
    },
  })

  const {
    show: showDeleteModal,
    title: deleteModalTitle,
    description: deleteModalDescription,
    buttons: deletePopupButtons,
    setShow: setShowDeleteModal,
    setDescription: setDeleteModalDescription,
    setTitle: setDeleteModalTitle,
    setButtons: setDeleteModalButtons,
  } = useModalController()

  const {
    show: showCancelModal,
    title: cancelModalTitle,
    description: cancelModalDescription,
    buttons: cancelPopupButtons,
    setShow: setShowCancelModal,
    setDescription: setCancelModalDescription,
    setTitle: setCancelModalTitle,
    setButtons: setCancelModalButtons,
  } = useModalController()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(TTOrderTranslation.recharge.summary.table.header.user) },
      {
        label: t(TTOrderTranslation.recharge.summary.table.header.balance.label),
        tooltip: t(TTOrderTranslation.recharge.summary.table.header.balance.tooltip),
        tooltipIcon: <SvgInfo width={18} height={18} />,
      },
      { label: t(TTOrderTranslation.recharge.summary.table.header.initialAmount) },
      {
        label: t(TTOrderTranslation.recharge.summary.table.header.finalBalance.label),
        tooltip: t(TTOrderTranslation.recharge.summary.table.header.finalBalance.tooltip),
        tooltipIcon: <SvgInfo width={18} height={18} />,
      },
      { label: t(TTOrderTranslation.recharge.summary.table.header.costCenter) },
      { label: t(TTOrderTranslation.recharge.summary.table.header.actions) },
    ],
  }

  const onClickDelete = (id: string): void => {
    setShowDeleteModal(true)
    setDeleteModalButtons(getDeleteModalButtons(id))
  }

  const getDeleteModalButtons = (id: string): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowDeleteModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => {
          removeTTOrder(id)
          setShowDeleteModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
        },
        size: 'large',
      },
    ]
  }

  const onClickCancel = (): void => {
    setShowCancelModal(true)
    setCancelModalButtons(getCancelModalButtons())
  }

  const getCancelModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.discard),
        category: 'primary',
        onClick: () => {
          clearTTOrders()
          setTTEmployeesToConfig([])
          setTTSelectedEmployees([])
          setShowCancelModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
          navigate(navigationRoutes.myOrdersTtTab)
        },
        size: 'large',
      },
    ]
  }

  const onClickEdit = (ttOrderToEdit: TTOrderModel): void => {
    navigate(
      `${navigationRoutes.ttRechargeEmployeesConfigurationEdit.replace(
        ':id',
        ttOrderToEdit.id.toString()
      )}`,
      {
        state: {
          from: navigationRoutes.ttRechargeSummary,
        },
      }
    )
  }

  const GetErrorsFromRequest = (
    data: CardOrderResumeModel | null,
    meta: MetaModel
  ): void => {
    if (meta.status == MetaStatusCodes.ERROR) {
      setErrorData(meta.messages)
      setExcelError(data?.additionalData || '')
    }
  }

  const onCheckoutOrder = async (summary: TTOrderSummaryForm): Promise<void> => {
    setShowSpinner(true)
    TTOrderService()
      .checkout(singleGetRechargeCheckout, summary, ttOrders)
      .then((response: CardOrderResumeDTO) => {
        if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
          setOrderContext(summary.orderTypeId)
          addTTSummaryForm(summary)
          addTTOrderCheckout(response)
          addNotification(t(forms.success.message), NotificationSeverity.success)
          navigate(navigationRoutes.ttRechargeCheckout)
        } else {
          GetErrorsFromRequest(response.data, response.meta)
          // if (response?.meta.messages.length > 0) {
          //   addNotification(t(forms.errors.genericError), NotificationSeverity.error)
          //   response?.meta?.messages.forEach((error: MessagesModel) => {
          //     if (error.value) {
          //       form.setError(error.value as any, {
          //         type: 'value',
          //         message: error.description,
          //       })
          //     }
          //   })
          // }
        }
      })
      .finally(() => setShowSpinner(false))
  }

  const backToSummary = (): void => {
    setErrorData(undefined)
    setExcelError('')
    setShowSpinner(false)
    navigate(navigationRoutes.ttRechargeSummary)
  }

  const TTOrderComplete = (): void => {
    const incompleteOrderString = sessionStorage.getItem(
      browserStorageKeys.ttOrderComplete
    )
    if (incompleteOrderString) {
      const incompleteOrder: IncompleteOrderModel<TTOrderModel> = incompleteOrderString
        ? JSON.parse(incompleteOrderString)
        : {}
      addTTOrders(incompleteOrder.cardOrders)
      sessionStorage.removeItem(browserStorageKeys.ttOrderComplete)
    }
  }

  useEffect(() => {
    TTOrderComplete()
    setDeleteModalTitle(t(TTOrderTranslation.recharge.summary.deleteModal.title))
    setDeleteModalDescription(t(TTOrderTranslation.summary.deleteModal.description))
    setCancelModalTitle(t(TTOrderTranslation.summary.cancelModal.title))
    setCancelModalDescription(t(TTOrderTranslation.summary.cancelModal.description))
  }, [])

  return {
    header,
    setShowDeleteModal,
    showDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
    onClickDelete,
    onClickEdit,
    form,
    onCheckoutOrder,
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
    onClickCancel,
    setShowSaveCloseModal,
    showSaveCloseModal,
    errorData,
    excelError,
    backToSummary,
    showSpinner,
    // setTTEmployeesToConfig,
  }
}
