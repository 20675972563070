import { OreList, OreListItem, OreMessageBar, OreStack } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { flexCollectivesTranslations } from '../../translations'

export const DeleteCollectiveDangerMessage = (props: {
  numberOfContracts: number
  collectiveId: string
  newCollectiveId: string
  downloadFile: () => Promise<void>
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <OreMessageBar color="error">
      <OreStack>
        <strong>{t(flexCollectivesTranslations.delete.title)}</strong>
      </OreStack>
      <OreStack space="2xsmall">
        <div className="ore-list">
          <OreList bullet>
            <OreListItem>
              {t(flexCollectivesTranslations.delete.line1)}
              <a onClick={props.downloadFile} style={{ color: 'var(--color-error-600)' }}>
                {props.numberOfContracts + t(flexCollectivesTranslations.delete.line1_1)}
              </a>
            </OreListItem>
            <OreListItem>{t(flexCollectivesTranslations.delete.line2)}</OreListItem>
            <OreListItem>{t(flexCollectivesTranslations.delete.line3)}</OreListItem>
          </OreList>
        </div>
      </OreStack>
    </OreMessageBar>
  )
}
