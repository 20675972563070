import { SvgBin, SvgEdit } from '@edenredespana/oreneta'
import { Seq } from 'immutable'
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { addCurrency, spendingRuleService } from '../../../../core/services'
import {
  CaptionModel,
  FooterModel,
  HeaderModel,
  RowModel,
} from '../../../../domain/customComponents/table'
import {
  edenredProducts,
  MetaStatusCodes,
  NotificationSeverity,
  SpendingRulesType,
} from '../../../../domain/enum'
import { SpendingRuleModel } from '../../../../domain/models'
import {
  forms,
  spendingRuleFormTranslation,
  spendingRuleTranslation,
} from '../../../../domain/translations'
import { useLoader } from '../../../context/loader/LoaderProvider'
import { iconButtonAction } from 'src/domain/constants'
import { SpendingRuleGetAllProtocol } from 'src/domain/protocols'
import { ITimeZones } from 'src/domain/interfaces/spendingRule/ISpendingRules'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useModalController } from '../../Edenred'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'

interface ControllerState {
  header: HeaderModel
  caption: CaptionModel
  rows: RowModel[]
  footer: FooterModel
  setRows: Dispatch<SetStateAction<RowModel[]>>
  popupFavShow: boolean
  popupFavTitle: string
  popupFavDescription: string
  popupFavButtons: PopupButtonModel[]
  setShowFavPopup: Dispatch<SetStateAction<boolean>>
  popupDelShow: boolean
  popupDelTitle: string
  popupDelDescription: string
  popupDelButtons: PopupButtonModel[]
  setShowDelPopup: Dispatch<SetStateAction<boolean>>
  deleteAndReplace: boolean
  handleSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void
  selectError: boolean
  currentActionId: number
}

export const useSpendingRulesController = (): ControllerState => {
  const { t } = useTranslation()
  const [rows, setRows] = useState<RowModel[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const [spendingRules, setSpendingRules] = useState<SpendingRuleModel[]>([])
  const [currentActionId, setCurrentActionId] = useState<number>(0)
  const [confirmFavAction, setconfirmFavAction] = useState<boolean>(false)
  const [confirmDelAction, setconfirmDelAction] = useState<boolean>(false)
  const [deleteAndReplace, setDeleteAndReplace] = useState<boolean>(false)
  const [caption, setCaption] = useState<CaptionModel>({
    title: t(spendingRuleTranslation.table.title),
    description: t(spendingRuleTranslation.table.caption),
  })
  const [spendingRuleSelectedId, setSpendingRuleSelectedId] = useState<number>(0)
  const [selectError, setSelectError] = useState<boolean>(false)
  const [footer, setFooter] = useState<FooterModel>({})
  const [page, setPage] = useState(1)
  const pageSize = 10
  const { startLoading, stopLoading } = useLoader()
  const { addNotification } = useNotification()
  const navigate = useNavigate()
  const [query, setQuery] = useState<SpendingRuleGetAllProtocol>({
    productCode: edenredProducts.ticketRestaurant,
    paginationModel: {
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    },
  })

  const {
    show: popupFavShow,
    title: popupFavTitle,
    description: popupFavDescription,
    buttons: popupFavButtons,
    setShow: setShowFavPopup,
    setDescription: setDescriptionFavPopup,
    setTitle: setTitleFavPopup,
    setButtons: setButtonsFavPopup,
  } = useModalController()

  const {
    show: popupDelShow,
    title: popupDelTitle,
    description: popupDelDescription,
    buttons: popupDelButtons,
    setShow: setShowDelPopup,
    setDescription: setDescriptionDelPopup,
    setTitle: setTitleDelPopup,
    setButtons: setButtonsDelPopup,
  } = useModalController()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(spendingRuleTranslation.table.header.name) },
      { label: t(spendingRuleTranslation.table.header.days) },
      { label: t(spendingRuleTranslation.table.header.timesheet) },
      { label: t(spendingRuleTranslation.table.header.types) },
      { label: t(spendingRuleTranslation.table.header.actions) },
    ],
  }

  const getSpendingRules = async (): Promise<SpendingRuleModel[]> => {
    startLoading()
    return new Promise<SpendingRuleModel[]>(resolve => {
      spendingRuleService()
        .getAll(query)
        .then(response => {
          setTotalRows(response.data.usageProfiles.totalRows)
          setSpendingRules(response.data.usageProfiles.rows)
          resolve(response.data.usageProfiles.rows)
        })
        .finally(() => stopLoading())
    })
  }

  const getFirstLetterOfDay = (timeZone: ITimeZones): string => {
    let days = ''
    if (timeZone.weekDays.monday)
      days = days + t(spendingRuleTranslation.weekDays.firstLetter.monday) + ', '
    if (timeZone.weekDays.tuesday)
      days = days + t(spendingRuleTranslation.weekDays.firstLetter.tuesday) + ', '
    if (timeZone.weekDays.wednesday)
      days = days + t(spendingRuleTranslation.weekDays.firstLetter.wednesday) + ', '
    if (timeZone.weekDays.thursday)
      days = days + t(spendingRuleTranslation.weekDays.firstLetter.thursday) + ', '
    if (timeZone.weekDays.friday)
      days = days + t(spendingRuleTranslation.weekDays.firstLetter.friday) + ', '
    if (timeZone.weekDays.saturday)
      days = days + t(spendingRuleTranslation.weekDays.firstLetter.saturday) + ', '
    if (timeZone.weekDays.sunday)
      days = days + t(spendingRuleTranslation.weekDays.firstLetter.sunday) + ', '
    return days.slice(0, -2)
  }

  const getUsageDays = (spendingRule: SpendingRuleModel): JSX.Element => {
    return (
      <div className="flex flex--column">
        {spendingRule.timeZones.map((timeZone: ITimeZones, index: number) => {
          return (
            <div key={'sr_' + index} className="whitespace-nowrap">
              {getFirstLetterOfDay(timeZone)}
            </div>
          )
        })}
      </div>
    )
  }

  const getUsageTimes = (spendingRule: SpendingRuleModel): JSX.Element => {
    return (
      <div className="flex flex--column">
        {spendingRule.timeZones.map((timeZone: ITimeZones, index: number) => {
          return (
            <div key={'tz_' + index} className="whitespace-nowrap">
              {timeZone.timeZoneFrom + ' - ' + timeZone.timeZoneTo}
            </div>
          )
        })}
      </div>
    )
  }

  const getSpendingRuleNameAndUsers = (spendingRule: SpendingRuleModel): JSX.Element => {
    return (
      <div className="flex flex--column">
        <div style={{ fontWeight: 'bold' }}>{spendingRule.profileName}</div>
        <div>{spendingRule.usersCount + t(spendingRuleTranslation.totalCards)}</div>
      </div>
    )
  }

  const getDietRestrictions = (spendingRule: SpendingRuleModel): JSX.Element => {
    return (
      <>
        <div className="flex flex--column">
          {spendingRule.timeZones.map((tz: ITimeZones, index: number) => {
            if (!tz.transactionAmount) return <div key={'index_' + index}></div>
            return (
              <div className="whitespace-nowrap" key={'index_' + index}>
                {t(spendingRuleTranslation.restrictions.maxTransactionAmount)}{' '}
                {addCurrency(tz.transactionAmount)}
              </div>
            )
          })}
        </div>
      </>
    )
  }

  const getRestrictions = (spendingRule: SpendingRuleModel): JSX.Element => {
    return (
      <>
        <div className="flex flex--column">
          {spendingRule.profileTypeId === SpendingRulesType.byAmount ? (
            <>
              <div>{t(spendingRuleTranslation.restrictions.byAmount.title)}</div>
              {spendingRule.amount.dayAmount ? (
                <div>
                  {t(spendingRuleTranslation.restrictions.byAmount.day)}{' '}
                  {addCurrency(spendingRule.amount.dayAmount)}
                </div>
              ) : (
                <></>
              )}
              {spendingRule.amount.weekAmount ? (
                <div>
                  {t(spendingRuleTranslation.restrictions.byAmount.week)}{' '}
                  {addCurrency(spendingRule.amount.weekAmount)}
                </div>
              ) : (
                <></>
              )}
              {spendingRule.amount.monthAmount ? (
                <div>
                  {t(spendingRuleTranslation.restrictions.byAmount.month)}{' '}
                  {addCurrency(spendingRule.amount.monthAmount)}
                </div>
              ) : (
                <></>
              )}
              {spendingRule.amount.yearAmount ? (
                <div>
                  {t(spendingRuleTranslation.restrictions.byAmount.year)}{' '}
                  {addCurrency(spendingRule.amount.yearAmount)}
                </div>
              ) : (
                <></>
              )}
            </>
          ) : spendingRule.profileTypeId === SpendingRulesType.byTransaction ? (
            <>
              <div>{t(spendingRuleTranslation.restrictions.byTransaction.title)}</div>
              {spendingRule.timeZones &&
              spendingRule.timeZones.length > 0 &&
              spendingRule.timeZones[0].transactionAmount > 0 ? (
                <div>
                  {t(spendingRuleTranslation.restrictions.byTransaction.maxTransactions)}{' '}
                  {addCurrency(spendingRule.timeZones[0].transactionAmount)}
                </div>
              ) : (
                <></>
              )}
              {spendingRule.amount.dayAmount ? (
                <div>
                  {t(spendingRuleTranslation.restrictions.byTransaction.day)}{' '}
                  {spendingRule.amount.dayAmount}
                </div>
              ) : (
                <></>
              )}
              {spendingRule.amount.weekAmount ? (
                <div>
                  {t(spendingRuleTranslation.restrictions.byTransaction.week)}{' '}
                  {spendingRule.amount.weekAmount}
                </div>
              ) : (
                <></>
              )}
              {spendingRule.amount.monthAmount ? (
                <div>
                  {t(spendingRuleTranslation.restrictions.byTransaction.month)}{' '}
                  {spendingRule.amount.monthAmount}
                </div>
              ) : (
                <></>
              )}
              {spendingRule.amount.yearAmount ? (
                <div>
                  {t(spendingRuleTranslation.restrictions.byTransaction.year)}{' '}
                  {spendingRule.amount.yearAmount}
                </div>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    )
  }

  const GetRows = (): void => {
    const spendingRulesRows: RowModel[] = []
    Seq(spendingRules).forEach((spendingRule: SpendingRuleModel, index: number) => {
      const spendingRuleRow: RowModel = {
        rowId: spendingRule.profileId,
        cells: [
          {
            type: 'element',
            cellElement: getSpendingRuleNameAndUsers(spendingRule),
          },
          {
            type: 'element',
            cellElement: getUsageDays(spendingRule),
          },
          {
            type: 'element',
            cellElement: getUsageTimes(spendingRule),
          },
          {
            type: 'element',
            cellElement:
              spendingRule.profileTypeId !== SpendingRulesType.timezones
                ? getRestrictions(spendingRule)
                : getDietRestrictions(spendingRule),
          },
          {
            type: 'actions',
            cellActions: [
              {
                name: 'deleteRow',
                icon: <SvgBin />,
                type: iconButtonAction,
                onClick: handleDeleteSR,
                disabled: spendingRule.isDefault,
                tooltip: spendingRule.isDefault
                  ? t(spendingRuleTranslation.actions.delete.deleteFavTooltip)
                  : '',
                id: `delete-action-${index}`,
              },
              {
                name: 'favorite',
                type: 'favs',
                onChange: handleChangeFav,
                checked: spendingRule.isDefault,
              },
              {
                name: 'edit',
                icon: <SvgEdit />,
                type: iconButtonAction,
                onClick: handleClickEdit,
              },
            ],
          },
        ],
      }
      spendingRulesRows.push(spendingRuleRow)
    })
    setRows(spendingRulesRows)
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...query,
      paginationModel: {
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
    setPage(page)
  }

  const handleDeleteSR = (rowId: number) => {
    setCurrentActionId(rowId)
    const spendingRuleToDelete = spendingRules.find(
      (sp: SpendingRuleModel) => sp.profileId === rowId
    )
    if (spendingRuleToDelete) {
      if (spendingRuleToDelete.usersCount) {
        // more than 0
        setDeleteAndReplace(true)
        InitDeletePopup(true)
        setShowDelPopup(true)
      } else {
        setDeleteAndReplace(false)
        InitDeletePopup()
        setShowDelPopup(true)
      }
    }
  }

  const handleChangeFav = (e: ChangeEvent<HTMLInputElement>, rowId: number) => {
    setCurrentActionId(rowId)
    setShowFavPopup(true)
  }

  const updateFav = async (): Promise<void> => {
    startLoading()
    const favSpendingRule = spendingRules.find(
      (sp: SpendingRuleModel) => sp.profileId === currentActionId
    )

    if (favSpendingRule) {
      const { meta } = await spendingRuleService().setFav(currentActionId)

      // Handle API response
      if (meta.status === MetaStatusCodes.SUCCESS) {
        addNotification(
          t(spendingRuleTranslation.actions.favorite.successMessage),
          NotificationSeverity.success
        )
        // Update table rows
        setSpendingRules(prev => {
          return prev.map((sr: SpendingRuleModel) => {
            if (sr.profileId === favSpendingRule.profileId) {
              return { ...sr, isDefault: true }
            }
            return { ...sr, isDefault: false }
          })
        })
      } else {
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      }
    }

    stopLoading()
  }

  const deleteSpendingRule = async (): Promise<void> => {
    startLoading()

    const deleteSPPayload = {
      productCode: edenredProducts.ticketRestaurant,
      usageProfileId: currentActionId ? currentActionId : 0,
    }

    const { meta } = await spendingRuleService().delete(
      deleteAndReplace
        ? { ...deleteSPPayload, newDefaultUsageProfileId: spendingRuleSelectedId }
        : deleteSPPayload
    )

    // // Handle API response
    if (meta.status === MetaStatusCodes.SUCCESS) {
      addNotification(
        t('Se ha eliminado el Perfil de uso con éxito'),
        NotificationSeverity.success
      )
      onPageChange(page) // refresh page
    } else {
      addNotification(t(forms.errors.genericError), NotificationSeverity.error)
    }

    stopLoading()
  }

  const handleConfirmUpdateFav = async (): Promise<void> => {
    await updateFav()
    setShowFavPopup(false)
    setconfirmFavAction(false)
  }

  const handleConfirmDeleteSR = async (): Promise<void> => {
    if (deleteAndReplace) {
      if (spendingRuleSelectedId !== 0) {
        await deleteSpendingRule()
        finallyConfirmDelete()
      } else {
        setSelectError(true)
        setconfirmDelAction(false)
      }
    } else {
      await deleteSpendingRule()
      finallyConfirmDelete()
    }
  }

  const finallyConfirmDelete = (): void => {
    setShowDelPopup(false)
    setconfirmDelAction(false)
  }

  const handleClickEdit = (rowId: number): void => {
    navigate(navigationRoutes.spendingRulesEditById.replace(':id', rowId.toString()))
  }

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>): void => {
    setSpendingRuleSelectedId(+e.target.value)
    if (+e.target.value !== 0) {
      setSelectError(false)
    } else {
      setSelectError(true)
    }
  }

  const handleDelCancel = (): void => {
    setShowDelPopup(false)
    setSelectError(false)
  }

  const getFooter = (): void => {
    setFooter({
      pagination: {
        onPageChange: onPageChange,
        labelNextPage: t(forms.pagination.next),
        labelPreviousPage: t(forms.pagination.back),
        rowsPerPage: pageSize,
        text: t(forms.pagination.of),
        page: page,
        isServerSide: true,
        totalRows: totalRows,
      },
    })
  }

  const SetFavPopupButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowFavPopup(false),
        size: 'large',
      },
      {
        title: t(spendingRuleFormTranslation.form.favoriteModal.confirmationButtonLabel),
        category: 'primary',
        onClick: () => setconfirmFavAction(true),
        size: 'large',
      },
    ]
  }

  const SetDelPopupButtons = (replaceMode?: boolean): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: handleDelCancel,
        size: 'large',
      },
      {
        title: replaceMode
          ? t(spendingRuleTranslation.actions.deleteAndReplace.modal.delete)
          : t(spendingRuleTranslation.actions.delete.modal.delete),
        category: 'primary',
        onClick: () => setconfirmDelAction(true),
        size: 'large',
      },
    ]
  }

  const InitFavoritePopup = (): void => {
    setTitleFavPopup(t(spendingRuleFormTranslation.form.favoriteModal.title))
    setDescriptionFavPopup(t(spendingRuleFormTranslation.form.favoriteModal.description))
    setButtonsFavPopup(SetFavPopupButtons())
  }

  const InitDeletePopup = (replaceMode?: boolean): void => {
    setTitleDelPopup(t(spendingRuleTranslation.actions.delete.modal.title))
    setDescriptionDelPopup(
      replaceMode
        ? t(spendingRuleTranslation.actions.deleteAndReplace.modal.description)
        : t(spendingRuleTranslation.actions.delete.modal.description)
    )
    setButtonsDelPopup(SetDelPopupButtons(replaceMode))
  }

  useEffect(() => {
    if (rows.length > 0) {
      setCaption(prevCaption => {
        return {
          ...prevCaption,
          rowCount: totalRows,
        }
      })
    }
  }, [rows])

  useEffect(() => {
    GetRows()
    getFooter()
  }, [spendingRules])

  useEffect(() => {
    getSpendingRules()
  }, [query])

  useEffect(() => {
    if (confirmFavAction) {
      handleConfirmUpdateFav()
    }
  }, [confirmFavAction])

  useEffect(() => {
    if (confirmDelAction) {
      handleConfirmDeleteSR()
    }
  }, [confirmDelAction])

  useEffect(() => {
    if (!popupDelShow) {
      // remove error from select when popup is being closed
      setSelectError(false)
    }
  }, [popupDelShow])

  useEffect(() => {
    InitFavoritePopup()
    InitDeletePopup()
  }, [])

  return {
    header,
    caption,
    rows,
    setRows,
    footer,
    popupFavShow,
    popupFavTitle,
    popupFavDescription,
    popupFavButtons,
    setShowFavPopup,
    popupDelShow,
    popupDelTitle,
    popupDelDescription,
    popupDelButtons,
    setShowDelPopup,
    deleteAndReplace,
    handleSelectChange,
    selectError,
    currentActionId,
  }
}
