import { OreTab, OreTabs } from '@edenredespana/oreneta'
import { t } from 'i18next'
import { edenredProducts } from 'src/domain/enum'
import EmployeesListTableTR from '../employeesTR/organism/EmployeesListTableTR'
import { EmployeesListTableTTContainer } from '../employeesTT/organism'
import { EmployeesListTableTGDContainer } from '../employeesTGD/organism'
import { productsTranslate } from 'src/domain/translations'
import { useProductTabsController } from '../../hooks/useProductTabsController'

interface ContainerProps {
  navigateValue?: edenredProducts
}

export const EmployeesPageContainer = (props: ContainerProps): JSX.Element => {
  const { handleTabClick, selectedTab, getIconByTab, hasTR, hasTT, hasTGD } =
    useProductTabsController(props.navigateValue)
  return (
    <>
      <OreTabs>
        {hasTR && (
          <OreTab
            handleClick={() => handleTabClick(edenredProducts.ticketRestaurant)}
            active={selectedTab === edenredProducts.ticketRestaurant && true}
            label={t(productsTranslate.ticketRestaurant)}
            icon={getIconByTab(edenredProducts.ticketRestaurant)}
          />
        )}
        {hasTT && (
          <OreTab
            handleClick={() => handleTabClick(edenredProducts.ticketTransporte)}
            active={selectedTab === edenredProducts.ticketTransporte && true}
            label={t(productsTranslate.ticketTransporte)}
            icon={getIconByTab(edenredProducts.ticketTransporte)}
          />
        )}
        {hasTGD && (
          <OreTab
            handleClick={() => handleTabClick(edenredProducts.ticketGuarderia)}
            active={selectedTab === edenredProducts.ticketGuarderia && true}
            label={t(productsTranslate.ticketGuarderia)}
            icon={getIconByTab(edenredProducts.ticketGuarderia)}
          />
        )}
      </OreTabs>
      {selectedTab === edenredProducts.ticketRestaurant && <EmployeesListTableTR />}
      {selectedTab === edenredProducts.ticketTransporte && (
        <EmployeesListTableTTContainer />
      )}
      {selectedTab === edenredProducts.ticketGuarderia && (
        <EmployeesListTableTGDContainer />
      )}
    </>
  )
}
