import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Dispatch, SetStateAction, useEffect, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderLabelModel, HeaderModel } from 'src/domain/customComponents/table'
import { currency, NotificationSeverity } from 'src/domain/enum'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { useNavigate, useParams } from 'react-router-dom'
import { OreCheckbox, SvgInfo } from '@edenredespana/oreneta'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { forms, spendingRuleFormTranslation } from 'src/domain/translations'
import { useModalController } from 'src/presentation/components/Edenred'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { useTTOrder } from '../../context/TTOrderProvider'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { usePreviousURL } from 'src/presentation/components/Edenred/navigation/hooks'

export interface EmployeesTTOrderFormModel {
  employeesTT: TTOrderModel[]
}

export interface TTUnloadConfigurationEmployeeState {
  header: HeaderModel
  totalRows: number
  employeesTT: TTOrderModel[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  form: UseFormReturn<EmployeesTTOrderFormModel, any>
  addConfigToOrder(): void
  selectedEmployees: TTOrderModel[]
  setSelectedEmployees: Dispatch<SetStateAction<TTOrderModel[]>>
  onSelectEmployee(event: ChangeEvent<HTMLInputElement>, employee: TTOrderModel): void
  isSelected(employee: TTOrderModel): boolean
  showSelected: boolean
  setShowSelected: Dispatch<SetStateAction<boolean>>
  onAssignMultipleValues: (initialAmount: string, costCenter: string) => void
  showCancelModal: boolean
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  errorMessage: string
  updateDataRowToTTOrderEmployee: (
    userId: number,
    initialAmount: string,
    costCenter: string
  ) => void
  unloadAllBalance: (userId?: number) => void
}

export const useTTUnloadConfigurationEmployees =
  (): TTUnloadConfigurationEmployeeState => {
    const { t } = useTranslation()
    const {
      setTTSelectedEmployees,
      setTTEmployeesToConfig,
      getTTEmployeesToConfig,
      addOrUpdateTTOrders,
      ttOrders,
      updateTTOrder,
      clearTTOrders,
    } = useTTOrder()
    const [page, setPage] = useState(1)
    const pageSize = 8
    const [employeesTT, setEmployeesTT] = useState<TTOrderModel[]>(
      getTTEmployeesToConfig()
    )
    const [totalRows, setTotalRows] = useState<number>(0)
    const navigate = useNavigate()
    const [selectedEmployees, setSelectedEmployees] = useState<TTOrderModel[]>([])
    const [showSelected, setShowSelected] = useState<boolean>(false)
    const [selectAll, setSelectAll] = useState<boolean>(false)
    const { addNotification } = useNotification()
    const [errorMessage, setErrorMessage] = useState<string>('')

    const { id: idTTEditOrder } = useParams()
    const comesFromSummary = usePreviousURL(navigationRoutes.ttRechargeSummary)

    useEffect(() => {
      if (idTTEditOrder) {
        const orderToEdit = ttOrders.find(order => order.id === idTTEditOrder)
        if (orderToEdit) {
          setTTEmployeesToConfig([{ ...orderToEdit }])
          setEmployeesTT([{ ...orderToEdit }])
        }
      }
    }, [idTTEditOrder])

    const {
      show: showCancelModal,
      buttons: cancelPopupButtons,
      setShow: setShowCancelModal,
      setButtons: setCancelModalButtons,
    } = useModalController()

    const getDiffBetweenEmployeeArrays = (
      array1: TTOrderModel[],
      array2: TTOrderModel[]
    ): TTOrderModel[] => {
      let result: TTOrderModel[] = []
      result = array1.filter(emp1 => {
        return !array2.some(emp2 => {
          return emp1.userId === emp2.userId
        })
      })
      return result
    }

    const headerLabel: HeaderLabelModel[] = [
      { label: t(employeesTranslation.table.header.titular) },
      {
        label: t(TTOrderTranslation.unload.employeesConfiguration.initialBalance),
        tooltip: t(
          TTOrderTranslation.unload.employeesConfiguration.initialBalanceTooltip
        ),
        tooltipIcon: <SvgInfo />,
      },
      {
        label: t(TTOrderTranslation.unload.employeesConfiguration.initialAmount),
        tooltip: t(TTOrderTranslation.unload.employeesConfiguration.initialAmountTooltip),
        tooltipIcon: <SvgInfo />,
      },
      { label: t(employeesTranslation.table.header.costCenter) },
      { label: '' },
    ]

    const headerElement: JSX.Element = (
      <OreCheckbox
        name="check"
        checked={
          selectAll ||
          getDiffBetweenEmployeeArrays(employeesTT, selectedEmployees).length <= 0
        }
        onChange={event => {
          onSelectAllEmployees(event)
        }}
        disabled={showSelected}
      />
    )

    const header: HeaderModel = !idTTEditOrder
      ? {
          headerElement: headerElement,
          headerLabel: headerLabel,
        }
      : { headerLabel: headerLabel }

    const onPageChange = (page: number): void => {
      setPage(page)
    }

    const schema = Yup.object({
      employeesTT: Yup.array().of(
        Yup.object().shape({
          costCenter: Yup.string().nullable().max(50, t(forms.errors.maxAllowed)),
          cardData: Yup.object().shape({
            initialAmount: Yup.number()
              .transform(value => (Number.isNaN(value) ? null : value))
              .nullable()
              .required(t(forms.errors.fieldRequired))
              .min(
                0.1,
                `${t(
                  spendingRuleFormTranslation.form.errors.amountMustBeGreaterThanOrEqual
                )} ${0.1 + currency.euro}`
              )
              .max(
                1500,
                `${t(
                  spendingRuleFormTranslation.form.errors.amountMustBeLessThanOrEqual
                )}  ${1500 + currency.euro}`
              )
              .test(
                'decimal-max-2-places',
                t(
                  spendingRuleFormTranslation.form.errors.amountMustContainMaxTwoDecimals
                ),
                value => {
                  const isNullable = value === null || value === undefined
                  if (isNullable) {
                    return true
                  }
                  const regex = /^\d+(\.\d{1,2})?$/
                  return regex.test(value?.toString())
                }
              ),
          }),
        })
      ),
    })

    const form = useForm<EmployeesTTOrderFormModel>({
      resolver: yupResolver(schema),
      mode: 'all',
      defaultValues: {
        employeesTT: employeesTT,
      },
    })

    const addConfigToOrder = (): void => {
      addOrUpdateTTOrders(employeesTT)
      setTTSelectedEmployees([])
      setTTEmployeesToConfig([])
      navigate(navigationRoutes.ttUnloadSummary)
    }

    const onSelectEmployee = (
      event: ChangeEvent<HTMLInputElement>,
      employee: TTOrderModel
    ): void => {
      if (event.target.checked) {
        const exist = selectedEmployees.find(emp => emp.userId == employee.userId)
        if (!exist) {
          setSelectedEmployees(prev => [...prev, employee])
        }
      } else {
        setSelectedEmployees(
          selectedEmployees.filter(emp => emp.userId !== employee.userId)
        )
      }
    }

    const onSelectAllEmployees = (event: ChangeEvent<HTMLInputElement>): void => {
      const selectedArray: TTOrderModel[] = []
      setSelectAll(event.target.checked)
      if (event.target.checked) {
        const difference = getDiffBetweenEmployeeArrays(employeesTT, selectedEmployees)
        selectedArray.push(...difference)
        if (selectedArray.length > 0)
          setSelectedEmployees(prev => [...prev, ...selectedArray])
      } else {
        selectedArray.push(
          ...selectedEmployees.filter((emp: TTOrderModel) => {
            return !employeesTT.some((e: TTOrderModel) => {
              return emp.userId === e.userId
            })
          })
        )
        setSelectedEmployees([...selectedArray])
      }
    }

    const isSelected = (employee: TTOrderModel): boolean => {
      const exist = selectedEmployees.find(emp => emp.userId == employee.userId)
      return !!exist
    }

    const onAssignMultipleValues = (initialAmount: string, costCenter: string): void => {
      const copyList = [...employeesTT]
      copyList.map((employee, index) => {
        if (selectedEmployees.includes(employee)) {
          if (initialAmount && initialAmount !== '') {
            employee.cardData.initialAmount = +initialAmount
            form.setValue(
              `employeesTT.${index + (page - 1) * pageSize}.cardData.initialAmount`,
              +initialAmount
            )
          }
          if (costCenter) {
            employee.costCenter = costCenter
            form.setValue(
              `employeesTT.${index + (page - 1) * pageSize}.costCenter`,
              costCenter
            )
          }
          return employee
        }
      })
      setEmployeesTT(copyList)
      setDefaultValuesAll()

      if (
        selectAll ||
        getDiffBetweenEmployeeArrays(employeesTT, selectedEmployees).length <= 0
      ) {
        form.trigger()
      }
    }

    const onClickCancel = (): void => {
      setShowCancelModal(true)
      setCancelModalButtons(getCancelModalButtons())
    }

    const getCancelModalButtons = (): PopupButtonModel[] => {
      return [
        {
          title: t(forms.buttons.cancel),
          category: 'secondary',
          onClick: () => setShowCancelModal(false),
          size: 'large',
        },
        {
          title: t(forms.buttons.discard),
          category: 'primary',
          onClick: () => {
            setTTEmployeesToConfig([])
            setTTSelectedEmployees([])
            setShowCancelModal(false)
            if (!comesFromSummary) {
              clearTTOrders()
              addNotification(
                t(forms.success.actionMessage),
                NotificationSeverity.success
              )
              navigate(navigationRoutes.myOrdersTtTab)
            } else {
              navigate(navigationRoutes.ttRechargeSummary)
            }
          },
          size: 'large',
        },
      ]
    }

    const updateDataRowToTTOrderEmployee = (
      userId: number,
      initialAmount: string,
      costCenter: string
    ): void => {
      const indexEmployeeToConfig: number = employeesTT.findIndex(
        (o: TTOrderModel) => o.userId === userId
      )
      const employeeOnOrder = ttOrders.find((o: TTOrderModel) => o.userId === userId)

      if (indexEmployeeToConfig >= 0) {
        const updatedOrders: TTOrderModel[] = [...employeesTT]
        const employee = { ...updatedOrders[indexEmployeeToConfig] }
        if (initialAmount) employee.cardData.initialAmount = +initialAmount
        if (costCenter) employee.costCenter = costCenter
        updatedOrders[indexEmployeeToConfig] = employee
        setEmployeesTT(updatedOrders)
      }

      if (employeeOnOrder) {
        if (initialAmount) employeeOnOrder.cardData.initialAmount = +initialAmount
        if (costCenter) employeeOnOrder.costCenter = costCenter
        updateTTOrder(employeeOnOrder)
      }
    }

    const setDefaultValuesAll = () => {
      employeesTT.forEach((employee, index) => {
        if (employee.cardData.initialAmount)
          form.setValue(
            `employeesTT.${index}.cardData.initialAmount`,
            employee.cardData.initialAmount
          )
        if (employee.costCenter)
          form.setValue(`employeesTT.${index}.costCenter`, employee.costCenter)
      })
    }

    const setDefaultValuesByPage = () => {
      const start = (page - 1) * pageSize
      const end = start + pageSize
      const pageEmployees = employeesTT.slice(start, end)
      pageEmployees.forEach((employee, index) => {
        if (employee.cardData.initialAmount) {
          form.setValue(
            `employeesTT.${index + (page - 1) * pageSize}.cardData.initialAmount`,
            employee.cardData.initialAmount
          )
        } else {
          form.setValue(
            `employeesTT.${index + (page - 1) * pageSize}.cardData.initialAmount`,
            null
          )
        }
        if (employee.costCenter) {
          form.setValue(
            `employeesTT.${index + (page - 1) * pageSize}.costCenter`,
            employee.costCenter
          )
        } else {
          form.setValue(`employeesTT.${index + (page - 1) * pageSize}.costCenter`, '')
        }
      })
    }

    const unloadAllBalance = (userId?: number): void => {
      if (employeesTT && employeesTT.length <= 0) return
      if (userId) {
        employeesTT.forEach((emp, index) => {
          if (emp.userId === userId) {
            const balance = emp.cardData.balance
            const value = balance ? +balance : 0
            form.setValue(`employeesTT.${index}.cardData.initialAmount`, value)
            form.trigger(`employeesTT.${index}.cardData.initialAmount`)
          }
        })
      } else {
        employeesTT.forEach((emp, index) => {
          const balance = emp.cardData.balance
          const value = balance ? +balance : 0
          form.setValue(`employeesTT.${index}.cardData.initialAmount`, value)
          form.trigger()
        })
      }
    }

    useEffect(() => {
      if (selectedEmployees.length === 0) setShowSelected(false)
    }, [selectedEmployees])

    useEffect(() => {
      setTotalRows(employeesTT.length)
      setDefaultValuesByPage()
    }, [employeesTT])

    useEffect(() => {
      setDefaultValuesByPage()
    }, [page])

    return {
      header,
      totalRows,
      employeesTT,
      page,
      setPage,
      pageSize,
      onPageChange,
      form,
      selectedEmployees,
      setSelectedEmployees,
      addConfigToOrder,
      onSelectEmployee,
      isSelected,
      showSelected,
      setShowSelected,
      onAssignMultipleValues,
      showCancelModal,
      setShowCancelModal,
      cancelPopupButtons,
      onClickCancel,
      errorMessage,
      updateDataRowToTTOrderEmployee,
      unloadAllBalance,
    }
  }
