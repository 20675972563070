import { SvgArrowWidget } from '@edenredespana/oreneta'
import { t } from 'i18next'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { cardOrderService } from 'src/core/services'
import { ClientHomeTablesContext, InvoiceTable } from 'src/core/strategies'
import {
  CaptionModel,
  FooterModel,
  RowModel,
  TableRowEspec,
} from 'src/domain/customComponents/table'
import { IInvoice } from 'src/domain/interfaces/invoice/IInvoiceApiResponse'
import { orderHomeTranslation } from 'src/domain/translations/cardOrder/home'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'

interface InvoiceClientState {
  rows: RowModel[]
  caption: CaptionModel
  setRows: Dispatch<SetStateAction<RowModel[]>>
  footer: FooterModel
}

export const useInvoiceClientHome = (): InvoiceClientState => {
  const navigate = useNavigate()
  const { startLoading, stopLoading } = useLoader()
  const [rows, setRows] = useState<RowModel[]>([])
  const [caption, setCaption] = useState<CaptionModel>({ description: '', title: '' })
  const [invoices, setInvoices] = useState<IInvoice[]>()

  const GetRows = async (): Promise<void> => {
    const strategy: ClientHomeTablesContext<IInvoice> = new ClientHomeTablesContext(
      new InvoiceTable()
    )
    const tableRowsSpec: TableRowEspec<IInvoice> = await strategy.GetRows()
    setRows(tableRowsSpec.rows)
    setInvoices(tableRowsSpec.rowsFromBackEnd)
    setCaption(strategy.GetCaption())
  }

  const downloadInvoice = (
    creditNoteId: number,
    invoiceNumber: string,
    isInvoiceDownloadable: boolean,
    isInvoiceReadyToDownload: boolean
  ): void => {
    if (!isInvoiceDownloadable || !isInvoiceReadyToDownload) return
    startLoading()
    cardOrderService()
      .downloadInvoice(creditNoteId)
      .then(response => {
        const link = document.createElement('a')
        link.href = `data:application/pdf;base64,${response.data.file}`
        link.download = invoiceNumber ? invoiceNumber + '.pdf' : 'invoice.pdf'
        link.click()
        link.remove()
      })
      .finally(() => {
        stopLoading()
      })
  }

  const GetRowsAction = (): void => {
    const strategy: ClientHomeTablesContext<IInvoice> = new ClientHomeTablesContext(
      new InvoiceTable()
    )
    setRows(strategy.GetRowActions(rows, downloadInvoice, invoices))
  }

  const footer: FooterModel = {
    actionButtons: [
      {
        icon: <SvgArrowWidget style={{ transform: 'rotate(180deg)' }} />,
        text: t(orderHomeTranslation.incompleteTable.action),
        onClick: () => navigate(navigationRoutes.invoices),
        category: 'highlight',
        size: 'large',
      },
    ],
  }

  useEffect(() => {
    GetRows()
  }, [])
  useEffect(() => {
    if (rows.length > 0) GetRowsAction()
  }, [rows])
  return { rows, caption, setRows, footer }
}
