import { OreHeading, OreStack, OreText } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { Divider } from 'src/presentation/layout'
import { getUndefinedDashString } from 'src/core/services'
import { forms } from 'src/domain/translations'
import { EmployeesWithLastCard } from 'src/domain/models'

interface EmployeeProfileFormLabelsTTProps {
  employeeData: EmployeesWithLastCard
}

export const EmployeeProfileFormLabelsTT = ({
  employeeData,
}: EmployeeProfileFormLabelsTTProps): JSX.Element => {
  const { t } = useTranslation()

  if (!employeeData) {
    return <></>
  }

  return (
    <div className="pt-3 pb-2">
      <OreHeading size="headline-sm">
        {t(employeesTranslation.profile.form.title)}
      </OreHeading>
      <Divider marginTop="1rem" marginBottom="2rem" />
      <div className="mb-105">
        <div className="mb-105">
          <OreStack direction="row">
            <OreStack>
              <OreText bold>{t(forms.contact.email)}:</OreText>
              <OreText bold>{t(forms.contact.phone)}:</OreText>
              <OreText bold>{t(forms.contact.birthdate)}:</OreText>
              <OreText bold>
                {t(employeesTranslation.profile.form.employeeNumber)}:
              </OreText>
              <OreText bold>{t(employeesTranslation.profile.form.costCenter)}</OreText>
            </OreStack>
            <OreStack>
              <OreText>{employeeData.corporativeEmail}</OreText>
              <OreText>{employeeData.corporativeTelephone}</OreText>
              <OreText>{employeeData.birthDate}</OreText>
              <OreText>{employeeData.userId}</OreText>
              <OreText>{getUndefinedDashString(employeeData.costCenter)}</OreText>
            </OreStack>
          </OreStack>
        </div>
      </div>
    </div>
  )
}
