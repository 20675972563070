import {
  OreTableContainer,
  OreTable,
  OreTableHead,
  OreTableRow,
  OreTableCell,
  OreTableBody,
  OrePagination,
  OreTableActions,
  OreText,
} from '@edenredespana/oreneta'

import { useTranslation } from 'react-i18next'
import { tableSelector } from 'src/domain/translations/tables/tableSelector'

import type { HeadersModel } from 'src/domain/models/tables/Headers'
import { PayrollModel } from 'src/Flex/Payrolls/domain'
import { FlexPayrollRow, usePayrolls } from 'src/Flex/Payrolls/ui/payroll-list'
import { flexPayrollsTranslations } from 'src/Flex/Payrolls/ui/translations'
import { TableSearcher } from 'src/Flex/Shared/ui/TableSearcher'
import { GetMonthFormattedWithYear } from 'src/core/helpers'

export const FlexPayrollList = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    payrolls,
    page,
    changePage,
    numberOfRecordsPerPage,
    totalRows,
    shownDate,
    search,
    searchParameters,
  } = usePayrolls()

  const payrollsHeaders: HeadersModel[] = [
    {
      key: 'product',
    },
    {
      key: 'name',
      label: t(flexPayrollsTranslations.table.header.name),
      size: 'quarter',
    },
    {
      key: 'collective',
      label: t(flexPayrollsTranslations.table.header.collective),
      size: 'quarter',
    },
    {
      key: 'exent',
      label: t(flexPayrollsTranslations.table.header.exemptValue),
    },
    {
      key: 'nonExent',
      label: t(flexPayrollsTranslations.table.header.nonExemptValue),
    },
  ]

  return (
    <>
      {payrolls.length === 0 && searchParameters.search === '' ? (
        <OreText>{t(flexPayrollsTranslations.table.noRows)}</OreText>
      ) : (
        <OreTableContainer>
          <TableSearcher
            handleSearch={search}
            title={t(flexPayrollsTranslations.table.title, {
              month: shownDate ? GetMonthFormattedWithYear(shownDate) : '-',
            })}
          />
          <OreTable>
            <OreTableHead valign="middle">
              <OreTableRow hover={false}>
                {payrollsHeaders.map(
                  (header: HeadersModel): JSX.Element => (
                    <OreTableCell as="th" key={header.key} size={header.size}>
                      <span style={{ whiteSpace: 'pre-line' }}>{header.label}</span>
                    </OreTableCell>
                  )
                )}
              </OreTableRow>
            </OreTableHead>
            <OreTableBody valign="top">
              {payrolls.map(
                (payroll: PayrollModel, index: number): JSX.Element => (
                  <FlexPayrollRow key={index} payroll={payroll} />
                )
              )}
            </OreTableBody>
          </OreTable>
          {payrolls.length === 0 ? (
            <OreTableActions>
              <OreText size="text-sm" tone="neutral-900">
                {t(flexPayrollsTranslations.table.noRowsWithFilters)}
              </OreText>
            </OreTableActions>
          ) : null}
          {payrolls.length > 0 ? (
            <OreTableActions>
              <OrePagination
                displayPageButtons
                count={totalRows}
                labelNextPage={t(tableSelector.nextPageLabel)}
                labelPreviousPage={t(tableSelector.previousPageLabel)}
                onPageChange={changePage}
                page={page + 1}
                rowsPerPage={numberOfRecordsPerPage}
                text={t(tableSelector.pageOf)}
                legend={t(tableSelector.rowsShowing)}
              />
            </OreTableActions>
          ) : null}
        </OreTableContainer>
      )}
    </>
  )
}
