import { OreCheckbox, SvgInfo } from '@edenredespana/oreneta'
import { ChangeEvent, Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { cardOrderService } from 'src/core/services'
import { getEmployeesWithLastCardMap } from 'src/domain/adapters/cardOrder/getEmployeesWithLastCardMap'
import { HeaderModel } from 'src/domain/customComponents/table'
import { currency, edenredProducts, orderType } from 'src/domain/enum'
import { CardOrderModel } from 'src/domain/models'
import { EmployeesWithLastCardNew } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardNew'
import { EmployeesWithLastCardRequest } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardRequest'
import { unloadTranslation } from 'src/domain/translations'
import { useCardOrder } from 'src/presentation/context/cardOrder/CardOrderProvider'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useUser } from 'src/presentation/context/user/UserProvider'
import { useModal } from 'src/presentation/hooks'

export interface UnloadEmployeesState {
  header: HeaderModel
  totalRows: number
  employees: EmployeesWithLastCardNew[]
  employeesSelected: EmployeesWithLastCardNew[]
  setEmployeesSelected: Dispatch<SetStateAction<EmployeesWithLastCardNew[]>>
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  showDismissModal: boolean
  setShowDismissModal: Dispatch<SetStateAction<boolean>>
  search(filter: string): void
  onSelectEmployee(
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesWithLastCardNew
  ): void
  isSelected(employee: EmployeesWithLastCardNew): boolean
  showSelected: boolean
  setShowSelected: Dispatch<SetStateAction<boolean>>
  goToConfig: () => void
}

export const useUnloadEmployees = (): UnloadEmployeesState => {
  const { t } = useTranslation()
  const [totalRows, setTotalRows] = useState<number>(0)
  const { showModal: showDismissModal, setShowModal: setShowDismissModal } = useModal()
  const [page, setPage] = useState(1)
  const pageSize = 8
  const { startLoading, stopLoading } = useLoader()
  const [employees, setEmployees] = useState<EmployeesWithLastCardNew[]>([])
  const { getCardOrderUnload, setTREmployeesToConfig, orders } = useCardOrder()
  const [employeesSelected, setEmployeesSelected] = useState<EmployeesWithLastCardNew[]>(
    getCardOrderUnload()
  )
  const [showSelected, setShowSelected] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [sortByEmployeeNumberAsc, setSortByEmployeeNumberAsc] = useState<boolean>(true)
  const navigate = useNavigate()
  const { company } = useUser()

  const [query, setQuery] = useState<EmployeesWithLastCardRequest>({
    productCode: edenredProducts.ticketRestaurant,
    paginationModel: {
      orderByField: 'employeeNumber',
      sortDescending: !sortByEmployeeNumberAsc,
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    },
    userDnisToExclude:
      orders && orders.length > 0 ? orders.map(a => a.employeeData.document) : [],
  })

  const getDiffBetweenEmployeeArrays = (
    array1: EmployeesWithLastCardNew[],
    array2: EmployeesWithLastCardNew[]
  ): EmployeesWithLastCardNew[] => {
    let result: EmployeesWithLastCardNew[] = []
    result = array1.filter(emp1 => {
      return !array2.some(emp2 => {
        return emp1.userId === emp2.userId
      })
    })
    return result
  }

  const header: HeaderModel = {
    headerElement: (
      <OreCheckbox
        name="check"
        checked={
          selectAll ||
          getDiffBetweenEmployeeArrays(employees, employeesSelected).length <= 0
        }
        onChange={event => {
          onSelectAllEmployees(event)
        }}
        disabled={showSelected}
      />
    ),
    headerLabel: [
      { label: t(unloadTranslation.tableHeader.owner) },
      {
        label: t(unloadTranslation.tableHeader.employeeNumber),
        isSortable: true,
        sortDirection: sortByEmployeeNumberAsc ? 'asc' : 'desc',
        onClick: () => {
          sortRowsByEmployeeNumber()
        },
      },
      { label: t(unloadTranslation.tableHeader.status) },
      {
        label: t(unloadTranslation.tableHeader.balance),
        tooltip: t(unloadTranslation.tableHeader.balanceTooltip),
        tooltipIcon: <SvgInfo aria-label="info" />,
      },
      { label: t(unloadTranslation.tableHeader.center) },
    ],
  }

  const sortRowsByEmployeeNumber = () => {
    setSortByEmployeeNumberAsc(!sortByEmployeeNumberAsc)
  }

  const getEmployeesDuplicate = (): void => {
    startLoading()
    cardOrderService()
      .getEmployeesWithLastCardUnload(query)
      .then(response => {
        const result = getEmployeesWithLastCardMap(response.data.rows)
        setEmployees(result)
        setTotalRows(response.data.totalRows)
      })
      .finally(() => stopLoading())
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...query,
      paginationModel: {
        orderByField: 'employeeNumber',
        sortDescending: !sortByEmployeeNumberAsc,
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
    setPage(page)
    setSelectAll(false)
  }

  const search = (filter: string): void => {
    setQuery({
      ...query,
      userNameOrDNIFilter: filter,
      paginationModel: {
        orderByField: 'employeeNumber',
        sortDescending: !sortByEmployeeNumberAsc,
        numberOfRecordsPerPage: pageSize,
        pageNumber: 0,
      },
    })
    setPage(1)
  }

  const onSelectEmployee = (
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesWithLastCardNew
  ): void => {
    if (event.target.checked) {
      const exist = employeesSelected.find(emp => emp.userId == employee.userId)
      if (!exist) {
        setEmployeesSelected(prev => [...prev, employee])
      }
    } else {
      setEmployeesSelected(
        employeesSelected.filter(emp => emp.userId !== employee.userId)
      )
    }
  }

  const onSelectAllEmployees = (event: ChangeEvent<HTMLInputElement>): void => {
    const selectedArray: EmployeesWithLastCardNew[] = []
    setSelectAll(event.target.checked)
    if (event.target.checked) {
      const difference = getDiffBetweenEmployeeArrays(employees, employeesSelected)
      selectedArray.push(...difference)
      if (selectedArray.length > 0)
        setEmployeesSelected(prev => [...prev, ...selectedArray])
    } else {
      selectedArray.push(
        ...employeesSelected.filter((emp: EmployeesWithLastCardNew) => {
          return !employees.some((e: EmployeesWithLastCardNew) => {
            return emp.userId === e.userId
          })
        })
      )
      setEmployeesSelected([...selectedArray])
    }
  }

  const isSelected = (employee: EmployeesWithLastCardNew): boolean => {
    const exist = employeesSelected.find(emp => emp.userId == employee.userId)
    return !!exist
  }

  const mapEmployee = (employee: EmployeesWithLastCardNew): CardOrderModel => {
    const cardOrderObject: CardOrderModel = {
      orderId: employee.userId,
      orderTypeId: orderType.Unload,
      cardData: {
        productTypeId: edenredProducts.ticketRestaurant,
        cardTypeId: Number(employee.cardTypeId),
        initialAmount: null,
        balance: employee.balance
          ? employee.balance.toString().replace(currency.euro, '')
          : '',
      },
      employeeData: {
        name: employee.userName?.trim(),
        firstSurname: '',
        secondSurname: '',
        document: employee.document?.trim(),
        companyId: company?.code,
        employeeNumber: employee.employeeNumber,
        costCenter: employee.costCenter,
        userId: employee.userId,
      },
    }
    return cardOrderObject
  }

  const goToConfig = (): void => {
    if (employeesSelected && employeesSelected.length <= 0) return
    const arrayEmployees: CardOrderModel[] = []
    employeesSelected.forEach(selectedEmployee => {
      arrayEmployees.push(mapEmployee(selectedEmployee))
    })
    setTREmployeesToConfig(arrayEmployees)
    navigate(navigationRoutes.cardOrderUnloadConfiguration)
  }

  useEffect(() => {
    if (!showSelected) getEmployeesDuplicate()
  }, [query])

  useEffect(() => {
    if (employeesSelected.length === 0) setShowSelected(false)
  }, [employeesSelected])

  useEffect(() => {
    setQuery({
      ...query,
      paginationModel: {
        orderByField: 'employeeNumber',
        sortDescending: !sortByEmployeeNumberAsc,
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
  }, [sortByEmployeeNumberAsc])

  return {
    header,
    totalRows,
    employees,
    page,
    setPage,
    pageSize,
    onPageChange,
    showDismissModal,
    setShowDismissModal,
    search,
    employeesSelected,
    setEmployeesSelected,
    onSelectEmployee,
    isSelected,
    showSelected,
    setShowSelected,
    goToConfig,
  }
}
