import {
  OreIconButton,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
  SvgShow,
  SvgUnsubscribeUser,
} from '@edenredespana/oreneta'
import { EmployeesWithLastCard } from 'src/domain/models'
import { useEmployeesSharedController } from '../../hooks'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { useTranslation } from 'react-i18next'
import { getUndefinedDashString } from 'src/core/services'

interface Props {
  employeeTT: EmployeesWithLastCard
  onClickDelete(document: string): void
  onClickEdit(employee: EmployeesWithLastCard): void
}

export const EmployeeListTableTTRow = ({
  employeeTT,
  onClickDelete,
  onClickEdit,
}: Props): JSX.Element => {
  const { getCardStatusPill } = useEmployeesSharedController()
  const { t } = useTranslation()
  return (
    <OreTableRow>
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {employeeTT.userName}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {employeeTT.document}
        </OreText>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {getUndefinedDashString(employeeTT.employeeNumber)}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {employeeTT.balance}€
        </OreText>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {getCardStatusPill(employeeTT.cardStatusId)}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <Grid gap=".5rem">
          <OreTooltip
            text={t(employeesTranslation.table.header.deleteTooltip)}
            placement="top"
            separation="small">
            <OreIconButton
              onClick={() => onClickDelete(employeeTT.document)}
              icon={<SvgUnsubscribeUser />}
            />
          </OreTooltip>
          <OreTooltip
            text={t(employeesTranslation.table.header.viewTooltip)}
            placement="top"
            separation="small">
            <OreIconButton onClick={() => onClickEdit(employeeTT)} icon={<SvgShow />} />
          </OreTooltip>
        </Grid>
      </OreTableCell>
    </OreTableRow>
  )
}
