import { OreTableCell, OreTableHead, OreTableRow } from '@edenredespana/oreneta'
import { HeadersModel } from 'src/domain/models/tables/Headers'

type TableHeadProps = {
  tableHeaders: HeadersModel[]
}

export const TableHead = ({ tableHeaders }: TableHeadProps): JSX.Element => (
  <OreTableHead>
    <OreTableRow hover={false}>
      {tableHeaders.map((header: HeadersModel) => (
        <OreTableCell as="th" key={header.key} size={header.size}>
          {header.label}
        </OreTableCell>
      ))}
    </OreTableRow>
  </OreTableHead>
)
