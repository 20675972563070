import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, FieldValues, UseFormProps, UseFormReturn } from 'react-hook-form'
import { useTranslation, TFunction } from 'react-i18next'
import type {
  PolicyModel,
  ProductConfigurationModels,
  TicketGuarderiaConfigurationModel,
} from 'src/Flex/Products/domain'
import { productsService } from 'src/Flex/Products/application'
import { edenredProducts, MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useProducts } from 'src/Flex/Products/ui/product-list'
import ObjectSchema, { ObjectShape } from 'yup/lib/object'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { flexProductsTranslations } from '../../translations'
import { useUserData } from 'src/Flex/User/ui/context'
import { ExternalHealthForm } from './products'
import { IMonths } from 'src/domain/interfaces/global/IMonths'
import { CollectiveConfigurationModel } from 'src/Flex/Collectives/domain'

interface UseConfigureProduct<
  T extends FieldValues,
  K extends keyof ProductConfigurationModels
> {
  load: () => Promise<void>
  save: (data: T) => Promise<boolean>
  deletePolicy: (id: string) => Promise<boolean>
  reset: () => void
  form: UseFormReturn<T>
  model: ProductConfigurationModels[K] | undefined
  isGlobal: boolean
  validateEmployeeAffected: (productType: edenredProducts, data: T) => Promise<void>
  employeesAffected: number | undefined
  setEmployeesAffected: Dispatch<SetStateAction<number | undefined>>
  confirmEmployeeAffected: (productType: edenredProducts, data: T) => Promise<boolean>
  confirmPolicy: (data: PolicyModel) => Promise<boolean>
  validatePolicy: (data: PolicyModel) => Promise<void>
  policyEmployeesAffected: PolicyEmployeesAffected[]
  setPolicyEmployeesAffected: Dispatch<SetStateAction<PolicyEmployeesAffected[]>>
  getProduct: () => ProductConfigurationModels[K]
  validateDeletePolicy: (id: string) => Promise<void>
  collective: CollectiveConfigurationModel | undefined
}

export interface PolicyEmployeesAffected {
  policyId: string | undefined
  employeesAffected: number | undefined
}

export const useConfigureProduct = <
  T extends FieldValues,
  K extends keyof ProductConfigurationModels
>({
  productType,
  formProps,
  formValidationScheme,
  convertFromFormToAPI,
  convertFromAPIToForm,
  setPolicyStateInit,
  globalValues,
}: {
  productType: K
  formProps: UseFormProps<T>
  formValidationScheme: (
    t: TFunction<'translation', undefined>
  ) => ObjectSchema<ObjectShape>
  convertFromFormToAPI?: (data: T, isGlobal: boolean) => ProductConfigurationModels[K]
  convertFromAPIToForm?: (data: ProductConfigurationModels[K]) => T
  setPolicyStateInit?: (data: T) => PolicyEmployeesAffected[]
  globalValues?: (form: UseFormReturn<T>) => T
}): UseConfigureProduct<T, K> => {
  const { getInitialFlexData } = useUserData()
  const [model, setModel] = useState<ProductConfigurationModels[K]>()
  const [employeesAffected, setEmployeesAffected] = useState<number | undefined>(
    undefined
  )
  const [policyEmployeesAffected, setPolicyEmployeesAffected] = useState<
    PolicyEmployeesAffected[]
  >([])
  const { startLoading, stopLoading } = useLoader()
  const { t } = useTranslation()
  const { addNotification } = useNotification()
  const { handleMetaResponse } = useMetaResponseHandler()
  const { collective, loadProducts } = useProducts()

  const isGlobal = collective === undefined

  const form = useForm<T>({
    resolver: yupResolver(formValidationScheme(t)),
    mode: 'onChange',
    ...formProps,
  })

  const validateEmployeeAffected = async (
    productType: edenredProducts,
    data: T
  ): Promise<void> => {
    startLoading()
    const response = await productsService().ValidateEmployeeAffected(
      convertFromFormToAPI
        ? convertFromFormToAPI(data, isGlobal)
        : (data as unknown as ProductConfigurationModels[K]),
      productType,
      collective?.id
    )
    stopLoading()
    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setEmployeesAffected(response.data.numberOfEmployeesContract)
    }
  }

  const validatePolicy = async (data: PolicyModel): Promise<void> => {
    startLoading()

    const response = await productsService().validateHealthExternalPolicyConfiguration(
      data
    )
    stopLoading()
    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      const cloneArray: PolicyEmployeesAffected[] = [...policyEmployeesAffected]
      cloneArray.map(policy => {
        if (policy.policyId === data.id) {
          policy.employeesAffected = response.data.numberOfEmployeesContract
        }
      })
      setPolicyEmployeesAffected(cloneArray)
    }
  }

  const confirmEmployeeAffected = async (
    productType: edenredProducts,
    data: T
  ): Promise<boolean> => {
    startLoading()

    const response = await productsService().ConfirmEmployeeAffected(
      convertFromFormToAPI
        ? convertFromFormToAPI(data, isGlobal)
        : (data as unknown as ProductConfigurationModels[K]),
      productType,
      collective?.id
    )

    stopLoading()

    if (
      response?.meta.status === MetaStatusCodes.INFO ||
      response?.meta.status === MetaStatusCodes.SUCCESS
    )
      loadProducts()
    await getInitialFlexData()
    if (response?.meta.status !== MetaStatusCodes.ERROR)
      addNotification(t(flexProductsTranslations.success), NotificationSeverity.success)

    return (
      handleMetaResponse(response?.meta, form) ||
      response?.meta.status === MetaStatusCodes.INFO
    )
  }

  const save = async (data: T): Promise<boolean> => {
    startLoading()

    const response = await productsService().SaveProductConfiguration(
      convertFromFormToAPI
        ? convertFromFormToAPI(data, isGlobal)
        : (data as unknown as ProductConfigurationModels[K]),
      productType,
      collective?.id
    )

    stopLoading()

    if (
      response?.meta.status === MetaStatusCodes.INFO ||
      response?.meta.status === MetaStatusCodes.SUCCESS
    )
      loadProducts()
    await getInitialFlexData()
    if (response?.meta.status !== MetaStatusCodes.ERROR) {
      addNotification(t(flexProductsTranslations.success), NotificationSeverity.success)
      setEmployeesAffected(undefined)
    }

    return (
      handleMetaResponse(response?.meta, form) ||
      response?.meta.status === MetaStatusCodes.INFO
    )
  }

  const confirmPolicy = async (data: PolicyModel): Promise<boolean> => {
    startLoading()

    const response = await productsService().confirmHealthExternalPolicyConfiguration(
      data
    )

    stopLoading()
    if (response?.meta.status !== MetaStatusCodes.ERROR) {
      addNotification(t(flexProductsTranslations.success), NotificationSeverity.success)
      const cloneArray: PolicyEmployeesAffected[] = [...policyEmployeesAffected]
      cloneArray.map(policy => {
        if (policy.policyId === data.id) {
          policy.employeesAffected = undefined
        }
      })
      setPolicyEmployeesAffected(cloneArray)
    }

    return (
      handleMetaResponse(response?.meta, form) ||
      response?.meta.status === MetaStatusCodes.INFO
    )
  }

  const validateDeletePolicy = async (id: string): Promise<void> => {
    startLoading()

    const response =
      await productsService().validateDeleteHealthExternalPolicyConfiguration(id)

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      const cloneArray: PolicyEmployeesAffected[] = [...policyEmployeesAffected]
      cloneArray.map(policy => {
        if (policy.policyId === id) {
          policy.employeesAffected = response.data.numberOfEmployeesContract
        }
      })
      setPolicyEmployeesAffected(cloneArray)
    }
  }

  const deletePolicy = async (id: string): Promise<boolean> => {
    startLoading()

    const response = await productsService().DeleteHealthExternalPolicyConfiguration(id)

    stopLoading()
    if (response?.meta.status !== MetaStatusCodes.ERROR)
      addNotification(t(flexProductsTranslations.success), NotificationSeverity.success)

    return (
      handleMetaResponse(response?.meta, form) ||
      response?.meta.status === MetaStatusCodes.INFO
    )
  }

  const load = async (): Promise<void> => {
    startLoading()
    const response = await productsService().GetProductConfiguration(
      productType,
      collective?.id
    )
    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      form.reset({
        ...form.getValues(),
        ...(convertFromAPIToForm
          ? convertFromAPIToForm(response.data)
          : (response.data as unknown as Partial<T>)),
      })
      const policyInitState =
        setPolicyStateInit &&
        convertFromAPIToForm &&
        setPolicyStateInit(convertFromAPIToForm(response.data))
      if (policyInitState) {
        setPolicyEmployeesAffected(policyInitState)
      }
      setModel(response.data)
    }
  }

  const reset = (): void => {
    if (globalValues) {
      form.reset({ ...form.getValues(), ...globalValues(form) })
    }
  }

  const getProduct = (): ProductConfigurationModels[K] => {
    const data = form.getValues()
    return convertFromFormToAPI
      ? convertFromFormToAPI(data, isGlobal)
      : (data as unknown as ProductConfigurationModels[K])
  }

  return {
    isGlobal,
    model,
    form,
    load,
    save,
    deletePolicy,
    reset,
    validateEmployeeAffected,
    employeesAffected,
    setEmployeesAffected,
    confirmEmployeeAffected,
    validatePolicy,
    confirmPolicy,
    policyEmployeesAffected,
    setPolicyEmployeesAffected,
    getProduct,
    validateDeletePolicy,
    collective,
  }
}
