import { OreHeading, OreMessageBar, OreText } from '@edenredespana/oreneta'
import { Modal } from 'src/presentation/components/Edenred'
import { TGDBasicOrderSummaryButtons, TGDBasicOrderSummaryForm } from '../molecules'
import { useTGDBasicOrderSummaryContainer } from '../hooks/useTGDBasicOrderSummaryContainer'
import { TGDBasicOrderSummaryTable } from '../molecules/TGDBasicOrderSummaryTable'
import { TGDBasicOrderSaveAndClose } from '../../TGDBasicSaveAndCloseModal'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { ModalSpinner } from 'src/Flex/Shared'
import {
  OrderValidationErrorStep,
  orderValidationsTranslations,
} from 'src/Flex/Orders/ui/order-validation'

export const TGDBasicOrderSummaryContainer = (): JSX.Element => {
  const props = useTGDBasicOrderSummaryContainer()
  const {
    showDeleteModal,
    setShowDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
    showSpinner,
    errorData,
    excelError,
    backToSummary,
  } = props
  const { t } = useTranslation()

  return (
    <>
      {props.existChildsOlderThan3 && (
        <OreMessageBar color="warning">
          {t(TGDOrderTranslation.summaryAssignment.messageBar.childsOlderThan3Warning)}
        </OreMessageBar>
      )}
      {!errorData ? (
        <>
          <TGDBasicOrderSummaryTable tgdProps={props} />
          <TGDBasicOrderSummaryForm tgdProps={props} />
          <TGDBasicOrderSummaryButtons tgdProps={props} />
          <ModalSpinner
            description={t(orderValidationsTranslations.spinner.description)}
            title={t(orderValidationsTranslations.spinner.title)}
            show={showSpinner}
          />
          <Modal
            handleOnClose={setShowDeleteModal}
            open={showDeleteModal}
            buttons={deletePopupButtons}
            closeIcon={false}>
            <>
              <OreHeading size="headline-lg">{deleteModalTitle}</OreHeading>
              <OreText align="left" as="p" size="text-md" tone="neutral">
                {deleteModalDescription}
              </OreText>
            </>
          </Modal>
          <Modal
            handleOnClose={setShowCancelModal}
            open={showCancelModal}
            buttons={cancelPopupButtons}
            closeIcon={false}>
            <>
              <OreHeading size="headline-lg">{cancelModalTitle}</OreHeading>
              <OreText align="left" as="p" size="text-md" tone="neutral">
                {cancelModalDescription}
              </OreText>
            </>
          </Modal>
          <TGDBasicOrderSaveAndClose
            setShowSaveCloseModal={props.setShowSaveCloseModal}
            showSaveCloseModal={props.showSaveCloseModal}
          />
        </>
      ) : (
        <OrderValidationErrorStep
          errors={errorData}
          excelError={excelError}
          back={backToSummary}
        />
      )}
    </>
  )
}
