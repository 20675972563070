import {
  OreIcon,
  OrePill,
  SvgDownload,
  SvgExcel,
  SvgForm,
  SvgKindergarten,
  SvgRepeat,
  SvgRestaurant,
  SvgTransport,
} from '@edenredespana/oreneta'
import { t } from 'i18next'
import { Seq } from 'immutable'
import { GetInvoiceMap } from '../../domain/adapters/invoiceAdapter'
import type {
  CaptionModel,
  RowModel,
  TableRowEspec,
} from '../../domain/customComponents/table'
import {
  currency,
  edenredProducts,
  invoicesTabs,
  orderStatus,
  orderType,
} from '../../domain/enum'
import type { IInvoice } from '../../domain/interfaces/invoice/IInvoiceApiResponse'
import type { LastOrderModel } from '../../domain/models'
import { clientHomeTranslation } from '../../domain/translations/client/home'
import { cardOrderService, getUndefinedEmptyString } from '../services'
import { getDownloadTooltip, InvoicesService } from '../services/InvoiceService'

export class ClientHomeTablesContext<T> {
  private strategy: Strategy<T>

  constructor(strategy: Strategy<T>) {
    this.strategy = strategy
  }

  SetStrategy(strategy: Strategy<T>) {
    this.strategy = strategy
  }

  GetCaption(totalRow?: number): CaptionModel {
    return this.strategy.GetCaption(totalRow)
  }

  async GetRows(): Promise<TableRowEspec<T>> {
    return await this.strategy.GetRows()
  }

  GetRowActions(rows: RowModel[], onClick: any, records?: T[]): RowModel[] {
    return this.strategy.GetRowActions(rows, onClick, records)
  }
}

interface Strategy<T> {
  GetCaption: (totalRow?: number) => CaptionModel
  GetRows: () => Promise<TableRowEspec<T>>
  GetRowActions: (rows: RowModel[], onClick: any, records?: T[]) => RowModel[]
}

export class Default<T> implements Strategy<T> {
  GetCaption(totalRow?: number | undefined): CaptionModel {
    return { description: '', title: '' }
  }

  async GetRows(): Promise<TableRowEspec<T>> {
    return new Promise<TableRowEspec<T>>(resolve => {
      resolve({ rows: [] })
    })
  }

  GetRowActions(rows: RowModel[], onClick: any, records?: T[]): RowModel[] {
    return []
  }
}

export class LastOrderRepetTable implements Strategy<LastOrderModel> {
  GetStatusPillById(status: number) {
    switch (status) {
      case orderStatus.placed:
        return 'success-600'
      case orderStatus.requested:
        return 'warning-600'
      case orderStatus.cancelled:
        return 'error-600'
      case orderStatus.inProcess:
        return 'cobalt-600'
      default:
        return 'grey'
    }
  }

  GetProductIconByCode(code: number): JSX.Element {
    let productIcon: JSX.Element = (
      <OreIcon icon={<SvgRestaurant />} tone="restaurant" size="extra-small" />
    )
    switch (code) {
      case edenredProducts.ticketRestaurant:
        productIcon = (
          <OreIcon icon={<SvgRestaurant />} tone="restaurant" size="extra-small" />
        )
        break
      case edenredProducts.ticketTransporte:
        productIcon = (
          <OreIcon icon={<SvgTransport />} tone="transport" size="extra-small" />
        )
        break
      case edenredProducts.ticketGuarderia:
        productIcon = (
          <OreIcon icon={<SvgKindergarten />} tone="kindergarten" size="extra-small" />
        )
        break
      default:
        productIcon = (
          <OreIcon icon={<SvgRestaurant />} tone="restaurant" size="extra-small" />
        )
        break
    }
    return (
      <div
        style={{ backgroundColor: '#e2e8f0', borderRadius: '0.3rem', width: '1.5rem' }}>
        {productIcon}
      </div>
    )
  }

  GetCaption(totalRow?: number): CaptionModel {
    return {
      title: t(clientHomeTranslation.ticketRestaurant.repetOrder.caption),
      description: '',
    }
  }

  async GetRows(): Promise<TableRowEspec<LastOrderModel>> {
    const tableEspec: TableRowEspec<LastOrderModel> = { rows: [] }
    return new Promise<TableRowEspec<LastOrderModel>>(resolve => {
      cardOrderService()
        .getLastOrders()
        .then(lastOrders => {
          const recharges: LastOrderModel[] = lastOrders.data.rows.filter(
            recharge =>
              recharge.orderType === orderType.Recharge ||
              recharge.orderType === orderType.NurseryAssignmentOrder
          )
          tableEspec.totalRows = recharges.length
          Seq(
            recharges
              .sort(
                (a, b): number =>
                  new Date(a.orderCreationDate).getTime() +
                  new Date(b.orderCreationDate).getTime()
              )
              .slice(0, 3)
          ).forEach((lastOrder: LastOrderModel) => {
            const date: Date = new Date(lastOrder.orderCreationDate)
            const dateFormat: string =
              date.getDay().toString().padStart(2, '0') +
              '/' +
              date.getMonth().toString().padStart(2, '0') +
              '/' +
              date.getFullYear().toString()
            const lastOrderRow: RowModel = {
              rowId: lastOrder.orderId,
              valign: 'middle',
              cells: [
                {
                  type: 'element',
                  cellElement: this.GetProductIconByCode(lastOrder.productCode),
                  align: 'right',
                },
                {
                  type: 'text',
                  cellText: [
                    {
                      text: '#' + lastOrder.orderId.toString(),
                      name: 'orderId',
                      size: 'text-sm',
                      isBold: true,
                      tone: 'neutral-900',
                    },
                    { text: dateFormat, name: 'orderCreationDate', size: 'text-sm' },
                    {
                      text: `${lastOrder.orderLinesCount} 
                        ${t(clientHomeTranslation.ticketRestaurant.repetOrder.rowText)}`,
                      name: 'recharges',
                      size: 'text-sm',
                    },
                  ],
                },
                {
                  type: 'text',
                  cellText: [
                    {
                      text: lastOrder.invoiceTotalAmount + currency.euro,
                      name: 'invoiceTotalAmount',
                      size: 'text-sm',
                    },
                  ],
                },
                {
                  type: 'element',
                  cellElement: (
                    <OrePill tone={this.GetStatusPillById(lastOrder.status)}>
                      {lastOrder.statusDescription}
                    </OrePill>
                  ),
                },
                {
                  type: 'element',
                  cellElement: lastOrder.isOrderCreatedByExcel ? (
                    <div className="svgExcel">
                      <SvgExcel />
                    </div>
                  ) : (
                    <div className="svgForm">
                      <SvgForm />
                    </div>
                  ),
                },
              ],
            }
            tableEspec.rows.push(lastOrderRow)
          })
          resolve(tableEspec)
        })
    })
  }

  GetRowActions(rows: RowModel[], onClick: any, records?: LastOrderModel[]): RowModel[] {
    Seq(rows).forEach((row: RowModel) => {
      row.cells.push({
        type: 'actions',
        cellActions: [
          { name: 'refresh', icon: <SvgRepeat />, type: 'refresh', onClick: onClick },
        ],
      })
    })
    return rows
  }
}

export class LastOrderFavoriteTable implements Strategy<LastOrderModel> {
  GetProductIconByCode(code: number): JSX.Element {
    let productIcon: JSX.Element = (
      <OreIcon icon={<SvgRestaurant />} tone="restaurant" size="extra-small" />
    )
    switch (code) {
      case edenredProducts.ticketRestaurant:
        productIcon = (
          <OreIcon icon={<SvgRestaurant />} tone="restaurant" size="extra-small" />
        )
        break
      case edenredProducts.ticketTransporte:
        productIcon = (
          <OreIcon icon={<SvgTransport />} tone="transport" size="extra-small" />
        )
        break
      case edenredProducts.ticketGuarderia:
        productIcon = (
          <OreIcon icon={<SvgKindergarten />} tone="kindergarten" size="extra-small" />
        )
        break
      default:
        productIcon = (
          <OreIcon icon={<SvgRestaurant />} tone="restaurant" size="extra-small" />
        )
        break
    }
    return (
      <div
        style={{ backgroundColor: '#e2e8f0', borderRadius: '0.3rem', width: '1.5rem' }}>
        {productIcon}
      </div>
    )
  }

  GetCaption(totalRow?: number): CaptionModel {
    return {
      title: t(clientHomeTranslation.ticketRestaurant.favoriteOrder.caption.title),
      description: t(
        clientHomeTranslation.ticketRestaurant.favoriteOrder.caption.description
      ),
      rowCount: totalRow,
    }
  }

  async GetRows(): Promise<TableRowEspec<LastOrderModel>> {
    const tableEspec: TableRowEspec<LastOrderModel> = { rows: [] }
    return new Promise<TableRowEspec<LastOrderModel>>(resolve => {
      cardOrderService()
        .getLastOrders()
        .then(lastOrders => {
          const recharges: LastOrderModel[] = lastOrders.data.rows.filter(
            recharge =>
              recharge.orderType === orderType.Recharge && recharge.isOrderFavorite
          )
          tableEspec.totalRows = recharges.length
          Seq(
            recharges
              .sort(
                (a, b): number =>
                  new Date(a.orderCreationDate).getTime() +
                  new Date(b.orderCreationDate).getTime()
              )
              .slice(0, 3)
          ).forEach((lastOrder: LastOrderModel) => {
            const date: Date = new Date(lastOrder.orderCreationDate)
            const dateFormat: string =
              date.getDay().toString().padStart(2, '0') +
              '/' +
              date.getMonth().toString().padStart(2, '0') +
              '/' +
              date.getFullYear().toString()
            const lastOrderRow: RowModel = {
              rowId: lastOrder.orderId,
              valign: 'middle',
              cells: [
                {
                  type: 'element',
                  cellElement: this.GetProductIconByCode(lastOrder.productCode),
                },
                {
                  type: 'text',
                  cellText: [
                    {
                      text: '#' + lastOrder.orderId.toString(),
                      name: 'orderId',
                      size: 'text-sm',
                      isBold: true,
                      tone: 'neutral-900',
                    },
                    // { text: dateFormat, name: 'orderCreationDate', size: 'text-sm' },
                    {
                      text: `${lastOrder.orderLinesCount} 
                        ${t(clientHomeTranslation.ticketRestaurant.repetOrder.rowText)}`,
                      name: 'recharges',
                      size: 'text-sm',
                    },
                  ],
                },
                {
                  type: 'text',
                  cellText: [
                    {
                      text: lastOrder.invoiceTotalAmount + currency.euro,
                      name: 'invoiceTotalAmount',
                      size: 'text-sm',
                      align: 'right',
                    },
                  ],
                },
                {
                  type: 'element',
                  cellElement: lastOrder.isOrderCreatedByExcel ? (
                    <div className="svgExcel">
                      <SvgExcel />
                    </div>
                  ) : (
                    <div className="svgForm">
                      <SvgForm />
                    </div>
                  ),
                  align: 'right',
                },
              ],
            }
            tableEspec.rows.push(lastOrderRow)
          })
          resolve(tableEspec)
        })
    })
  }

  GetRowActions(rows: RowModel[], onClick: any, records?: LastOrderModel[]): RowModel[] {
    Seq(rows).forEach((row: RowModel) => {
      row.cells.push({
        type: 'actions',
        cellActions: [
          { name: 'refresh', icon: <SvgRepeat />, type: 'refresh', onClick: onClick },
        ],
      })
    })
    return rows
  }
}

export class InvoiceTable implements Strategy<IInvoice> {
  GetProductIconByCode(code: number): JSX.Element {
    let productIcon: JSX.Element = (
      <OreIcon icon={<SvgRestaurant />} tone="restaurant" size="extra-small" />
    )
    switch (code) {
      case edenredProducts.ticketRestaurant:
        productIcon = (
          <OreIcon icon={<SvgRestaurant />} tone="restaurant" size="extra-small" />
        )
        break
      case edenredProducts.ticketTransporte:
        productIcon = (
          <OreIcon icon={<SvgTransport />} tone="transport" size="extra-small" />
        )
        break
      case edenredProducts.ticketGuarderia:
        productIcon = (
          <OreIcon icon={<SvgKindergarten />} tone="kindergarten" size="extra-small" />
        )
        break
      default:
        productIcon = (
          <OreIcon icon={<SvgRestaurant />} tone="restaurant" size="extra-small" />
        )
        break
    }
    return (
      <div
        style={{ backgroundColor: '#e2e8f0', borderRadius: '0.3rem', width: '1.5rem' }}>
        {productIcon}
      </div>
    )
  }

  GetCaption(totalRow?: number): CaptionModel {
    return {
      title: t(clientHomeTranslation.ticketRestaurant.invoices.caption.title),
      description: '',
    }
  }

  async GetRows(): Promise<TableRowEspec<IInvoice>> {
    const tableEspec: TableRowEspec<IInvoice> = { rows: [] }
    // TODO: Move to type query params variable
    const query =
      '?paginationModel.numberOfRecordsPerPage=3&paginationModel.pageNumber=0&paginationModel.orderByField=creationDate&paginationModel.sortDescending=true'
    return new Promise<TableRowEspec<IInvoice>>(resolve => {
      InvoicesService()
        .GetInvoices(query)
        .then(response => {
          tableEspec.totalRows = response.data.totalRows
          tableEspec.rowsFromBackEnd = GetInvoiceMap(response.data.rows)
          Seq(tableEspec.rowsFromBackEnd).forEach((invoice: IInvoice, index: number) => {
            const row: RowModel = {
              rowId: index + 1,
              valign: 'middle',
              cells: [
                {
                  type: 'element',
                  cellElement: this.GetProductIconByCode(invoice.productCode),
                },
                {
                  type: 'text',
                  cellText: [
                    {
                      text: '#' + getUndefinedEmptyString(invoice.orderNumber),
                      name: 'orderNumber',
                      size: 'text-sm',
                      isBold: true,
                    },
                    { text: invoice.creationDate, name: 'creationDate', size: 'text-sm' },
                    {
                      text: t(clientHomeTranslation.ticketRestaurant.repetOrder.rowText),
                      name: 'recharges',
                      size: 'text-sm',
                    },
                  ],
                },
                {
                  type: 'text',
                  cellText: [
                    {
                      text: invoice.totalAmount.toString() + currency.euro,
                      name: 'totalAmount',
                      size: 'text-sm',
                      align: 'right',
                    },
                  ],
                },
              ],
            }
            tableEspec.rows.push(row)
          })
          resolve(tableEspec)
        })
    })
  }

  GetRowActions(rows: RowModel[], onClick: any, records?: IInvoice[]): RowModel[] {
    if (records) {
      Seq(records).forEach((invoice: IInvoice, index: number) => {
        rows[index].cells.push({
          type: 'actions',
          cellActions: [
            {
              name: 'download',
              icon: <SvgDownload />,
              type: 'download',
              tooltip: getDownloadTooltip(
                invoice.isInvoiceDownloadable,
                invoice.isInvoiceReadyToDownload,
                invoicesTabs.invoices
              ),
              disabled:
                !invoice.isInvoiceDownloadable || !invoice.isInvoiceReadyToDownload,
              onClick: () =>
                onClick(
                  invoice.invoiceId,
                  invoice.invoiceNumber,
                  invoice.isInvoiceDownloadable,
                  invoice.isInvoiceReadyToDownload
                ),
            },
          ],
        })
      })
    }
    return rows
  }
}
