import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import {
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreDivider,
  OreHeading,
  OreMessageBar,
  OreStack,
  OreText,
  SvgArrowWidget,
} from '@edenredespana/oreneta'
import { forms } from 'src/domain/translations'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'

import {
  TemplateDownload,
  TemplatesEditBasic,
  TemplatesEditCkEditor,
  TemplateTesting,
  useTemplatesEdit,
} from 'src/Flex/CommunicationsTemplates/ui/templates-detail'

export const TemplatesEditForm = (): JSX.Element => {
  const { id } = useParams()
  const { model, form, save, dirtyEditor, setDirtyEditor, varsError } = useTemplatesEdit()
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <OreCardContainer>
        <OreCardWrapper space="xlarge">
          <OreStack space="xlarge" placeContent="stretch">
            <OreStack space="medium" placeContent="stretch">
              <OreHeading size="headline-md">
                {t(flexCommunicationsTemplatesTranslations.configure.form.title)}
              </OreHeading>
              {model?.description ? (
                <OreText>{model?.description}</OreText>
              ) : (
                <OreText>
                  {t(flexCommunicationsTemplatesTranslations.configure.form.subtitle)}
                </OreText>
              )}
              {model?.optionalText ? <OreText>{model?.optionalText}</OreText> : null}
            </OreStack>
            <form>
              <OreStack space="2xlarge" placeContent="stretch">
                <OreStack space="large" placeContent="stretch">
                  <TemplatesEditBasic id={id} />
                  <TemplatesEditCkEditor setDirty={setDirtyEditor} />
                </OreStack>

                {varsError.length > 0 && (
                  <OreMessageBar color="error">
                    {varsError.length > 0
                      ? `${t(
                          flexCommunicationsTemplatesTranslations.configure.form
                            .error_vars
                        )} ${varsError.join(', ')}`
                      : null}
                  </OreMessageBar>
                )}
                {id ? (
                  model?.downloadOption && model.downloadOption.canDownload ? (
                    <TemplateDownload
                      downloadModel={model.downloadOption}
                      name={model.subject}
                    />
                  ) : (
                    <TemplateTesting id={id} />
                  )
                ) : null}
              </OreStack>
            </form>
            <OreText>{t(forms.errors.fieldsRequired)}</OreText>
          </OreStack>
        </OreCardWrapper>
      </OreCardContainer>
      <OreDivider space="larger-top" />
      <OreStack direction="row" placeContent="space-between" space="medium">
        <OreButton
          size="small"
          icon={<SvgArrowWidget />}
          category="tertiary"
          onClick={(): void =>
            navigate(flexNavigationRoutes.flexCommunicationsTemplates)
          }>
          {t(forms.buttons.goBack)}
        </OreButton>

        <OreButton
          type="submit"
          disabled={id !== undefined && !form.formState.isDirty && !dirtyEditor}
          onClick={form.handleSubmit(save)}
          size="small"
          category="primary">
          {t(flexCommunicationsTemplatesTranslations.configure.form.button.label)}
        </OreButton>
      </OreStack>
    </>
  )
}
