import {
  OreButton,
  OreHeading,
  OrePagination,
  OrePill,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
  SvgAlert,
  SvgDownload,
} from '@edenredespana/oreneta'
import React, { useState } from 'react'
import ErrorLogo from '../../../../assets/img/Warning.svg'
import { cardOrderTranslation, forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { MessagesModel } from 'src/domain/models'
import { ErrorMessagesTable } from 'src/presentation/components/Edenred/errorMessagesTable/ErrorMessagesTable'

interface Props {
  errorMessages: MessagesModel[]
  isExcel: boolean
  excel: string
}

export const TGDBasicOrderCompleteError = ({ errorMessages, isExcel, excel }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [page, setPage] = useState(1)
  const pageSize = 8

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const downloadFile = async () => {
    if (excel === '') return
    const link = document.createElement('a')
    link.href = `data:application/octet-stream;base64,${excel}`
    link.download = 'listado-errores.xlsx'
    link.click()
    link.remove()
  }

  return (
    <>
      <div style={{ margin: '3rem 20% 0 20%' }}>
        <OreStack
          direction="column"
          space="large"
          placeContent="space-between"
          placeItems="center">
          <div>
            <figure>
              <img src={ErrorLogo} alt="" />
            </figure>
          </div>
          <OreStack direction="column" space="medium">
            <header className="width-max">
              <OreHeading as="h1" size="title-sm" align="center">
                {t(cardOrderTranslation.cardOrderComplete.titleError)}
              </OreHeading>
            </header>
            <div>
              <OreText align="center">{errorMessages[0].description}</OreText>
            </div>
            <div>
              <OreText align="center">
                {t(TGDOrderTranslation.complete.errorMessage2)}
              </OreText>
            </div>
          </OreStack>
          {/* {isExcel &&
          excel &&
          excel !== '' &&
          errorMessages &&
          errorMessages.length > 1 && (
            <>
              <OreStack>
                <OreTableContainer>
                  <div className="bg-white px-1">
                    <div className="py-105">
                      <OreHeading size="headline-md">
                        {t(TGDOrderTranslation.stoppersAndWarnings.grid.title2)}
                      </OreHeading>
                    </div>
                  </div>
                  <OreTable>
                    <OreTableHead>
                      <OreTableRow hover={false}>
                        <OreTableCell as="th">
                          {t(TGDOrderTranslation.stoppersAndWarnings.grid.row)}
                        </OreTableCell>
                        <OreTableCell as="th">
                          {t(TGDOrderTranslation.stoppersAndWarnings.grid.errorType)}
                        </OreTableCell>
                        <OreTableCell as="th">
                          {t(
                            TGDOrderTranslation.stoppersAndWarnings.grid.errorDescription
                          )}
                        </OreTableCell>
                      </OreTableRow>
                    </OreTableHead>
                    <OreTableBody as="tbody" valign="top">
                      {errorMessages
                        .slice((page - 1) * pageSize, page * pageSize)
                        .map((message: MessagesModel, index: number) => {
                          return (
                            <OreTableRow valign="middle" key={index} hover={false}>
                              <OreTableCell align="left" as="td" size="auto">
                                <OreText>{message.value}</OreText>
                              </OreTableCell>
                              <OreTableCell align="left" as="td" size="auto">
                                <div className="whitespace-nowrap">
                                  <OrePill icon={<SvgAlert />} tone="error-600">
                                    {t(cardOrderTranslation.excelAlerts.table.errorLabel)}
                                  </OrePill>
                                </div>
                              </OreTableCell>
                              <OreTableCell align="left" as="td" size="auto">
                                <OreText>{message.description}</OreText>
                              </OreTableCell>
                            </OreTableRow>
                          )
                        })}
                    </OreTableBody>
                  </OreTable>
                  <OreTableActions>
                    <OrePagination
                      count={errorMessages.length}
                      labelNextPage={t(forms.pagination.next)}
                      labelPreviousPage={t(forms.pagination.back)}
                      onPageChange={onPageChange}
                      page={page}
                      rowsPerPage={pageSize}
                      text={t(forms.pagination.of)}
                    />
                  </OreTableActions>
                </OreTableContainer>
              </OreStack>
            </>
          )}
          <OreStack
            direction="column"
            space="small"
            placeContent="space-between"
            placeItems="center">
            <div className="mt-1">
              {isExcel &&
              excel &&
              excel !== '' &&
              errorMessages &&
              errorMessages.length > 1 ? (
                <OreButton
                  as="button"
                  category="secondary"
                  size="large"
                  icon={<SvgDownload />}
                  onClick={() => downloadFile()}>
                  {t(TGDOrderTranslation.stoppersAndWarnings.downloadButton)}
                </OreButton>
              ) : (
                <OreButton
                  as="button"
                  category="primary"
                  onClick={() => navigate(navigationRoutes.tgdOrderForm)}
                  size="large">
                  {t(cardOrderTranslation.cardOrderComplete.newOrder)}
                </OreButton>
              )}
            </div>
            <OreButton
              as="button"
              category="highlight"
              onClick={() => navigate(navigationRoutes.clientsHome)}
              size="large"
              style={{ color: '#0F172A' }}>
              {t(forms.buttons.goHome)}
            </OreButton>
          </OreStack> */}
        </OreStack>
      </div>
      {errorMessages && errorMessages.length > 0 && (
        <ErrorMessagesTable
          errorMessages={errorMessages}
          back={() => navigate(navigationRoutes.tgdBasicOrderForm)}
          backToHome={() => navigate(navigationRoutes.clientsHome)}
          additionalDataExcel={excel || ''}
          inStep={false}
        />
      )}
    </>
  )
}
