import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { collectivesService } from 'src/Flex/Collectives/application'
import type { CollectiveModel } from 'src/Flex/Collectives/domain'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'

interface IUseProductController {
  validateDeleteCollective: (id: string, newCollectiveId: string) => Promise<void>
  DeleteCollectiveExcel: (id: string, newCollectiveId: string) => Promise<string>
  deleteCollective: (id: string, newCollectiveId: string) => Promise<boolean>
  changePage: (page: number) => void
  numberOfRecordsPerPage: number
  collectives: CollectiveModel[]
  totalRows: number
  page: number
  allCollectives: CollectiveModel[]
  employeesAffected: number | undefined
  showSSIAlert: boolean
  loadAllCollectives: () => Promise<void>
  setEmployeesAffected: Dispatch<SetStateAction<number | undefined>>
}

export const useCollectiveController = (
  initPage: number | null = 0
): IUseProductController => {
  const [collectives, setCollectives] = useState<CollectiveModel[]>([])
  const [allCollectives, setAllCollectives] = useState<CollectiveModel[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const [page, setPage] = useState<number | null>(initPage)
  const [employeesAffected, setEmployeesAffected] = useState<number | undefined>(
    undefined
  )
  const [showSSIAlert, setShowSSIAlert] = useState<boolean>(false)
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const numberOfRecordsPerPage = 8

  const loadAllCollectives = async (): Promise<void> => {
    startLoading()

    const response = await collectivesService().GetCollectives()

    stopLoading()

    setAllCollectives(response.data.rows)
  }

  const loadCollectives = async (page: number | null = 0): Promise<void> => {
    startLoading()

    const response =
      page !== null
        ? await collectivesService().GetCollectives(page, numberOfRecordsPerPage)
        : await collectivesService().GetCollectives()

    stopLoading()

    setCollectives(response.data.rows)
    setTotalRows(response.data.totalRows)
  }

  const deleteCollective = async (
    id: string,
    newCollectiveId: string
  ): Promise<boolean> => {
    startLoading()

    const response = await collectivesService().DeleteCollective(id, newCollectiveId)

    stopLoading()

    const valid = handleMetaResponse(response?.meta)

    if (valid) {
      if (collectives.length === 1 && (page ?? 0) >= 1) {
        setPage(page => (page ?? 0) - 1)
      } else {
        await loadCollectives(page)
      }
    }

    return valid
  }

  const validateDeleteCollective = async (
    id: string,
    newCollectiveId: string
  ): Promise<void> => {
    startLoading()

    const response = await collectivesService().ValidateDeleteCollective(
      id,
      newCollectiveId
    )

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setEmployeesAffected(response.data.contractStateInfo.numberOfEmployeesContract)
    }
  }

  const DeleteCollectiveExcel = async (
    id: string,
    newCollectiveId: string
  ): Promise<string> => {
    let result = ''
    startLoading()
    const response = await collectivesService().DeleteCollectiveExcel(id, newCollectiveId)

    stopLoading()
    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      result = response?.data.file
    }
    return result
  }

  const changePage = (page: number): void => {
    setPage(page)
  }

  useEffect(() => {
    loadCollectives(page)
  }, [page])

  return {
    validateDeleteCollective,
    DeleteCollectiveExcel,
    deleteCollective,
    changePage,
    numberOfRecordsPerPage,
    collectives,
    totalRows,
    page: (page ?? 0) + 1,
    allCollectives,
    loadAllCollectives,
    employeesAffected,
    showSSIAlert,
    setEmployeesAffected,
  }
}
