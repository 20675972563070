import {
  OreCardContainer,
  OreCardWrapper,
  OreDivider,
  OreHeading,
  OreText,
} from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { accountSettingsTranslation } from '../../../../../domain/translations/myAccount/accountSettings'
import { useUserDataController } from '../hooks/useUserDataController'

export const UserDataSso = (): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useUserDataController()
  if (!user) return <></>
  return (
    <OreCardContainer>
      <OreCardWrapper space="large">
        <OreHeading as="h1" size="headline-sm">
          {t(accountSettingsTranslation.myData.title)}
        </OreHeading>

        <OreDivider space="medium" />

        <div>
          <div>
            <OreText bold={true}>{t(accountSettingsTranslation.myData.fullName)}</OreText>
            <OreText>{user.fullName}</OreText>
          </div>
          <div className="pt-2">
            <OreText bold={true}>{t(accountSettingsTranslation.myData.email)}</OreText>
            <OreText>{user?.email}</OreText>
          </div>
        </div>
      </OreCardWrapper>
    </OreCardContainer>
  )
}
