export const TGDOrderTranslation = {
  title: 'tgdOrderTranslation.title',

  form: {
    title: 'tgdOrderTranslation.form.title',
    childTitle: 'tgdOrderTranslation.form.childTitle',
    mandatoryFields: 'tgdOrderTranslation.form.mandatoryFields',
    registerAndAssign: 'tgdOrderTranslation.form.registerAndAssign',
    checkoutButton: 'tgdOrderTranslation.form.checkoutButton',

    user: {
      title: 'tgdOrderTranslation.form.user.title',
      childTitle: 'tgdOrderTranslation.form.user.childTitle',
      documentType: {
        helpText: 'tgdOrderTranslation.form.user.documentType.helpText',
      },
      corporativeEmail: {
        helpText: 'tgdOrderTranslation.form.user.corporativeEmail.helpText',
      },
      errors: {
        existsInServer: 'tgdOrderTranslation.form.user.errors.existsInServer',
        existsInOrder: 'tgdOrderTranslation.form.user.errors.existsInOrder',
      },
    },
    child: {
      title: 'tgdOrderTranslation.form.child.title',
      kindergartenAssignment: 'tgdOrderTranslation.form.child.kindergartenAssignment',
      addAnotherChild: 'tgdOrderTranslation.form.child.addAnotherChild',
      deleteChild: 'tgdOrderTranslation.form.child.deleteChild',
      deleteModalTitle: 'tgdOrderTranslation.form.child.deleteModalTitle',
      deleteModalDescription: 'tgdOrderTranslation.form.child.deleteModalDescription',
      cancelModalTitle: 'tgdOrderTranslation.form.child.cancelModalTitle',
      cancelModalDescription: 'tgdOrderTranslation.form.child.cancelModalDescription',
      repeatedChildModalTitle: 'tgdOrderTranslation.form.child.repeatedChildModalTitle',
      repeatedChildModalRadio: {
        mustBeAnError:
          'tgdOrderTranslation.form.child.repeatedChildModalRadio.mustBeAnError',
        continue: 'tgdOrderTranslation.form.child.repeatedChildModalRadio.continue',
      },
      birthdate: {
        helpText: 'tgdOrderTranslation.form.child.birthdate.helpText',
      },
      rechargeAmount: {
        label: 'tgdOrderTranslation.form.child.rechargeAmount.label',
        helpText: 'tgdOrderTranslation.form.child.rechargeAmount.helpText',
      },
      errors: {
        validateMinDateBirth:
          'tgdOrderTranslation.form.child.errors.validateMinDateBirth',
        validateMaxDateBirth:
          'tgdOrderTranslation.form.child.errors.validateMaxDateBirth',
        amountTwoDecimalsMax:
          'tgdOrderTranslation.form.child.errors.amountTwoDecimalsMax',
        repeatedNameOrBirthDate:
          'tgdOrderTranslation.form.child.errors.repeatedNameOrBirthDate',
        repeatedName: 'tgdOrderTranslation.form.child.errors.repeatedName',
      },
      warnings: {
        specialEducation: 'tgdOrderTranslation.form.child.warnings.specialEducation',
        childOlderThan6: 'tgdOrderTranslation.form.child.warnings.childOlderThan6',
      },
    },
  },

  kindergartenAssignment: {
    title: 'tgdOrderTranslation.kindergartenAssignment.title',
    emptyTitle: 'tgdOrderTranslation.kindergartenAssignment.emptyTitle',
    emptySubtitle: 'tgdOrderTranslation.kindergartenAssignment.emptySubtitle',
    selectButton: 'tgdOrderTranslation.kindergartenAssignment.selectButton',
    address: 'tgdOrderTranslation.kindergartenAssignment.address',
    phoneContact: 'tgdOrderTranslation.kindergartenAssignment.phoneContact',
    deleteSelection: 'tgdOrderTranslation.kindergartenAssignment.deleteSelection',
    changeSelection: 'tgdOrderTranslation.kindergartenAssignment.changeSelection',
    modalForm: {
      title: 'tgdOrderTranslation.kindergartenAssignment.modalForm.title',
      subtitle: 'tgdOrderTranslation.kindergartenAssignment.modalForm.subtitle',
      titleViewOnly: 'tgdOrderTranslation.kindergartenAssignment.modalForm.titleViewOnly',
      subtitleViewOnly:
        'tgdOrderTranslation.kindergartenAssignment.modalForm.subtitleViewOnly',
      name: 'tgdOrderTranslation.kindergartenAssignment.modalForm.name',
      zipCodePlaceholder:
        'tgdOrderTranslation.kindergartenAssignment.modalForm.zipCodePlaceholder',
      cityPlaceholder:
        'tgdOrderTranslation.kindergartenAssignment.modalForm.cityPlaceholder',
      affiliateCode: 'tgdOrderTranslation.kindergartenAssignment.modalForm.affiliateCode',
      affiliateCodePlaceholder:
        'tgdOrderTranslation.kindergartenAssignment.modalForm.affiliateCodePlaceholder',
      selectButton: 'tgdOrderTranslation.kindergartenAssignment.modalForm.selectButton',
      selectedDescription:
        'tgdOrderTranslation.kindergartenAssignment.modalForm.selectedDescription',
      emptyList: 'tgdOrderTranslation.kindergartenAssignment.modalForm.emptyList',
      notFoundTitle: 'tgdOrderTranslation.kindergartenAssignment.modalForm.notFoundTitle',
      notFoundSubtitle:
        'tgdOrderTranslation.kindergartenAssignment.modalForm.notFoundSubtitle',
      requestKindergarten:
        'tgdOrderTranslation.kindergartenAssignment.modalForm.requestKindergarten',
      totalResults1: 'tgdOrderTranslation.kindergartenAssignment.modalForm.totalResults1',
      totalResults2: 'tgdOrderTranslation.kindergartenAssignment.modalForm.totalResults2',
      merchantNumber:
        'tgdOrderTranslation.kindergartenAssignment.modalForm.merchantNumber',
    },
  },
  summaryAssignment: {
    messageBar: {
      childsOlderThan3Warning:
        'tgdOrderTranslation.summaryAssignment.messageBar.childsOlderThan3Warning',
    },
    table: {
      title: 'tgdOrderTranslation.summaryAssignment.table.title',
      subtitle: 'tgdOrderTranslation.summaryAssignment.table.subtitle',
      header: {
        user: 'tgdOrderTranslation.summaryAssignment.table.header.user',
        child: 'tgdOrderTranslation.summaryAssignment.table.header.child',
        amount: 'tgdOrderTranslation.summaryAssignment.table.header.amount',
        kindergarten: 'tgdOrderTranslation.summaryAssignment.table.header.kindergarten',
        actions: 'tgdOrderTranslation.summaryAssignment.table.header.actions',
      },
      footer: {
        addEmployeeChild:
          'tgdOrderTranslation.summaryAssignment.table.footer.addEmployeeChild',
        addChild: 'tgdOrderTranslation.summaryAssignment.table.footer.addChild',
        addAssignment: 'tgdOrderTranslation.summaryAssignment.table.footer.addAssignment',
      },
      emptyTable: {
        emptyText: 'tgdOrderTranslation.summaryAssignment.table.emptyTable.emptyText',
        createNewOrderTitle:
          'tgdOrderTranslation.summaryAssignment.table.emptyTable.createNewOrderTitle',
        createNewOrderDescription:
          'tgdOrderTranslation.summaryAssignment.table.emptyTable.createNewOrderDescription',
        createNewOrderOption1:
          'tgdOrderTranslation.summaryAssignment.table.emptyTable.createNewOrderOption1',
        createNewOrderOption2:
          'tgdOrderTranslation.summaryAssignment.table.emptyTable.createNewOrderOption2',
        createNewOrderOption3:
          'tgdOrderTranslation.summaryAssignment.table.emptyTable.createNewOrderOption3',
      },
    },
    completeOrder: {
      title: 'tgdOrderTranslation.summaryAssignment.completeOrder.title',
      dateDescription:
        'tgdOrderTranslation.summaryAssignment.completeOrder.dateDescription',
      dateAssignment:
        'tgdOrderTranslation.summaryAssignment.completeOrder.dateAssignment',
      dateAssignmentLabelTooltip:
        'tgdOrderTranslation.summaryAssignment.completeOrder.dateAssignmentLabelTooltip',
      dateAssignmentPlaceholder:
        'tgdOrderTranslation.summaryAssignment.completeOrder.dateAssignmentPlaceholder',
      billReferenceDescription:
        'tgdOrderTranslation.summaryAssignment.completeOrder.billReferenceDescription',
      billReference: 'tgdOrderTranslation.summaryAssignment.completeOrder.billReference',
      billReferencePlaceholder:
        'tgdOrderTranslation.summaryAssignment.completeOrder.billReferencePlaceholder',
      fieldsRequired:
        'tgdOrderTranslation.summaryAssignment.completeOrder.fieldsRequired',
      termsAcceptance1:
        'tgdOrderTranslation.summaryAssignment.completeOrder.termsAcceptance1',
      termsAcceptance2:
        'tgdOrderTranslation.summaryAssignment.completeOrder.termsAcceptance2',
    },
    deleteModal: {
      title: 'tgdOrderTranslation.summaryAssignment.deleteModal.title',
      description: 'tgdOrderTranslation.summaryAssignment.deleteModal.description',
    },
    cancelModal: {
      title: 'tgdOrderTranslation.summaryAssignment.cancelModal.title',
      description: 'tgdOrderTranslation.summaryAssignment.cancelModal.description',
    },
    tooltips: {
      newEmployeeChild: 'tgdOrderTranslation.summaryAssignment.tooltips.newEmployeeChild',
      editNewChild: 'tgdOrderTranslation.summaryAssignment.tooltips.editNewChild',
      editAssignment: 'tgdOrderTranslation.summaryAssignment.tooltips.editAssignment',
    },
  },
  checkout: {
    headerTitle: 'tgdOrderTranslation.checkout.header.title',
    assignmentTitle: 'tgdOrderTranslation.checkout.assignment.title',
    initialDate: 'tgdOrderTranslation.checkout.assignment.initialDate',
  },
  complete: {
    errorMessage1: 'tgdOrderTranslation.complete.errorMessage1',
    errorMessage2: 'tgdOrderTranslation.complete.errorMessage2',
    successMessage1: 'tgdOrderTranslation.complete.successMessage1',
    successMessage2: 'tgdOrderTranslation.complete.successMessage2',
    assignmentDate: 'tgdOrderTranslation.complete.assignmentDate',
    totalAmount: 'tgdOrderTranslation.complete.totalAmount',
    successMessage3: 'tgdOrderTranslation.complete.successMessage3',
    iban: 'tgdOrderTranslation.complete.iban',
    concept: 'tgdOrderTranslation.complete.concept',
    transferAmount: 'tgdOrderTranslation.complete.transferAmount',
    important: 'tgdOrderTranslation.complete.important',
    assignmentOrderID: 'tgdOrderTranslation.complete.assignmentOrderID',
  },
  employees: {
    notFoundTitle: 'tgdOrderTranslation.employees.notFoundTitle',
    notFoundDescription: 'tgdOrderTranslation.employees.notFoundDescription',
    disabledTooltip: 'tgdOrderTranslation.employees.disabledTooltip',
    multipleSelect: {
      title: 'tgdOrderTranslation.employees.multipleSelect.title',
      configAssignmentButton:
        'tgdOrderTranslation.employees.multipleSelect.configAssignmentButton',
    },
    configuration: {
      title: 'tgdOrderTranslation.employees.configuration.title',
      table: {
        header: {
          kindergartenTooltip:
            'tgdOrderTranslation.employees.configuration.table.header.kindergartenTooltip',
          assignmentAmount:
            'tgdOrderTranslation.employees.configuration.table.header.assignmentAmount',
          assignmentAmountTooltip:
            'tgdOrderTranslation.employees.configuration.table.header.assignmentAmountTooltip',
          costCenter:
            'tgdOrderTranslation.employees.configuration.table.header.costCenter',
        },
      },
      buttons: {
        addToOrder: 'tgdOrderTranslation.employees.configuration.buttons.addToOrder',
        applyToSelection:
          'tgdOrderTranslation.employees.configuration.buttons.applyToSelection',
      },
      childsWithoutKindergarten:
        'tgdOrderTranslation.employees.configuration.childsWithoutKindergarten',
    },
  },
  excel: {
    repeat: {
      title: 'tgdOrderTranslation.excel.repeat.modal.title',
      desc: 'tgdOrderTranslation.excel.repeat.modal.desc',
      desc_1: 'tgdOrderTranslation.excel.repeat.modal.desc_1',
      desc_2: 'tgdOrderTranslation.excel.repeat.modal.desc_2',
      desc_3: 'tgdOrderTranslation.excel.repeat.modal.desc_3',
      desc_4: 'tgdOrderTranslation.excel.repeat.modal.desc_4',
      rechargeFilename: 'tgdOrderTranslation.excel.repeat.rechargeFilename',
      assignmentFilename: 'tgdOrderTranslation.excel.repeat.assignmentFilename',
    },
    download: {
      textHtml1: 'tgdOrderTranslation.excel.download.textHtml1',
      textHtml2: 'tgdOrderTranslation.excel.download.textHtml2',
      downloadButton: 'tgdOrderTranslation.excel.download.downloadButton',
      repeat1: 'tgdOrderTranslation.excel.download.repeat1',
      repeat2: 'tgdOrderTranslation.excel.download.repeat2',
    },
    kindergartenSearch: {
      title: 'tgdOrderTranslation.excel.kindergartenSearch.title',
      description: 'tgdOrderTranslation.excel.kindergartenSearch.description',
      searchButton: 'tgdOrderTranslation.excel.kindergartenSearch.searchButton',
    },
    help: {
      description1: 'tgdOrderTranslation.excel.help.description1',
      description2: 'tgdOrderTranslation.excel.help.description2',
    },
    dropZone: {
      selectFile: 'tgdOrderTranslation.excel.dropZone.selectFile',
      changeFile: 'tgdOrderTranslation.excel.dropZone.changeFile',
      dropFileOr: 'tgdOrderTranslation.excel.dropZone.dropFileOr',
      dropNewFileOr: 'tgdOrderTranslation.excel.dropZone.dropNewFileOr',
      uploadErrorTitle: 'tgdOrderTranslation.excel.dropZone.uploadErrorTitle',
    },
    checkExcelData: 'tgdOrderTranslation.excel.checkExcelData',
    table: {
      header: {
        concept: 'tgdOrderTranslation.excel.table.header.concept',
        units: 'tgdOrderTranslation.excel.table.header.units',
      },
    },
  },
  stoppersAndWarnings: {
    stoppers: {
      title: 'tgdOrderTranslation.stoppersAndWarnings.stoppers.title',
      description: 'tgdOrderTranslation.stoppersAndWarnings.stoppers.description',
      option1: 'tgdOrderTranslation.stoppersAndWarnings.stoppers.option1',
      option1bold: 'tgdOrderTranslation.stoppersAndWarnings.stoppers.option1bold',
      option11: 'tgdOrderTranslation.stoppersAndWarnings.stoppers.option11',
      option2: 'tgdOrderTranslation.stoppersAndWarnings.stoppers.option2',
      option2bold: 'tgdOrderTranslation.stoppersAndWarnings.stoppers.option2bold',
      option22: 'tgdOrderTranslation.stoppersAndWarnings.stoppers.option22',
    },
    grid: {
      title: 'tgdOrderTranslation.stoppersAndWarnings.grid.title',
      title2: 'tgdOrderTranslation.stoppersAndWarnings.grid.title2',
      total: 'tgdOrderTranslation.stoppersAndWarnings.grid.total',
      row: 'tgdOrderTranslation.stoppersAndWarnings.grid.row',
      errorType: 'tgdOrderTranslation.stoppersAndWarnings.grid.errorType',
      errorDescription: 'tgdOrderTranslation.stoppersAndWarnings.grid.errorDescription',
    },
    downloadButton: 'tgdOrderTranslation.stoppersAndWarnings.downloadButton',
    changeFile: 'tgdOrderTranslation.stoppersAndWarnings.changeFile',
    continueToResume: 'tgdOrderTranslation.stoppersAndWarnings.continueToResume',
    warnings: {
      description1: 'tgdOrderTranslation.stoppersAndWarnings.warnings.description1',
      description2: 'tgdOrderTranslation.stoppersAndWarnings.warnings.description2',
    },
  },
}
