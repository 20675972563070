import type { CardOrderCompleteModel } from '../../../../domain/models/cardOrder/CardOrderCompleteModel'
import RedLogo from '../../../assets/img/RedLogo.svg'
import { useTranslation } from 'react-i18next'
import type { MessagesModel } from '../../../../domain/models'
import { accountSettingsTranslation } from '../../../../domain/translations/myAccount/accountSettings'
import { OreButton, OreHeading, OreText } from '@edenredespana/oreneta'
import { useDeleteAccountController } from './hooks/useDeleteAccountController'

export interface CustomState {
  success: boolean
  errorMessage: MessagesModel[]
  data: CardOrderCompleteModel
}

export const AccountDeletedSuccessPage = (): JSX.Element => {
  const { t } = useTranslation()
  const { redirectToLogin } = useDeleteAccountController()

  return (
    <>
      <div className="account-container--center account-deleted-container">
        <div>
          <figure>
            <img src={RedLogo} alt="" />
          </figure>
        </div>
        <div>
          <header className="width-max mt-2">
            <OreHeading as="h1" size="title-sm" align="center">
              {t(accountSettingsTranslation.deleteAccount.modal.successTitle)}
            </OreHeading>
          </header>
        </div>
        <div>
          <div className="pt-105">
            <OreText size="text-sm" align="center">
              {t(accountSettingsTranslation.deleteAccount.modal.successDescription)}
            </OreText>
          </div>
          <div className="py-2">
            <OreButton
              as="button"
              category="primary"
              onClick={redirectToLogin}
              size="large">
              {t(accountSettingsTranslation.deleteAccount.modal.successButton)}
            </OreButton>
          </div>
        </div>
      </div>
    </>
  )
}
