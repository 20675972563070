import { OreHeading, OreStack, OreText } from '@edenredespana/oreneta'
import { Trans, useTranslation } from 'react-i18next'
import { flexPendingTasksTranslations } from 'src/Flex/TaskManager/ui/translations'
import { usePendingTasks } from 'src/Flex/TaskManager/ui/pending-tasks'
import { TaskTypeEnum } from 'src/Flex/TaskManager/domain'

export const PendingTasksTitle = (): JSX.Element => {
  const { t } = useTranslation()
  const { tasks } = usePendingTasks()

  return (
    <OreStack>
      <OreHeading as="h2" size="headline-lg">
        {tasks[TaskTypeEnum.pendingOnboardingSteps] &&
        tasks[TaskTypeEnum.pendingOnboardingSteps].length > 0
          ? t(flexPendingTasksTranslations.tasks.subtitles.pendingOnboardingSteps)
          : t(flexPendingTasksTranslations.title)}
      </OreHeading>
      {Object.keys(tasks).length > 0 ? (
        tasks[TaskTypeEnum.pendingOnboardingSteps] &&
        tasks[TaskTypeEnum.pendingOnboardingSteps].length > 0 ? (
          <OreStack placeContent="stretch">
            <OreText>
              <Trans t={t}>
                {t(flexPendingTasksTranslations.tasks.onboarding.subtitle1)}
              </Trans>{' '}
              <Trans t={t}>
                {t(flexPendingTasksTranslations.tasks.onboarding.subtitle2)}
              </Trans>
            </OreText>
          </OreStack>
        ) : (
          <></>
        )
      ) : (
        <OreText bold tone="neutral-800">
          {t(flexPendingTasksTranslations.noTasks)}
        </OreText>
      )}
    </OreStack>
  )
}
