import { OreCheckbox, OreStack, OreTableCell, OreTableRow } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { ExcelLayout, useExcelUpload } from 'src/Flex/Employees/ui/employee-excel'
import { SimpleTable } from 'src/Flex/Shared/ui/Table'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { useDownloader } from 'src/Flex/Employees/ui/employee-detail'
import { LegalDocumentType } from 'src/domain/enum'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'

export const ExcelValidation = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    uploadResponse,
    validateForm: { formState, register, handleSubmit },
    goToEmployees,
    confirm,
  } = useExcelUpload()
  const { downloadLegalDocument } = useDownloader()
  const { errors } = handleErrors(formState)

  return (
    <ExcelLayout
      onCancel={goToEmployees}
      onContinue={handleSubmit(confirm)}
      continueLabel={t(flexEmployeesTranslations.excel.validation.label)}
      continueDisabled={!formState.isValid}>
      <OreStack space="2xlarge" placeContent="stretch">
        <SimpleTable
          title={t(flexEmployeesTranslations.excel.validation.table.title)}
          tableHeaders={[
            {
              label: t(flexEmployeesTranslations.excel.validation.table.header.concepts),
              key: 'concepts',
              size: 'half',
            },
            {
              label: t(flexEmployeesTranslations.excel.validation.table.header.units),
              key: 'units',
              size: 'half',
            },
          ]}
          rows={[
            {
              concept: t(flexEmployeesTranslations.excel.validation.table.body.add),
              units: uploadResponse?.created,
            },
            {
              concept: t(flexEmployeesTranslations.excel.validation.table.body.modify),
              units: uploadResponse?.updated,
            },
            {
              concept: t(flexEmployeesTranslations.excel.validation.table.body.remove),
              units: uploadResponse?.deleted,
            },
          ]}
          renderRow={(row, index) => (
            <OreTableRow key={index}>
              <OreTableCell>{row.concept}</OreTableCell>
              <OreTableCell>{row.units}</OreTableCell>
            </OreTableRow>
          )}
        />
        <OreCheckbox
          {...register('terms')}
          {...errors('terms')}
          label={
            <>
              {t(flexEmployeesTranslations.configure.common.form.conditions.label)}
              <button
                type="button"
                onClick={() =>
                  downloadLegalDocument(
                    LegalDocumentType.flexPrivacyPolicy,
                    t(flexEmployeesTranslations.configure.common.form.conditions.filename)
                  )
                }
                style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                {t(flexEmployeesTranslations.configure.common.form.conditions.link)}
              </button>
            </>
          }
          required
        />
      </OreStack>
    </ExcelLayout>
  )
}
