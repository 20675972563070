import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Dispatch, SetStateAction, useEffect, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderModel } from 'src/domain/customComponents/table'
import { currency, NotificationSeverity } from 'src/domain/enum'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { useNavigate } from 'react-router-dom'
import { useTGDOrder } from '../../context/TGDOrderProvider'
import {
  EmployeesTGDOrderModel,
  TGDChildModel,
  TGDEmployeeAndChildsModel,
} from 'src/domain/models/TGDOrder/TGDOrderModel'
import { tgdOrderType } from 'src/domain/enum/tgdOrder'
import { OreCheckbox, SvgInfo } from '@edenredespana/oreneta'
import { ConvertStringDateToISOOnly, createOrderId } from 'src/core/helpers'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { forms, spendingRuleFormTranslation } from 'src/domain/translations'
import { useModalController } from 'src/presentation/components/Edenred'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { KindergartenModel } from 'src/domain/models/TGDOrder'

export interface EmployeesTGDOrderFormModel {
  employeesTGD: EmployeesTGDOrderModel[]
}

export interface TGDOrderConfigurationEmployeeState {
  header: HeaderModel
  totalRows: number
  employeesTGD: EmployeesTGDOrderModel[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  form: UseFormReturn<EmployeesTGDOrderFormModel, any>
  addConfigToOrder(): void
  selectedEmployees: EmployeesTGDOrderModel[]
  setSelectedEmployees: Dispatch<SetStateAction<EmployeesTGDOrderModel[]>>
  onSelectEmployee(
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesTGDOrderModel
  ): void
  isSelected(employee: EmployeesTGDOrderModel): boolean
  showSelected: boolean
  setShowSelected: Dispatch<SetStateAction<boolean>>
  onAssignMultipleValues: (initialAmount: string, costCenter: string) => void
  showCancelModal: boolean
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  errorMessage: string
  updateKindergartenToEmployee: (kindergarten: KindergartenModel, childId: number) => void
}

export const useTGDOrderConfigurationEmployees =
  (): TGDOrderConfigurationEmployeeState => {
    const { t } = useTranslation()
    const { setTGDEmployeesToConfig, getTGDEmployeesToConfig, addOrUpdateTDGOrders } =
      useTGDOrder()
    const [page, setPage] = useState(1)
    const pageSize = 8
    const [employeesTGD, setEmployeesTGD] = useState<EmployeesTGDOrderModel[]>(
      getTGDEmployeesToConfig()
    )
    const [totalRows, setTotalRows] = useState<number>(0)
    const navigate = useNavigate()
    const [selectedEmployees, setSelectedEmployees] = useState<EmployeesTGDOrderModel[]>(
      []
    )
    const [showSelected, setShowSelected] = useState<boolean>(false)
    const [selectAll, setSelectAll] = useState<boolean>(false)
    const { addNotification } = useNotification()
    const [errorMessage, setErrorMessage] = useState<string>('')

    const {
      show: showCancelModal,
      buttons: cancelPopupButtons,
      setShow: setShowCancelModal,
      setButtons: setCancelModalButtons,
    } = useModalController()

    const getDiffBetweenEmployeeArrays = (
      array1: EmployeesTGDOrderModel[],
      array2: EmployeesTGDOrderModel[]
    ): EmployeesTGDOrderModel[] => {
      let result: EmployeesTGDOrderModel[] = []
      result = array1.filter(emp1 => {
        return !array2.some(emp2 => {
          return emp1.userSon.userId === emp2.userSon.userId
        })
      })
      return result
    }

    const header: HeaderModel = {
      headerElement: (
        <OreCheckbox
          name="check"
          checked={
            selectAll ||
            getDiffBetweenEmployeeArrays(employeesTGD, selectedEmployees).length <= 0
          }
          onChange={event => {
            onSelectAllEmployees(event)
          }}
          disabled={showSelected}
        />
      ),
      headerLabel: [
        { label: t(employeesTranslation.table.header.titular) },
        { label: t(employeesTranslation.table.header.childs) },
        {
          label: t(employeesTranslation.table.header.kindergarten),
          tooltip: t(
            TGDOrderTranslation.employees.configuration.table.header.kindergartenTooltip
          ),
          tooltipIcon: <SvgInfo />,
        },
        {
          label: t(
            TGDOrderTranslation.employees.configuration.table.header.assignmentAmount
          ),
          tooltip: t(
            TGDOrderTranslation.employees.configuration.table.header
              .assignmentAmountTooltip
          ),
          tooltipIcon: <SvgInfo />,
        },
        { label: t(TGDOrderTranslation.employees.configuration.table.header.costCenter) },
      ],
    }

    const onPageChange = (page: number): void => {
      setPage(page)
    }

    const schema = Yup.object({
      employeesTGD: Yup.array().of(
        Yup.object().shape({
          userSon: Yup.object().shape({
            initialAmount: Yup.number()
              .transform(value => (Number.isNaN(value) ? null : value))
              .nullable()
              .required(t(forms.errors.fieldRequired))
              .min(
                0.1,
                `${t(
                  spendingRuleFormTranslation.form.errors.amountMustBeGreaterThanOrEqual
                )} ${0.1 + currency.euro}`
              )
              .max(
                5000,
                `${t(
                  spendingRuleFormTranslation.form.errors.amountMustBeLessThanOrEqual
                )}  ${5000 + currency.euro}`
              )
              .test(
                'decimal-max-2-places',
                t(
                  spendingRuleFormTranslation.form.errors.amountMustContainMaxTwoDecimals
                ),
                value => {
                  const isNullable = value === null || value === undefined
                  if (isNullable) {
                    return true
                  }
                  const regex = /^\d+(\.\d{1,2})?$/
                  return regex.test(value?.toString())
                }
              ),
          }),
        })
      ),
    })

    const form = useForm<EmployeesTGDOrderFormModel>({
      resolver: yupResolver(schema),
      mode: 'all',
      defaultValues: {
        employeesTGD: employeesTGD,
      },
    })

    const GetTGDEmployeeAndChildsModelAsignmentOnly = (
      employee: EmployeesTGDOrderModel
    ): TGDEmployeeAndChildsModel => {
      const employeeContext: TGDEmployeeAndChildsModel = {
        userId: employee.userId,
        birthDate: employee.userBirthdayDate,
        costCenter: employee.costCenter,
        document: employee.userDni,
        documentTypeId: employee.documentTypeId.toString(),
        email: employee.userEmail,
        employeeNumber: employee.employeeNumber,
        firstSurname: employee.userLastName,
        secondSurname: employee.userLastName2,
        name: employee.userFirstName,
        telephone: employee.userTelephone,
        existingChilds: [],
        childs: [],
        companyId: 0,
        tgdOrderType: tgdOrderType.assignmentOnly,
        id: createOrderId(),
      }
      return employeeContext
    }

    const updateOrder = (employeesTGDOrderModelList: EmployeesTGDOrderModel[]): void => {
      const arrayEmployees: TGDEmployeeAndChildsModel[] = []
      // move unique employees to arrayEmployees
      employeesTGDOrderModelList.forEach(employee => {
        // ha de duplicar el padre segun tipo de pedido
        const parentExists = arrayEmployees.find(emp => emp.document === employee.userDni)
        // parent by order type not exists
        if (!parentExists) {
          addNewParentByAssignmentOnly()
        }

        function addNewParentByAssignmentOnly() {
          const tgdEmployeeAndChildsModel: TGDEmployeeAndChildsModel =
            GetTGDEmployeeAndChildsModelAsignmentOnly(employee)
          arrayEmployees.push(tgdEmployeeAndChildsModel)
        }
      })

      // group employees by document
      const parentChildRelationsByOrderType = employeesTGDOrderModelList.reduce(
        (groupedEmployees, employee) => {
          const document = employee.userDni
          if (!groupedEmployees[document]) {
            groupedEmployees[document] = []
          }
          groupedEmployees[document].push(employee)
          return groupedEmployees
        },
        {} as Record<string, EmployeesTGDOrderModel[]>
      )

      let totalIndex = 0
      // move groupdEmployees childs to arrayEmployees by document
      Object.keys(parentChildRelationsByOrderType).forEach(dni => {
        const childParentRelation = parentChildRelationsByOrderType[dni]
        childParentRelation.forEach(childRelationship => {
          const childInitialAmount = form.getValues(
            `employeesTGD.${totalIndex}.userSon.initialAmount`
          )
          const childCostCenter = form.getValues(
            `employeesTGD.${totalIndex}.userSon.costCenter`
          )
          totalIndex++
          const child: TGDChildModel = {
            firstName: childRelationship.userSon.userFirstName,
            firstName2: '',
            lastName: childRelationship.userSon.userLastName,
            lastName2: childRelationship.userSon.userLastName2,
            birthDate: ConvertStringDateToISOOnly(
              childRelationship.userSon.userBirthdayDate
            ),
            kindergartenId: childRelationship.userSon.lastNurseryId,
            userId: childRelationship.userSon.userId,
            initialAmount: childInitialAmount,
            costCenter: childCostCenter,
            kindergartenData: {
              name: childRelationship.userSon.lastNurseryName,
              id: childRelationship.userSon.lastNurseryId,
              finantialName: '',
              streetType: '',
              streetName: '',
              streetNumber: '',
              streetDescription1: '',
              streetDescription2: '',
              zipCode: '',
              city: '',
              province: '',
              telephone: '',
              email: '',
              latitude: '',
              longitude: '',
              usesPaymentDay: false,
              paymentDay1: 0,
              paymentDay2: 0,
              paymentDay3: 0,
            },
          }
          const index = arrayEmployees.findIndex(
            emp => emp.document === childRelationship.userDni
          )
          arrayEmployees[index]?.childs?.push(child)
        })
      })
      addOrUpdateTDGOrders(arrayEmployees)
      setTGDEmployeesToConfig([])
      navigate(navigationRoutes.tgdOrderSummary)
    }

    const addConfigToOrder = (): void => {
      const childWithoutKindergarten = employeesTGD?.find(
        employee => !employee.userSon.lastNurseryId || employee.userSon.lastNurseryId <= 0
      )
      setErrorMessage(
        childWithoutKindergarten
          ? t(TGDOrderTranslation.employees.configuration.childsWithoutKindergarten)
          : ''
      )
      if (childWithoutKindergarten) return

      updateOrder(employeesTGD)
    }

    const onSelectEmployee = (
      event: ChangeEvent<HTMLInputElement>,
      employee: EmployeesTGDOrderModel
    ): void => {
      if (event.target.checked) {
        const exist = selectedEmployees.find(
          emp => emp.userSon.userId == employee.userSon.userId
        )
        if (!exist) {
          setSelectedEmployees(prev => [...prev, employee])
        }
      } else {
        setSelectedEmployees(
          selectedEmployees.filter(emp => emp.userSon.userId !== employee.userSon.userId)
        )
      }
    }

    const onSelectAllEmployees = (event: ChangeEvent<HTMLInputElement>): void => {
      const selectedArray: EmployeesTGDOrderModel[] = []
      setSelectAll(event.target.checked)
      if (event.target.checked) {
        const difference = getDiffBetweenEmployeeArrays(employeesTGD, selectedEmployees)
        selectedArray.push(...difference)
        if (selectedArray.length > 0)
          setSelectedEmployees(prev => [...prev, ...selectedArray])
      } else {
        selectedArray.push(
          ...selectedEmployees.filter((emp: EmployeesTGDOrderModel) => {
            return !employeesTGD.some((e: EmployeesTGDOrderModel) => {
              return emp.userSon.userId === e.userSon.userId
            })
          })
        )
        setSelectedEmployees([...selectedArray])
      }
    }

    const isSelected = (employee: EmployeesTGDOrderModel): boolean => {
      const exist = selectedEmployees.find(
        emp => emp.userSon.userId == employee.userSon.userId
      )
      return !!exist
    }

    const onAssignMultipleValues = (initialAmount: string, costCenter: string): void => {
      const copyList = [...employeesTGD]
      copyList.map((employee, index) => {
        if (selectedEmployees.includes(employee)) {
          if (initialAmount) {
            employee.userSon.initialAmount = +initialAmount
            form.setValue(
              `employeesTGD.${index + (page - 1) * pageSize}.userSon.initialAmount`,
              +initialAmount
            )
          }
          if (costCenter) {
            employee.userSon.costCenter = costCenter
            form.setValue(
              `employeesTGD.${index + (page - 1) * pageSize}.userSon.costCenter`,
              costCenter
            )
          }
          return employee
        }
      })
      setEmployeesTGD(copyList)
      setDefaultValuesAll()
      setTGDEmployeesToConfig([])
    }

    const onClickCancel = (): void => {
      setShowCancelModal(true)
      setCancelModalButtons(getCancelModalButtons())
    }

    const getCancelModalButtons = (): PopupButtonModel[] => {
      return [
        {
          title: t(forms.buttons.cancel),
          category: 'secondary',
          onClick: () => setShowCancelModal(false),
          size: 'large',
        },
        {
          title: t(forms.buttons.discard),
          category: 'primary',
          onClick: () => {
            setTGDEmployeesToConfig([])
            setShowCancelModal(false)
            addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
            navigate(-2)
          },
          size: 'large',
        },
      ]
    }

    const updateKindergartenToEmployee = (
      kindergarten: KindergartenModel,
      childId: number
    ): void => {
      if (!kindergarten || !kindergarten.id) return
      const index: number = employeesTGD.findIndex(
        (o: EmployeesTGDOrderModel) => o.userSon.userId === childId
      )
      const updatedOrders: EmployeesTGDOrderModel[] = [...employeesTGD]
      const employee = { ...updatedOrders[index] }
      employee.userSon.lastNurseryId = kindergarten.id
      employee.userSon.lastNurseryName = kindergarten.name
      employee.userSon.lastNurseryCity = kindergarten.city
      employee.userSon.lastNurseryProvince = kindergarten.province
      employee.userSon.lastNurseryStreetDescription1 = kindergarten.streetDescription1
      employee.userSon.lastNurseryStreetDescription2 = kindergarten.streetDescription2
      employee.userSon.lastNurseryStreetName = kindergarten.streetName
      employee.userSon.lastNurseryStreetNumber = kindergarten.streetNumber
      employee.userSon.lastNurseryStreetType = kindergarten.streetType
      employee.userSon.lastNurseryZipCode = kindergarten.zipCode
      updatedOrders[index] = employee
      setEmployeesTGD(updatedOrders)
    }

    const setDefaultValuesAll = () => {
      employeesTGD.forEach((employee, index) => {
        if (employee.userSon.initialAmount) {
          form.setValue(
            `employeesTGD.${index}.userSon.initialAmount`,
            employee.userSon.initialAmount
          )
        } else {
          form.setValue(`employeesTGD.${index}.userSon.initialAmount`, undefined)
        }
        if (employee.userSon.costCenter) {
          form.setValue(
            `employeesTGD.${index}.userSon.costCenter`,
            employee.userSon.costCenter
          )
        } else {
          form.setValue(`employeesTGD.${index}.userSon.costCenter`, undefined)
        }
      })
      form.trigger()
    }

    const setDefaultValuesByPage = () => {
      const start = (page - 1) * pageSize
      const end = start + pageSize
      const pageEmployees = employeesTGD.slice(start, end)
      pageEmployees.forEach((employee, index) => {
        if (employee.userSon.initialAmount) {
          form.setValue(
            `employeesTGD.${index + (page - 1) * pageSize}.userSon.initialAmount`,
            employee.userSon.initialAmount
          )
        } else {
          form.setValue(
            `employeesTGD.${index + (page - 1) * pageSize}.userSon.initialAmount`,
            undefined
          )
        }
        if (employee.userSon.costCenter) {
          form.setValue(
            `employeesTGD.${index + (page - 1) * pageSize}.userSon.costCenter`,
            employee.userSon.costCenter
          )
        } else {
          form.setValue(
            `employeesTGD.${index + (page - 1) * pageSize}.userSon.costCenter`,
            undefined
          )
        }
      })
    }

    useEffect(() => {
      setDefaultValuesByPage()
    }, [page])

    useEffect(() => {
      if (selectedEmployees.length === 0) setShowSelected(false)
    }, [selectedEmployees])

    useEffect(() => {
      setTotalRows(employeesTGD.length)
      setDefaultValuesByPage()
    }, [employeesTGD])

    return {
      header,
      totalRows,
      employeesTGD,
      page,
      setPage,
      pageSize,
      onPageChange,
      form,
      selectedEmployees,
      setSelectedEmployees,
      addConfigToOrder,
      onSelectEmployee,
      isSelected,
      showSelected,
      setShowSelected,
      onAssignMultipleValues,
      showCancelModal,
      setShowCancelModal,
      cancelPopupButtons,
      onClickCancel,
      errorMessage,
      updateKindergartenToEmployee,
    }
  }
