import { OreButton, OreNavBarHeaderDivider } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { getUndefinedEmptyString } from 'src/core/services'
import { NotificationSeverity } from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'

export const DevLogin = (): JSX.Element => {
  const { startLoading, stopLoading } = useLoader()
  const { addNotification } = useNotification()
  const { t } = useTranslation()
  const { user } = useAuth()

  if (process.env.NODE_ENV !== 'development') {
    return <></>
  }

  const handleLogin = async (): Promise<void> => {
    startLoading()

    const myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')

    const urlencoded = new URLSearchParams()
    urlencoded.append(
      'username',
      getUndefinedEmptyString(process.env.REACT_APP_DEV_USERNAME)
    )
    urlencoded.append(
      'password',
      getUndefinedEmptyString(process.env.REACT_APP_DEV_PASSWORD)
    )
    urlencoded.append('grant_type', 'password')
    urlencoded.append(
      'scope',
      'openid email profile offline_access eres-user-api autoconnect'
    )
    urlencoded.append('acr_values', 'tenant:es-ben')
    urlencoded.append(
      'client_id',
      getUndefinedEmptyString(process.env.REACT_APP_DEV_CLIENT_ID)
    )
    urlencoded.append(
      'client_secret',
      getUndefinedEmptyString(process.env.REACT_APP_DEV_CLIENT_SECRET)
    )

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
    }

    fetch('https://sso.sbx.edenred.io/connect/token', requestOptions)
      .then(response => response.json())
      .then(result => {
        localStorage.setItem('token', result.access_token)
        sessionStorage.setItem('token', result.access_token)
        addNotification(t(forms.success.message), NotificationSeverity.success)
        window.location.reload()
      })
      .catch(error => {
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
        console.log('error', error)
      })
      .finally(() => stopLoading())
  }

  return (
    <OreNavBarHeaderDivider>
      <OreButton
        category="tertiary"
        onClick={handleLogin}
        disabled={JSON.stringify(user) === '{}' ? false : true}>
        LogIn (dev only)
      </OreButton>
    </OreNavBarHeaderDivider>
  )
}
