import { OreList, OreListItem, OreMessageBar, OreStack } from '@edenredespana/oreneta'
import { flexProductsTranslations } from '../../translations'
import { useTranslation } from 'react-i18next'
import { edenredProducts } from 'src/domain/enum'

export const ProductDangerMessage = (props: {
  numberOfContracts: number
  activeChange: boolean | undefined
  productType: edenredProducts
  downloadFile: () => Promise<void>
  collectiveId?: string
}): JSX.Element => {
  const { t } = useTranslation()

  const selectActiveText_Line1 = (): string => {
    let text = t(flexProductsTranslations.employeeContractAlert.active.line1)
    if (props.productType === edenredProducts.formacion) {
      text = t(flexProductsTranslations.employeeContractAlert.active.line1_formacion)
    }
    if (props.productType === edenredProducts.saludExterno) {
      text = t(flexProductsTranslations.employeeContractAlert.policies.line1)
    }
    return text
  }
  const selectActiveText_Line1_1 = (): string => {
    let text = t(flexProductsTranslations.employeeContractAlert.active.line1_1)
    if (props.productType === edenredProducts.formacion) {
      text = t(flexProductsTranslations.employeeContractAlert.active.line1_1_formacion)
    }
    if (props.productType === edenredProducts.saludExterno) {
      text = t(flexProductsTranslations.employeeContractAlert.policies.line2)
    }
    return text
  }

  const Item1 = (): JSX.Element => {
    let component = (
      <OreListItem>
        <>
          {props.activeChange
            ? selectActiveText_Line1()
            : t(flexProductsTranslations.employeeContractAlert.line1)}
        </>

        <a onClick={props.downloadFile} style={{ color: 'var(--color-error-600)' }}>
          {props.numberOfContracts +
            t(flexProductsTranslations.employeeContractAlert.link)}
        </a>
        {props.productType === edenredProducts.saludExterno ? (
          <>{t(flexProductsTranslations.employeeContractAlert.policies.line1_1)}</>
        ) : null}
      </OreListItem>
    )
    if (props.collectiveId !== undefined && props.productType === edenredProducts.salud) {
      component = (
        <OreListItem>
          <>{t(flexProductsTranslations.employeeContractAlert.collective.line1_health)}</>
          <a onClick={props.downloadFile} style={{ color: 'var(--color-error-600)' }}>
            {props.numberOfContracts +
              t(flexProductsTranslations.employeeContractAlert.link)}
          </a>
        </OreListItem>
      )
    }
    if (
      props.collectiveId !== undefined &&
      props.productType === edenredProducts.saludExterno
    ) {
      component = (
        <OreListItem>
          <>
            {t(
              flexProductsTranslations.employeeContractAlert.collective
                .line1_externalhealth
            )}
          </>
          <a onClick={props.downloadFile} style={{ color: 'var(--color-error-600)' }}>
            {props.numberOfContracts +
              t(flexProductsTranslations.employeeContractAlert.link)}
          </a>
          {t(flexProductsTranslations.employeeContractAlert.policies.line1_1)}
        </OreListItem>
      )
    }
    return component
  }

  const Item2 = (): JSX.Element => {
    let component = (
      <OreListItem>
        <>
          {props.activeChange
            ? selectActiveText_Line1_1()
            : t(flexProductsTranslations.employeeContractAlert.line1_1)}
        </>
      </OreListItem>
    )
    if (props.collectiveId !== undefined && props.productType === edenredProducts.salud) {
      component = (
        <OreListItem>
          {t(flexProductsTranslations.employeeContractAlert.collective.line2_health)}
        </OreListItem>
      )
    }
    return component
  }
  const Item3 = (): JSX.Element => {
    let component = (
      <OreListItem>
        {props.productType === edenredProducts.formacion
          ? t(flexProductsTranslations.employeeContractAlert.active.line2_formacion)
          : t(flexProductsTranslations.employeeContractAlert.line2)}
      </OreListItem>
    )
    if (props.collectiveId !== undefined && props.productType === edenredProducts.salud) {
      component = (
        <OreListItem>
          {t(flexProductsTranslations.employeeContractAlert.collective.line3_health)}
        </OreListItem>
      )
    }
    return component
  }

  const Item4 = (): JSX.Element => {
    let component = <></>
    if (props.collectiveId !== undefined && props.productType === edenredProducts.salud) {
      component = (
        <OreListItem>
          {t(flexProductsTranslations.employeeContractAlert.collective.line4_health)}
        </OreListItem>
      )
    }
    return component
  }

  return (
    <OreMessageBar color="error">
      <OreStack>
        <>
          {props.activeChange ? (
            <strong>
              {t(flexProductsTranslations.employeeContractAlert.active.title)}
            </strong>
          ) : (
            <strong>{t(flexProductsTranslations.employeeContractAlert.title)}</strong>
          )}
        </>

        <OreStack space="2xsmall">
          <div className="ore-list">
            <OreList bullet>
              {Item1()}
              {Item2()}
              {Item3()}
              {Item4()}
              {props.activeChange ? (
                <OreListItem>
                  {t(flexProductsTranslations.employeeContractAlert.active.line2)}
                </OreListItem>
              ) : null}
            </OreList>
          </div>
        </OreStack>
      </OreStack>
    </OreMessageBar>
  )
}
