import { useCallback, useContext, useMemo, useState } from 'react'
import { logout, singleClientData } from '../../../config/constants/endpoints'
import { axiosFetch, getTokenConfig } from '../../../infrastructure/axios'
import type { User } from './AuthContext'
import { AuthContext } from './AuthContext'
import type { HttpRequest } from '../../../domain/protocols'
import { HttpMethod } from '../../../domain/protocols'
import { browserStorageKeys, MetaStatusCodes } from '../../../domain/enum'
import { userService } from 'src/core/services/userService'
import { getUserHomePage } from 'src/core/services'
import { autoenrollmentService } from 'src/core/services/AutoenrollmentService'
import { useRoleStandarization } from '../roleStandarization/RoleStandarizationContext'

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<string>()
  const [oldToken, setOldToken] = useState<string>('')
  const [user, setUser] = useState<User>()
  const [permissionTags, setPermissionTags] = useState<string[]>([])
  const [userHomePage, setUserHomePage] = useState<string>('')
  const [isLogged, setIsLogged] = useState(false)
  const { handleSetFile, handleShowModalAndWarning } = useRoleStandarization()

  const logIn = useCallback(async () => {
    try {
      // Works only in Stage and Prod
      const { data: appToken } = await getTokenConfig({
        url: 'token',
        method: 'GET',
        headers: {
          cors: 'no-cors',
        },
      })
      window.localStorage.setItem(browserStorageKeys.token, appToken.data)

      await getUserData()
    } catch (error: any) {
      // Throws error on localhost and dev environments
      await getUserData()
    }
  }, [user])

  const getUserData = async (): Promise<void> => {
    try {
      const { data: userData } = await axiosFetch(singleClientData, HttpMethod.GET, {})
      const { data: permissionsData } = await userService().GetUserPermissionTags()
      setUser({ ...userData })
      setPermissionTags(permissionsData)

      setUserHomePage(getUserHomePage(permissionsData))
      setIsLogged(true)

      // launch role standarization context if data controller acceptance is pending
      if (userData.dataControllerAcceptancePending) {
        getRoleStandarizationContext(userData)
      }
    } catch (error: any) {
      // setIsLogged(false) // Disabled by now for development purposes
    }
  }

  const getRoleStandarizationContext = async (userData: User): Promise<void> => {
    if (!userData.dataControllerAcceptancePending) return

    const response = await autoenrollmentService().GetDataControllerContext()

    if (
      response.meta.status === MetaStatusCodes.SUCCESS &&
      response.data.documents.length > 0
    ) {
      handleSetFile(response.data.documents[0].documentContent)
      handleShowModalAndWarning(true)
    } else {
      // do nothing
    }
  }

  const logOut = async () => {
    try {
      const { url, method, body }: HttpRequest = {
        url: logout,
        method: HttpMethod.POST,
        body: {},
      }
      await axiosFetch(url, method, body).then(() => {
        sessionStorage.clear()
        localStorage.clear()
        setIsLogged(false)
        setUser(undefined)
      })
    } catch (error) {
      // do nothing
    }
  }

  const value = useMemo(
    () => ({
      token,
      oldToken,
      logIn,
      logOut,
      user,
      permissionTags,
      userHomePage,
      isLogged,
    }),
    [token, logIn, logOut, user, isLogged]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}
export const useAuth = () => useContext(AuthContext)
