import { OreButton, OreStack, OreText } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { orderValidationsTranslations } from '../orderValidationsTranslations'
import { edenredProducts } from 'src/domain/enum'
import NoCard from '../../../../../presentation/assets/img/NoCard.svg'
import { forms } from 'src/domain/translations'
import { useNavigate } from 'react-router-dom'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'

interface Props {
  productType: number
}

const OrderValidationInactiveTR = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <OreStack direction="column" space="large">
      <OreStack>
        <OreText bold>{t(orderValidationsTranslations.configure.subtitleTR)}</OreText>
        <OreText>
          {t(orderValidationsTranslations.configure.TRText1_1)}
          <span className="bold">
            {t(orderValidationsTranslations.configure.TRText1_2)}
          </span>
          {t(orderValidationsTranslations.configure.TRText1_3)}
        </OreText>
        <OreText>{t(orderValidationsTranslations.configure.TRText2)}</OreText>
      </OreStack>
      <OreStack>
        <OreButton
          size="large"
          category="primary"
          onClick={() => navigate(flexNavigationRoutes.flexProducts)}>
          {t(forms.buttons.configure)}
        </OreButton>
      </OreStack>
    </OreStack>
  )
}

const OrderValidationInactiveTT = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <OreStack direction="column" space="large">
      <OreStack>
        <OreText bold>{t(orderValidationsTranslations.configure.subtitleTT)}</OreText>
        <OreText>
          {t(orderValidationsTranslations.configure.TTText1_1)}
          <span className="bold">
            {t(orderValidationsTranslations.configure.TTText1_2)}
          </span>
          {t(orderValidationsTranslations.configure.TTText1_3)}
        </OreText>
        <OreText>{t(orderValidationsTranslations.configure.TTText2)}</OreText>
      </OreStack>
      <OreStack>
        <OreButton
          size="large"
          category="primary"
          onClick={() => navigate(flexNavigationRoutes.flexProducts)}>
          {t(forms.buttons.configure)}
        </OreButton>
      </OreStack>
    </OreStack>
  )
}

const OrderValidationInactiveTGD = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <OreStack direction="column" space="large">
      <OreStack>
        <OreText bold>{t(orderValidationsTranslations.configure.subtitleTGD)}</OreText>
        <OreText>
          {t(orderValidationsTranslations.configure.TGDText1_1)}
          <span className="bold">
            {t(orderValidationsTranslations.configure.TGDText1_2)}
          </span>
          {t(orderValidationsTranslations.configure.TGDText1_3)}
        </OreText>
        <OreText>{t(orderValidationsTranslations.configure.TGDText2)}</OreText>
      </OreStack>
      <OreStack>
        <OreButton
          size="large"
          category="primary"
          onClick={() => navigate(flexNavigationRoutes.flexProducts)}>
          {t(forms.buttons.configure)}
        </OreButton>
      </OreStack>
    </OreStack>
  )
}

export const OrderValidationInactiveProduct = ({ productType }: Props) => {
  const { t } = useTranslation()

  const getModuleByProductType = (productType: number): JSX.Element => {
    switch (productType) {
      case edenredProducts.ticketRestaurant:
        return <OrderValidationInactiveTR />
      case edenredProducts.ticketTransporte:
        return <OrderValidationInactiveTT />
      case edenredProducts.ticketGuarderia:
        return <OrderValidationInactiveTGD />
      default:
        return <OrderValidationInactiveTR />
    }
  }

  return (
    <OreStack direction="row" space="3xlarge">
      {getModuleByProductType(productType)}
      <OreStack>
        <div>
          <figure>
            <img src={NoCard} alt="" />
          </figure>
        </div>
      </OreStack>
    </OreStack>
  )
}
