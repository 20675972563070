import { CutOffDate, ProductModel } from 'src/Flex/Products/domain'
import { CollectiveConfigurationModel } from 'src/Flex/Collectives/domain'
import { createGenericContext } from 'src/Flex/Shared/ui/createGenericContext'
import { useProductController } from 'src/Flex/Products/ui/product-list'
import { ConfigureModalActions } from 'src/Flex/Products/ui/product-configuration'
import { ComponentType, PropsWithChildren, RefAttributes } from 'react'
import { CutOffDateForm } from 'src/Flex/Products/domain'
import { UseFormReturn } from 'react-hook-form'

type Context = {
  products: ProductModel[]
  collective?: CollectiveConfigurationModel
  modal: (id: number) => {
    modal: ComponentType<RefAttributes<ConfigureModalActions>>
    modalIcon?: ComponentType
  } | null
  loadProducts: () => Promise<void>
  loadProductsCollectives: (collectiveId: string) => Promise<void>
  loadCutOffDate: () => Promise<void>
  cutOffDate: CutOffDate | undefined
  form: UseFormReturn<CutOffDateForm>
  saveCutOffDate: (data: CutOffDateForm) => Promise<boolean>
}

type Props = PropsWithChildren & {
  collective?: CollectiveConfigurationModel
  productConfigurations: () => {
    [key: number]: {
      modal: ComponentType<RefAttributes<ConfigureModalActions>>
      modalIcon?: ComponentType
    }
  }
}

const [useProducts, StateContextProvider] = createGenericContext<Context>()

const ProductsProvider = ({
  collective,
  productConfigurations,
  children,
}: Props): JSX.Element => {
  const {
    products,
    loadProducts,
    loadProductsCollectives,
    loadCutOffDate,
    cutOffDate,
    form,
    saveCutOffDate,
  } = useProductController(collective)

  const modal = (
    id: number
  ): {
    modal: ComponentType<RefAttributes<ConfigureModalActions>>
    modalIcon?: ComponentType
  } | null => productConfigurations()[id] ?? null

  return (
    <StateContextProvider
      value={{
        modal,
        collective,
        products,
        loadProducts,
        loadProductsCollectives,
        loadCutOffDate,
        saveCutOffDate,
        cutOffDate,
        form,
      }}>
      {children}
    </StateContextProvider>
  )
}

export { ProductsProvider, useProducts }
