import {
  OreHeading,
  OreMessageBar,
  OreRadioButtonBox,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { MessageBar, Modal, Textbox } from 'src/presentation/components/Edenred'
import { useSpendingRulesForm } from '../hooks/useSpendingRulesForm'
import { Grid } from 'src/presentation/components/Edenred/layout'
import {
  spendingRuleFormTranslation,
  spendingRuleTranslation,
} from 'src/domain/translations'
import { OrderFooter } from 'src/presentation/components/cardOrder/cardOrderForm/molecules'
import { SpendingRuleModel } from 'src/domain/models'
import {
  SpendingRulesTRType,
  SpendingRulesType,
  edenredProducts,
  edenredSubProducts,
} from 'src/domain/enum'
import { SpendingRulesStripesContainer } from './SpendingRulesStripesContainer'
import { SpendingRulesFormTR } from '../molecules/SpendingRulesFormTR'
import { useUser } from 'src/presentation/context/user/UserProvider'

interface SpendingRulesFormProps {
  spendingRuleToEdit?: SpendingRuleModel
}

export const SpendingRulesForm = ({
  spendingRuleToEdit,
}: SpendingRulesFormProps): JSX.Element => {
  const {
    form,
    nameTextbox,
    additionalRestrictions,
    handleAdditionalRestrictionsChange,
    amountTextboxes,
    transactionTextboxes,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    setShowPopup,
    handleChangeFav,
    validateStripesDontOverlap,
    setShowCancelPopup,
    cancelPopupShow,
    cancelPopupTitle,
    cancelPopupDescription,
    cancelPopupButtons,
    errorMessage,
    handleTRTypeOptionChange,
    srType,
  } = useSpendingRulesForm(spendingRuleToEdit)
  const { t } = useTranslation()
  const { contracts } = useUser()
  const currentContract = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketRestaurant
  )

  return (
    <FormProvider {...form}>
      <form className="form-organism">
        <OreStack placeItems="stretch" placeContent="stretch" space="large">
          <OreHeading as="h2" size="headline-lg">
            {t(spendingRuleFormTranslation.form.title)}
          </OreHeading>

          {spendingRuleToEdit &&
            spendingRuleToEdit.profileTypeId === SpendingRulesType.timezones && (
              <OreMessageBar color="warning">
                {t(spendingRuleTranslation.editPage.warningMessage)}
              </OreMessageBar>
            )}

          <section>
            <Textbox
              register={form.register}
              errors={form.formState.errors}
              textBoxList={nameTextbox}
            />
          </section>
          {!spendingRuleToEdit &&
            currentContract?.subProductId === edenredSubProducts.dieta && (
              <section>
                <Grid gap="1rem">
                  <OreRadioButtonBox
                    id="TRD"
                    label={t(spendingRuleFormTranslation.form.profileTypeLabelDiet)}
                    subtitle={t(spendingRuleFormTranslation.form.profileTypeSubtitleDiet)}
                    value={SpendingRulesTRType.TRDiet}
                    onChange={handleTRTypeOptionChange}
                    checked={srType === SpendingRulesTRType.TRDiet}
                  />
                  <OreRadioButtonBox
                    id="TRC"
                    label={t(spendingRuleFormTranslation.form.profileTypeLabelTR)}
                    subtitle={t(spendingRuleFormTranslation.form.profileTypeSubtitleTR)}
                    value={SpendingRulesTRType.TRCard}
                    onChange={handleTRTypeOptionChange}
                    checked={srType === SpendingRulesTRType.TRCard}
                  />
                </Grid>
              </section>
            )}

          {srType === SpendingRulesTRType.TRCard && (
            <SpendingRulesFormTR
              additionalRestrictions={additionalRestrictions}
              amountTextboxes={amountTextboxes}
              handleAdditionalRestrictionsChange={handleAdditionalRestrictionsChange}
              handleChangeFav={handleChangeFav}
              transactionTextboxes={transactionTextboxes}
            />
          )}

          {srType === SpendingRulesTRType.TRDiet && <SpendingRulesStripesContainer />}

          <section>
            <OreText>{t(spendingRuleFormTranslation.form.mandatoryFields)} (*)</OreText>
          </section>
        </OreStack>
      </form>

      <MessageBar color="error" message={errorMessage} className="mt-105" />

      <OrderFooter
        continueButtonLabel={
          spendingRuleToEdit
            ? t(spendingRuleFormTranslation.form.submitEditButtonLabel)
            : t(spendingRuleFormTranslation.form.submitButtonLabel)
        }
        onCancelClick={() => setShowCancelPopup(true)}
        onContinueClick={form.handleSubmit(validateStripesDontOverlap)}
      />

      <Modal
        handleOnClose={setShowPopup}
        open={popupShow}
        title={popupTitle}
        description={popupDescription}
        buttons={popupButtons}
        size="small"
      />
      <Modal
        handleOnClose={setShowCancelPopup}
        open={cancelPopupShow}
        title={cancelPopupTitle}
        description={cancelPopupDescription}
        buttons={cancelPopupButtons}
        size="small"
      />
    </FormProvider>
  )
}
