import {
  ChangeEvent,
  MouseEvent,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreDatePicker,
  OreHeading,
  OreInput,
  OreMessageBar,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreText,
  OreStack,
  OreCardTitle,
  SvgTransport,
  SvgCardhand,
  OreSwitch,
} from '@edenredespana/oreneta'
import { currency, edenredProducts } from 'src/domain/enum'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { forms } from 'src/domain/translations'
import {
  useConfigureTicketTransporteController,
  TicketTransporteForm,
  ConfigureModalActions,
  useEmployeContractAlert,
  EmployeeContractMessage,
  EmployeeContractAlert,
} from 'src/Flex/Products/ui/product-configuration'
import { decimalValueInput, getToday } from 'src/core/helpers'
import { MonthCheckboxesGroup } from 'src/Flex/Products/ui/shared'
import { ResetButton } from 'src/Flex/Shared/ui/ResetButton'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { Divider } from 'src/presentation/layout'
import { Grid } from 'src/presentation/components/Edenred/layout'

export const ConfigureTicketTransporteModalForm = forwardRef<ConfigureModalActions>(
  (_, ref) => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState(false)
    const {
      isGlobal,
      model,
      form,
      load,
      save,
      reset,
      employeesAffected,
      setEmployeesAffected,
      validateEmployeeAffected,
      confirmEmployeeAffected,
      getProduct,
      collective,
    } = useConfigureTicketTransporteController()
    const activeWatch = form.watch('active')
    const monthsWatch = form.watch('months')
    const monthlyLimitWatch = form.watch('monthlyLimit')
    const { errors } = handleErrors(form.formState)
    const { onChange, ...rest } = form.register('monthlyLimit')

    const close = (): void => {
      setEmployeesAffected(undefined)
      setOpen(false)
    }

    const validate = (event: MouseEvent): void => {
      event.preventDefault()
      form.handleSubmit(async (data: TicketTransporteForm) => {
        const result = await validateEmployeeAffected(
          edenredProducts.ticketTransporte,
          data
        )
      })()
    }

    const confirm = (event: MouseEvent): void => {
      event.preventDefault()

      form.handleSubmit(async (data: TicketTransporteForm) => {
        const result = await confirmEmployeeAffected(
          edenredProducts.ticketTransporte,
          data
        )

        if (result) {
          setEmployeesAffected(undefined)
          setOpen(false)
        }
      })()
    }

    const saveAndClose = (event: MouseEvent): void => {
      event.preventDefault()

      form.handleSubmit(async (data: TicketTransporteForm) => {
        const result = await save(data)

        if (result) {
          setOpen(false)
        }
      })()
    }

    useImperativeHandle<ConfigureModalActions, ConfigureModalActions>(ref, () => ({
      open: (): void => {
        load().then(() => {
          setOpen(true)
        })
      },
      close,
    }))

    const handleMonthlyLimitChange = (event: ChangeEvent<HTMLInputElement>): void => {
      event.preventDefault()

      if (Number(event.target.value) % 1 !== 0) {
        form.setValue('monthlyLimit', decimalValueInput(event.target.value))
      }

      onChange(event)
    }

    useEffect(() => {
      setEmployeesAffected(undefined)
    }, [activeWatch, monthsWatch, monthlyLimitWatch])

    const [active, setActive] = useState<boolean | undefined>(false)
    useEffect(() => {
      setActive(form.formState.dirtyFields.active)
    }, [form.formState.dirtyFields.active])

    return (
      <OreModal open={isOpen}>
        <OreModalBox
          size="large"
          handleOnClose={() => {
            setEmployeesAffected(undefined)
            setOpen(false)
          }}>
          <form>
            <OreModalBoxBody noGap>
              <FormProvider {...form}>
                <OreCardTitle
                  icon={<SvgTransport />}
                  tone="transport"
                  title={t(flexProductsTranslations.configure.ticketTransporte.title)}
                  subtitle={
                    model?.isVirtual
                      ? t(
                          flexProductsTranslations.configure.common.productTypologyTypes
                            .virtual
                        )
                      : t(
                          flexProductsTranslations.configure.common.productTypologyTypes
                            .physical
                        )
                  }
                  subtitleIcon={<SvgCardhand />}
                  subtitleTone="grey"
                />
                <Divider marginTop="1rem" marginBottom="2rem" />

                <OreStack placeContent="stretch" space="large">
                  {model &&
                  model.contractStateInfo &&
                  (model.contractStateInfo.isInProgress > 0 ||
                    model.contractStateInfo.isPendingSignature > 0) ? (
                    <EmployeeContractMessage
                      activeContracts={model.contractStateInfo.isInProgress}
                      pendingContracts={model.contractStateInfo.isPendingSignature}
                      isDefault={true}
                    />
                  ) : (
                    <></>
                  )}
                  <OreStack placeContent="stretch" space="medium">
                    <OreText tone="neutral-600">
                      {t(
                        flexProductsTranslations.configure.ticketTransporte.form
                          .monthlyLimit.legend
                      )}
                    </OreText>
                    <Grid direction="row" gap="2rem">
                      <OreDatePicker
                        {...form.register('startDate')}
                        {...errors('startDate')}
                        id="startDate"
                        label={t(
                          flexProductsTranslations.configure.common.form.startDate.label
                        )}
                        legend={t(
                          model?.canUpdateStartDate
                            ? flexProductsTranslations.configure.common.form.startDate
                                .legend
                            : flexProductsTranslations.configure.common.form.startDate
                                .disabledLegend
                        )}
                        required={model?.canUpdateStartDate}
                        min={getToday()}
                        disabled={!model?.canUpdateStartDate}
                        labelTooltip={
                          model?.canUpdateStartDate
                            ? undefined
                            : t(
                                flexProductsTranslations.configure.common.form.startDate
                                  .tooltip
                              )
                        }
                      />
                      <OreInput
                        {...rest}
                        {...errors('monthlyLimit')}
                        id="monthlyLimit"
                        label={t(
                          flexProductsTranslations.configure.common.form.yearlyLimit.label
                        )}
                        type="number"
                        placeholder={t(
                          flexProductsTranslations.configure.ticketTransporte.form
                            .monthlyLimit.placeholder
                        )}
                        startAdornment={currency.euro}
                        required
                        onChange={handleMonthlyLimitChange}
                      />
                    </Grid>
                    <MonthCheckboxesGroup
                      {...errors('months')}
                      label={t(
                        flexProductsTranslations.configure.ticketTransporte.form.months
                          .label
                      )}
                      fieldName="months"
                      required
                      enabledMonths={form.getValues('globalMonths')}
                      legend={t(
                        flexProductsTranslations.configure.ticketTransporte.form.months
                          .legend
                      )}
                    />
                  </OreStack>
                  {isGlobal && (
                    <div className="pt-1">
                      <OreStack>
                        <OreHeading size="headline-md">
                          {t(
                            flexProductsTranslations.configure.ticketRestaurant.form
                              .activate.title
                          )}
                        </OreHeading>
                        <OreText>
                          {t(
                            flexProductsTranslations.configure.ticketRestaurant.form
                              .activate.subtitle
                          )}
                        </OreText>
                        <OreSwitch
                          {...form.register('active')}
                          label={t(
                            flexProductsTranslations.configure.ticketRestaurant.form
                              .activate.activate
                          )}
                          secondLabel={t(
                            flexProductsTranslations.configure.ticketRestaurant.form
                              .activate.deactivate
                          )}
                        />
                      </OreStack>
                    </div>
                  )}
                  <OreText>{t(forms.errors.fieldsRequired)}</OreText>
                  {!isGlobal ? (
                    <ResetButton
                      reset={reset}
                      text={t(
                        flexCollectivesTranslations.configure.edit.products.common
                          .resetValuesTitle
                      )}
                      button={t(
                        flexCollectivesTranslations.configure.edit.products.common
                          .resetValuesButton
                      )}
                    />
                  ) : null}
                  {employeesAffected !== undefined && employeesAffected > 0 && !active ? (
                    <EmployeeContractAlert
                      activeChange={false}
                      deleteChange={false}
                      numberOfContracts={employeesAffected}
                      productType={edenredProducts.ticketTransporte}
                      product={getProduct()}
                      collectiveId={collective?.id}
                    />
                  ) : null}
                  {employeesAffected !== undefined && employeesAffected > 0 && active ? (
                    <EmployeeContractAlert
                      activeChange={true}
                      deleteChange={false}
                      numberOfContracts={employeesAffected}
                      productType={edenredProducts.ticketTransporte}
                      product={getProduct()}
                      collectiveId={collective?.id}
                    />
                  ) : null}
                  {employeesAffected !== undefined && employeesAffected === 0 ? (
                    <EmployeeContractAlert
                      activeChange={false}
                      deleteChange={false}
                      numberOfContracts={employeesAffected}
                      productType={edenredProducts.ticketTransporte}
                      product={getProduct()}
                      collectiveId={collective?.id}
                    />
                  ) : null}
                </OreStack>
              </FormProvider>
            </OreModalBoxBody>
            <OreModalBoxFooter>
              <OreButton onClick={close} size="small" category="secondary">
                {t(forms.buttons.cancel)}
              </OreButton>
              {employeesAffected !== undefined ? (
                <OreButton
                  type="submit"
                  onClick={confirm}
                  size="small"
                  category={'danger'}>
                  {t(flexProductsTranslations.configure.common.submit)}
                </OreButton>
              ) : (
                <OreButton
                  type="submit"
                  onClick={validate}
                  size="small"
                  category={'primary'}>
                  {t(flexProductsTranslations.configure.common.submit)}
                </OreButton>
              )}
            </OreModalBoxFooter>
          </form>
        </OreModalBox>
      </OreModal>
    )
  }
)
