import { SvgInfo } from '@edenredespana/oreneta'
import { useEffect, useState } from 'react'
import type { UseFormRegister } from 'react-hook-form'
import { useFormContext } from 'react-hook-form'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import type {
  RadioButtonModel,
  TextBoxRowModel,
} from '../../../../../domain/customComponents'
import type { BasicParameters } from '../../../../../domain/forms'
import {
  AddressComponent,
  DeliverySiteModel,
  GeocoderResponseResult,
  GeocoderResponseStatus,
  GeocoderStatus,
} from '../../../../../domain/models'
import { deliverySiteFormTranslation, forms } from '../../../../../domain/translations'
import useDebounce from './useDebounce'

export interface AdressState {
  register: UseFormRegister<DeliverySiteModel>
  errors: any
  adressRadioButtons: RadioButtonModel[]
  adressTextBoxes: TextBoxRowModel<DeliverySiteModel>[]
  t: TFunction<'translation', undefined>
}
export const useAdressController = (
  formParameters: BasicParameters<DeliverySiteModel>
): AdressState => {
  const [adressRadioButtons, setAdressRadioButtons] = useState<RadioButtonModel[]>([])
  const [adressTextBoxes, setAdressTextBoxes] = useState<
    TextBoxRowModel<DeliverySiteModel>[]
  >([])
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
    reset,
    setValue,
    setError,
    getValues,
  } = useFormContext<DeliverySiteModel>()

  const [zipValue, setZipValue] = useState<string>('')
  const debouncedValue = useDebounce<string>(zipValue, 500)

  const setProvinceError = (errorMessage: string): void => {
    setValue('province', '')
    setError('province', {
      type: 'value',
      message: t(errorMessage),
    })
  }

  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZipValue(e.currentTarget.value)
  }

  const updateProvinceByZip = async (): Promise<void> => {
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode(
      {
        address: zipValue,
        componentRestrictions: { country: 'ES' },
      },
      (results: GeocoderResponseResult, status: GeocoderResponseStatus) => {
        if (!results || zipValue === '') {
          setProvinceError(forms.errors.fieldRequired)
          return
        }
        if (status === GeocoderStatus.OK && results.length > 0) {
          const addressComponents = results[0].address_components
          let administrativeZone = addressComponents.find((component: AddressComponent) =>
            component.types.includes('administrative_area_level_2')
          )
          if (!administrativeZone) {
            // Check if Ceutao or Melilla
            administrativeZone = addressComponents.find(
              (component: AddressComponent) =>
                component.types.includes('administrative_area_level_1') &&
                ['Ceuta', 'Melilla'].includes(component.long_name)
            )
          }
          if (administrativeZone) {
            setValue('province', administrativeZone.long_name, { shouldValidate: true })
          } else {
            setProvinceError(deliverySiteFormTranslation.address.zipCodeNotFound)
          }
        } else {
          setProvinceError(deliverySiteFormTranslation.address.zipCodeNotFound)
        }
      }
    )
  }

  const GetAdressTextBoxes = () => {
    setAdressTextBoxes([
      {
        className: 'form-atom',
        textBoxes: [
          {
            id: 'streetName',
            label: t(deliverySiteFormTranslation.address.streetName),
            name: 'streetName',
            placeHolder: t(deliverySiteFormTranslation.address.streetNamePlaceHolder),
            required: true,
            maxLength: 50,
            type: 'text',
            googleApi: true,
            autoComplete: 'off',
          },
        ],
      },

      {
        className: 'form-atom form-atom--half',
        textBoxes: [
          {
            id: 'streetNumber',
            label: t(deliverySiteFormTranslation.address.streetNumber),
            name: 'streetNumber',
            placeHolder: t(deliverySiteFormTranslation.address.streetNumberPlaceHolder),
            legend: t(deliverySiteFormTranslation.address.additionalInfoFooter),
            required: true,
            maxLength: 10,
            type: 'text',
            googleApi: false,
          },

          {
            id: 'streetDescription',
            label: t(deliverySiteFormTranslation.address.additionalInfo),
            name: 'streetDescription',
            placeHolder: t(deliverySiteFormTranslation.address.additionalInfoPlaceHolder),
            required: false,
            maxLength: 60,
            type: 'text',
            googleApi: false,
          },
        ],
      },

      {
        className: 'form-atom form-atom--three',
        textBoxes: [
          {
            id: 'zipCode',
            label: t(deliverySiteFormTranslation.address.zipCode),
            name: 'zipCode',
            placeHolder: t(deliverySiteFormTranslation.address.zipCodePlaceHolder),
            required: true,
            maxLength: 5,
            type: 'text',
            googleApi: false,
            onInput: handleZipChange,
          },

          {
            id: 'city',
            label: t(deliverySiteFormTranslation.address.city),
            name: 'city',
            placeHolder: t(deliverySiteFormTranslation.address.cityPlaceHolder),
            required: true,
            maxLength: 50,
            type: 'text',
            googleApi: false,
          },

          {
            id: 'province',
            label: t(deliverySiteFormTranslation.address.province),
            name: 'province',
            placeHolder: t(deliverySiteFormTranslation.address.provincePlaceHolder),
            legend: t(deliverySiteFormTranslation.address.provinceFooter),
            required: true,
            type: 'text',
            googleApi: false,
            disabled: true,
          },
        ],
      },
    ])
  }

  const GetAdressRadioButtons = () => {
    setAdressRadioButtons([
      {
        label: t(deliverySiteFormTranslation.address.company),
        value: '1',
        tooltip: false,
      },

      {
        label: t(deliverySiteFormTranslation.address.particular),
        value: '2',
        tooltip: false,
      },

      {
        label: t(deliverySiteFormTranslation.address.others),
        value: '3',
        tooltip: true,
        tooltipIcon: <SvgInfo aria-label="info" />,
        tooltipText: t(deliverySiteFormTranslation.address.othersInfo),
      },
    ])
  }

  const SetEditModeFormValues = () => {
    reset(prevForm => {
      if (formParameters.entity) {
        setZipValue(formParameters.entity.zipCode)
        return {
          ...prevForm,
          contactType: formParameters.entity.contactType,
          streetName: formParameters.entity.streetName,
          streetNumber: formParameters.entity.streetNumber,
          streetDescription: formParameters.entity.streetDescription,
          zipCode: formParameters.entity.zipCode,
          city: formParameters.entity.city,
          province: formParameters.entity.province,
        }
      }
      return prevForm
    })
  }

  useEffect(() => {
    GetAdressTextBoxes()
    GetAdressRadioButtons()
    if (formParameters) SetEditModeFormValues()
  }, [formParameters])

  useEffect(() => {
    if (window.google) {
      updateProvinceByZip()
    }
  }, [debouncedValue])

  useEffect(() => {
    setZipValue(getValues('zipCode'))
  }, [getValues('zipCode')])

  return { register, errors, adressRadioButtons, adressTextBoxes, t }
}
