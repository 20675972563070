import {
  OreButton,
  OreCheckbox,
  OreTableCell,
  OreTableRow,
  OreText,
  SvgPdf,
} from '@edenredespana/oreneta'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import type { HealthProductModel } from 'src/Flex/Products/domain'
import { currency } from 'src/domain/enum'

interface IProps {
  policy: HealthProductModel
  downloadConditions: (id: string, name: string) => void
  getPolicyIcon: (id: string) => Promise<string>
}

export const FlexHealthProductRow = ({
  policy,
  downloadConditions,
  getPolicyIcon,
}: IProps): JSX.Element => {
  const { id, companyLogoURL, modality, monthlyLimit, conditionsFileName, active } =
    policy
  const { register } = useFormContext()
  const [policyIcon, setPolicyIcon] = useState<string>('')

  const getPolicyIconRow = (): void => {
    getPolicyIcon(id).then(response => {
      if (response) {
        setPolicyIcon('data:image/png;charset=utf-8;base64,' + response)
      }
    })
  }

  useEffect(() => {
    getPolicyIconRow()
  }, [])

  return (
    <OreTableRow valign="middle">
      {active !== undefined ? (
        <OreTableCell>
          <OreCheckbox defaultChecked={active} {...register('policies')} value={id} />
        </OreTableCell>
      ) : null}
      <OreTableCell>
        <img src={policyIcon} alt="logo" width="100" height="30" />
      </OreTableCell>
      <OreTableCell>
        <OreText size="text-sm">{modality}</OreText>
      </OreTableCell>
      <OreTableCell>
        <OreText size="text-sm">
          {monthlyLimit} {currency.euro}/mes
        </OreText>
      </OreTableCell>
      <OreTableCell>
        <OreButton
          size="small"
          category="tertiary"
          onClick={() => downloadConditions(id, conditionsFileName)}>
          <SvgPdf />
        </OreButton>
      </OreTableCell>
    </OreTableRow>
  )
}
