import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreIconButton,
  OreTableCell,
  OreTableRow,
  OreText,
  OreStack,
  OreTooltip,
  SvgAccept,
  SvgDecline,
} from '@edenredespana/oreneta'
import { Kinship, type ProductAdherenceModel } from 'src/Flex/Orders/domain'
import { edenredProducts } from 'src/domain/enum'
import {
  useProductsAdherence,
  productAdherenceTranslations,
  DeclineProductAdherenceModal,
} from 'src/Flex/Orders/ui/order-adherence'
import { ConfigureModalActions } from 'src/Flex/Products/ui/product-configuration'
import { GetDateFormatted, currency } from 'src/core/helpers'
import { flexOrdersTranslations } from '../../translations'
import { Modal } from 'src/presentation/components/Edenred'
import { forms } from 'src/domain/translations'

interface IProps {
  product: ProductAdherenceModel
  productIcon: JSX.Element | null
}

export const FlexProductAdherenceRow = ({
  product,
  productIcon,
}: IProps): JSX.Element => {
  const {
    id,
    name,
    document,
    totalAmount,
    applicationDate,
    userName,
    userLastName,
    kinship,
    schoolName,
    description,
    company,
    beneficiary,
  } = product

  const { t } = useTranslation()
  const ref = useRef<ConfigureModalActions>(null)

  const { acceptOrRejectProductAdherence } = useProductsAdherence()

  const handleDeclineProductAdherence = (): void => {
    ref?.current?.open()
  }

  const handleAcceptProductAdherence = async (id: string): Promise<void> => {
    acceptOrRejectProductAdherence(id, true)
  }

  const [showPopup, setShowPopup] = useState<boolean>(false)

  return (
    <>
      <OreTableRow valign="middle">
        <OreTableCell size="quarter">
          <div className="flex">
            <OreStack
              space="small"
              placeContent="center"
              placeItems="center"
              direction="row">
              {productIcon ? (
                <OreTooltip text={name} placement="top" separation="small">
                  {productIcon}
                </OreTooltip>
              ) : null}

              <OreStack space="2xsmall">
                <OreText size="text-sm" tone="neutral-800" bold={true}>
                  {userName} {userLastName}
                </OreText>
                <OreText size="text-sm">{document}</OreText>
              </OreStack>
            </OreStack>
          </div>
        </OreTableCell>
        <OreTableCell size="quarter">
          {product.productType === edenredProducts.salud ||
          product.productType === edenredProducts.saludExterno ? (
            <>
              {kinship === Kinship.couple ||
              kinship === Kinship.parent ||
              kinship === Kinship.sibling ||
              kinship === Kinship.son ||
              kinship === Kinship.others ? (
                <>
                  <OreText size="text-sm">
                    {t(flexOrdersTranslations.kinship[kinship])} - {beneficiary}
                  </OreText>
                  <OreText size="text-sm">{company}</OreText>
                  <OreText size="text-sm">{description}</OreText>
                </>
              ) : (
                <>
                  <OreText size="text-sm">
                    {userName} {userLastName}
                  </OreText>
                  <OreText size="text-sm">{company}</OreText>
                  <OreText size="text-sm">{description}</OreText>
                </>
              )}
            </>
          ) : product.productType === edenredProducts.formacion ? (
            <>
              <OreText bold>{company}</OreText>
              <OreText>{schoolName}</OreText>
              <div className="hover-pointer">
                <OreText size="text-xs">
                  <u onClick={() => setShowPopup(true)}>
                    {t(productAdherenceTranslations.table.row.seeMore)}
                  </u>
                </OreText>
              </div>
            </>
          ) : null}
        </OreTableCell>
        <OreTableCell>
          <OreStack space="small">
            <OreText size="text-sm">{currency(totalAmount)}</OreText>
          </OreStack>
        </OreTableCell>
        <OreTableCell>
          <OreStack space="small">
            <OreText size="text-sm">{GetDateFormatted(applicationDate)}</OreText>
          </OreStack>
        </OreTableCell>
        <OreTableCell>
          <OreStack space="2xsmall" direction="row">
            <OreTooltip text={t(forms.buttons.decline)} placement="top">
              <OreIconButton
                icon={<SvgDecline />}
                title={t(productAdherenceTranslations.table.row.actions.declineLabel)}
                onClick={(event): void => {
                  event.preventDefault()
                  handleDeclineProductAdherence()
                }}
              />
            </OreTooltip>
            <DeclineProductAdherenceModal id={id} ref={ref} />
            <OreTooltip text={t(forms.buttons.accept)} placement="top">
              <OreIconButton
                icon={<SvgAccept />}
                title={t(productAdherenceTranslations.table.row.actions.acceptLabel)}
                onClick={(event): void => {
                  event.preventDefault()
                  handleAcceptProductAdherence(id)
                }}
              />
            </OreTooltip>
          </OreStack>
        </OreTableCell>
      </OreTableRow>
      <Modal
        size="medium"
        handleOnClose={setShowPopup}
        open={showPopup}
        title={schoolName}
        description={description}
        buttons={[
          {
            title: t(forms.buttons.close),
            category: 'primary',
            onClick: () => setShowPopup(false),
            size: 'large',
          },
        ]}
      />
    </>
  )
}
