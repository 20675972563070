import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { Dispatch, SetStateAction } from 'react'
import { flexEmployeesTranslations } from '../../translations'

interface Props {
  totalEmployees: number
  showDeleteMultipleModal: boolean
  setShowDeleteMultipleModal: Dispatch<SetStateAction<boolean>>
  deleteEmployees: () => void
}

export const DeleteMultipleEmployeesModal = ({
  showDeleteMultipleModal,
  setShowDeleteMultipleModal,
  totalEmployees,
  deleteEmployees,
}: Props) => {
  const { t } = useTranslation()

  return (
    <OreModal
      open={showDeleteMultipleModal}
      handleOnClose={() => setShowDeleteMultipleModal(false)}>
      <OreModalBox size="medium" handleOnClose={() => setShowDeleteMultipleModal(false)}>
        <OreModalBoxBody>
          <OreHeading align="left" size="headline-md">
            {t(flexEmployeesTranslations.deleteToolbar.modal.title1) +
              totalEmployees +
              t(flexEmployeesTranslations.deleteToolbar.modal.title2)}
          </OreHeading>
          <OreStack direction="column" space="small">
            <OreText>
              {t(flexEmployeesTranslations.deleteToolbar.modal.description1)}
            </OreText>
            <OreText>
              {t(flexEmployeesTranslations.deleteToolbar.modal.description2)}
            </OreText>
          </OreStack>
        </OreModalBoxBody>
        <OreModalBoxFooter>
          <OreButton
            onClick={() => setShowDeleteMultipleModal(false)}
            size="small"
            category="secondary">
            {t(forms.buttons.cancel)}
          </OreButton>
          <OreButton onClick={deleteEmployees} size="small" category="danger">
            {t(forms.buttons.delete)}
          </OreButton>
        </OreModalBoxFooter>
      </OreModalBox>
    </OreModal>
  )
}
