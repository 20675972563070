import {
  OreCardActions,
  OreCardContainer,
  OreCardWrapper,
  OreData,
  OreHeading,
  OreIcon,
  OreMap,
  OreSpinner,
  OreStack,
  OreText,
  SvgDestinationDirection,
  SvgKindergartenPlace,
  SvgPhone,
} from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { UserSonModel } from 'src/domain/models'
import { forms } from 'src/domain/translations'
import { useKindergartenCardController } from '../hooks'
import { KindergartenModel } from 'src/domain/models/TGDOrder'
import {
  addComma,
  getUndefinedDashString,
  getUndefinedEmptyString,
} from 'src/core/services'

interface KindergartenCardTGDProps {
  son: UserSonModel
  footerInfoText?: string
}

export const KindergartenCard = ({
  son,
  footerInfoText,
}: KindergartenCardTGDProps): JSX.Element => {
  const { lastNursery, handleApiLoaded } = useKindergartenCardController(
    son.lastNurseryId
  )
  const { t, i18n } = useTranslation()

  if (!lastNursery) return <OreSpinner />

  return (
    <OreCardContainer>
      <OreCardWrapper>
        <OreStack direction="row" placeContent="normal" sameSize>
          <OreStack space="medium">
            <OreStack direction="row" placeItems="center">
              <OreIcon
                icon={<SvgKindergartenPlace />}
                size="medium"
                tone="generic-dark"
              />
              <div>
                <OreHeading size="headline-sm">{lastNursery.name}</OreHeading>
                <OreText>Afiliado No. {lastNursery.id}</OreText>
              </div>
            </OreStack>
            <OreData
              icon={<SvgDestinationDirection />}
              title={t(forms.address.address)}
              description={
                addComma(lastNursery.streetName) +
                addComma(lastNursery.streetNumber) +
                addComma(lastNursery.streetDescription1) +
                addComma(lastNursery.streetDescription2) +
                getUndefinedEmptyString(lastNursery.zipCode) +
                ' ' +
                addComma(lastNursery.city) +
                getUndefinedEmptyString(lastNursery.province)
              }
            />
            <OreData
              icon={<SvgDestinationDirection />}
              title={t(forms.contact.contactPhone)}
              description={getUndefinedDashString(lastNursery.telephone)}
            />
          </OreStack>

          <OreMap
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
              language: i18n.language,
              region: 'ES',
            }}
            defaultCenter={{
              lat: Number(lastNursery.latitude),
              lng: Number(lastNursery.longitude),
            }}
            defaultZoom={13}
            yesIWantToUseGoogleMapApiInternals
            options={{
              disableDefaultUI: true,
              mapTypeControl: false,
              zoomControl: false,
              panControl: false,
              disableDoubleClickZoom: true,
              gestureHandling: 'none',
            }}
            onGoogleApiLoaded={({ map, maps }: any) => handleApiLoaded(map, maps)}
          />
        </OreStack>
      </OreCardWrapper>
      <OreCardActions>
        {footerInfoText && (
          <OreText bold tone="neutral-400">
            {footerInfoText}
          </OreText>
        )}
        &nbsp;
      </OreCardActions>
    </OreCardContainer>
  )
}
