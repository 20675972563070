import {
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
} from '@edenredespana/oreneta'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { useTranslation } from 'react-i18next'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { useTTOrder } from '../../../context/TTOrderProvider'
import { CardOrderResumeDataModel } from 'src/domain/models'

interface Props {
  title: string
  resumeData: CardOrderResumeDataModel[]
}

export const TTOrderExcelSummaryTable = ({ title, resumeData }: Props) => {
  const { t } = useTranslation()

  return (
    <OreTableContainer>
      <OreTable>
        <OreTableCaption title={title} />
        <OreTableHead>
          <OreTableRow>
            <OreTableCell as="th">
              {t(TTOrderTranslation.excel.summary.table.header.concepts)}
            </OreTableCell>
            <OreTableCell as="th">
              {t(TTOrderTranslation.excel.summary.table.header.units)}
            </OreTableCell>
          </OreTableRow>
        </OreTableHead>
        <OreTableBody as="tbody" valign="top">
          {resumeData.map((resumeDataItem: CardOrderResumeDataModel, index: number) => (
            <OreTableRow valign="middle" key={'row' + index}>
              <OreTableCell align="left" as="td" size="auto">
                <OreText bold size="text-sm" tone="neutral-600">
                  {resumeDataItem.name}
                </OreText>
              </OreTableCell>
              <OreTableCell align="left" as="td" size="auto">
                <OreText bold size="text-sm" tone="neutral-600">
                  {resumeDataItem.value}
                </OreText>
              </OreTableCell>
            </OreTableRow>
          ))}
        </OreTableBody>
      </OreTable>
    </OreTableContainer>
  )
}
