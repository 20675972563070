import { OreButton, OreDivider, SvgDownload } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmployeesTTState } from '../hooks/useEmployeesListTableTTController'
import { forms } from 'src/domain/translations'
import { Grid } from 'src/presentation/components/Edenred/layout'

interface Props {
  employeesTTProps: EmployeesTTState
}

export const EmployeeListTableTTFooterButtons = ({
  employeesTTProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { downloadExcel, employeesTT } = employeesTTProps

  return (
    <div className="py-2">
      <OreDivider />
      <div className="py-1">
        <Grid justify="flex-end">
          <OreButton
            as="button"
            category="primary"
            size="large"
            icon={<SvgDownload />}
            // disabled={!employeesTT || employeesTT.length <= 0}
            onClick={downloadExcel}>
            {t(forms.buttons.downloadExcel)}
          </OreButton>
        </Grid>
      </div>
    </div>
  )
}
