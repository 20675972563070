import { OreNavBarItem } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { useUI } from '../../../../../context/ui/UIProvider'
import { useUserData } from 'src/Flex/User/ui/context'
import { computeDisabled } from './computeDisabled'

interface SidebarLinkProps {
  title: string
  icon?: JSX.Element
  hover: boolean
  link?: string
  isFlex?: boolean
  pillText?: string | JSX.Element
  activeLinks?: string[]
}

const SidebarLink = (props: SidebarLinkProps): JSX.Element => {
  const { sidebarCollapsed } = useUI()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()

  const { flexData } = useUserData()

  const isActive = (): boolean => {
    if (props.activeLinks) {
      return props.activeLinks.some(activeLink => {
        const activeLinkParts = activeLink.split('/')
        const pathParts = location.pathname.split('/')
        if (activeLinkParts.length !== pathParts.length) {
          return false
        }
        for (let i = 0; i < activeLinkParts.length; i++) {
          if (activeLinkParts[i].startsWith(':')) {
            continue
          }
          if (activeLinkParts[i] !== pathParts[i]) {
            return false
          }
        }
        return true
      })
    }
    return location.pathname === props.link
  }

  const handleClick = () => {
    if (props.link) {
      navigate(props.link)
    }
  }

  return (
    <>
      {props.icon ? (
        <OreNavBarItem
          active={isActive()}
          title={t(props.title)}
          icon={props.icon}
          collapse={sidebarCollapsed}
          hover={props.hover}
          handleClick={handleClick}
          disabled={props.isFlex ? computeDisabled(flexData, props.link) : false}
        />
      ) : (
        <OreNavBarItem
          active={isActive()}
          title={t(props.title)}
          collapse={sidebarCollapsed}
          hover={props.hover}
          handleClick={handleClick}
          pillText={props.pillText || ''}
        />
      )}
    </>
  )
}

export default SidebarLink
