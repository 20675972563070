import { OreCheckbox, OreTableCell, OreTableRow, OreText } from '@edenredespana/oreneta'
import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import {
  addCurrency,
  getCardOrderStatusLabelById,
  getUndefinedDashString,
} from 'src/core/services'
import { EmployeesWithLastCard } from 'src/domain/models'
import { OreTooltipCustom } from 'src/presentation/components/Edenred/OreTooltipCustom'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'

interface Props {
  employeeTT: EmployeesWithLastCard
  selectedEmployees: EmployeesWithLastCard[]
  setSelectedEmployees: Dispatch<SetStateAction<EmployeesWithLastCard[]>>
  onSelectEmployee(
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesWithLastCard
  ): void
  isSelected(employee: EmployeesWithLastCard): boolean
  isInContext: (employee: EmployeesWithLastCard) => boolean
}

export const TTRechargeSelectMultipleEmployeesRow = ({
  employeeTT,
  onSelectEmployee,
  isSelected,
  isInContext,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const getRowWithTooltip = (): JSX.Element => {
    return (
      <OreTooltipCustom
        text={t(TTOrderTranslation.selectMultipleEmployees.table.rechargeTooltip)}
        placement="left"
        separation="xsmall">
        {getRow()}
      </OreTooltipCustom>
    )
  }

  const getRow = (): JSX.Element => {
    return (
      <OreTableRow valign="middle">
        <OreTableCell align="left" as="td" size="auto">
          <OreCheckbox
            name="check"
            onChange={event => onSelectEmployee(event, employeeTT)}
            role="checkbox"
            aria-checked={isSelected(employeeTT)}
            checked={isSelected(employeeTT)}
            disabled={isInContext(employeeTT)}
          />
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText bold size="text-sm" tone="neutral-900">
            {employeeTT.userName}
          </OreText>
          <OreText size="text-sm" tone="neutral-500">
            {employeeTT.document}
          </OreText>
          <OreText size="text-sm" tone="neutral-500">
            {employeeTT.email}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          {getUndefinedDashString(employeeTT.employeeNumber)}
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          {addCurrency(employeeTT.balance)}
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          {getUndefinedDashString(employeeTT.costCenter)}
        </OreTableCell>
      </OreTableRow>
    )
  }

  return isInContext(employeeTT) ? getRowWithTooltip() : getRow()
}
