import type { TFuncReturn } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { OreButton, OreDivider, SvgSave } from '@edenredespana/oreneta'
import { Divider } from '../../../../layout'
import { forms } from '../../../../../domain/translations'
import { Grid } from 'src/presentation/components/Edenred/layout'

interface OrderFooterProps {
  showSaveAndCloseButton?: boolean
  onSaveClick?: () => void
  onCancelClick?: () => void
  onContinueClick: () => void
  continueButtonLabel: TFuncReturn<
    'translation',
    string,
    | React.ReactElement<any, string | React.JSXElementConstructor<any>>
    | string
    | number
    | Iterable<React.ReactNode>
    | React.ReactPortal
    | boolean
    | null
    | undefined,
    undefined
  >
  isMainButtonEnabled?: boolean
}

export function OrderFooter({
  isMainButtonEnabled = true,
  showSaveAndCloseButton = false,
  ...props
}: OrderFooterProps) {
  const { t } = useTranslation()

  return (
    <>
      <OreDivider space="larger-top" />
      <Grid justify="flex-end" columnGap="1.5rem">
        {showSaveAndCloseButton && (
          <OreButton
            as="button"
            category="highlight"
            size="large"
            style={{ color: '#0F172A' }}
            icon={<SvgSave />}
            onClick={props.onSaveClick}>
            {t(forms.buttons.saveAndClose)}
          </OreButton>
        )}
        {props.onCancelClick != undefined && (
          <OreButton
            as="button"
            category="secondary"
            onClick={props.onCancelClick}
            size="large">
            {t(forms.buttons.cancel)}
          </OreButton>
        )}
        <OreButton
          as="button"
          category="primary"
          size="large"
          disabled={!isMainButtonEnabled}
          onClick={props.onContinueClick}>
          {props.continueButtonLabel}
        </OreButton>
      </Grid>
    </>
  )
}
