import {
  OreTableCell,
  OreTableRow,
  OreText,
  OreStack,
  OreIcon,
  OreIconButton,
  SvgBin,
  OreDivider,
  SvgInternalHealth,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { productsTranslate } from 'src/domain/translations'
import { flexEmployeesTranslations } from '../../translations'
import { HealthInsuranceProductModel } from 'src/Flex/Employees/domain/EmployeeProductListModel'
import ISalud from 'src/presentation/assets/logo/isalud.png'
import { ConfigureEmployeeProductRowItem } from '../atoms/ConfigureEmployeeProductRowItem'
import { useConfigureEmployeeProducts } from '../hooks'
import { edenredProducts } from 'src/domain/enum'
import { ConfigureEmployeeProductDeleteAction } from '../atoms/ConfigureEmployeeProductDeleteAction'

interface IProps {
  employeeId: string
  products: HealthInsuranceProductModel[]
  deleteProductEmployee: (employeeId: string, productId: string) => Promise<void>
}

export const ConfigureEmployeeInternalHealthRow = ({
  employeeId,
  products,
  deleteProductEmployee,
}: IProps): JSX.Element => {
  const { t } = useTranslation()
  const { getRelativeNameByType } = useConfigureEmployeeProducts()

  return (
    <OreTableRow valign="middle" hover={false}>
      <OreTableCell size="quarter">
        <div className="flex">
          <OreStack
            space="xsmall"
            placeContent="center"
            placeItems="center"
            direction="row">
            <OreIcon
              size="extra-small"
              icon={<SvgInternalHealth />}
              tone="flex-products"
            />
            <OreText size="text-sm" tone="neutral-900" bold={true}>
              {t(productsTranslate.ssi)}
            </OreText>
          </OreStack>
        </div>
      </OreTableCell>
      <OreTableCell colSpan={2}>
        <div>
          {products.map((product, index) => (
            <div key={index}>
              <OreStack direction="row" placeContent="space-between">
                <ul>
                  {product.name && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details.name
                      )}
                      value={
                        product.name +
                        (product.isBeneficiary !== undefined
                          ? product.isBeneficiary
                            ? ' (' +
                              t(
                                flexEmployeesTranslations.configure.products.table.details
                                  .beneficiary
                              ) +
                              ')'
                            : ' (' +
                              t(
                                flexEmployeesTranslations.configure.products.table.details
                                  .notBeneficiary
                              ) +
                              ')'
                          : '')
                      }
                    />
                  )}
                  {product.relativeType !== undefined && product.relativeType > 0 && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .relativeType
                      )}
                      value={getRelativeNameByType(product.relativeType)}
                    />
                  )}
                  {product.company && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details.company
                      )}
                      value={product.company}
                    />
                  )}
                  {product.type && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details.type
                      )}
                      value={product.type}
                    />
                  )}
                  {product.monthAmount && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .monthAmount
                      )}
                      value={product.monthAmount.toString() + '€'}
                    />
                  )}
                  {product.contractDate && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .contractDate
                      )}
                      value={product.contractDate}
                    />
                  )}
                  {product.startDate && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .startDate
                      )}
                      value={product.startDate}
                    />
                  )}
                </ul>
                <div className="custom-row--action">
                  <OreStack direction="column" space="2xsmall" placeItems="start">
                    <ConfigureEmployeeProductDeleteAction
                      employeeId={employeeId}
                      productId={product.id}
                      productNumber={edenredProducts.salud}
                      deleteProductEmployee={deleteProductEmployee}
                      disabled={true}
                    />
                    <div style={{ paddingLeft: '0.5rem' }}>
                      <img width={75} src={ISalud} alt="isalud" />
                    </div>
                    <div style={{ paddingLeft: '0.5rem' }} className="underline">
                      <OreText align="center" size="text-sm">
                        902 882 860
                      </OreText>
                    </div>
                  </OreStack>
                </div>
              </OreStack>
              {index < products.length - 1 && (
                <div className="pt-1 pb-1">
                  <OreDivider />
                </div>
              )}
            </div>
          ))}
        </div>
      </OreTableCell>
    </OreTableRow>
  )
}
