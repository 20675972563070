import { OreTableCell, OreTableRow, OreText } from '@edenredespana/oreneta'
import { GetDateFormatted } from 'src/core/helpers/dates'
import { GetAssignmentsMap } from 'src/domain/adapters/historyConsumptionAdapters'
import { currency, emptyValues } from 'src/domain/enum'
import { AssignmentsModel } from 'src/domain/models/historySpendings/AssignmentsModel'

interface Props {
  assignment: AssignmentsModel
}

export const AssignmentsRow = ({ assignment: assignment }: Props): JSX.Element => {
  const assignmentsMap = GetAssignmentsMap(assignment)
  return (
    <OreTableRow>
      {/* Column 1: Employee name */}
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {assignmentsMap.userName}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {assignmentsMap.userDni}
        </OreText>
        <OreText size="text-sm" tone="neutral-500"></OreText>
      </OreTableCell>
      {/* Column  2: Child name */}
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {assignmentsMap.sonName}
        </OreText>
      </OreTableCell>
      {/* Column  3: Nursery/Payment Date*/}
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {assignmentsMap.nurseryName}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {assignmentsMap.paymentDate
            ? GetDateFormatted(assignmentsMap.paymentDate)
            : emptyValues.dash}
        </OreText>
      </OreTableCell>
      {/* Column  4: Assignment Date */}
      <OreTableCell align="left" as="td" size="auto">
        {GetDateFormatted(assignmentsMap.assignmentDate)}
      </OreTableCell>
      {/* Column  5: Amount */}
      <OreTableCell align="left" as="td" size="auto">
        {assignmentsMap.amount + currency.euro}
      </OreTableCell>
    </OreTableRow>
  )
}
