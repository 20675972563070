import {
  OrePagination,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTooltip,
} from '@edenredespana/oreneta'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { tableSelector } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { AccountUsersAndLicensesState } from '../hooks/useAccountUsersAndLicences'
import { accountUsersLicensesTranslations } from 'src/domain/translations/myAccount/accountUsersLicenses'
import { UsersAndLicensesGridModel } from 'src/domain/models/myAccount/UsersAndLicenses'
import { AccountUsersAndLicensesRow } from '../molecules'
import { Modal } from 'src/presentation/components/Edenred'
import { AccountUsersAndLicensesForm } from './AccountUsersAndLicensesForm'

interface Props {
  props: AccountUsersAndLicensesState
}

export const AccountUsersAndLicensesTable = ({ props }: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    totalRows,
    header,
    usersAndLicenses,
    onPageChange,
    page,
    pageSize,
    setShowDeleteModal,
    showDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
    onClickDelete,
    onClickEdit,
    showEdit,
    setShowEdit,
    showEditTitle,
    showEditButtons,
    isEditing,
    form,
    getUserDataForm,
    errorMessage,
    usersAndLicense,
  } = props

  return (
    <>
      <OreTableContainer>
        <OreTable>
          <OreTableCaption title={t(accountUsersLicensesTranslations.table.title)} />
          <OreTableHead>
            <OreTableRow>
              {header.headerElement && (
                <OreTableCell as="th">{header.headerElement}</OreTableCell>
              )}
              {header.headerLabel.map((header, index) =>
                !header.tooltip ? (
                  <OreTableCell as="th" key={'lbl' + index}>
                    {header.label}
                  </OreTableCell>
                ) : (
                  <OreTableCell as="th" key={'lbl' + index}>
                    {header.label}&nbsp;
                    <OreTooltip placement="top" separation="small" text={header.tooltip}>
                      <span>{header.tooltipIcon}</span>
                    </OreTooltip>
                  </OreTableCell>
                )
              )}
            </OreTableRow>
          </OreTableHead>
          <OreTableBody as="tbody" valign="top">
            {usersAndLicenses && usersAndLicenses.length <= 0 ? (
              <TableEmpty
                colSpan={7}
                firstText={t(accountUsersLicensesTranslations.table.emptyText)}
              />
            ) : (
              usersAndLicenses.map(
                (userAndLicense: UsersAndLicensesGridModel, index: number) => {
                  return (
                    <AccountUsersAndLicensesRow
                      userAndLicense={userAndLicense}
                      key={'rows' + index}
                      onClickDelete={onClickDelete}
                      onClickEdit={onClickEdit}
                    />
                  )
                }
              )
            )}
          </OreTableBody>
        </OreTable>
        {usersAndLicenses && usersAndLicenses.length > 0 && (
          <OreTableActions>
            <OrePagination
              count={totalRows}
              labelNextPage={t(tableSelector.nextPageLabel)}
              labelPreviousPage={t(tableSelector.previousPageLabel)}
              onPageChange={onPageChange}
              page={page}
              rowsPerPage={pageSize}
              text={t(tableSelector.pageOf)}
            />
          </OreTableActions>
        )}
      </OreTableContainer>
      <Modal
        handleOnClose={setShowDeleteModal}
        open={showDeleteModal}
        title={deleteModalTitle}
        description={deleteModalDescription}
        buttons={deletePopupButtons}
      />
      <Modal
        handleOnClose={setShowEdit}
        open={showEdit}
        title={showEditTitle}
        showDivider={true}
        buttons={showEditButtons}
        size={'large'}>
        <AccountUsersAndLicensesForm
          form={form}
          getUserDataForm={getUserDataForm}
          errorMessage={errorMessage}
          isEditing={isEditing}
          userData={usersAndLicense}
        />
      </Modal>
    </>
  )
}
