import {
  EmployeeProductListModel,
  RelationShipEnum,
} from 'src/Flex/Employees/domain/EmployeeProductListModel'
import { flexOrdersTranslations } from 'src/Flex/Orders/ui/translations'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { employeesService } from 'src/Flex/Employees/application'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { flexEmployeesTranslations } from '../../translations'

interface Props {
  getRelativeNameByType: (type: RelationShipEnum) => string
  productList: EmployeeProductListModel | undefined
  getProductsByEmployee: (id: string) => Promise<void>
  deleteProductEmployee: (employeeId: string, productId: string) => Promise<void>
}

export const useConfigureEmployeeProducts = (): Props => {
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const [productList, setProductList] = useState<EmployeeProductListModel>()
  const { t } = useTranslation()

  const getRelativeNameByType = (type: RelationShipEnum): string => {
    const { t } = useTranslation()

    const relationShipMap: { [key in RelationShipEnum]?: string } = {
      [RelationShipEnum.son]: flexOrdersTranslations.kinship[1],
      [RelationShipEnum.couple]: flexOrdersTranslations.kinship[2],
      [RelationShipEnum.sibling]: flexOrdersTranslations.kinship[3],
      [RelationShipEnum.parent]: flexOrdersTranslations.kinship[4],
      [RelationShipEnum.other]: flexOrdersTranslations.kinship[5],
    }

    return t(relationShipMap[type] || '')
  }

  const getProductsByEmployee = async (id: string): Promise<void> => {
    startLoading()

    const response = await employeesService().GetEmployeeProductList(id)

    stopLoading()

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      setProductList(response.data)
    }
  }

  const deleteProductEmployee = async (
    employeeId: string,
    productId: string
  ): Promise<void> => {
    startLoading()
    const response = await employeesService().DeleteEmployeeProductList(productId)
    stopLoading()

    if (
      handleMetaResponse(response?.meta, undefined, {
        notifySuccess: true,
        successMessage: t(
          flexEmployeesTranslations.configure.products.table.details.successMessage
        ),
      })
    ) {
      if (response.data) getProductsByEmployee(employeeId)
    }
  }

  return {
    getRelativeNameByType,
    productList,
    getProductsByEmployee,
    deleteProductEmployee,
  }
}
