import {
  OreCheckbox,
  OrePill,
  OreTableCell,
  OreTableRow,
  OreText,
  SvgWarning,
} from '@edenredespana/oreneta'
import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { GetDateFormatted, isOlderThan6OrWillTurn7DuringSchool } from 'src/core/helpers'
import {
  addComma,
  getUndefinedDashString,
  getUndefinedEmptyString,
} from 'src/core/services'
import { EmployeesTGDOrderModel } from 'src/domain/models/TGDOrder/TGDOrderModel'
import { forms } from 'src/domain/translations'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { OreTooltipCustom } from '../../../Edenred/OreTooltipCustom'

interface Props {
  employeeTGD: EmployeesTGDOrderModel
  selectedEmployees: EmployeesTGDOrderModel[]
  setSelectedEmployees: Dispatch<SetStateAction<EmployeesTGDOrderModel[]>>
  onSelectEmployee(
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesTGDOrderModel
  ): void
  isSelected(employee: EmployeesTGDOrderModel): boolean
}

export const TGDOrderSelectMultipleEmployeesRow = ({
  employeeTGD,
  onSelectEmployee,
  isSelected,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const childBirthday: Date = new Date(employeeTGD.userSon.userBirthdayDate)
  const isChildOlderThan6 = isOlderThan6OrWillTurn7DuringSchool(
    childBirthday.toISOString()
  )

  const getRowChildWithEducationWarning = (): JSX.Element => {
    return (
      <OreTooltipCustom
        text={
          isChildOlderThan6
            ? t(TGDOrderTranslation.form.child.warnings.childOlderThan6)
            : t(TGDOrderTranslation.form.child.warnings.specialEducation)
        }
        placement="left"
        separation="xsmall">
        {getRowchild()}
      </OreTooltipCustom>
    )
  }

  const getRowchild = (): JSX.Element => {
    return (
      <OreTableRow valign="middle" data-disabled-row={isChildOlderThan6}>
        <OreTableCell align="left" as="td" size="auto">
          <OreCheckbox
            name="check"
            onChange={event => onSelectEmployee(event, employeeTGD)}
            role="checkbox"
            aria-checked={isSelected(employeeTGD)}
            checked={isChildOlderThan6 ? false : isSelected(employeeTGD)}
            disabled={isChildOlderThan6}
          />
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText bold size="text-sm" tone="neutral-900">
            {employeeTGD.userName}
          </OreText>
          <OreText size="text-sm" tone="neutral-500">
            {employeeTGD.userDni}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          {getUndefinedDashString(employeeTGD.employeeNumber)}
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText bold size="text-sm" tone="neutral-900">
            {employeeTGD.userSon.userFirstName +
              ' ' +
              employeeTGD.userSon.userLastName +
              ' ' +
              (employeeTGD.userSon.userLastName2
                ? employeeTGD.userSon.userLastName2
                : '')}
          </OreText>
          <OreText size="text-sm" tone="neutral-500">
            {employeeTGD.userSon.userBirthdayDate &&
              GetDateFormatted(employeeTGD.userSon.userBirthdayDate)}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          {employeeTGD.userSon.lastNurseryName ? (
            <>
              <OreText size="text-sm" tone="neutral-900" bold>
                {employeeTGD.userSon.lastNurseryName}
              </OreText>
              <OreText size="text-sm" tone="neutral-900">
                {addComma(employeeTGD.userSon.lastNurseryStreetName) +
                  addComma(employeeTGD.userSon.lastNurseryStreetNumber) +
                  addComma(employeeTGD.userSon.lastNurseryStreetDescription1) +
                  addComma(employeeTGD.userSon.lastNurseryStreetDescription2) +
                  getUndefinedEmptyString(employeeTGD.userSon.lastNurseryZipCode) +
                  ' ' +
                  addComma(employeeTGD.userSon.lastNurseryCity) +
                  getUndefinedEmptyString(employeeTGD.userSon.lastNurseryProvince)}
              </OreText>
            </>
          ) : (
            <OrePill icon={<SvgWarning aria-hidden />} tone="warning-600">
              {t(forms.labels.unassigned)}
            </OrePill>
          )}
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          {getUndefinedDashString(employeeTGD.costCenter)}
        </OreTableCell>
      </OreTableRow>
    )
  }
  return isChildOlderThan6 ? getRowChildWithEducationWarning() : getRowchild()
}
