import {
  OreHeading,
  OrePagination,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTableSortLabel,
  OreText,
  OreTooltip,
} from '@edenredespana/oreneta'
import { DuplicatedEmployeesState } from '../hooks/useDuplicatedEmployees'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { duplicatedTranslation } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { EmployeesWithLastCardNew } from 'src/domain/models/cardOrder/employeesWithLastCard/EmployeesWithLastCardNew'
import { DuplicatedEmployeesCaption } from './DuplicatedEmployeesCaption'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { DuplicatedEmployeesRow } from './DuplicatedEmployeesRow'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { Fragment } from 'react'

interface Props {
  useDuplicatedEmployees: DuplicatedEmployeesState
}

export const DuplicatedEmployeesTable = ({
  useDuplicatedEmployees: duplicatedEmployeesProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    totalRows,
    header,
    employees,
    onPageChange,
    page,
    pageSize,
    employeesSelected,
    onSelectEmployee,
    isSelected,
    showSelected,
  } = duplicatedEmployeesProps

  return (
    <OreTableContainer>
      <div className="bg-white">
        <Grid items="center" columnGap="1rem" className="px-1 pt-1">
          <OreHeading size="headline-md">
            {t(duplicatedTranslation.captionTitle)}
          </OreHeading>
          <OreText tone="neutral-500" size="text-sm">
            {totalRows + ' ' + t(duplicatedTranslation.rowsTotalDescription)}
          </OreText>
        </Grid>
        <DuplicatedEmployeesCaption duplicatedEmployeesProps={duplicatedEmployeesProps} />
      </div>
      <OreTable>
        <OreTableHead>
          <OreTableRow>
            {header.headerElement && (
              <OreTableCell as="th">{header.headerElement}</OreTableCell>
            )}
            {header.headerLabel.map((header, index) =>
              !header.isSortable ? (
                <OreTableCell as="th" key={'lbl' + index}>
                  {header.label}&nbsp;
                  {header.tooltip && (
                    <OreTooltip placement="top" separation="small" text={header.tooltip}>
                      <span>{header.tooltipIcon}</span>
                    </OreTooltip>
                  )}
                </OreTableCell>
              ) : (
                <OreTableCell as="th" key={'lbl' + index}>
                  {showSelected ? (
                    <>
                      {header.label}&nbsp;
                      {header.tooltip && (
                        <Fragment key={'tooltip_icon_' + index}>
                          {' '}
                          <OreTooltip
                            placement="top"
                            separation="small"
                            text={header.tooltip}>
                            <span>{header.tooltipIcon}</span>
                          </OreTooltip>
                        </Fragment>
                      )}
                    </>
                  ) : (
                    <OreTableSortLabel
                      active
                      direction={header.sortDirection}
                      onClick={header.onClick}>
                      {header.label}&nbsp;
                      {header.tooltip && (
                        <Fragment key={'tooltip_icon_' + index}>
                          {' '}
                          <OreTooltip
                            placement="top"
                            separation="small"
                            text={header.tooltip}>
                            <span>{header.tooltipIcon}</span>
                          </OreTooltip>
                        </Fragment>
                      )}
                    </OreTableSortLabel>
                  )}
                </OreTableCell>
              )
            )}
          </OreTableRow>
        </OreTableHead>
        <OreTableBody as="tbody" valign="top">
          {employees && employees.length <= 0 ? (
            <TableEmpty
              colSpan={7}
              firstText={t(duplicatedTranslation.searchNoFound.first)}
              linkText={t(duplicatedTranslation.searchNoFound.link)}
              endText={t(duplicatedTranslation.searchNoFound.end)}
              linkUrl={navigationRoutes.cardOrderNew}
            />
          ) : showSelected && employeesSelected.length > 0 ? (
            employeesSelected
              .slice((page - 1) * pageSize, page * pageSize)
              .map((employee: EmployeesWithLastCardNew, index: number) => {
                return (
                  <DuplicatedEmployeesRow
                    employee={employee}
                    isSelected={isSelected}
                    onSelectEmployee={onSelectEmployee}
                    key={'rows' + index}
                  />
                )
              })
          ) : (
            employees.map((employee: EmployeesWithLastCardNew, index: number) => {
              return (
                <DuplicatedEmployeesRow
                  employee={employee}
                  isSelected={isSelected}
                  onSelectEmployee={onSelectEmployee}
                  key={'rows' + index}
                />
              )
            })
          )}
        </OreTableBody>
      </OreTable>
      <OreTableActions>
        <Grid gap="2rem">
          <OreText as="span" size="text-sm" tone="neutral">
            {employeesSelected.length}{' '}
            {employeesSelected.length == 1
              ? t(duplicatedTranslation.selectedRowsDescriptionOne)
              : t(duplicatedTranslation.selectedRowsDescriptionOthers)}
          </OreText>
        </Grid>
        <OrePagination
          count={showSelected ? employeesSelected.length : totalRows}
          labelNextPage={t(duplicatedTranslation.nextPageLabel)}
          labelPreviousPage={t(duplicatedTranslation.previousPageLabel)}
          onPageChange={onPageChange}
          page={page}
          rowsPerPage={pageSize}
          text={t(duplicatedTranslation.pageOf)}
        />
      </OreTableActions>
    </OreTableContainer>
  )
}
