import {
  OreTableCell,
  OreTableRow,
  OreText,
  OreStack,
  OreIcon,
  OreDivider,
  SvgFormation,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { productsTranslate } from 'src/domain/translations'
import { TrainingProductModel } from 'src/Flex/Employees/domain/EmployeeProductListModel'
import { flexEmployeesTranslations } from '../../translations'
import React from 'react'
import { ConfigureEmployeeProductRowItem } from '../atoms/ConfigureEmployeeProductRowItem'
import { ConfigureEmployeeProductDeleteAction } from '../atoms/ConfigureEmployeeProductDeleteAction'
import { edenredProducts } from 'src/domain/enum'

interface IProps {
  employeeId: string
  products: TrainingProductModel[]
  deleteProductEmployee: (employeeId: string, productId: string) => Promise<void>
}

export const ConfigureEmployeeTrainingRow = ({
  employeeId,
  products,
  deleteProductEmployee,
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <OreTableRow valign="middle" hover={false}>
      <OreTableCell size="quarter">
        <div className="flex">
          <OreStack
            space="xsmall"
            placeContent="center"
            placeItems="center"
            direction="row">
            <OreIcon size="extra-small" icon={<SvgFormation />} tone="flex-products" />
            <OreText size="text-sm" tone="neutral-900" bold={true}>
              {t(productsTranslate.training)}
            </OreText>
          </OreStack>
        </div>
      </OreTableCell>
      <OreTableCell colSpan={2}>
        <div>
          {products.map((product, index) => (
            <div key={index}>
              <OreStack direction="row" placeContent="space-between">
                <ul>
                  {product.trainingName && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .trainingName
                      )}
                      value={product.trainingName}
                    />
                  )}
                  {product.schoolName && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .schoolName
                      )}
                      value={product.schoolName}
                    />
                  )}
                  {product.contractDate && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .contractDate
                      )}
                      value={product.contractDate}
                    />
                  )}
                  {product.startDate && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .startDate
                      )}
                      value={product.startDate}
                    />
                  )}
                  {product.totalAmount && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .trainingTotalAmount
                      )}
                      value={product.totalAmount.toString() + '€'}
                    />
                  )}
                  {product.splitPayment && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .splitPayment
                      )}
                      value={product.splitPayment.toString()}
                    />
                  )}
                  {product.pendingAmount && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .pendingPaymentAmount
                      )}
                      value={`${product.pendingAmount}€`}
                    />
                  )}
                </ul>
                <ConfigureEmployeeProductDeleteAction
                  employeeId={employeeId}
                  productId={product.id}
                  productNumber={edenredProducts.formacion}
                  deleteProductEmployee={deleteProductEmployee}
                />
              </OreStack>
              {index < products.length - 1 && (
                <div className="pt-1 pb-1">
                  <OreDivider />
                </div>
              )}
            </div>
          ))}
        </div>
      </OreTableCell>
    </OreTableRow>
  )
}
