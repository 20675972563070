import {
  OreCheckbox,
  OreTableCell,
  OreTableSortLabel,
  OreTooltip,
} from '@edenredespana/oreneta'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { Fragment, memo } from 'react'
import { useHeader } from '../..'
import type {
  HeaderLabelModel,
  HeaderModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'

export const Header = memo(
  (props: {
    header?: HeaderModel
    multiSelected: boolean | undefined
    selectedRows: number[]
    totalRows: number[]
    rows: RowModel[]
    setSelectedRows: Dispatch<SetStateAction<number[]>>
    setRows: Dispatch<SetStateAction<RowModel[]>>
  }): JSX.Element => {
    const { onChangeSelectedAllRows } = useHeader(
      props.rows,
      props.setSelectedRows,
      props.setRows
    )
    return (
      <>
        {props.multiSelected && (
          <OreTableCell as="th">
            <OreCheckbox
              name="allRowSelected"
              indeterminate={
                props.selectedRows.length > 0 &&
                props.selectedRows.length < props.totalRows.length
              }
              checked={
                props.totalRows.length > 0 &&
                props.selectedRows.length === props.totalRows.length
              }
              onChange={event => onChangeSelectedAllRows(event)}
            />
          </OreTableCell>
        )}
        {props.header?.headerElement && (
          <OreTableCell as="th">{props.header.headerElement}</OreTableCell>
        )}
        {props.header &&
          Seq(props.header.headerLabel).map((header: HeaderLabelModel, index: number) =>
            !header.isSortable ? (
              <OreTableCell
                key={'headerLabel_' + index}
                as="th"
                align={header.align || 'left'}
                size={header.size}>
                {header.headerElement}
                {header.label}
                {header.tooltip && header.tooltipIcon && (
                  <Fragment key={'tooltip_icon_' + index}>
                    {' '}
                    <OreTooltip text={header.tooltip} placement="top">
                      <span>{header.tooltipIcon}</span>
                    </OreTooltip>
                  </Fragment>
                )}
              </OreTableCell>
            ) : (
              <OreTableCell
                key={'headerLabel_' + index}
                as="th"
                align={header.align || 'left'}
                size={header.size}>
                <OreTableSortLabel
                  active
                  direction={header.sortDirection}
                  onClick={header.onClick}>
                  {header.label}
                  {header.tooltip && header.tooltipIcon && (
                    <Fragment key={'tooltip_icon_' + index}>
                      {' '}
                      <OreTooltip text={header.tooltip} placement="top">
                        <span>{header.tooltipIcon}</span>
                      </OreTooltip>
                    </Fragment>
                  )}
                </OreTableSortLabel>
              </OreTableCell>
            )
          )}
      </>
    )
  }
)
