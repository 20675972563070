import {
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreFormGroup,
  OreHeading,
  OreIconButton,
  OreMap,
  OrePagination,
  OreRadioButtonBox,
  OreStack,
  OreText,
  SvgDuplicate,
  SvgExternalLink,
} from '@edenredespana/oreneta'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { Textbox } from 'src/presentation/components/Edenred'
import { KindergartenSearchProps } from '../hooks/useTGDOrderFormKindergartenAssignment'
import { Grid } from 'src/presentation/components/Edenred/layout'
import i18n from 'src/presentation/i18n/i18n'
interface Props {
  searchKindergartenProps: KindergartenSearchProps
}

export const TGDOrderSearchKindergarten = ({ searchKindergartenProps }: Props) => {
  const { t } = useTranslation()

  const {
    errorMessage,
    form,
    getSearchKindergartenForm,
    clearFilters,
    allowSearch,
    search,
    onPageChange,
    totalRows,
    nurseryList,
    page,
    pageSize,
    handleApiLoaded,
    showNotFound,
    kindergartenSelected,
    setKindergartenSelected,
    viewOnly,
  } = searchKindergartenProps
  return (
    <>
      <OreStack>
        <OreHeading size="headline-md">
          {viewOnly
            ? t(TGDOrderTranslation.kindergartenAssignment.modalForm.titleViewOnly)
            : t(TGDOrderTranslation.kindergartenAssignment.modalForm.title)}
        </OreHeading>
        <OreText size="text-md">
          {viewOnly
            ? t(TGDOrderTranslation.kindergartenAssignment.modalForm.subtitleViewOnly)
            : t(TGDOrderTranslation.kindergartenAssignment.modalForm.subtitle)}
        </OreText>
      </OreStack>
      <FormProvider {...form}>
        <form>
          <Textbox
            textBoxList={getSearchKindergartenForm()}
            register={form.register}
            errors={form.formState.errors}
          />
          <Grid columnGap="1rem" className="pt-1">
            <OreButton
              size="large"
              category="primary"
              disabled={!allowSearch}
              onClick={form.handleSubmit(search)}>
              {t(forms.buttons.search)}
            </OreButton>
            <OreButton size="large" category="secondary" onClick={() => clearFilters()}>
              {t(forms.buttons.clearFilters)}
            </OreButton>
          </Grid>
        </form>
      </FormProvider>
      {nurseryList && nurseryList.length <= 0 && showNotFound && (
        <OreText size="text-md">
          {t(TGDOrderTranslation.kindergartenAssignment.modalForm.emptyList)}
        </OreText>
      )}
      {nurseryList && nurseryList.length > 0 && (
        <>
          {viewOnly ? (
            <OreText>
              {t(TGDOrderTranslation.kindergartenAssignment.modalForm.totalResults1) +
                totalRows +
                t(TGDOrderTranslation.kindergartenAssignment.modalForm.totalResults2)}
            </OreText>
          ) : (
            <OreText size="text-md">
              {kindergartenSelected &&
                kindergartenSelected.name &&
                t(
                  TGDOrderTranslation.kindergartenAssignment.modalForm.selectedDescription
                ) + kindergartenSelected.name}
            </OreText>
          )}

          <OreStack direction="row" placeContent="normal" sameSize space="large">
            <OreStack>
              <div style={{ maxHeight: '279px', overflowY: 'scroll' }}>
                <OreFormGroup direction="column">
                  {nurseryList.map(nursery => {
                    return viewOnly ? (
                      <div
                        className="kindergarten-search-card"
                        key={nursery.id}
                        onClick={() => setKindergartenSelected(nursery)}>
                        <OreStack space="xsmall">
                          <OreText bold>{nursery.name}</OreText>
                          <OreText>
                            {nursery.streetName +
                              ' ' +
                              nursery.streetNumber +
                              ', ' +
                              nursery.zipCode +
                              ' ' +
                              nursery.city}
                          </OreText>
                          <OreText>
                            {t(
                              TGDOrderTranslation.kindergartenAssignment.modalForm
                                .merchantNumber
                            ) + nursery.id}
                            <OreIconButton
                              as="a"
                              icon={<SvgDuplicate />}
                              onClick={() =>
                                navigator.clipboard.writeText(nursery.id.toString())
                              }
                            />
                          </OreText>
                        </OreStack>
                      </div>
                    ) : (
                      <OreRadioButtonBox
                        key={nursery.id}
                        id={nursery.id.toString()}
                        label={nursery.name}
                        subtitle={
                          '(#' +
                          nursery.id +
                          ') ' +
                          nursery.streetName +
                          ' ' +
                          nursery.streetNumber +
                          ', ' +
                          nursery.zipCode +
                          ' ' +
                          nursery.city
                        }
                        value={nursery.id}
                        onChange={() => setKindergartenSelected(nursery)}
                        onClick={() => setKindergartenSelected(nursery)}
                        checked={
                          kindergartenSelected
                            ? nursery.id === kindergartenSelected.id
                            : false
                        }
                      />
                    )
                  })}
                </OreFormGroup>
              </div>
            </OreStack>
            {kindergartenSelected && kindergartenSelected.id && (
              <OreMap
                key={kindergartenSelected.id}
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
                  language: i18n.language,
                  region: 'ES',
                }}
                defaultCenter={{
                  lat: Number(kindergartenSelected.latitude),
                  lng: Number(kindergartenSelected.longitude),
                }}
                defaultZoom={13}
                yesIWantToUseGoogleMapApiInternals
                options={{
                  disableDefaultUI: true,
                  mapTypeControl: false,
                  zoomControl: false,
                  panControl: false,
                  disableDoubleClickZoom: true,
                  gestureHandling: 'none',
                }}
                onGoogleApiLoaded={({ map, maps }: any) => handleApiLoaded(map, maps)}
              />
            )}
          </OreStack>
          <OreStack placeContent="start">
            <OrePagination
              count={totalRows}
              labelNextPage={t(forms.pagination.next)}
              labelPreviousPage={t(forms.pagination.back)}
              onPageChange={onPageChange}
              page={page}
              rowsPerPage={pageSize}
              text={t(forms.pagination.of)}
            />
          </OreStack>
        </>
      )}
      <OreStack placeContent="start" direction="column">
        <OreHeading size="headline-sm">
          {t(TGDOrderTranslation.kindergartenAssignment.modalForm.notFoundTitle)}
        </OreHeading>
        <OreText size="text-md">
          {t(TGDOrderTranslation.kindergartenAssignment.modalForm.notFoundSubtitle)}
        </OreText>
        <OreStack placeContent="start">
          <OreButton
            size="large"
            category="highlight"
            icon={<SvgExternalLink />}
            onClick={() =>
              window.open(
                'https://www.edenred.es/ticket-guarderia/recomendar-centro/',
                '_blank'
              )
            }>
            {t(TGDOrderTranslation.kindergartenAssignment.modalForm.requestKindergarten)}
          </OreButton>
        </OreStack>
      </OreStack>
    </>
  )
}
