import { useTranslation } from 'react-i18next'
import {
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreSpinner,
  OreText,
} from '@edenredespana/oreneta'
import { dropZoneTranslations } from 'src/Flex/Shared/ui/DropZone'

type UploadingModalProps = {
  uploading: boolean
}

export const UploadingModal = ({ uploading }: UploadingModalProps): JSX.Element => {
  const { t } = useTranslation()

  if (!uploading) {
    return <></>
  }

  return (
    <OreModal open>
      <OreModalBox size="small">
        <OreModalBoxBody>
          <OreSpinner />
          <OreHeading align="center" size="headline-md">
            {t(dropZoneTranslations.modal.title)}
          </OreHeading>
          <OreText align="center">{t(dropZoneTranslations.modal.subtitle)}</OreText>
        </OreModalBoxBody>
      </OreModalBox>
    </OreModal>
  )
}
