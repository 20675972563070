import { useTranslation } from 'react-i18next'
import { reportingService } from 'src/Flex/Reporting/application'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { GetMonthYear, downloadExcel } from 'src/core/helpers'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { flexReportingTranslations } from '../../translations'

interface IUseKPIsController {
  downloadReport: () => Promise<void>
}

export const useKPIsController = (): IUseKPIsController => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()

  const downloadReport = async () => {
    startLoading()
    const date = new Date()
    const monthYear = GetMonthYear(
      date.getFullYear().toString() + '-' + (date.getMonth() + 1).toString()
    )
    const response = await reportingService().downloadReport()

    stopLoading()

    if (
      handleMetaResponse(response?.meta, undefined, {
        notifySuccess: false,
      }) &&
      response.data.file
    ) {
      downloadExcel(
        response.data.file,
        t(flexReportingTranslations.downloadReport.fileName) +
          user?.code +
          '_' +
          monthYear.stringMonth +
          '_' +
          monthYear.year
      )
    }
  }

  return { downloadReport }
}
