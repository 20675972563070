import React from 'react'
import { TGDOrderExcelState } from '../hooks/useTGDOrderImportExcel'
import {
  OreButton,
  OreDivider,
  OreDropZone,
  OreHeading,
  OreSpinner,
  OreStack,
  OreText,
  OreWysiwyg,
  SvgConsult,
  SvgDownload,
  SvgExcel,
} from '@edenredespana/oreneta'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useTranslation } from 'react-i18next'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'
import { useTGDOrderFormKindergartenAssignment } from '../../../TGDOrderForm/hooks'
import { cardOrderTranslation, forms } from 'src/domain/translations'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { Modal } from 'src/presentation/components/Edenred'
import { TGDOrderSearchKindergarten } from '../../../TGDOrderForm/molecules/TGDOrderSearchKindergarten'
import {
  ButtonDropdown,
  ButtonDropdownItem,
} from 'src/presentation/components/Edenred/ButtonDropdown'
import { Download } from '@mui/icons-material'

interface Props {
  props: TGDOrderExcelState
}

export const TGDOrderImportForm = ({ props }: Props) => {
  const { downloadTemplate, form, checkExcelData, onClickCancel, fileIsLoaded } = props
  const { t } = useTranslation()
  const navigate = useNavigate()
  const searchKindergartenProps = useTGDOrderFormKindergartenAssignment(true, 1)

  return (
    <>
      <OreStack>
        <div className="mb-1">
          <OreWysiwyg>
            <OreText
              dangerouslySetInnerHTML={{
                __html: t(TGDOrderTranslation.excel.download.textHtml1),
              }}
            />
          </OreWysiwyg>
          <br />
          <div className="list-items-space-narrow">
            <OreWysiwyg>
              <OreText
                dangerouslySetInnerHTML={{
                  __html: t(TGDOrderTranslation.excel.download.textHtml2),
                }}
              />
            </OreWysiwyg>
          </div>
        </div>

        <OreStack>
          <ButtonDropdown
            buttonLabel={t(forms.buttons.downloadTemplate)}
            buttonIcon={<Download />}>
            <ButtonDropdownItem
              title={t(forms.buttons.downloadEmptyTemplate)}
              icon={<SvgExcel />}
              handleClick={() => downloadTemplate(true)}
            />
            <ButtonDropdownItem
              title={t(forms.buttons.downloadPreLoadedTemplate)}
              icon={<SvgExcel />}
              handleClick={() => downloadTemplate(false)}
            />
          </ButtonDropdown>
        </OreStack>
        <OreText>
          {t(TGDOrderTranslation.excel.download.repeat1)}{' '}
          <span
            className="underline pointer"
            onClick={() => navigate(navigationRoutes.myOrdersHistory)}>
            {t(TGDOrderTranslation.excel.download.repeat2)}
          </span>
        </OreText>
        <div className="pt-05">
          <OreStack space="xsmall">
            <OreHeading size="headline-sm">
              {t(TGDOrderTranslation.excel.kindergartenSearch.title)}
            </OreHeading>
            <OreText>
              {t(TGDOrderTranslation.excel.kindergartenSearch.description)}
            </OreText>
            <OreStack>
              <OreButton
                as="button"
                category="tertiary"
                icon={<SvgConsult />}
                iconAlign="left"
                onClick={() => searchKindergartenProps.setShowPopup(true)}
                size="small">
                {t(TGDOrderTranslation.excel.kindergartenSearch.searchButton)}
              </OreButton>
            </OreStack>
          </OreStack>
        </div>
      </OreStack>
      <div>
        <OreDropZone
          {...form.register('file')}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          dropText={
            <>
              <OreText as="span" tone="cobalt">
                {!fileIsLoaded
                  ? t(TGDOrderTranslation.excel.dropZone.selectFile)
                  : t(TGDOrderTranslation.excel.dropZone.changeFile)}
              </OreText>
              {!fileIsLoaded
                ? t(TGDOrderTranslation.excel.dropZone.dropFileOr)
                : t(TGDOrderTranslation.excel.dropZone.dropNewFileOr)}
            </>
          }
          hasError={form.formState.errors.file?.message !== undefined}
          errorMessage={form.formState.errors.file?.message}
          deleteFileText={t(cardOrderTranslation.excel.deleteFileText)}
          errorUploadMessage={t(TGDOrderTranslation.excel.dropZone.uploadErrorTitle)}
          supportsText={t(cardOrderTranslation.excel.supportsText)}
          validMessage={t(cardOrderTranslation.excel.validMessage)}
          onRemove={() => {
            form.trigger('file')
          }}
        />
      </div>
      <div className="py-2">
        <OreDivider />
        <div className="py-1">
          <Grid justify="flex-end" gap="1.5rem">
            <OreButton
              as="button"
              category="secondary"
              size="large"
              onClick={onClickCancel}>
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              as="button"
              category="primary"
              size="large"
              disabled={!form.formState.isValid}
              onClick={checkExcelData}>
              {t(TGDOrderTranslation.excel.checkExcelData)}
            </OreButton>
          </Grid>
        </div>
      </div>
      <Modal
        handleOnClose={searchKindergartenProps.setShowPopup}
        open={searchKindergartenProps.popupShow}
        buttons={searchKindergartenProps.popupButtons}
        size="extralarge"
        children={
          <TGDOrderSearchKindergarten searchKindergartenProps={searchKindergartenProps} />
        }
      />
    </>
  )
}
