import type { TFunction } from 'react-i18next'
import { object } from 'yup'
import { edenredProducts } from 'src/domain/enum'
import type { FormationConfigurationModel } from 'src/Flex/Products/domain'
import { useConfigureProduct } from 'src/Flex/Products/ui/product-configuration'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import {
  checkboxSchema,
  nonEmptyChoiceSchema,
  nonEmptyPositiveNumberSchema,
  positiveNumberSchema,
} from 'src/Flex/Shared/ui/Form'

export interface FormationTypes {
  trainingTypeId: number
  trainingTypeName: string
}

export interface FormationCourse {
  trainingId: string
  trainingType: number
  trainingTypeName: string
  name: string
  description: string
  price: number
  discountPercentage: number
  finalPrice: number
  pdfLink: string
}
export interface FormationSchool {
  schoolId: string
  schoolLogo: string
  active: boolean
  courses: FormationCourse[]
}

export interface FormationForm {
  globalMonthlyLimit?: number
  globalNumberOfMonths?: number
  numberOfEmployeesContract?: number
  monthlyLimit: number | undefined
  numberOfMonths: number | undefined
  isEnteredManually: boolean
  canUserEnterSmallerValue: 'true' | 'false'
  active: boolean
  trainingTypes: FormationTypes[]
  trainingSchools: FormationSchool[]
}

export const useConfigureFormationController = () =>
  useConfigureProduct<FormationForm, edenredProducts.formacion>({
    productType: edenredProducts.formacion,
    formProps: {
      defaultValues: {
        monthlyLimit: undefined,
        numberOfMonths: undefined,
        isEnteredManually: false,
        canUserEnterSmallerValue: 'false',
        active: true,
        trainingTypes: [],
        trainingSchools: [],
      },
    },
    formValidationScheme: (t: TFunction<'translation', undefined>) =>
      object({
        monthlyLimit: nonEmptyPositiveNumberSchema(t).when(
          'globalMonthlyLimit',
          (globalMonthlyLimit: number | undefined, schema) =>
            globalMonthlyLimit
              ? schema.max(
                  globalMonthlyLimit,
                  t(flexCollectivesTranslations.configure.edit.products.error.exceded, {
                    globalMonthlyLimit,
                  })
                )
              : schema
        ),
        numberOfMonths: nonEmptyPositiveNumberSchema(t, 48, true)
          .when('isEnteredManually', (isEnteredManually: boolean, schema) =>
            isEnteredManually ? positiveNumberSchema(t, undefined, true) : schema
          )
          .when(
            'globalNumberOfMonths',
            (globalNumberOfMonths: number | undefined, schema) =>
              globalNumberOfMonths
                ? schema.max(
                    globalNumberOfMonths,
                    t(flexCollectivesTranslations.configure.edit.products.error.months, {
                      globalNumberOfMonths,
                    })
                  )
                : schema
          ),
        isEnteredManually: checkboxSchema(t),
        canUserEnterSmallerValue: nonEmptyChoiceSchema(t, ['true', 'false']),
      }),
    convertFromAPIToForm: (data: FormationConfigurationModel): FormationForm => {
      const schools: FormationSchool[] = []
      const courses: FormationCourse[] = []
      data.trainingSchools.forEach(school => {
        school.courses.forEach(course => {
          courses.push({
            trainingId: course.trainingId,
            trainingType: course.trainingType,
            trainingTypeName: course.trainingTypeName,
            name: course.name,
            description: course.description,
            price: course.price,
            discountPercentage: course.discountPercentage,
            finalPrice: course.finalPrice,
            pdfLink: course.pdfLink,
          })
        })
        schools.push({
          schoolId: school.schoolId,
          schoolLogo: school.schoolLogo,
          active: school.active,
          courses: courses,
        })
      })
      const trainingTypes: FormationTypes[] = []
      data.trainingTypes.forEach(type => {
        trainingTypes.push({
          trainingTypeId: type.trainingTypeId,
          trainingTypeName: type.trainingTypeName,
        })
      })
      return {
        globalMonthlyLimit: data.clientMonthlyLimit,
        monthlyLimit:
          data.monthlyLimit === 0 ? undefined : Math.round(data.monthlyLimit * 100) / 100,
        globalNumberOfMonths: data.clientNumberOfMonths,
        numberOfMonths: data.numberOfMonths ?? undefined,
        isEnteredManually: data.isEnteredManually ?? false,
        canUserEnterSmallerValue: data.canUserEnterSmallerValue ? 'true' : 'false',
        active: data.active,
        trainingTypes: trainingTypes,
        trainingSchools: schools,
      }
    },
    convertFromFormToAPI: (
      data: FormationForm,
      isGlobal: boolean
    ): FormationConfigurationModel => {
      return {
        monthlyLimit: data.monthlyLimit ?? 0,
        numberOfMonths: !data.isEnteredManually ? data.numberOfMonths : undefined,
        isEnteredManually: isGlobal ? data.isEnteredManually : undefined,
        canUserEnterSmallerValue:
          isGlobal && !data.isEnteredManually
            ? data.canUserEnterSmallerValue === 'true'
            : undefined,
        active: data.active,
        trainingTypes: data.trainingTypes,
        trainingSchools: data.trainingSchools.map(({ active, ...rest }) => ({
          ...rest,
          active: active,
        })),
        disabledVASTrainingSchools: data.trainingSchools
          .filter(school => !school.active)
          .map(school => school.schoolId),
      }
    },
  })
