import React from 'react'
import { Modal, Table } from '../../Edenred'
import { useSpendingRulesController } from '../hooks/useSpendingRulesController'
import { OreText } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { spendingRuleTranslation } from 'src/domain/translations'
import { Grid, GridItem } from '../../Edenred/layout'
import { ReplaceSpendingRuleSelect } from '../atoms'

export const SpendingRulesContainer = (): JSX.Element => {
  const {
    header,
    caption,
    rows,
    setRows,
    footer,
    popupFavShow,
    popupFavTitle,
    popupFavDescription,
    popupFavButtons,
    setShowFavPopup,
    popupDelShow,
    popupDelTitle,
    popupDelDescription,
    popupDelButtons,
    setShowDelPopup,
    deleteAndReplace,
    handleSelectChange,
    selectError,
    currentActionId,
  } = useSpendingRulesController()
  const { t } = useTranslation()
  return (
    <>
      <GridItem width={65}>
        <Grid direction="column" gap={'1rem'}>
          <div>
            <OreText>{t(spendingRuleTranslation.description1)}</OreText>
          </div>
          <div className="pb-105">
            <OreText>{t(spendingRuleTranslation.description2)}</OreText>
          </div>
        </Grid>
      </GridItem>

      <Table
        header={header}
        caption={caption}
        rows={rows}
        setRows={setRows}
        tableBodyValign="middle"
        footer={footer}
      />

      <Modal
        handleOnClose={setShowFavPopup}
        open={popupFavShow}
        title={popupFavTitle}
        description={popupFavDescription}
        buttons={popupFavButtons}
        size="small"
      />
      <Modal
        handleOnClose={setShowDelPopup}
        open={popupDelShow}
        title={popupDelTitle}
        description={popupDelDescription}
        buttons={popupDelButtons}
        size={deleteAndReplace ? 'medium' : 'small'}>
        {deleteAndReplace ? (
          <ReplaceSpendingRuleSelect
            handleSelectChange={handleSelectChange}
            selectError={selectError}
            currentSRToDeleteId={currentActionId}
          />
        ) : (
          <></>
        )}
      </Modal>
    </>
  )
}
