import {
  OreButton,
  OreDivider,
  OreList,
  OreListItem,
  OreMessageBar,
  OreStack,
} from '@edenredespana/oreneta'
import {
  ExternalHealthForm,
  PolicyEmployeesAffected,
  useEmployeContractAlert,
} from '../hooks'
import { flexProductsTranslations } from '../../translations'
import { useTranslation } from 'react-i18next'
import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from 'react'
import { FieldArrayWithId } from 'react-hook-form'
import { EmployeeContractAlert } from '../atoms'
import { edenredProducts } from 'src/domain/enum'
import { use } from 'i18next'
import { PolicyModel } from 'src/Flex/Products/domain'

export const ExternalHealthUserAlert = (props: {
  formValues: ExternalHealthForm
  index: number
  field: FieldArrayWithId<ExternalHealthForm, 'policies', 'id'>
  policyEmployeesAffected: PolicyEmployeesAffected[]
  savePolicyChanges(event: MouseEvent, index: number): void
  validatePolicyChanges: (event: MouseEvent, index: number) => void
}): JSX.Element => {
  const { t } = useTranslation()
  const [isDirty, setIsDirty] = useState<boolean | undefined>()
  const [policyStateDanger, setPolicyStateDanger] = useState<PolicyEmployeesAffected[]>(
    []
  )
  const [policyStateWarning, setPolicyStateWarning] = useState<PolicyEmployeesAffected[]>(
    []
  )
  const [policyStateValidate, setPolicyStateValidate] = useState<
    PolicyEmployeesAffected[]
  >([])
  const [policyStateConfirm, setPolicyStateConfirm] = useState<PolicyEmployeesAffected[]>(
    []
  )

  const getPolicy = (): PolicyModel => {
    return {
      company: props.formValues.policies[props.index].company,
      id: props.formValues.policies[props.index].id,
      monthlyLimit: props.formValues.policies[props.index].monthlyPrice,
      policyName: props.formValues.policies[props.index].policyName,
      arePhoneRequired: props.formValues.policies[props.index].arePhoneRequired === 'yes',
      areAddressRequired:
        props.formValues.policies[props.index].areAddressRequired === 'yes',
    }
  }

  useEffect(() => {
    setPolicyStateDanger(
      props.policyEmployeesAffected.filter(
        policy =>
          policy.policyId === props.formValues.policies[props.index].id &&
          policy.employeesAffected &&
          policy.employeesAffected > 0
      )
    )
    setPolicyStateWarning(
      props.policyEmployeesAffected.filter(
        policy =>
          policy.policyId === props.formValues.policies[props.index].id &&
          policy.employeesAffected === 0
      )
    )
    setPolicyStateValidate(
      props.policyEmployeesAffected.filter(
        policy =>
          policy.policyId === props.formValues.policies[props.index].id &&
          policy.employeesAffected === undefined
      )
    )
    setPolicyStateConfirm(
      props.policyEmployeesAffected.filter(
        policy =>
          policy.policyId === props.formValues.policies[props.index].id &&
          policy.employeesAffected !== undefined
      )
    )
  }, [props.policyEmployeesAffected])

  useEffect(() => {
    setIsDirty(
      props.formValues.policies[props.index].isWarningAreAddressRequired ||
        props.formValues.policies[props.index].isWarningArePhoneRequired ||
        props.formValues.policies[props.index].isWarningCompany ||
        props.formValues.policies[props.index].isWarningPolicyName ||
        props.formValues.policies[props.index].isWarningMonthlyPrice
    )
  }, [props.formValues])

  return (
    <>
      {policyStateDanger.length > 0 && policyStateDanger[0].employeesAffected ? (
        <EmployeeContractAlert
          activeChange={false}
          deleteChange={false}
          numberOfContracts={policyStateDanger[0].employeesAffected}
          productType={edenredProducts.saludExterno}
          policy={getPolicy()}
        />
      ) : null}

      {policyStateWarning.length > 0 ? (
        <EmployeeContractAlert
          activeChange={false}
          deleteChange={false}
          numberOfContracts={0}
          productType={edenredProducts.saludExterno}
          policy={getPolicy()}
        />
      ) : null}

      {isDirty && !props.field.isNew && policyStateValidate.length > 0 ? (
        <>
          <OreDivider />
          <OreStack placeContent="end">
            <OreButton
              type="submit"
              onClick={event => props.validatePolicyChanges(event, props.index)}
              size="small"
              category="secondary">
              {t(flexProductsTranslations.configure.healthExternal.save_changes)}
            </OreButton>
          </OreStack>
        </>
      ) : (
        <></>
      )}
      {policyStateConfirm.length > 0 ? (
        <>
          <OreDivider />
          <OreStack placeContent="end">
            <OreButton
              type="submit"
              onClick={event => props.savePolicyChanges(event, props.index)}
              size="small"
              category="danger">
              {t(flexProductsTranslations.configure.healthExternal.save_changes)}
            </OreButton>
          </OreStack>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
