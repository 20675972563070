import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreText,
} from '@edenredespana/oreneta'

interface Props {
  isOpen: boolean
  title: string
  body: string
  yesText: string
  noText: string
  confirm: () => void
  cancel: () => void
}

// This component is a confirmation modal.
// Shows 'yes'(confirm) and 'no'(cancel) buttons.
const ConfirmationModal: React.FC<Props> = ({
  isOpen,
  title,
  body,
  yesText,
  noText,
  confirm,
  cancel,
}) => (
  <OreModal open={isOpen}>
    <OreModalBox size="small">
      <OreModalBoxBody>
        <OreHeading size="headline-md">{title}</OreHeading>
        <OreText>{body}</OreText>
      </OreModalBoxBody>
      <OreModalBoxFooter>
        <OreButton onClick={cancel} size="large" category="tertiary">
          {noText}
        </OreButton>
        <OreButton onClick={confirm} size="large" category="primary">
          {yesText}
        </OreButton>
      </OreModalBoxFooter>
    </OreModalBox>
  </OreModal>
)

export default ConfirmationModal
