import { OreSelect, OreSelectPlaceholder } from '@edenredespana/oreneta'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { SpendingRuleModel } from 'src/domain/models'
import { forms, spendingRuleTranslation } from 'src/domain/translations'
import { useReplaceSpendingRuleSelect } from '../hooks'

interface Props {
  handleSelectChange: (e: ChangeEvent<HTMLSelectElement>) => void
  selectError: boolean
  currentSRToDeleteId: number
}

export const ReplaceSpendingRuleSelect = ({
  handleSelectChange,
  selectError,
  currentSRToDeleteId,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { spendingRules } = useReplaceSpendingRuleSelect()

  return (
    <OreSelect
      id="replaceSpendingRule"
      label={t(spendingRuleTranslation.actions.deleteAndReplace.modal.selectLabel)}
      name="replaceSpendingRule"
      onChange={handleSelectChange}
      disabled={spendingRules.length === 0}
      errorMessage={t(forms.errors.fieldRequired)}
      hasError={selectError}
      style={spendingRules.length === 0 ? { cursor: 'wait' } : {}}
      required>
      <OreSelectPlaceholder
        label={t(
          spendingRuleTranslation.actions.deleteAndReplace.modal.selectPlaceholder
        )}
      />
      {spendingRules.map((sp: SpendingRuleModel) => {
        if (sp.profileId !== currentSRToDeleteId) {
          return <option label={sp.profileName} value={sp.profileId} key={sp.profileId} />
        }
      })}
    </OreSelect>
  )
}
