import { OreCheckbox, OreHeading } from '@edenredespana/oreneta'
import type { ChangeEvent } from 'react'
import type { DeliverySiteModel } from '../../../../../domain/models'
import { deliverySiteFormTranslation } from '../../../../../domain/translations/myProducts/deliverySite'
import { Textbox } from '../../../Edenred'
import { useShipmentContactController } from '../hooks'
import type { FormProps } from '../../../../../domain/forms/FormProps'

interface ShipmentContactProps extends FormProps<DeliverySiteModel> {
  enabledFavoriteCheckBox: boolean
  isCheckedFavoriteCheckBox: boolean
  onValueChange?: (event: ChangeEvent<HTMLInputElement>) => void
  requiredPhone: boolean
}

const ShipmentContact = (props: ShipmentContactProps): JSX.Element => {
  const { t, register, errors, shipmentContactTextBoxes } = useShipmentContactController(
    props.formParameters,
    props.requiredPhone
  )

  return (
    <section className="form-molecule mt-1">
      <div className="pl-05">
        <OreHeading as="h2" size="headline-md">
          {props.title}
        </OreHeading>
      </div>
      <Textbox
        textBoxList={shipmentContactTextBoxes}
        errors={errors}
        register={register}
      />
      <div className="form-atom">
        <OreCheckbox
          {...register('isFavorite')}
          name="isFavorite"
          id="isFavorite"
          errorMessage="This field is required"
          label={t(deliverySiteFormTranslation.shipmentContact.favorite)}
          labelCheckIcon="Check Icon"
          disabled={!props.enabledFavoriteCheckBox}
          onChange={props.onValueChange}
          checked={props.isCheckedFavoriteCheckBox}
        />
      </div>
    </section>
  )
}
export default ShipmentContact
