import { OreNavBarUserListItem } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface NavbarUserListItemProps {
  title: string
  icon?: JSX.Element
  link?: string
  size?: 'text-sm' | 'text-xs'
  divider?: boolean
  onClick?: () => void
}

const NavbarUserListItem = (props: NavbarUserListItemProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleClick = (): void => {
    if (props.link) {
      navigate(props.link)
    }
  }

  return (
    <OreNavBarUserListItem
      title={t(props.title)}
      icon={props.icon}
      size={props.size}
      divider={props.divider}
      handleClick={props.onClick ? props.onClick : handleClick}
    />
  )
}

export default NavbarUserListItem
