import { Trans, useTranslation } from 'react-i18next'
import {
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
} from '@edenredespana/oreneta'
import { orderValidationsTranslations } from 'src/Flex/Orders/ui/order-validation/orderValidationsTranslations'
import { CardOrderDetailModel } from 'src/domain/models'

type OrderValidationSummaryDetailProps = {
  card: CardOrderDetailModel
  charge: CardOrderDetailModel
}

export const OrderValidationSummaryDelivery = ({
  card,
  charge,
}: OrderValidationSummaryDetailProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <OreTableContainer>
      <OreTable>
        <OreTableCaption title={t(orderValidationsTranslations.step2.delivery.title)} />
        <OreTableBody>
          {card.services.length > 0 && (
            <>
              <OreTableRow>
                <OreTableCell>
                  {t(orderValidationsTranslations.step2.delivery.fisicalCards.title)}
                </OreTableCell>
                <OreTableCell align="right">
                  <Trans t={t}>
                    {orderValidationsTranslations.step2.delivery.fisicalCards.description}
                  </Trans>
                </OreTableCell>
              </OreTableRow>

              <OreTableRow>
                <OreTableCell>
                  {t(orderValidationsTranslations.step2.delivery.virtualCards.title)}
                </OreTableCell>
                <OreTableCell align="right">
                  <Trans t={t}>
                    {orderValidationsTranslations.step2.delivery.virtualCards.description}
                  </Trans>
                </OreTableCell>
              </OreTableRow>
            </>
          )}
          {charge.services.length > 0 && (
            <OreTableRow>
              <OreTableCell>
                {t(orderValidationsTranslations.step2.delivery.recharges.title)}
              </OreTableCell>
              <OreTableCell align="right">
                <Trans t={t}>
                  {orderValidationsTranslations.step2.delivery.recharges.description}
                </Trans>
              </OreTableCell>
            </OreTableRow>
          )}
        </OreTableBody>
      </OreTable>
    </OreTableContainer>
  )
}
