import {
  OreIcon,
  OreStack,
  OreText,
  SvgMessengerService,
  SvgPostalMail,
} from '@edenredespana/oreneta'
import React from 'react'
import { deliveryType } from 'src/domain/enum'
import { DeliverySiteModel } from 'src/domain/models'

interface Props {
  ds: DeliverySiteModel
}

export const TTOrderSummaryTableDeliverySiteCell = ({ ds }: Props): JSX.Element => {
  return (
    <OreStack space="small" direction="row" placeItems="center">
      <div>
        {ds.deliveryType === deliveryType.messenger && (
          <OreIcon
            size="extra-small"
            icon={<SvgMessengerService />}
            tone="generic-dark"
          />
        )}
        {ds.deliveryType === deliveryType.mail && (
          <OreIcon size="extra-small" icon={<SvgPostalMail />} tone="postal-service" />
        )}
      </div>
      <div>
        <OreText size="text-sm" tone="neutral-600">
          {`${ds.alias} - ${ds.deliveryPointName}`}
        </OreText>
        <OreText size="text-sm" tone="neutral-600">
          {ds.contactName}
        </OreText>
      </div>
    </OreStack>
  )
}
