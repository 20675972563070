import { OreStack, OreWysiwyg } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'

export const TTOrderSummaryNoOrderDescription = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div style={{ maxWidth: '686px' }}>
      <OreStack space="large">
        <OreWysiwyg>
          <h3>{t(TTOrderTranslation.summary.information.newCard.title)}</h3>
          <p>{t(TTOrderTranslation.summary.information.newCard.description)}</p>
        </OreWysiwyg>
        <OreWysiwyg>
          <h3>{t(TTOrderTranslation.summary.information.duplicated.title)}</h3>
          <p>{t(TTOrderTranslation.summary.information.duplicated.title)}</p>
          <ul>
            <li>
              <b>
                {t(
                  TTOrderTranslation.summary.information.duplicated.bulletList.item1.title
                )}
              </b>{' '}
              {t(
                TTOrderTranslation.summary.information.duplicated.bulletList.item1
                  .description
              )}
            </li>
            <li>
              <b>
                {t(
                  TTOrderTranslation.summary.information.duplicated.bulletList.item2.title
                )}
              </b>{' '}
              {t(
                TTOrderTranslation.summary.information.duplicated.bulletList.item2
                  .description
              )}
            </li>
          </ul>
        </OreWysiwyg>
      </OreStack>
    </div>
  )
}
