import {
  OreButton,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTooltip,
  SvgPlus,
} from '@edenredespana/oreneta'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { useTranslation } from 'react-i18next'
import { TGDOrderSummaryState } from '../hooks/useTGDOrderSummaryContainer'
import { useTGDOrder } from '../../context/TGDOrderProvider'
import { TGDEmployeeAndChildsModel } from 'src/domain/models/TGDOrder/TGDOrderModel'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { TGDOrderSummaryTableRow } from '../atoms'

interface Props {
  tgdProps: TGDOrderSummaryState
}
export const TGDOrderSummaryTable = ({ tgdProps }: Props) => {
  const { t } = useTranslation()
  const { header, onClickDelete, onClickEdit } = tgdProps
  const { tgdOrders } = useTGDOrder()
  const navigate = useNavigate()

  return (
    <OreTableContainer>
      <OreTable>
        <OreTableCaption
          counter={
            tgdOrders.length + t(TGDOrderTranslation.summaryAssignment.table.subtitle)
          }
          title={t(TGDOrderTranslation.summaryAssignment.table.title)}
        />
        <OreTableHead>
          <OreTableRow>
            {header.headerElement && (
              <OreTableCell as="th">{header.headerElement}</OreTableCell>
            )}
            {header.headerLabel.map((header, index) =>
              !header.tooltip ? (
                <OreTableCell as="th" key={'lbl' + index}>
                  {header.label}
                </OreTableCell>
              ) : (
                <OreTableCell as="th" key={'lbl' + index}>
                  {header.label}&nbsp;
                  <OreTooltip placement="top" separation="small" text={header.tooltip}>
                    <span>{header.tooltipIcon}</span>
                  </OreTooltip>
                </OreTableCell>
              )
            )}
          </OreTableRow>
        </OreTableHead>
        <OreTableBody as="tbody" valign="top">
          {tgdOrders && tgdOrders.length <= 0 ? (
            <TableEmpty
              colSpan={5}
              firstText={t(
                TGDOrderTranslation.summaryAssignment.table.emptyTable.emptyText
              )}
            />
          ) : (
            tgdOrders.map((tgdOrder: TGDEmployeeAndChildsModel, index: number) => {
              return (
                <TGDOrderSummaryTableRow
                  key={'rows' + index}
                  tgdOrder={tgdOrder}
                  onClickDelete={() => onClickDelete(tgdOrder.id)}
                  onClickEdit={() => onClickEdit(tgdOrder)}
                />
              )
            })
          )}
        </OreTableBody>
      </OreTable>
      <OreTableActions>
        <OreStack placeItems="start" direction="row">
          <OreButton
            category="highlight"
            icon={<SvgPlus />}
            size="large"
            onClick={() => navigate(navigationRoutes.tgdOrderForm)}>
            {t(TGDOrderTranslation.summaryAssignment.table.footer.addEmployeeChild)}
          </OreButton>
          <OreButton
            category="highlight"
            icon={<SvgPlus />}
            size="large"
            onClick={() => navigate(navigationRoutes.tgdOrderSelectSingleEmployee)}>
            {t(TGDOrderTranslation.summaryAssignment.table.footer.addChild)}
          </OreButton>
          <OreButton
            category="highlight"
            icon={<SvgPlus />}
            size="large"
            onClick={() => navigate(navigationRoutes.tgdOrderSelectMultipleEmployee)}>
            {t(TGDOrderTranslation.summaryAssignment.table.footer.addAssignment)}
          </OreButton>
        </OreStack>
      </OreTableActions>
    </OreTableContainer>
  )
}
