import React from 'react'
import { TTOrderExcelState } from '../hooks/useTTOrderImportExcel'
import {
  OreButton,
  OreDivider,
  OreDropZone,
  OreHeading,
  OreSpinner,
  OreStack,
  OreText,
  OreWysiwyg,
  SvgDownload,
  SvgExcel,
} from '@edenredespana/oreneta'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useTranslation } from 'react-i18next'
import { cardOrderTranslation, forms } from 'src/domain/translations'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { Modal } from 'src/presentation/components/Edenred'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { useTTOrder } from '../../../context/TTOrderProvider'
import { orderType } from 'src/domain/enum'
import { NavLink } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import {
  ButtonDropdown,
  ButtonDropdownItem,
} from 'src/presentation/components/Edenred/ButtonDropdown'
import { Download } from '@mui/icons-material'

interface Props {
  props: TTOrderExcelState
}

export const TTOrderImportForm = ({ props }: Props) => {
  const { t } = useTranslation()
  const { orderContext } = useTTOrder()
  const {
    downloadTemplate,
    form,
    checkExcelData,
    onClickCancel,
    fileIsLoaded,
    getDescriptionHtmlText,
  } = props

  return (
    <>
      <OreStack>
        {orderContext === orderType.Card ? (
          <>
            <OreText>{t(TTOrderTranslation.excel.import.download.description)}</OreText>
            <OreStack space="xsmall">
              <OreText>{t(TTOrderTranslation.excel.import.download.step1)}</OreText>
              <OreText>{t(TTOrderTranslation.excel.import.download.step2)}</OreText>
              <OreText>{t(TTOrderTranslation.excel.import.download.step3)}</OreText>
            </OreStack>
          </>
        ) : (
          <div className="mb-1">
            <OreWysiwyg>
              <OreText
                dangerouslySetInnerHTML={{
                  __html: getDescriptionHtmlText('text1'),
                }}
              />
            </OreWysiwyg>
            <br />
            <div className="list-items-space-narrow">
              <OreWysiwyg>
                <OreText
                  dangerouslySetInnerHTML={{
                    __html: getDescriptionHtmlText('text2'),
                  }}
                />
              </OreWysiwyg>
            </div>
          </div>
        )}
        <OreStack>
          {orderContext === orderType.Card ? (
            <OreButton
              as="button"
              category="secondary"
              icon={<SvgDownload />}
              iconAlign="left"
              onClick={() => downloadTemplate(true)}>
              {t(forms.buttons.downloadTemplate)}
            </OreButton>
          ) : (
            <ButtonDropdown
              buttonLabel={t(forms.buttons.downloadTemplate)}
              buttonIcon={<Download />}>
              <ButtonDropdownItem
                title={t(forms.buttons.downloadEmptyTemplate)}
                icon={<SvgExcel />}
                handleClick={() => downloadTemplate(true)}
              />
              <ButtonDropdownItem
                title={t(forms.buttons.downloadPreLoadedTemplate)}
                icon={<SvgExcel />}
                handleClick={() => downloadTemplate(false)}
              />
            </ButtonDropdown>
          )}
        </OreStack>
        {orderContext === orderType.Recharge && (
          <>
            <OreText>
              {t(TTOrderTranslation.excel.import.download.recharge.repeat1)}{' '}
              <NavLink to={navigationRoutes.myOrdersHistory}>
                {t(TTOrderTranslation.excel.import.download.recharge.repeat2)}
              </NavLink>
            </OreText>
          </>
        )}
      </OreStack>
      <div>
        <OreDropZone
          {...form.register('file')}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          dropText={
            <>
              <OreText as="span" tone="cobalt">
                {!fileIsLoaded
                  ? t(TGDOrderTranslation.excel.dropZone.selectFile)
                  : t(TGDOrderTranslation.excel.dropZone.changeFile)}
              </OreText>
              {!fileIsLoaded
                ? t(TGDOrderTranslation.excel.dropZone.dropFileOr)
                : t(TGDOrderTranslation.excel.dropZone.dropNewFileOr)}
            </>
          }
          hasError={form.formState.errors.file?.message !== undefined}
          errorMessage={form.formState.errors.file?.message}
          deleteFileText={t(cardOrderTranslation.excel.deleteFileText)}
          errorUploadMessage={t(TGDOrderTranslation.excel.dropZone.uploadErrorTitle)}
          supportsText={t(cardOrderTranslation.excel.supportsText)}
          validMessage={t(cardOrderTranslation.excel.validMessage)}
          onRemove={() => {
            form.trigger('file')
          }}
        />
      </div>
      <div className="py-2">
        <OreDivider />
        <div className="py-1">
          <Grid justify="flex-end" gap="1.5rem">
            <OreButton
              as="button"
              category="secondary"
              size="large"
              onClick={onClickCancel}>
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              as="button"
              category="primary"
              size="large"
              disabled={!form.formState.isValid}
              onClick={checkExcelData}>
              {t(TGDOrderTranslation.excel.checkExcelData)}
            </OreButton>
          </Grid>
        </div>
      </div>
    </>
  )
}
