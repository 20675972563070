import { useTranslation } from 'react-i18next'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { useRoleStandarization } from 'src/presentation/context/roleStandarization/RoleStandarizationContext'
import { OreMessageBar } from '@edenredespana/oreneta'
import { roleStandarizationTranslation } from 'src/domain/translations/autoenrollment/autoEnrollmentTranslate'
import { useRoleStandarizationController } from '../hooks/useRoleStandarizationController'

export const RoleStandarizationMessageBar = (): JSX.Element | null => {
  const { t } = useTranslation()
  const { downloadPDFFile, initRoleStandarizationAcceptance } =
    useRoleStandarizationController()
  const { user } = useAuth()
  const { file } = useRoleStandarization()
  const acceptancePending = user?.dataControllerAcceptancePending

  if (!acceptancePending || !file) return null

  return (
    <div style={{ marginTop: '2rem' }}>
      <OreMessageBar color="warning">
        {t(roleStandarizationTranslation.messageBar.first)}{' '}
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            initRoleStandarizationAcceptance()
          }}>
          {t(roleStandarizationTranslation.messageBar.second)}
        </a>{' '}
        {t(roleStandarizationTranslation.messageBar.third)}{' '}
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            downloadPDFFile()
          }}>
          {t(roleStandarizationTranslation.messageBar.fourth)}
        </a>{' '}
        {t(roleStandarizationTranslation.messageBar.fifth)}
      </OreMessageBar>
    </div>
  )
}
