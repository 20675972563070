import { useEffect, useState } from 'react'
import {
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreDivider,
  OreHeading,
  OreSelect,
  OreStack,
  SvgEdit,
} from '@edenredespana/oreneta'

import { locale } from 'src/presentation/i18n/i18n'
import { forms, languages } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'

import { accountSettingsTranslation } from 'src/domain/translations/myAccount/accountSettings'
import { ShowMultiLanguageOnMyAccount } from 'src/core/services/ToggleFeatureService'

export const ChangeLanguage = () => {
  const { t } = useTranslation()

  const [isEditing, setIsEditing] = useState(false)
  const [selectedLanguage, setSelectedLanguage] = useState('es')

  const handleEditLanguage = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()

    localStorage.setItem('i18nextLng', selectedLanguage)
    setIsEditing(!isEditing)

    window.location.reload()
  }

  useEffect(() => {
    const currentLanguage = localStorage.getItem('i18nextLng')

    setSelectedLanguage(currentLanguage || 'es')
  }, [])

  return (
    <OreCardContainer>
      <OreCardWrapper space="large">
        <OreHeading as="h1" size="headline-sm">
          {t(accountSettingsTranslation.changeLanguage.title)}
        </OreHeading>

        <OreDivider space="medium" />

        <OreStack space="large" placeContent="stretch">
          <OreSelect
            label={t(accountSettingsTranslation.changeLanguage.select.label)}
            disabled={!isEditing}
            onChange={e => setSelectedLanguage(e.target.value)}
            value={selectedLanguage}>
            {locale.map(lang => (
              <option key={lang} value={lang}>
                {t(languages[lang as keyof typeof languages])}
              </option>
            ))}
          </OreSelect>
          {ShowMultiLanguageOnMyAccount() && (
            <div>
              {!isEditing ? (
                <OreButton
                  as="button"
                  category="secondary"
                  icon={<SvgEdit />}
                  onClick={() => setIsEditing(true)}
                  size="large">
                  {t(accountSettingsTranslation.changeLanguage.cta.label)}
                </OreButton>
              ) : (
                <OreButton
                  as="button"
                  category="primary"
                  icon={<SvgEdit />}
                  onClick={e => handleEditLanguage(e)}
                  size="large">
                  {t(forms.buttons.save)}
                </OreButton>
              )}
            </div>
          )}
        </OreStack>
      </OreCardWrapper>
    </OreCardContainer>
  )
}
