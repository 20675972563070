import { OreButton, OreStack, OreText, OreToolbar, SvgBin } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { EmployeeModel } from 'src/Flex/Employees/domain'
import { flexEmployeesTranslations } from '../../translations'
import { Dispatch, SetStateAction } from 'react'

interface ApplyButtonClick {
  selectedEmployees: EmployeeModel[]
  setShowDeleteMultipleModal: Dispatch<SetStateAction<boolean>>
}

export const FlexEmployeeDeleteToolbar: React.FC<ApplyButtonClick> = ({
  selectedEmployees,
  setShowDeleteMultipleModal,
}): JSX.Element => {
  const { t } = useTranslation()

  return (
    <>
      <OreToolbar
        first_section={
          <>
            <OreText
              bold
              size="text-md"
              style={{ marginBottom: '4px' }}
              tone="neutral-900">
              {t(flexEmployeesTranslations.deleteToolbar.title)}
            </OreText>
            <OreText size="text-xs">
              {selectedEmployees.length}{' '}
              {t(flexEmployeesTranslations.deleteToolbar.subtitle)}
            </OreText>
          </>
        }
        second_section={
          <OreStack direction="row" space="xsmall">
            <OreStack placeContent="end">
              <OreButton
                as="button"
                onClick={() => setShowDeleteMultipleModal(true)}
                icon={<SvgBin />}
                category="danger">
                {t(flexEmployeesTranslations.deleteToolbar.deleteEmployees)}
              </OreButton>
            </OreStack>
          </OreStack>
        }
        sticky
      />
    </>
  )
}
