import {
  OreHeading,
  OreIcon,
  OrePagination,
  OreStack,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
  OreTooltip,
  SvgWarning,
} from '@edenredespana/oreneta'
import { EmployeesTTState } from '../hooks/useEmployeesListTableTTController'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { forms } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { EmployeeListTableTTCaption } from './EmployeeListTableTTCaption'
import { EmployeesWithLastCard } from 'src/domain/models'
import { EmployeeListTableTTRow } from './EmployeeListTableTTRow'
import { Modal } from 'src/presentation/components/Edenred'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'

interface Props {
  employeesTTProps: EmployeesTTState
}

export const EmployeeListTableTT = ({ employeesTTProps }: Props): JSX.Element => {
  const { t } = useTranslation()
  const {
    totalRows,
    header,
    employeesTT,
    onPageChange,
    page,
    pageSize,
    onClickDelete,
    onClickEdit,
    showDeleteModal,
    setShowDeleteModal,
    deleteModalTitle,
    deleteModalDescription,
    deletePopupButtons,
  } = employeesTTProps

  return (
    <>
      <OreTableContainer>
        <div className="bg-white">
          <EmployeeListTableTTCaption employeesTTProps={employeesTTProps} />
        </div>
        <OreTable>
          <OreTableHead>
            <OreTableRow>
              {header.headerElement && (
                <OreTableCell as="th">{header.headerElement}</OreTableCell>
              )}
              {header.headerLabel.map((header, index) =>
                !header.tooltip ? (
                  <OreTableCell as="th" key={'lbl' + index}>
                    {header.label}
                  </OreTableCell>
                ) : (
                  <OreTableCell as="th" key={'lbl' + index}>
                    {header.label}&nbsp;
                    <OreTooltip placement="top" separation="small" text={header.tooltip}>
                      <span>{header.tooltipIcon}</span>
                    </OreTooltip>
                  </OreTableCell>
                )
              )}
            </OreTableRow>
          </OreTableHead>
          <OreTableBody as="tbody" valign="top">
            {employeesTT && employeesTT.length <= 0 ? (
              <TableEmpty colSpan={7} firstText={t(forms.table.emptyMessage)} />
            ) : (
              employeesTT.map((employeeTT: EmployeesWithLastCard, index: number) => {
                return (
                  <EmployeeListTableTTRow
                    key={'rows' + index}
                    employeeTT={employeeTT}
                    onClickDelete={onClickDelete}
                    onClickEdit={() => onClickEdit(employeeTT)}
                  />
                )
              })
            )}
          </OreTableBody>
        </OreTable>
        <OreTableActions>
          <OrePagination
            count={totalRows}
            labelNextPage={t(forms.pagination.next)}
            labelPreviousPage={t(forms.pagination.back)}
            onPageChange={onPageChange}
            page={page}
            rowsPerPage={pageSize}
            text={t(forms.pagination.of)}
          />
        </OreTableActions>
      </OreTableContainer>
      <Modal
        handleOnClose={setShowDeleteModal}
        open={showDeleteModal}
        buttons={deletePopupButtons}
        closeIcon={false}>
        <>
          <OreStack placeItems="start" direction="row">
            <OreIcon
              size="small"
              tone="neutral"
              icon={<SvgWarning color="var(--color-warning-500)" />}
            />
            <OreHeading size="headline-lg">{deleteModalTitle}</OreHeading>
          </OreStack>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {deleteModalDescription}
          </OreText>
          <OreText align="left" as="p" bold size="text-md" tone="neutral">
            {t(employeesTranslation.table.deleteModal.confirmDeleteDescription)}
          </OreText>
        </>
      </Modal>
    </>
  )
}
