import {
  OreCardActions,
  OreCardContainer,
  OreCardWrapper,
  OreData,
  OreHeading,
  OreIcon,
  OreMap,
  OreStack,
  OreText,
  SvgDestinationDirection,
  SvgKindergartenPlace,
} from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { KindergartenModel } from 'src/domain/models/TGDOrder'
import {
  addComma,
  getUndefinedDashString,
  getUndefinedEmptyString,
} from 'src/core/services'

interface Props {
  kindergarten?: KindergartenModel
  children?: React.ReactNode
}

export const KindergartenCard = ({ kindergarten, children }: Props): JSX.Element => {
  const { t, i18n } = useTranslation()

  const handleApiLoaded = (map: any, maps: any): any => {
    if (!kindergarten) return
    return new maps.Marker({
      position: {
        lat: Number(kindergarten.latitude),
        lng: Number(kindergarten.longitude),
      },
      map,
      title: kindergarten.name,
    })
  }

  if (!kindergarten) return <></>

  return (
    <OreCardContainer>
      <OreCardWrapper>
        <OreStack direction="row" placeContent="normal" sameSize>
          <OreStack space="medium">
            <OreStack direction="row" placeItems="center">
              <OreIcon
                icon={<SvgKindergartenPlace />}
                size="medium"
                tone="generic-dark"
              />
              <div>
                <OreHeading size="headline-sm">{kindergarten.name}</OreHeading>
                <OreText>Afiliado No. {kindergarten.id}</OreText>
              </div>
            </OreStack>
            <OreData
              icon={<SvgDestinationDirection />}
              title={t(forms.address.address)}
              description={
                addComma(kindergarten.streetName) +
                addComma(kindergarten.streetNumber) +
                addComma(kindergarten.streetDescription1) +
                addComma(kindergarten.streetDescription2) +
                getUndefinedEmptyString(kindergarten.zipCode) +
                ' ' +
                addComma(kindergarten.city) +
                getUndefinedEmptyString(kindergarten.province)
              }
            />
            <OreData
              icon={<SvgDestinationDirection />}
              title={t(forms.contact.contactPhone)}
              description={getUndefinedDashString(kindergarten.telephone)}
            />
          </OreStack>

          <OreMap
            key={kindergarten.id}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
              language: i18n.language,
              region: 'ES',
            }}
            defaultCenter={{
              lat: Number(kindergarten.latitude),
              lng: Number(kindergarten.longitude),
            }}
            defaultZoom={13}
            yesIWantToUseGoogleMapApiInternals
            options={{
              disableDefaultUI: true,
              mapTypeControl: false,
              zoomControl: false,
              panControl: false,
              disableDoubleClickZoom: true,
              gestureHandling: 'none',
            }}
            onGoogleApiLoaded={({ map, maps }: any) => handleApiLoaded(map, maps)}
          />
        </OreStack>
      </OreCardWrapper>
      {children && <OreCardActions>{children}</OreCardActions>}
    </OreCardContainer>
  )
}
