import {
  OreButton,
  OreHeading,
  OreList,
  OreListItem,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { Dispatch, SetStateAction } from 'react'
import { flexEmployeesTranslations } from '../../translations'
import { edenredProducts } from 'src/domain/enum'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'

interface Props {
  productNumber: edenredProducts
  showDeleteModal: boolean
  setShowDeleteModal: Dispatch<SetStateAction<boolean>>
  deleteProduct: () => void
}

export const ConfigureEmployeeProductDeleteModal = ({
  productNumber,
  showDeleteModal,
  setShowDeleteModal,
  deleteProduct,
}: Props) => {
  const { t } = useTranslation()

  if (productNumber === edenredProducts.salud) return null

  return (
    <OreModal open={showDeleteModal} handleOnClose={() => setShowDeleteModal(false)}>
      <OreModalBox size="small" handleOnClose={() => setShowDeleteModal(false)}>
        <OreModalBoxBody>
          <OreHeading align="left" size="headline-md">
            {t(flexEmployeesTranslations.deleteProduct.title) +
              t(flexProductsTranslations.ids[productNumber]) +
              '?'}
          </OreHeading>
          <OreStack direction="column" space="small">
            <OreList bullet>
              {(productNumber === edenredProducts.ticketRestaurant ||
                productNumber === edenredProducts.ticketTransporte) && (
                <>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.tr_tt.item1)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.tr_tt.item2)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.tr_tt.item3)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.tr_tt.item4)}
                    </OreText>
                  </OreListItem>
                </>
              )}
              {productNumber === edenredProducts.ticketGuarderia && (
                <>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.tgd.item1)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.tgd.item2)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.tgd.item3)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.tgd.item4)}
                    </OreText>
                  </OreListItem>
                </>
              )}
              {productNumber === edenredProducts.formacion && (
                <>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.formacion.item1)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.formacion.item2)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.formacion.item3)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.formacion.item4)}
                    </OreText>
                  </OreListItem>
                </>
              )}
              {productNumber === edenredProducts.saludExterno && (
                <>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.sse.item1)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.sse.item2)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.sse.item3)}
                    </OreText>
                  </OreListItem>
                  <OreListItem>
                    <OreText>
                      {t(flexEmployeesTranslations.deleteProduct.sse.item4)}
                    </OreText>
                  </OreListItem>
                </>
              )}
            </OreList>
          </OreStack>
        </OreModalBoxBody>
        <OreModalBoxFooter>
          <OreButton
            onClick={() => setShowDeleteModal(false)}
            size="small"
            category="secondary">
            {t(forms.buttons.cancel)}
          </OreButton>
          <OreButton
            onClick={() => {
              deleteProduct()
              setShowDeleteModal(false)
            }}
            size="small"
            category="danger">
            {t(forms.buttons.delete)}
          </OreButton>
        </OreModalBoxFooter>
      </OreModalBox>
    </OreModal>
  )
}
