import { OreList, OreListItem, OreMessageBar, OreStack } from '@edenredespana/oreneta'
import { flexProductsTranslations } from '../../translations'
import { useTranslation } from 'react-i18next'

export const PolicyDangerMessage = (props: {
  activeChange: boolean | undefined
  deleteChange: boolean | undefined
  numberOfContracts: number
  downloadFile: () => Promise<void>
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    //   <strong>
    //   {t(flexProductsTranslations.employeeContractAlert.policies.title)}
    // </strong>
    <OreMessageBar color="error">
      <OreStack>
        <>
          {props.activeChange ? (
            <strong>
              {t(flexProductsTranslations.employeeContractAlert.active.title)}
            </strong>
          ) : null}
          {props.deleteChange ? (
            <strong>
              {t(flexProductsTranslations.employeeContractAlert.delete.title)}
            </strong>
          ) : null}
          {!props.deleteChange && !props.activeChange ? (
            <strong>
              {t(flexProductsTranslations.employeeContractAlert.policies.title)}
            </strong>
          ) : null}
        </>
        <OreStack space="2xsmall">
          <div className="ore-list">
            <OreList bullet>
              <OreListItem>
                {props.deleteChange ? (
                  <>{t(flexProductsTranslations.employeeContractAlert.delete.line1)}</>
                ) : (
                  <>{t(flexProductsTranslations.employeeContractAlert.policies.line1)}</>
                )}
                <a
                  onClick={props.downloadFile}
                  style={{ color: 'var(--color-error-600)' }}>
                  {props.numberOfContracts +
                    t(flexProductsTranslations.employeeContractAlert.link)}
                </a>
                {t(flexProductsTranslations.employeeContractAlert.policies.line1_1)}
              </OreListItem>
              <OreListItem>
                {t(flexProductsTranslations.employeeContractAlert.policies.line2)}
              </OreListItem>
              <OreListItem>
                {t(flexProductsTranslations.employeeContractAlert.line2)}
              </OreListItem>
              {props.activeChange ? (
                <OreListItem>
                  {t(flexProductsTranslations.employeeContractAlert.active.line2)}
                </OreListItem>
              ) : null}
            </OreList>
          </div>
        </OreStack>
      </OreStack>
    </OreMessageBar>
  )
}
