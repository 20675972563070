import { OreIconButton, OreTooltip, SvgBin } from '@edenredespana/oreneta'
import React, { useState } from 'react'
import { flexEmployeesTranslations } from '../../translations'
import { useTranslation } from 'react-i18next'
import { edenredProducts } from 'src/domain/enum'
import { ConfigureEmployeeProductDeleteModal } from './ConfigureEmployeeProductDeleteModal'

interface Props {
  employeeId: string
  productId: string
  productNumber: number
  deleteProductEmployee: (employeeId: string, productId: string) => Promise<void>
  disabled?: boolean
}

export const ConfigureEmployeeProductDeleteAction = ({
  employeeId,
  productId,
  deleteProductEmployee,
  productNumber,
  disabled,
}: Props) => {
  const { t } = useTranslation()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  return (
    <div className="custom-row--action">
      <div className={productNumber !== edenredProducts.salud ? 'svg-danger' : ''}>
        <OreTooltip
          text={
            productNumber !== edenredProducts.salud
              ? t(flexEmployeesTranslations.delete.contract_tooltip)
              : t(flexEmployeesTranslations.delete.iSalud_tooltip)
          }
          placement="top">
          <OreIconButton
            as="button"
            disabled={!!disabled}
            icon={<SvgBin />}
            onClick={() => setShowDeleteModal(true)}
          />
        </OreTooltip>
      </div>
      <ConfigureEmployeeProductDeleteModal
        key={productId}
        deleteProduct={() => deleteProductEmployee(employeeId, productId)}
        productNumber={productNumber}
        setShowDeleteModal={setShowDeleteModal}
        showDeleteModal={showDeleteModal}
      />
    </div>
  )
}
