import {
  OreIconButton,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
  SvgBin,
  SvgEdit,
} from '@edenredespana/oreneta'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { useTranslation } from 'react-i18next'
import { emptyValues } from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import {
  addCurrency,
  getTotalAmountUnload,
  getUndefinedDashString,
} from 'src/core/services'

interface Props {
  ttOrder: TTOrderModel
  onClickDelete: (id: string) => void
  onClickEdit: (ttOrder: TTOrderModel) => void
}

export const TTUnloadSummaryTableRow = ({
  onClickDelete,
  onClickEdit,
  ttOrder,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  return (
    <OreTableRow valign="middle">
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-600">
          {ttOrder.name + ' ' + ttOrder.firstSurname + ' ' + ttOrder.secondSurname}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {ttOrder.document}
        </OreText>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <OreText size="text-sm" tone="neutral-600">
          {ttOrder.cardData.balance
            ? addCurrency(ttOrder.cardData.balance)
            : emptyValues.none}
        </OreText>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <OreText size="text-sm" tone="neutral-600">
          {addCurrency(ttOrder.cardData.initialAmount)}
        </OreText>
      </OreTableCell>

      <OreTableCell align="left" as="td" size="auto">
        <OreText size="text-sm" tone="neutral-600">
          {getTotalAmountUnload(ttOrder)}
        </OreText>
      </OreTableCell>

      <OreTableCell align="left" as="td" size="auto">
        <OreText size="text-sm" tone="neutral-600">
          {getUndefinedDashString(ttOrder.costCenter)}
        </OreText>
      </OreTableCell>

      <OreTableCell align="left" as="td" size="auto">
        <Grid gap=".5rem">
          <OreTooltip text={t(forms.buttons.delete)} placement="top" separation="small">
            <OreIconButton
              onClick={() => onClickDelete(ttOrder.id.toString())}
              icon={<SvgBin />}
            />
          </OreTooltip>
          <OreTooltip text={t(forms.buttons.edit)} placement="top" separation="small">
            <OreIconButton onClick={() => onClickEdit(ttOrder)} icon={<SvgEdit />} />
          </OreTooltip>
        </Grid>
      </OreTableCell>
    </OreTableRow>
  )
}
