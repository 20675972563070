import { forwardRef, useImperativeHandle, useState } from 'react'

import {
  OreButton,
  OreCardContainer,
  OreCardWrapper,
  OreData,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreStack,
  OreText,
  SvgUser,
} from '@edenredespana/oreneta'

import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { useEmployees } from 'src/Flex/Employees/ui/employee-list'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { EmployeeModel } from 'src/Flex/Employees/domain'

export type ContractReceivedModalActions = {
  open: (employee: EmployeeModel) => void
}

interface IProps {
  ref: React.Ref<ContractReceivedModalActions>
}

export const ContractReceivedModal = forwardRef<ContractReceivedModalActions, IProps>(
  (_, ref): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false)
    const [employee, setEmployee] = useState<EmployeeModel>()

    const { t } = useTranslation()
    const { confirmContractReception } = useEmployees()

    useImperativeHandle(ref, () => ({
      open: async (employee: EmployeeModel): Promise<void> => {
        setEmployee(employee)
        setOpen(true)
      },
    }))

    const saveAndClose = async (): Promise<void> => {
      if (!employee) return

      confirmContractReception(employee).then(response => {
        if (response) {
          setOpen(false)
        }
      })
    }

    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="medium" handleOnClose={() => setOpen(false)}>
          <OreModalBoxBody>
            <OreHeading align="left" size="headline-md">
              {t(flexEmployeesTranslations.configure.acceptContract.form.title)}
            </OreHeading>
            <OreStack space="large" placeContent="stretch">
              <OreText size="text-md" tone="neutral-900">
                {t(flexEmployeesTranslations.configure.acceptContract.form.description)}
              </OreText>
              <OreCardContainer tone="neutral-100" hasShadow={false}>
                <OreCardWrapper space="small">
                  <OreData
                    icon={<SvgUser />}
                    title={`${employee?.userFirstName} ${employee?.userLastName} ${employee?.userLastName2}`}
                    description={employee?.document}
                    iconAlign="center"
                    titleBold
                  />
                </OreCardWrapper>
              </OreCardContainer>
            </OreStack>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={() => setOpen(false)} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton onClick={saveAndClose} size="small" category="primary">
              {t(flexEmployeesTranslations.configure.acceptContract.form.cta)}
            </OreButton>
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
