import { OreText } from '@edenredespana/oreneta'
import { Seq } from 'immutable'
import type {
  TextOrderInfoSectionModel,
  TextSectionModel,
} from '../../../../../domain/models'

export const OrderInfo = (props: {
  sectionText: TextOrderInfoSectionModel[]
}): JSX.Element => {
  return (
    <section className="width-max mt-1">
      {Seq(props.sectionText).map(
        (infoSection: TextOrderInfoSectionModel, indexInfo: number) => (
          <OreText align="center" key={'info_' + indexInfo}>
            {Seq(infoSection.info).map((text: TextSectionModel, indexText: number) =>
              !text.isBold ? (
                text.text
              ) : (
                <strong key={'strong_' + indexText}>{text.text}</strong>
              )
            )}
          </OreText>
        )
      )}
    </section>
  )
}
