import { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'
import { forms } from 'src/domain/translations'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { useConfigureEmployee } from '../contexts'
import { useNavigate } from 'react-router-dom'

export type DeleteModalActions = {
  open: (employeeId: string, hasISaludActiveHires: boolean) => void
}

interface IProps {
  ref: React.Ref<DeleteModalActions>
}

export const DeleteEmployeeModal = forwardRef<DeleteModalActions, IProps>(
  (_, ref): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false)
    const [employeeData, setEmployeeData] = useState<any>(undefined)
    const { t } = useTranslation()
    const { remove } = useConfigureEmployee()
    const navigate = useNavigate()

    useImperativeHandle(ref, () => ({
      open: async (employeeId: string, hasISaludActiveHires: boolean): Promise<void> => {
        setEmployeeData({
          employeeId: employeeId,
          hasISaludActiveHires: hasISaludActiveHires,
        })
        setOpen(true)
      },
    }))

    const saveAndClose = async (): Promise<void> => {
      if (!employeeData) return

      remove(employeeData.employeeId).then(response => {
        if (response) {
          setOpen(false)
          navigate(-1)
        }
      })
    }

    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="medium" handleOnClose={() => setOpen(false)}>
          <OreModalBoxBody>
            <OreHeading align="left" size="headline-md">
              {t(flexEmployeesTranslations.delete.title)}
            </OreHeading>
            <OreStack space="large">
              <OreText align="left">
                {employeeData?.hasISaludActiveHires
                  ? t(flexEmployeesTranslations.delete.descriptionSSI)
                  : t(flexEmployeesTranslations.delete.description)}
              </OreText>
            </OreStack>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={() => setOpen(false)} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton onClick={saveAndClose} size="small" category="danger">
              {t(forms.buttons.delete)}
            </OreButton>
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
