import { SvgBin, SvgEdit, SvgInfo, SvgPlus } from '@edenredespana/oreneta'
import { Seq } from 'immutable'
import React, { useEffect, useState } from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import type {
  CaptionModel,
  HeaderModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import type {
  FooterModel,
  TableEmptyModel,
} from '../../../../../domain/customComponents/table'
import type { CardOrderModel } from '../../../../../domain/models'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useTranslation } from 'react-i18next'
import { unloadTranslation } from '../../../../../domain/translations'
import { orderType } from '../../../../../domain/enum'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import {
  addCurrency,
  getTotalAmountUnload,
  getUndefinedDashString,
} from '../../../../../core/services'
import { deleteAction, iconButtonAction } from '../../../../../domain/constants'

interface IUseUnloadHomeController {
  rows: RowModel[]
  setRows: React.Dispatch<React.SetStateAction<RowModel[]>>
  header: HeaderModel
  footer: () => FooterModel
  tableEmpty: TableEmptyModel
  caption: CaptionModel
}

export const useUnloadHomeController = (): IUseUnloadHomeController => {
  const { orders, removeUnloadOrder, setTREmployeesToConfig } = useCardOrder()
  const navigate = useNavigate()
  const [rows, setRows] = useState<RowModel[]>([])
  const { t } = useTranslation()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(unloadTranslation.tableHeader.owner) },
      {
        label: t(unloadTranslation.tableHeader.initialBalance),
        tooltip: t(unloadTranslation.tableHeader.initialBalanceTooltip),
        tooltipIcon: <SvgInfo />,
      },
      { label: t(unloadTranslation.tableHeader.unloadAmount) },
      {
        label: t(unloadTranslation.tableHeader.finalBalance),
        tooltip: t(unloadTranslation.tableHeader.finalBalanceTooltip),
        tooltipIcon: <SvgInfo />,
      },
      { label: t(unloadTranslation.tableHeader.center) },
      { label: t(unloadTranslation.tableHeader.actions) },
    ],
  }

  const tableEmpty: TableEmptyModel = {
    colSpan: 7,
    firstText: t(unloadTranslation.tableEmpty.message),
  }

  const caption: CaptionModel = {
    title: t(unloadTranslation.name),
    description: t(unloadTranslation.tableCaption.description),
  }

  const onClickEdit = (orderId: number) => {
    if (orderId == 0 || orderId == undefined) return
    const params = { id: orderId.toString() }
    navigate({
      pathname: navigationRoutes.cardOrderUnloadConfigurationEdit,
      search: `?${createSearchParams(params)}`,
    })
  }

  const onClickEditAll = () => {
    setTREmployeesToConfig(orders)
    navigate(navigationRoutes.cardOrderUnloadConfiguration, {
      state: {
        from: navigationRoutes.unloadHome,
      },
    })
  }

  const GetRows = () => {
    const orderRows: RowModel[] = []
    Seq(orders).forEach((order: CardOrderModel) => {
      if (order.orderTypeId === orderType.Unload) {
        const orderRow: RowModel = {
          rowId: order.orderId,
          cells: [
            {
              type: 'text',
              cellText: [
                {
                  text: getUndefinedDashString(order.employeeData.name),
                  name: 'name',
                  tone: 'neutral-900',
                  size: 'text-sm',
                  isBold: true,
                },
                {
                  text: getUndefinedDashString(order.employeeData.document),
                  name: 'document',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text: addCurrency(getUndefinedDashString(order.cardData.balance)),
                  name: 'balance',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text: addCurrency(order.cardData.initialAmount),
                  name: 'initialAmount',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text: getTotalAmountUnload(order),
                  name: 'totalAmount',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'text',
              cellText: [
                {
                  text: getUndefinedDashString(order.employeeData.costCenter),
                  name: 'costCenter',
                  size: 'text-sm',
                },
              ],
            },
            {
              type: 'actions',
              cellActions: [
                {
                  name: 'deleteRow',
                  icon: <SvgBin />,
                  type: deleteAction,
                  onClick: onClickDelete,
                },
                {
                  name: 'editRow',
                  icon: <SvgEdit />,
                  type: iconButtonAction,
                  onClick: onClickEdit,
                },
              ],
            },
          ],
        }
        orderRows.push(orderRow)
      }
    })
    setRows(orderRows)
  }

  const onClickDelete = (orderId: number) => {
    if (orderId > 0 && orderId !== undefined) {
      removeUnloadOrder(orderId)
    }
  }

  const getAddMakeUnloadButtonText = () => {
    if (rows.length > 0) {
      return t(unloadTranslation.tableFooter.addUnload)
    }
    return t(unloadTranslation.tableFooter.makeUnload)
  }

  const footer = () => {
    const footerObj: FooterModel = {
      actionButtons: [
        {
          onClick: () => navigate(navigationRoutes.unloadCard),
          text: getAddMakeUnloadButtonText(),
          category: 'highlight',
          size: 'large',
          icon: <SvgPlus />,
        },
      ],
    }
    if (orders.some(order => order.orderTypeId === orderType.Unload)) {
      footerObj.actionButtons?.push({
        onClick: onClickEditAll,
        text: t(unloadTranslation.tableFooter.editAll),
        icon: <SvgEdit />,
        category: 'highlight',
        size: 'large',
      })
    }
    return footerObj
  }

  useEffect(() => {
    GetRows()
  }, [orders])

  return {
    rows,
    setRows,
    header,
    footer,
    tableEmpty,
    caption,
  }
}
