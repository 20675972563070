import { OreButton, OreDivider, OreStack, SvgBin } from '@edenredespana/oreneta'
import { ExternalHealthForm, PolicyEmployeesAffected } from '../hooks'
import { flexProductsTranslations } from '../../translations'
import { useTranslation } from 'react-i18next'
import { MouseEvent, useEffect, useState } from 'react'
import { FieldArrayWithId } from 'react-hook-form'
import { EmployeeContractAlert } from '../atoms'
import { edenredProducts } from 'src/domain/enum'
import { PolicyModel } from 'src/Flex/Products/domain'

export const ExternalHealthUserDeleteAlert = (props: {
  formValues: ExternalHealthForm
  index: number
  field: FieldArrayWithId<ExternalHealthForm, 'policies', 'id'>
  policyEmployeesAffected: PolicyEmployeesAffected[]
  deletePolicyChanges(event: MouseEvent, index: number): void
  validateDeletePolicyChanges: (event: MouseEvent, index: number) => void
  collectiveId?: string
}): JSX.Element => {
  const { t } = useTranslation()
  const [isClear, setIsClear] = useState<boolean | undefined>()
  const [policyStateDanger, setPolicyStateDanger] = useState<PolicyEmployeesAffected[]>(
    []
  )
  const [policyStateWarning, setPolicyStateWarning] = useState<PolicyEmployeesAffected[]>(
    []
  )
  const [policyStateValidate, setPolicyStateValidate] = useState<
    PolicyEmployeesAffected[]
  >([])
  const [policyStateConfirm, setPolicyStateConfirm] = useState<PolicyEmployeesAffected[]>(
    []
  )

  const getPolicy = (): PolicyModel => {
    return {
      company: props.formValues.policies[props.index].company,
      id: props.formValues.policies[props.index].id,
      monthlyLimit: props.formValues.policies[props.index].monthlyPrice,
      policyName: props.formValues.policies[props.index].policyName,
      arePhoneRequired: props.formValues.policies[props.index].arePhoneRequired === 'yes',
      areAddressRequired:
        props.formValues.policies[props.index].areAddressRequired === 'yes',
    }
  }

  useEffect(() => {
    setPolicyStateDanger(
      props.policyEmployeesAffected.filter(
        policy =>
          policy.policyId === props.formValues.policies[props.index].id &&
          policy.employeesAffected &&
          policy.employeesAffected > 0
      )
    )
    setPolicyStateWarning(
      props.policyEmployeesAffected.filter(
        policy =>
          policy.policyId === props.formValues.policies[props.index].id &&
          policy.employeesAffected === 0
      )
    )
    setPolicyStateValidate(
      props.policyEmployeesAffected.filter(
        policy =>
          policy.policyId === props.formValues.policies[props.index].id &&
          policy.employeesAffected === undefined
      )
    )
    setPolicyStateConfirm(
      props.policyEmployeesAffected.filter(
        policy =>
          policy.policyId === props.formValues.policies[props.index].id &&
          policy.employeesAffected !== undefined
      )
    )
  }, [props.policyEmployeesAffected])

  useEffect(() => {
    setIsClear(
      !props.formValues.policies[props.index].isWarningAreAddressRequired &&
        !props.formValues.policies[props.index].isWarningArePhoneRequired &&
        !props.formValues.policies[props.index].isWarningCompany &&
        !props.formValues.policies[props.index].isWarningPolicyName &&
        !props.formValues.policies[props.index].isWarningMonthlyPrice &&
        !props.field.isNew &&
        !props.formValues.policies[props.index].forceDelete
    )
  }, [props.formValues])

  return (
    <>
      {policyStateDanger.length > 0 &&
      policyStateDanger[0].employeesAffected &&
      props.formValues.policies[props.index].forceDelete ? (
        <EmployeeContractAlert
          activeChange={false}
          deleteChange={true}
          numberOfContracts={policyStateDanger[0].employeesAffected}
          productType={edenredProducts.saludExterno}
          policy={getPolicy()}
          collectiveId={props.collectiveId}
        />
      ) : null}

      {policyStateWarning.length > 0 &&
      props.formValues.policies[props.index].forceDelete ? (
        <EmployeeContractAlert
          activeChange={false}
          deleteChange={true}
          numberOfContracts={0}
          productType={edenredProducts.saludExterno}
          policy={getPolicy()}
          collectiveId={props.collectiveId}
        />
      ) : null}

      {isClear && !props.field.isNew && policyStateValidate.length > 0 ? (
        <>
          <OreDivider />
          <OreStack placeContent="start">
            <OreButton
              icon={<SvgBin />}
              category="tertiary"
              onClick={event => props.validateDeletePolicyChanges(event, props.index)}>
              {t(flexProductsTranslations.configure.healthExternal.delete_policy)}
            </OreButton>
          </OreStack>
        </>
      ) : (
        <></>
      )}
      {props.formValues.policies[props.index].forceDelete &&
      policyStateConfirm.length > 0 ? (
        <>
          <OreDivider />
          <OreStack placeContent="start">
            <OreButton
              icon={<SvgBin />}
              category="tertiary"
              style={{ color: 'red' }}
              onClick={event => props.deletePolicyChanges(event, props.index)}>
              {t(flexProductsTranslations.configure.healthExternal.force_delete_policy)}
            </OreButton>
          </OreStack>
        </>
      ) : (
        <></>
      )}
    </>
  )
}
