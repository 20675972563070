import { OreTableCaption } from '@edenredespana/oreneta'
import { memo } from 'react'
import type { CaptionModel } from '../../../../../domain/customComponents/table/TableModel'
import { useCaption } from '../../hooks/table'

export const Caption = memo(
  (props: { caption: CaptionModel; totalRows: number[] }): JSX.Element => {
    const { description } = useCaption(props.totalRows.length, props.caption)

    return <OreTableCaption title={props.caption.title} counter={description} />
  }
)
