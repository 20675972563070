import { OreAvatar, OreButton, SvgArrowLeft, SvgOut } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { getAcronym } from '../../core/services'
import { forms } from '../../domain/translations'
import { employeesTranslation } from '../../domain/translations/cardOrder/employees'
import { Grid } from '../components/Edenred/layout'
import { Divider } from '../layout'
import {
  OreHeading,
  OreIcon,
  OreStack,
  OreText,
  SvgWarning,
} from '@edenredespana/oreneta'
import { Modal } from '../components/Edenred'
import { DeleteEmployeeProps } from '../components/employees/hooks'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { queryParam } from 'src/domain/enum'

interface EmployeeProfileContainerProps {
  userName?: string
  employeeNumber: string
  organism?: React.ReactNode
  deleteEmployeeProps: DeleteEmployeeProps
}

export const EmployeeProfileTemplate = (
  props: EmployeeProfileContainerProps
): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <>
      <Grid items="flex-end" justify="space-between">
        <OreAvatar
          placeholderText={getAcronym(props.userName)}
          size="large"
          subtitle={props.employeeNumber}
          title={props.userName}
        />
        <OreButton
          size="small"
          icon={<SvgOut />}
          category="tertiary"
          onClick={() =>
            props.deleteEmployeeProps.onClickDeleteEmployee(props.employeeNumber)
          }>
          {t(employeesTranslation.profile.heading.removeEmployee)}
        </OreButton>
      </Grid>
      {props.organism}
      <Divider marginTop="4rem" marginBottom="2rem" />
      <OreButton
        size="small"
        icon={<SvgArrowLeft />}
        category="tertiary"
        onClick={() =>
          navigate(
            navigationRoutes.employeesHome +
              '?' +
              queryParam.product +
              '=' +
              searchParams.get(queryParam.product)
          )
        }>
        {t(forms.buttons.goBack)}
      </OreButton>
      <Modal
        handleOnClose={props.deleteEmployeeProps.setShowPopup}
        open={props.deleteEmployeeProps.popupShow}
        buttons={props.deleteEmployeeProps.popupButtons}
        closeIcon={false}>
        <>
          <OreStack placeItems="start" direction="row" space="small">
            <OreIcon
              size="small"
              tone="neutral"
              icon={<SvgWarning color="var(--color-warning-500)" />}
            />
            <OreHeading size="headline-lg">
              {props.deleteEmployeeProps.popupTitle}
            </OreHeading>
          </OreStack>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {props.deleteEmployeeProps.popupDescription}
          </OreText>
          <OreText align="left" as="p" bold size="text-md" tone="neutral">
            {t(employeesTranslation.table.deleteModal.confirmDeleteDescription)}
          </OreText>
        </>
      </Modal>
    </>
  )
}
