import { OreDatePicker } from '@edenredespana/oreneta'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  getDaysAfter,
  getLastDayOfMonthOneYearLater,
  getLastMilesimaDate,
  getToday,
  getTomorrow,
} from '../../../../../core/helpers'
import type { SaveCardOrder } from '../../../../../domain/protocols/CardOrderProtocol'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { CardOrderDatesParagraph } from '../atoms'
import { useCardOrderDates } from '../hooks'
import { hasUnloadOrders, isDischarge } from '../../../../../core/services'

export const CardOrderDates = (): JSX.Element => {
  const {
    register,
    formState: { errors },
  } = useFormContext<SaveCardOrder>()
  const {
    orders,
    orderContext: { orderType },
  } = useCardOrder()
  const { t } = useTranslation()
  const { currentContract, getInitialChargeDateLabel, getInitialDateTooltip } =
    useCardOrderDates()

  return (
    <div className="form-molecule">
      <div className="mt-2">
        <CardOrderDatesParagraph />
      </div>
      <div className="form-atom form-atom--half pl-0">
        <OreDatePicker
          {...register('initialChargeDate')}
          name="initialChargeDate"
          label={getInitialChargeDateLabel()}
          labelTooltip={getInitialDateTooltip()}
          id="initialChargeDate"
          min={isDischarge(orderType) ? getToday() : getTomorrow()}
          max={
            currentContract?.allowMilesima
              ? getLastMilesimaDate()
              : getLastDayOfMonthOneYearLater()
          }
          hasError={!!errors?.initialChargeDate}
          errorMessage={errors?.initialChargeDate?.message}
          required
        />
      </div>
      {currentContract?.allowLoadExpiredDate && !hasUnloadOrders(orders) && (
        <div className="form-atom form-atom--half pl-0">
          <OreDatePicker
            {...register('expiredDate')}
            name="expiredDate"
            label={t(cardOrderTranslation.cardOrderHome.expiredDate)}
            labelTooltip={t(cardOrderTranslation.cardOrderHome.expiredDateTooltip)}
            id="expiredDate"
            min={getDaysAfter(2)}
            hasError={!!errors?.expiredDate}
            errorMessage={errors?.expiredDate?.message}
            required
          />
        </div>
      )}
    </div>
  )
}
