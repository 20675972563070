import { OreMessageBar } from '@edenredespana/oreneta'
import { Table } from '../../../Edenred'
import type { CustomState } from '../CardOrderCompletePage'
import { useContainerController } from '../hooks/useContainerController'
import { Buttons } from '../molecules/Buttons'
import { Fail } from '../molecules/Fail'
import { OrderInfo } from '../molecules/OrderInfo'
import { Section } from '../molecules/Section'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { t } from 'i18next'

export const Container = (props: { orderCompleteData: CustomState }): JSX.Element => {
  const { sections, rows, header, caption, setRows } = useContainerController(
    props.orderCompleteData
  )

  const warning = t(TGDOrderTranslation.complete.important)
  return (
    <>
      {sections && sections.responsibleSection && (
        <Section sectionText={sections.responsibleSection} />
      )}
      {/* no warning info */}
      {sections && sections.infoSection && !sections.infoSectionAsWarning && (
        <Section sectionText={sections.infoSection} />
      )}
      {/* warning info */}
      {sections && sections.infoSection && sections.infoSectionAsWarning && (
        <div>
          <br />
          <OreMessageBar color="warning" icon>
            {warning}
          </OreMessageBar>
        </div>
      )}
      {sections && sections.availableSection && (
        <Section sectionText={sections.availableSection} />
      )}
      {sections && sections.orderInfoSection && (
        <OrderInfo sectionText={sections.orderInfoSection} />
      )}
      {sections && sections.ibanSection && <Section sectionText={sections.ibanSection} />}
      {sections && sections.failSection && <Fail sectionText={sections.failSection} />}
      {!props.orderCompleteData.success &&
        props.orderCompleteData.errorMessage.length > 0 && (
          <div className="width-max mt-2">
            <Table rows={rows} header={header} setRows={setRows} caption={caption} />
          </div>
        )}
      <Buttons
        success={props.orderCompleteData.success}
        excel={props.orderCompleteData.excel}
      />
    </>
  )
}
