import { OreButton, OreHeading, OreStack, OreText, SvgPlus } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'

export const TGDSingleEmployeeNoOrderDescription = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <div className="pt-3">
      <OreStack direction="column" placeItems="start">
        <OreHeading size="headline-md">
          {t(TGDOrderTranslation.employees.notFoundTitle)}
        </OreHeading>
        <OreText>{t(TGDOrderTranslation.employees.notFoundDescription)}</OreText>
        <OreButton
          category="highlight"
          icon={<SvgPlus />}
          size="large"
          onClick={() => navigate(navigationRoutes.tgdOrderForm)}>
          {t(TGDOrderTranslation.summaryAssignment.table.footer.addEmployeeChild)}
        </OreButton>
      </OreStack>
    </div>
  )
}
