import {
  OreIconButton,
  OrePill,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
  SvgBin,
  SvgCardDuplicate,
  SvgCardNew,
  SvgEdit,
} from '@edenredespana/oreneta'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { useTranslation } from 'react-i18next'
import { cardRequestTypeId, emptyValues } from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { addCurrency } from 'src/core/services'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { useEffect, useState } from 'react'
import { useUser } from 'src/presentation/context/user/UserProvider'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { DeliverySiteModel } from 'src/domain/models'
import { TTOrderSummaryTableDeliverySiteCell } from './TTOrderSummaryTableDeliverySiteCell'

interface Props {
  ttOrder: TTOrderModel
  onClickDelete: (id: string) => void
  onClickEdit: (ttOrder: TTOrderModel) => void
}

export const TTOrderSummaryTableRow = ({
  onClickDelete,
  onClickEdit,
  ttOrder,
}: Props): JSX.Element => {
  const { startLoading, stopLoading } = useLoader()
  const { userDeliverySitesById } = useUser()
  const [deliverySiteData, setDeliverySiteData] = useState<DeliverySiteModel>(
    ttOrder.deliverySiteData
  )
  const { t } = useTranslation()

  const fetchDeliverySiteDataWhenNull = () => {
    if (ttOrder.deliverySiteId && !ttOrder.deliverySiteData.deliveryPointId) {
      startLoading()
      userDeliverySitesById(ttOrder.deliverySiteId)
        .then(deliverySiteResponseData => {
          if (deliverySiteResponseData) {
            setDeliverySiteData({ ...deliverySiteResponseData })
          }
        })
        .finally(() => stopLoading())
    }
  }

  useEffect(() => fetchDeliverySiteDataWhenNull(), [ttOrder])

  return (
    <OreTableRow valign="middle">
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-600">
          {ttOrder.name + ' ' + ttOrder.firstSurname + ' ' + ttOrder.secondSurname}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {ttOrder.document}
        </OreText>
        <OreText size="text-sm" tone="neutral-500">
          {ttOrder.corporativeEmail}
        </OreText>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {ttOrder.cardData.cardRequestTypeId === cardRequestTypeId.newPlastic ? (
          <OrePill icon={<SvgCardNew />} tone="cobalt-600">
            {t(TTOrderTranslation.summary.table.body.cardRequestType.newCard)}
          </OrePill>
        ) : (
          <OrePill icon={<SvgCardDuplicate />} tone="grey">
            {t(TTOrderTranslation.summary.table.body.cardRequestType.duplicated)}
          </OrePill>
        )}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <OreText size="text-sm" tone="neutral-600">
          {addCurrency(ttOrder.cardData.initialAmount)}
        </OreText>
      </OreTableCell>

      <OreTableCell align="left" as="td" size="auto">
        <OreText size="text-sm" tone="neutral-600">
          {ttOrder.cardData.balance ? ttOrder.cardData.balance : emptyValues.none}
        </OreText>
      </OreTableCell>

      <OreTableCell align="left" as="td" size="auto">
        <TTOrderSummaryTableDeliverySiteCell ds={deliverySiteData} />
      </OreTableCell>

      <OreTableCell align="left" as="td" size="auto">
        <Grid gap=".5rem">
          <OreTooltip text={t(forms.buttons.delete)} placement="top" separation="small">
            <OreIconButton
              onClick={() => onClickDelete(ttOrder.id.toString())}
              icon={<SvgBin />}
            />
          </OreTooltip>
          <OreTooltip text={t(forms.buttons.edit)} placement="top" separation="small">
            <OreIconButton onClick={() => onClickEdit(ttOrder)} icon={<SvgEdit />} />
          </OreTooltip>
        </Grid>
      </OreTableCell>
    </OreTableRow>
  )
}
