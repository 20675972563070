import {
  OreTableCell,
  OreTableRow,
  OreText,
  OreStack,
  OreIcon,
  SvgKindergarten,
  OreDivider,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { productsTranslate } from 'src/domain/translations'
import { GuarderiaProductModel } from 'src/Flex/Employees/domain/EmployeeProductListModel'
import { flexEmployeesTranslations } from '../../translations'
import React from 'react'
import { ConfigureEmployeeProductRowItem } from '../atoms/ConfigureEmployeeProductRowItem'
import { MonthsAndAmountsItem } from '../atoms/MonthsAndAmountsItem'
import { ConfigureEmployeeProductDeleteAction } from '../atoms/ConfigureEmployeeProductDeleteAction'
import { edenredProducts } from 'src/domain/enum'

interface IProps {
  employeeId: string
  products: GuarderiaProductModel[]
  deleteProductEmployee: (employeeId: string, productId: string) => Promise<void>
}

export const ConfigureEmployeeTGDRow = ({
  employeeId,
  products,
  deleteProductEmployee,
}: IProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <OreTableRow valign="middle" hover={false}>
      <OreTableCell size="quarter">
        <div className="flex">
          <OreStack
            space="xsmall"
            placeContent="center"
            placeItems="center"
            direction="row">
            <OreIcon size="extra-small" icon={<SvgKindergarten />} tone="kindergarten" />
            <OreText size="text-sm" tone="neutral-900" bold={true}>
              {t(productsTranslate.ticketGuarderia)}
            </OreText>
          </OreStack>
        </div>
      </OreTableCell>
      <OreTableCell colSpan={2}>
        <div>
          {products.map((product, index) => (
            <div key={index}>
              <OreStack direction="row" placeContent="space-between">
                <ul>
                  {product.childrenName && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .childrenName
                      )}
                      value={product.childrenName}
                    />
                  )}
                  {product.birthDate && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .birthDate
                      )}
                      value={product.birthDate}
                    />
                  )}
                  {product.kindergarten && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .kindergarten
                      )}
                      value={product.kindergarten}
                    />
                  )}
                  {product.contractDate && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .contractDate
                      )}
                      value={product.contractDate}
                    />
                  )}
                  {product.startDate && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .startDate
                      )}
                      value={product.startDate}
                    />
                  )}
                  {product.monthsAndAmounts && product.monthsAndAmounts.length > 0 && (
                    <MonthsAndAmountsItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .monthsAndAmounts
                      )}
                      items={product.monthsAndAmounts}
                    />
                  )}
                  {product.anualContractAmount && (
                    <ConfigureEmployeeProductRowItem
                      label={t(
                        flexEmployeesTranslations.configure.products.table.details
                          .anualContractAmount
                      )}
                      value={product.anualContractAmount.toString() + '€'}
                    />
                  )}
                </ul>
                <ConfigureEmployeeProductDeleteAction
                  employeeId={employeeId}
                  productId={product.id}
                  productNumber={edenredProducts.ticketGuarderia}
                  deleteProductEmployee={deleteProductEmployee}
                />
              </OreStack>
              {index < products.length - 1 && (
                <div className="pt-1 pb-1">
                  <OreDivider />
                </div>
              )}
            </div>
          ))}
        </div>
      </OreTableCell>
    </OreTableRow>
  )
}
