import { PageWrapper } from 'src/presentation/layout'
import { OreHeading, OreDivider, OreText, OreStack } from '@edenredespana/oreneta'

import { Trans, useTranslation } from 'react-i18next'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { CollectivesDetail } from 'src/Flex/Collectives/ui/collective-detail/organisms'
import { useParams } from 'react-router-dom'

import { useUserData } from 'src/Flex/User/ui/context'

export const FlexCollectiveEditPage = (): JSX.Element => {
  const { t } = useTranslation()
  const { id } = useParams()

  const { flexData } = useUserData()

  return (
    <PageWrapper>
      <OreHeading as="h1" size="title-md">
        {id
          ? t(flexCollectivesTranslations.configure.edit.title)
          : t(flexCollectivesTranslations.configure.create.title)}
      </OreHeading>

      <OreDivider space="larger-bottom" />

      {!flexData?.isOnboardingDone && id ? (
        <OreStack space="2xlarge" placeContent="stretch">
          <OreText>
            <Trans t={t}>{t(flexCollectivesTranslations.onboarding_edit)}</Trans>
          </OreText>
          <CollectivesDetail id={id} />
        </OreStack>
      ) : (
        <CollectivesDetail id={id} />
      )}
    </PageWrapper>
  )
}
