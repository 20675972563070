import { SvgInfo } from '@edenredespana/oreneta'
import { t } from 'i18next'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import type { PopupButtonModel } from '../../../../../domain/customComponents/Popup'
import type {
  CaptionModel,
  CellModel,
  HeaderModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import type { CardOrderModel } from '../../../../../domain/models'
import {
  forms,
  rechargesConfigurationTranslations,
} from '../../../../../domain/translations'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useModalController } from '../../../Edenred'
import { orderType } from '../../../../../domain/enum'
import type { OrderConfigParameters } from '../../../../../domain/forms'

export interface EditConfigurationState {
  caption: CaptionModel
  header: HeaderModel
  rows: RowModel[]
  validateTable: boolean
  setRows: Dispatch<SetStateAction<RowModel[]>>
  clearCardOrderRecharges: () => void
  navigate: NavigateFunction
  ValidateTableFields: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  setShowPopup: Dispatch<SetStateAction<boolean>>
  popupShow: boolean
  popupTitle: string
  popupDescription: string
  popupButtons: PopupButtonModel[]
  configurationParameters: OrderConfigParameters<RowModel[]> | undefined
}
export const useEditRechargesConfigurationController = (): EditConfigurationState => {
  const {
    configurationParameters,
    clearCardOrderRecharges,
    getOrderById,
    editOrder,
    clearAll,
  } = useCardOrder()
  const navigate = useNavigate()
  const [rows, setRows] = useState<RowModel[]>([])
  const [validateTable, setValidateTable] = useState<boolean>(false)

  const {
    show: popupShow,
    title: popupTitle,
    description: popupDescription,
    buttons: popupButtons,
    setShow: setShowPopup,
    setDescription: setDescriptionPopup,
    setTitle: setTitlePopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const caption: CaptionModel = {
    title: t(rechargesConfigurationTranslations.table.caption.title),
    description: t(rechargesConfigurationTranslations.table.caption.description),
  }
  const header: HeaderModel = {
    headerLabel: [
      { label: t(rechargesConfigurationTranslations.table.header.name) },
      { label: t(rechargesConfigurationTranslations.table.header.amount) },
      {
        label: t(rechargesConfigurationTranslations.table.header.recharge),
        tooltip: t(rechargesConfigurationTranslations.table.header.rechargeTooltip),
        tooltipIcon: <SvgInfo />,
      },
      { label: t(rechargesConfigurationTranslations.table.header.costs) },
    ],
  }

  const ValidateTableFields = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()
    setValidateTable(true)
  }

  const SetPopupButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowPopup(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => handleDiscardPopup(),
        size: 'large',
      },
    ]
  }

  const handleDiscardPopup = () => {
    clearAll()
    clearCardOrderRecharges()
    setShowPopup(false)
    navigate(navigationRoutes.rechargeHome)
  }

  const GetErrors = (): boolean => {
    let hasError = false
    Seq(rows).forEach(row => {
      Seq(row.cells).forEach(cell => {
        if (cell.cellControll && cell.cellControll.hasError === true) {
          hasError = true
          return
        }
      })
    })
    return hasError
  }

  const editRechargesToCardOrder = () => {
    Seq(rows).forEach((row: RowModel) => {
      let amount: string | undefined
      let costs: string | undefined
      const cardOrder: CardOrderModel | undefined = getOrderById(row.rowId)
      Seq(row.cells).forEach((cell: CellModel) => {
        if (cell.cellControll && cell.cellControll.name === 'recharge') {
          amount = cell.cellControll.value
        }

        if (cell.cellControll && cell.cellControll.name === 'costs') {
          costs = cell.cellControll.value
        }
      })

      if (cardOrder) {
        cardOrder.employeeData.costCenter = costs
        cardOrder.cardData.initialAmount = Number(amount)
        cardOrder.orderTypeId = orderType.Recharge
        editOrder(cardOrder)
      }
    })
    navigate(navigationRoutes.rechargeHome)
  }

  useEffect(() => {
    setTitlePopup(t(rechargesConfigurationTranslations.modal.title))
    setDescriptionPopup(t(rechargesConfigurationTranslations.modal.description))
    setButtonsPopup(SetPopupButtons())
  }, [])

  useEffect(() => {
    if (configurationParameters && configurationParameters.entity)
      setRows(configurationParameters.entity)
  }, [configurationParameters])

  useEffect(() => {
    if (validateTable) {
      if (!GetErrors()) editRechargesToCardOrder()
      setValidateTable(false)
    }
  }, [validateTable])

  return {
    caption,
    header,
    rows,
    validateTable,
    setRows,
    clearCardOrderRecharges,
    navigate,
    ValidateTableFields,
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    configurationParameters,
  }
}
