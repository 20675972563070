import {
  confirmCollectiveEmployeeAffected,
  confirmDeletePolicyEmployeeAffected,
  confirmEmployeeAffected,
  confirmPolicyEmployeeAffected,
  deleteExternalHealthPolicy,
  disableCollectiveProductState,
  employeeAffectedExcel,
  employeeAffectedExcelHealth,
  employeeCollectiveAffectedExcel,
  employeeDeleteAffectedExcelHealth,
  employeesAffectedExcelDisabledProduct,
  enableCollectiveProductState,
  getCollectiveIsaludConfiguration,
  getCollectiveProductConfiguration,
  getCollectiveProductList,
  getIsaludInsurance,
  getIsaludInsuranceConditions,
  getPolicyIcons,
  getProductConfiguration,
  indexCutOffDate,
  indexProductList,
  saveChangesPolicies,
  saveCollectiveIsaludConfiguration,
  saveCollectiveProductConfiguration,
  saveCollectiveProductState,
  saveProductConfiguration,
  saveProductState,
  validateCollectiveEmployeeAffected,
  validateDeletePolicyEmployeeAffected,
  validateEmployeeAffected,
  validatePolicyEmployeeAffected,
} from 'src/config/constants/endpoints'
import type {
  ProductConfigurationModels,
  ProductRepositoryContract,
  SaveProductConfigurationDTO,
  ProductModel,
  AnswerDTO,
  CutOffDateForm,
  CutOffDate,
  PolicyModel,
  PolicyCollectiveModel,
  ExcelFile,
  ContractStateInfoModel,
} from 'src/Flex/Products/domain'
import { edenredProducts } from 'src/domain/enum'
import { HttpModel } from 'src/domain/models'
import { HttpMethod } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'
import { IMonths } from 'src/domain/interfaces/global/IMonths'

type ApiProductCutOffData = HttpModel<string | null>

type ApiProductList = HttpModel<
  | {
      name: string
      description: string
      active: boolean
      productType: number
      toggleable: boolean
      configurated: boolean
      isVirtual?: boolean
      startDate?: string
      monthlyLimit?: number
      months?: IMonths
      numberOfMonths?: number
      policies?: PolicyCollectiveModel[]
      contractStateInfo: ContractStateInfoModel
    }[]
  | null
>

const convertToDomain = (response: ApiProductCutOffData): CutOffDate => {
  return {
    cutOffDate: response.data ? new Date(response.data) : undefined,
  }
}

const convertProductListToDomain = (response: ApiProductList): ProductModel[] => {
  return (
    response.data
      ?.filter(product => Object.values(edenredProducts).includes(product.productType))
      .map<ProductModel>(product => ({
        name: product.name,
        description: product.description,
        active: product.active,
        productType: product.productType,
        toggleable: product.toggleable,
        configurated: product.configurated,
        isVirtual: product.isVirtual,
        monthlyLimit: product.monthlyLimit,
        startDate: product.startDate,
        months: product.months,
        numberOfMonths: product.numberOfMonths,
        policies: product.policies,
        contractStateInfo: product.contractStateInfo,
      })) ?? []
  )
}

export class ProductRepository implements ProductRepositoryContract {
  async GetProducts(collectiveId?: string): Promise<HttpModel<ProductModel[]>> {
    const response: ApiProductList = await axiosFetch(
      collectiveId
        ? getCollectiveProductList.replace('{collectiveId}', collectiveId)
        : indexProductList,
      HttpMethod.GET,
      {}
    )

    return {
      data: convertProductListToDomain(response),
      meta: response.meta,
    }
  }

  async GetProductConfiguration<T extends edenredProducts>(
    productType: T,
    collectiveId?: string
  ): Promise<HttpModel<ProductConfigurationModels[T]>> {
    return await axiosFetch(
      collectiveId
        ? productType === edenredProducts.salud
          ? getCollectiveIsaludConfiguration.replace('{collectiveId}', collectiveId)
          : getCollectiveProductConfiguration
              .replace('{collectiveId}', collectiveId)
              .replace('{productType}', productType.toString())
        : productType === edenredProducts.salud
        ? getIsaludInsurance
        : getProductConfiguration.replace('{productType}', productType.toString()),
      HttpMethod.GET,
      {}
    )
  }

  async GetEmployeesAffectedExcel<T extends edenredProducts>(
    productType: T,
    product: ProductConfigurationModels[T],
    collectiveId?: string
  ): Promise<HttpModel<ExcelFile>> {
    if (collectiveId === undefined) {
      return await axiosFetch(
        employeeAffectedExcel.replace('{productType}', productType.toString()),
        HttpMethod.POST,
        product
      )
    } else {
      return await axiosFetch(
        employeeCollectiveAffectedExcel
          .replace('{productType}', productType.toString())
          .replace('{collectiveId}', collectiveId.toString()),
        HttpMethod.POST,
        product
      )
    }
  }

  async GetEmployeesAffectedExcelHealth(
    policy: PolicyModel
  ): Promise<HttpModel<ExcelFile>> {
    return await axiosFetch(employeeAffectedExcelHealth, HttpMethod.POST, policy)
  }

  async GetEmployeesAffectedExcelDeleteHealth(
    policyId: string
  ): Promise<HttpModel<ExcelFile>> {
    return await axiosFetch(employeeDeleteAffectedExcelHealth + policyId, HttpMethod.GET)
  }
  async GetEmployeesAffectedExcelDisabledProduct(
    productType: edenredProducts,
    collectiveId: string
  ): Promise<HttpModel<ExcelFile>> {
    return await axiosFetch(
      employeesAffectedExcelDisabledProduct
        .replace('{productId}', productType.toString())
        .replace('{collectiveId}', collectiveId.toString()),
      HttpMethod.GET
    )
  }

  async GetHealthProductConditions<T extends string>(
    policyId: T
  ): Promise<HttpModel<string>> {
    return await axiosFetch(getIsaludInsuranceConditions + policyId, HttpMethod.GET, {})
  }

  async GetHealthPolicyIcon<T extends string>(policyId: T): Promise<HttpModel<string>> {
    return await axiosFetch(
      getPolicyIcons.replace('{policyId}', policyId),
      HttpMethod.GET,
      {}
    )
  }

  async SaveProductConfiguration<T extends edenredProducts>(
    data: ProductConfigurationModels[T],
    productType: T,
    collectiveId?: string
  ): Promise<SaveProductConfigurationDTO> {
    return await axiosFetch(
      collectiveId
        ? productType === edenredProducts.salud
          ? saveCollectiveIsaludConfiguration.replace('{collectiveId}', collectiveId)
          : saveCollectiveProductConfiguration
              .replace('{collectiveId}', collectiveId)
              .replace('{productType}', productType.toString())
        : saveProductConfiguration.replace('{productType}', productType.toString()),
      HttpMethod.POST,
      data
    )
  }

  async ValidateEmployeeAffected<T extends edenredProducts>(
    data: ProductConfigurationModels[T],
    productType: T,
    collectiveId?: string
  ): Promise<HttpModel<ContractStateInfoModel>> {
    if (collectiveId !== undefined) {
      return await axiosFetch(
        validateCollectiveEmployeeAffected
          .replace('{productType}', productType.toString())
          .replace('{collectiveId}', collectiveId ? collectiveId.toString() : ''),
        HttpMethod.POST,
        data
      )
    } else {
      return await axiosFetch(
        validateEmployeeAffected.replace('{productType}', productType.toString()),
        HttpMethod.POST,
        data
      )
    }
  }

  async ConfirmEmployeeAffected<T extends edenredProducts>(
    data: ProductConfigurationModels[T],
    productType: T,
    collectiveId?: string
  ): Promise<HttpModel<ContractStateInfoModel>> {
    if (collectiveId !== undefined) {
      return await axiosFetch(
        confirmCollectiveEmployeeAffected
          .replace('{productType}', productType.toString())
          .replace('{collectiveId}', collectiveId ? collectiveId.toString() : ''),
        HttpMethod.POST,
        data
      )
    } else {
      return await axiosFetch(
        confirmEmployeeAffected.replace('{productType}', productType.toString()),
        HttpMethod.POST,
        data
      )
    }
  }

  async validateHealthExternalPolicyConfiguration(
    policy: PolicyModel
  ): Promise<HttpModel<ContractStateInfoModel>> {
    return await axiosFetch(validatePolicyEmployeeAffected, HttpMethod.POST, policy)
  }

  async confirmHealthExternalPolicyConfiguration(
    policy: PolicyModel
  ): Promise<HttpModel<boolean>> {
    return await axiosFetch(confirmPolicyEmployeeAffected, HttpMethod.POST, policy)
  }

  async DeleteHealthExternalPolicyConfiguration(
    policyId: string
  ): Promise<HttpModel<boolean>> {
    return await axiosFetch(
      confirmDeletePolicyEmployeeAffected + policyId,
      HttpMethod.DELETE
    )
  }

  async validateDeleteHealthExternalPolicyConfiguration(
    policyId: string
  ): Promise<HttpModel<ContractStateInfoModel>> {
    return await axiosFetch(
      validateDeletePolicyEmployeeAffected + policyId,
      HttpMethod.GET
    )
  }

  async SaveProductStatus<T extends edenredProducts>(
    productType: T,
    collectiveId?: string
  ): Promise<SaveProductConfigurationDTO> {
    return await axiosFetch(
      collectiveId
        ? saveCollectiveProductState
            .replace('{collectiveId}', collectiveId)
            .replace('{productType}', productType.toString())
        : saveProductState.replace('{productType}', productType.toString()),
      HttpMethod.POST,
      {}
    )
  }

  async EnableDisableProductStatus<T extends edenredProducts>(
    productType: T,
    enable: boolean,
    collectiveId: string
  ): Promise<SaveProductConfigurationDTO> {
    const endpoint = !enable
      ? enableCollectiveProductState
      : disableCollectiveProductState
    return await axiosFetch(
      endpoint
        .replace('{collectiveId}', collectiveId)
        .replace('{productType}', productType.toString()),
      HttpMethod.POST,
      {}
    )
  }

  async GetCutOffDate(): Promise<CutOffDate> {
    const response = await axiosFetch(indexCutOffDate, HttpMethod.GET, {})
    return convertToDomain(response)
  }

  async SaveCutOffDate(data: CutOffDateForm): Promise<AnswerDTO> {
    return await axiosFetch(indexCutOffDate, HttpMethod.PUT, data)
  }
}
