import { OreButton, OreNavBarHeaderDivider, SvgNavBarHelp } from '@edenredespana/oreneta'
import React from 'react'
import { navigationTranslation } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'

export const Help = () => {
  const { t } = useTranslation()
  const subject = t(navigationTranslation.header.helpSubject)

  const handleButtonClick = () => {
    const mailtoLink = `mailto:info-es@edenred.com?subject=${subject}`
    window.location.href = mailtoLink
  }

  return (
    <OreNavBarHeaderDivider>
      <OreButton
        as="a"
        category="tertiary"
        icon={<SvgNavBarHelp />}
        onClick={handleButtonClick}>
        {t(navigationTranslation.header.help)}
      </OreButton>
    </OreNavBarHeaderDivider>
  )
}
