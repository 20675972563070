import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OrePagination,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCaption,
  OreTableContainer,
  OreText,
} from '@edenredespana/oreneta'
import { HeadersModel } from 'src/domain/models/tables/Headers'
import { TableHead } from 'src/Flex/Shared/ui/Table'
import { tableSelector } from 'src/domain/translations'

type TableHeadProps<T> = {
  title?: string
  tableHeaders: HeadersModel[]
  rows: T[]
  recordsPerPage?: number
  withPagination?: boolean
  noRows?: string
  renderRow: (row: T, index: number) => JSX.Element
}

export const SimpleTable = <T extends object>({
  title,
  tableHeaders,
  rows,
  recordsPerPage = 8,
  withPagination = false,
  noRows,
  renderRow,
}: TableHeadProps<T>): JSX.Element => {
  const [page, setPage] = useState(1)
  const { t } = useTranslation()

  const handlePageChange = (value: number): void => {
    setPage(value)
  }

  const offset = (page - 1) * recordsPerPage
  const shouldShowPagination = withPagination && rows.length > recordsPerPage
  const shouldShowNoRows = rows.length === 0 && noRows && !shouldShowPagination
  const slicedRows = withPagination ? rows.slice(offset, page * recordsPerPage) : rows

  return (
    <OreTableContainer>
      <OreTable>
        {title ? <OreTableCaption title={title} /> : null}
        <TableHead tableHeaders={tableHeaders} />
        <OreTableBody>
          {slicedRows.map((row, index) => renderRow(row, index + offset))}
        </OreTableBody>
      </OreTable>
      {shouldShowPagination ? (
        <OreTableActions>
          <OrePagination
            displayPageButtons
            count={rows.length}
            labelNextPage={t(tableSelector.nextPageLabel)}
            labelPreviousPage={t(tableSelector.previousPageLabel)}
            onPageChange={handlePageChange}
            page={page}
            rowsPerPage={recordsPerPage}
            text={t(tableSelector.pageOf)}
            legend={t(tableSelector.rowsShowing)}
          />
        </OreTableActions>
      ) : null}
      {shouldShowNoRows ? (
        <OreTableActions>
          <OreText size="text-sm" tone="neutral-900">
            {noRows}
          </OreText>
        </OreTableActions>
      ) : null}
    </OreTableContainer>
  )
}
