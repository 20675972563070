import React from 'react'
import { UsersAndLicensesFormModel } from 'src/domain/models/myAccount/UsersAndLicenses'
import { OreText } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { accountUsersLicensesTranslations } from 'src/domain/translations/myAccount/accountUsersLicenses'
import { Grid } from 'src/presentation/components/Edenred/layout'

interface Props {
  userData: UsersAndLicensesFormModel
}

export const AccountUsersAndLicensesEditUserData = ({ userData }: Props): JSX.Element => {
  const { t } = useTranslation()
  const fullName =
    userData.name +
    ' ' +
    userData.surname +
    ' ' +
    (userData.surname2 ? userData.surname2 : '')
  return (
    <div className="mt-1">
      <div className="mb-05">
        <Grid gap="2rem" direction="row">
          <Grid direction="column">
            <OreText bold={true}>{t(accountUsersLicensesTranslations.edit.name)}</OreText>
            <OreText>{fullName}</OreText>
          </Grid>
          <Grid direction="column">
            <OreText bold={true}>{t(accountUsersLicensesTranslations.edit.mail)}</OreText>
            <OreText>{userData.email}</OreText>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
