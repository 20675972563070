import { useTranslation } from 'react-i18next'

import {
  OreButton,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
  OreText,
  SvgPlus,
} from '@edenredespana/oreneta'
import { useNavigate } from 'react-router-dom'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import { getCardOrderTypeLength } from '../../../../../core/services'

export const CardOrderHomeTableEmpty = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { orders, setFormMode } = useCardOrder()
  return (
    <>
      <OreTableContainer>
        <OreTable>
          <OreTableCaption
            title={t(cardOrderTranslation.cardOrderHome.tableTitle)}
            counter={`${getCardOrderTypeLength(orders)} ${t(
              cardOrderTranslation.cardOrderHome.tableCaption
            )}`}
          />
          <OreTableBody>
            <OreTableRow>
              <OreTableCell as="td">
                <div className="p-05">
                  <OreText as="p" size="text-sm" tone="neutral">
                    {t(cardOrderTranslation.cardOrderHome.tableBodyEmpty)}
                  </OreText>
                </div>
              </OreTableCell>
            </OreTableRow>
          </OreTableBody>
        </OreTable>
        <OreTableActions>
          <div className="flex flex--row float-right">
            <OreButton
              as="a"
              category="highlight"
              onClick={() => {
                setFormMode({ editMode: false })
                navigate(navigationRoutes.cardOrderNew)
              }}
              icon={<SvgPlus />}
              size="large">
              {t(cardOrderTranslation.cardOrderHome.addNewLink)}
            </OreButton>
            <OreButton
              as="a"
              category="highlight"
              onClick={() => {
                navigate(navigationRoutes.duplicatedCard)
              }}
              icon={<SvgPlus />}
              size="large">
              {t(cardOrderTranslation.cardOrderHome.addDuplicateLink)}
            </OreButton>
          </div>
        </OreTableActions>
      </OreTableContainer>
      <div className="mt-2"></div>
    </>
  )
}
