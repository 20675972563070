import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreTableCell,
  OreTableRow,
  OreText,
  OreStack,
  OreTooltip,
} from '@edenredespana/oreneta'
import { GetDateFormatted } from 'src/core/helpers'
import { EmployeesLastAdherenceModel } from 'src/Flex/Orders/domain/EmployeesLastAdherenceModel'
import { getProductIconByProductType } from 'src/Flex/Products/ui/shared'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { edenredProducts } from 'src/domain/enum'

interface IProps {
  employee: EmployeesLastAdherenceModel
}

export const FlexEmployeeLastAdherenceRow = ({ employee }: IProps): JSX.Element => {
  const {
    employeeName,
    accessionDate,
    employeeDocument,
    employeeFirstSurname,
    employeeSecondSurname,
    productsContracted,
  } = employee

  const { t } = useTranslation()

  return (
    <OreTableRow valign="middle">
      <OreTableCell size="auto">
        <OreStack space="2xsmall">
          <OreText size="text-sm" tone="neutral-800" bold={true}>
            {employeeName} {employeeFirstSurname} {employeeSecondSurname}
          </OreText>
          <OreText size="text-sm">{employeeDocument}</OreText>
        </OreStack>
      </OreTableCell>
      <OreTableCell>
        <OreStack>
          <OreText size="text-sm">{GetDateFormatted(accessionDate)}</OreText>
        </OreStack>
      </OreTableCell>
      <OreTableCell>
        <OreStack direction="row">
          {productsContracted.map((productId: edenredProducts) => {
            return (
              <OreTooltip
                key={productId}
                text={t(flexProductsTranslations.ids[productId])}
                placement="top"
                separation="small">
                {getProductIconByProductType(productId)}
              </OreTooltip>
            )
          })}
        </OreStack>
      </OreTableCell>
    </OreTableRow>
  )
}
