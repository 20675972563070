import {
  OreButton,
  OreDivider,
  OreStack,
  OreTable,
  OreTableBody,
  OreTableCaption,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
  OreText,
  SvgArrowWidget,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { orderValidationsTranslations } from '../../order-validation'
import { orderImputationsTranslations } from '../orderImputationsTranslations'
import { flexProductsTranslations } from 'src/Flex/Products/ui/translations'
import { useOrders } from '../contexts'
import { currency } from 'src/core/helpers'

export const OrderImputationStepTwo = (): JSX.Element => {
  const { t } = useTranslation()
  const { productType, summaryImputation, back, confirm } = useOrders()

  return (
    <>
      <OreStack space="xlarge" placeContent="stretch">
        <OreTableContainer>
          <OreTable>
            <OreTableCaption
              title={t(orderValidationsTranslations.step2.general.title)}
            />
            <OreTableBody>
              <OreTableRow>
                <OreTableCell>
                  {t(orderImputationsTranslations.stepTwo.label1) +
                    t(flexProductsTranslations.ids[productType])}
                </OreTableCell>
                <OreTableCell>
                  <OreText bold align="right" size="text-sm">
                    {summaryImputation?.imputationMonth}
                  </OreText>
                </OreTableCell>
              </OreTableRow>
              <OreTableRow>
                <OreTableCell>
                  {t(flexProductsTranslations.ids[productType]) +
                    t(orderImputationsTranslations.stepTwo.label2)}
                </OreTableCell>
                <OreTableCell>
                  <OreText bold align="right" size="text-sm">
                    {summaryImputation?.totalImputationsNumber}
                  </OreText>
                </OreTableCell>
              </OreTableRow>
              <OreTableRow>
                <OreTableCell>
                  {t(orderImputationsTranslations.stepTwo.label3) +
                    t(flexProductsTranslations.ids[productType])}
                </OreTableCell>
                <OreTableCell>
                  <OreText bold align="right" size="text-sm">
                    {currency(
                      summaryImputation?.totalImputationsAmount
                        ? summaryImputation?.totalImputationsAmount
                        : 0
                    )}
                  </OreText>
                </OreTableCell>
              </OreTableRow>
            </OreTableBody>
          </OreTable>
        </OreTableContainer>
      </OreStack>
      <OreDivider space="larger-top" />
      <OreStack direction="row" placeContent="space-between">
        <OreButton category="tertiary" icon={<SvgArrowWidget />} onClick={back}>
          {t(orderValidationsTranslations.step2.buttonBack)}
        </OreButton>
        <OreButton category="primary" onClick={confirm}>
          {t(orderValidationsTranslations.step2.buttonConfirm)}
        </OreButton>
      </OreStack>
    </>
  )
}
