import { OreHeading, OreInput, OreSelect } from '@edenredespana/oreneta'
import { cardOrderFormTranslation } from '../../../../../domain/translations'
import { RadioButton } from '../../../Edenred'
import { ComboBox } from '../../../Edenred/ComboBox'
import { useCardDetailController } from '../hooks'
import { ComboBoxOptionModel } from 'src/domain/customComponents'

export const CardDetail = (props: { title: string }): JSX.Element => {
  const {
    GetCardDetailsRadioButtons,
    spendingRulesOptions,
    errors,
    register,
    t,
    onChangeRadioCardType,
    radioCardTypeState,
    spendingRuleDisabled,
    disableEnterKey,
  } = useCardDetailController()

  return (
    <>
      <section className="form-molecule mt-3">
        <div className="pl-05">
          <OreHeading as="h2" size="headline-md">
            {props.title}
          </OreHeading>
        </div>

        <div className="form-atom">
          <RadioButton
            label={t(cardOrderFormTranslation.cardDetail.cardType)}
            name="cardData.cardTypeId"
            direction="row"
            required={true}
            register={register}
            errors={errors}
            radioButtons={GetCardDetailsRadioButtons()}
            onValueChange={onChangeRadioCardType}
            selectedValue={radioCardTypeState}
          />
        </div>

        <div className="form-atom">
          <OreSelect
            {...register('cardData.spendingRuleId')}
            required={true}
            id="spendingRuleId"
            name="cardData.spendingRuleId"
            label={t(cardOrderFormTranslation.cardDetail.spendingRules)}
            disabled={spendingRuleDisabled}
            hasError={!!errors?.cardData?.spendingRuleId}
            errorMessage={errors.cardData?.spendingRuleId?.message}>
            {spendingRulesOptions.map((option: ComboBoxOptionModel) =>
              option.options.map((opt: [string, number], index: number) => (
                <option key={'option_' + index} label={opt[0]} value={opt[1]} />
              ))
            )}
          </OreSelect>
        </div>
        <div className="form-atom">
          <OreInput
            {...register('cardData.initialAmount')}
            name="cardData.initialAmount"
            id="initialAmount"
            label={t(cardOrderFormTranslation.cardDetail.rechargeAmount)}
            placeholder={t(cardOrderFormTranslation.cardDetail.rechargeAmountPlaceholder)}
            legend={t(cardOrderFormTranslation.cardDetail.rechargeAmountFooter)}
            type="number"
            hasError={!!errors?.cardData?.initialAmount}
            errorMessage={errors.cardData?.initialAmount?.message}
            onKeyDown={disableEnterKey}
          />
        </div>
      </section>
    </>
  )
}
