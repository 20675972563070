import {
  OreButton,
  OreHeading,
  OreText,
  OreTooltip,
  SvgEdit,
  SvgInfo,
} from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParentProfileFormController } from '../hooks'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { Divider } from 'src/presentation/layout'
import { MessageBar, Textbox } from 'src/presentation/components/Edenred'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { getUndefinedDashString } from 'src/core/services'
import { forms } from 'src/domain/translations'
import { EmployeesNurseryModel } from 'src/domain/models'

interface EmployeeProfileParentFormProps {
  employeeData: EmployeesNurseryModel
}

export const EmployeeProfileParentForm = ({
  employeeData,
}: EmployeeProfileParentFormProps): JSX.Element => {
  const {
    register,
    errors,
    GetEmployeeDataTextBoxes,
    updateEmployee,
    handleSubmit,
    enableEdit,
    handleEnableEdit,
    errorMessage,
  } = useParentProfileFormController(employeeData)
  const { t } = useTranslation()

  if (!employeeData) {
    return <></>
  }

  return (
    <div className="pt-1">
      <OreHeading size="headline-md">
        {t(employeesTranslation.profile.form.title)}
      </OreHeading>
      <Divider marginTop="1rem" marginBottom="2rem" />
      <div className="mb-105">
        <Textbox
          errors={errors}
          register={register}
          textBoxList={GetEmployeeDataTextBoxes()}
        />
      </div>

      <Grid gap="1rem" className="mb-2">
        <OreText bold={true}>{t(employeesTranslation.profile.form.costCenter)}</OreText>
        <Grid>
          <OreText>{getUndefinedDashString(employeeData.costCenter)}</OreText>
          <OreTooltip
            text={t(employeesTranslation.profile.form.costCenterTooltipText)}
            placement="right">
            <div className="ml-05">
              <SvgInfo aria-label="info" />
            </div>
          </OreTooltip>
        </Grid>
      </Grid>

      <MessageBar color="error" message={errorMessage} />

      {enableEdit ? (
        <OreButton category="primary" onClick={handleSubmit(updateEmployee)}>
          {t(forms.buttons.save)}
        </OreButton>
      ) : (
        <OreButton icon={<SvgEdit />} onClick={handleEnableEdit}>
          {t(forms.buttons.editData)}
        </OreButton>
      )}
    </div>
  )
}
