import { OreHeading } from '@edenredespana/oreneta'
import type { Dispatch, SetStateAction } from 'react'
import { RadioButtonBox } from 'src/presentation/components/Edenred/RadioButtonBox'
import type { FormProps } from '../../../../../domain/forms/FormProps'
import type { DeliverySiteModel } from '../../../../../domain/models'
import { useShipmentController } from '../hooks'

interface ShipmentProps extends FormProps<DeliverySiteModel> {
  setRequiredPhone: Dispatch<SetStateAction<boolean>>
}

export const Shipment = (props: ShipmentProps): JSX.Element => {
  const {
    t,
    errors,
    register,
    shipmentRadioButtons,
    radioDeliveryTypeValue,
    onChangeRadioShipmentType,
  } = useShipmentController(props.formParameters, props.setRequiredPhone)
  return (
    <section className="form-molecule mt-1">
      <div className="pl-05">
        <OreHeading as="h2" size="headline-md">
          {props.title}
        </OreHeading>
      </div>

      <div className="form-atom mt-1">
        <RadioButtonBox
          label=""
          name="deliveryType"
          direction="column"
          required={false}
          errors={errors}
          register={register}
          radioButtons={shipmentRadioButtons}
          selectedValue={radioDeliveryTypeValue}
          onValueChange={onChangeRadioShipmentType}
        />
      </div>
    </section>
  )
}
