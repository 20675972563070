import { OreNavBarSection } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { useUI } from '../../../../../context/ui/UIProvider'
import { SidebarLink } from '../atoms'
import type { SidebarItemModel } from '../Sidebar'
import SidebarParentLink from './SidebarParentLink'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'
import { Fragment, useState } from 'react'

interface SidebarSectionProps {
  title?: string
  hover: boolean
  items: SidebarItemModel[]
  isFlex?: boolean
  protectedBy?: string
}

const SidebarSection = (props: SidebarSectionProps): JSX.Element => {
  const [currentParentOpen, setCurrentParentOpen] = useState<number>(0)
  const [toggleParent, setToggleParent] = useState<boolean>(true)
  const { t } = useTranslation()
  const { sidebarCollapsed } = useUI()
  const { permissionTags } = useAuth()

  return (
    <OreNavBarSection
      title={t(props.title || '')}
      collapse={sidebarCollapsed}
      hover={props.hover}>
      {props.items.map((sectionItem: SidebarItemModel, index: number): JSX.Element => {
        const { protectedBy } = sectionItem

        if (protectedBy && !permissionTags.includes(protectedBy)) {
          return <Fragment key={index}></Fragment>
        }

        if (sectionItem.childs) {
          return (
            <SidebarParentLink
              title={t(sectionItem.title || '')}
              icon={sectionItem.icon || <></>}
              hover={props.hover}
              childs={sectionItem.childs}
              key={index}
              isFlex={props.isFlex}
              index={index}
              currentParentOpen={currentParentOpen}
              setCurrentParentOpen={setCurrentParentOpen}
              toggleParent={toggleParent}
              setToggleParent={setToggleParent}
            />
          )
        }

        return (
          <SidebarLink
            title={t(sectionItem.title || '')}
            icon={sectionItem.icon || <></>}
            link={sectionItem.link}
            hover={props.hover}
            key={index}
            isFlex={props.isFlex}
            activeLinks={sectionItem.activeLinks}
          />
        )
      })}
    </OreNavBarSection>
  )
}

export default SidebarSection
