import React from 'react'
import { useTTOrderExcelSummaryController } from '../hooks/useTTOrderExcelSummaryController'
import { Navigate, useLocation } from 'react-router-dom'
import {
  TTOrderExcelSummaryButtons,
  TTOrderExcelSummaryForm,
  TTOrderExcelSummaryTable,
} from '../molecules'
import { Modal } from 'src/presentation/components/Edenred'
import { OreHeading, OreText } from '@edenredespana/oreneta'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { useTranslation } from 'react-i18next'
import { useTTOrder } from '../../../context/TTOrderProvider'
import { orderType } from 'src/domain/enum'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

export const TTOrderExcelSummaryContainer = (): JSX.Element => {
  const location = useLocation()
  const data: any = location.state
  const { ttOrderCheckout, orderContext } = useTTOrder()
  const props = useTTOrderExcelSummaryController()
  const { t } = useTranslation()
  const {
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
    getCardOrderResumeData,
    getRechargeResumeData,
    getUnloadResumeData,
  } = props

  if (!ttOrderCheckout.data) {
    return <Navigate to={navigationRoutes.ttNewOrderExcel} />
  }

  return (
    <>
      {ttOrderCheckout.data.card.services.length > 0 && (
        <TTOrderExcelSummaryTable
          title={t(TTOrderTranslation.excel.summary.table.cardsSummary)}
          resumeData={getCardOrderResumeData(
            ttOrderCheckout.data.abstractCardOrder.resumeData
          )}
        />
      )}
      {ttOrderCheckout.data.charge.services.length > 0 &&
        (orderContext === orderType.Card || orderContext === orderType.Recharge) && (
          <TTOrderExcelSummaryTable
            title={t(TTOrderTranslation.excel.summary.table.rechargesSummary)}
            resumeData={getRechargeResumeData(
              ttOrderCheckout.data.abstractCardOrder.resumeData
            )}
          />
        )}
      {ttOrderCheckout.data.charge.services.length > 0 &&
        orderContext === orderType.Unload && (
          <TTOrderExcelSummaryTable
            title={t(TTOrderTranslation.excel.summary.table.unloadsSummary)}
            resumeData={getUnloadResumeData(
              ttOrderCheckout.data.abstractCardOrder.resumeData
            )}
          />
        )}
      <TTOrderExcelSummaryForm ttProps={props} />
      <TTOrderExcelSummaryButtons ttProps={props} />
      <Modal
        handleOnClose={setShowCancelModal}
        open={showCancelModal}
        buttons={cancelPopupButtons}
        closeIcon={false}>
        <>
          <OreHeading size="headline-lg">{cancelModalTitle}</OreHeading>
          <OreText align="left" as="p" size="text-md" tone="neutral">
            {cancelModalDescription}
          </OreText>
        </>
      </Modal>
    </>
  )
}
