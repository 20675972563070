import { OreDatePicker } from '@edenredespana/oreneta'
import type { Dispatch, SetStateAction } from 'react'
import type {
  FilterControlModel,
  FilterModel,
} from '../../../../../domain/customComponents/table'
import { useFilterDatePicker } from '../../hooks/table'
import { getUndefinedEmptyString } from 'src/core/services'

export const FilterDatePicker = (props: {
  datePicker: FilterControlModel
  setFilter: Dispatch<SetStateAction<FilterModel | undefined>>
}): JSX.Element => {
  const { onChange } = useFilterDatePicker(props.setFilter)
  return (
    <OreDatePicker
      name={props.datePicker.name}
      label={props.datePicker.label ? props.datePicker.label : ''}
      placeholder={props.datePicker.placeHolder}
      min={props.datePicker.min}
      max={props.datePicker.max}
      value={props.datePicker.value}
      hasError={props.datePicker.hasError}
      errorMessage={props.datePicker.errorMassage}
      onChange={event => {
        const defaultValue = getUndefinedEmptyString(
          props.datePicker.defaultValue?.toString()
        )
        const min = getUndefinedEmptyString(props.datePicker.min?.toString())
        const max = getUndefinedEmptyString(props.datePicker.max?.toString())
        onChange(event, props.datePicker.name, defaultValue, min, max)
      }}
    />
  )
}
