import {
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
} from '@edenredespana/oreneta'
import cx from 'classnames'
import { Header } from '../molecules/Header'
import { Rows } from '../molecules/Rows'
import { Footer } from '../molecules/Footer'
import { Caption } from '../molecules/Caption'
import type {
  CaptionModel,
  HeaderModel,
  RowModel,
  SelectorModel,
} from '../../../../../domain/customComponents/table/TableModel'
import type { Dispatch, SetStateAction } from 'react'
import { memo } from 'react'
import type {
  FilterModel,
  FooterModel,
  TableEmptyModel,
} from '../../../../../domain/customComponents/table'
import TableEmpty from '../molecules/TableEmpty'
import { Filter } from './Filter'
import { Selector } from './Selector'
import { useNewTable } from '../../hooks/table'
import { Modal } from '../../Modal'

interface TableValues {
  multiSelected?: boolean
  rows: RowModel[]
  header?: HeaderModel
  selector?: SelectorModel
  children?: JSX.Element
  caption?: CaptionModel
  footer?: FooterModel
  tableBodyValign?: 'top' | 'bottom' | 'baseline' | 'middle'
  columnsEven?: boolean
  className?: string
  setRows: Dispatch<SetStateAction<RowModel[]>>
  validateTable?: boolean
  confirmDelete?: boolean
  confirmDeleteTitle?: string
  confirmDeleteDescription?: string
  isDeleteDanger?: boolean
  tableEmpty?: TableEmptyModel
  filter?: FilterModel
  setFilter?: Dispatch<SetStateAction<FilterModel>>
  onClickFilter?: () => void
}

export const Table = memo((props: TableValues): JSX.Element => {
  const {
    rows,
    selectedRows,
    totalRows,
    setSelectedRows,
    setTotalRows,
    setRows,
    modalTitle,
    modalDescription,
    setModalShow,
    modalShow,
    modalButtons,
    deleteConfirm,
    setDeleteConfirm,
    isLoading,
    FilterRows,
  } = useNewTable(
    props.rows,
    props.setRows,
    props.validateTable,
    props.confirmDeleteTitle,
    props.confirmDeleteDescription,
    props.filter,
    props.isDeleteDanger
  )
  return (
    <>
      <OreTableContainer>
        {props.filter && props.setFilter && props.onClickFilter && (
          <Filter
            filterTable={{
              filter: props.filter,
              setFilter: props.setFilter,
              onClick: props.onClickFilter,
            }}
          />
        )}
        <OreTable
          className={cx(
            'ore-table',
            props.className,
            { 'table-columns-even': props.columnsEven },
            { 'has-selector': props.columnsEven && props.multiSelected }
          )}>
          {props.caption && <Caption caption={props.caption} totalRows={totalRows} />}
          {!props.header?.hideWhenEmpty && rows.length > 0 && (
            <OreTableHead valign="middle">
              <OreTableRow>
                <Header
                  header={props.header}
                  multiSelected={props.multiSelected}
                  selectedRows={selectedRows}
                  totalRows={totalRows}
                  rows={rows}
                  setSelectedRows={setSelectedRows}
                  setRows={setRows}
                />
              </OreTableRow>
            </OreTableHead>
          )}
          <OreTableBody valign={props.tableBodyValign || 'top'}>
            {rows.length === 0 && props.tableEmpty && !isLoading ? (
              <TableEmpty
                colSpan={props.tableEmpty.colSpan}
                firstText={props.tableEmpty.firstText}
                linkText={props.tableEmpty.linkText}
                linkUrl={props.tableEmpty.linkUrl}
                endText={props.tableEmpty.endText}
              />
            ) : (
              <Rows
                multiSelected={props.multiSelected}
                rows={FilterRows()}
                selectedRows={selectedRows}
                setRows={setRows}
                setSelectedRows={setSelectedRows}
                setTotalRows={setTotalRows}
                modalShow={modalShow}
                setModalShow={setModalShow}
                deleteConfirm={deleteConfirm}
                setDeleteConfirm={setDeleteConfirm}
                confirmDelete={props.confirmDelete}
                pageSize={props.footer?.pagination?.rowsPerPage}
                page={props.footer?.pagination?.page}
                isServerSide={props.footer?.pagination?.isServerSide}
              />
            )}
          </OreTableBody>
        </OreTable>
        {props.footer && (
          <OreTableActions>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}>
              <Footer footer={props.footer} rows={FilterRows()} />
            </div>
          </OreTableActions>
        )}
      </OreTableContainer>
      {props.children}
      {props.selector && rows.length > 1 && selectedRows.length > 1 && (
        <Selector
          selectedRows={selectedRows}
          selector={props.selector}
          rows={rows}
          setRows={setRows}
        />
      )}
      <Modal
        title={modalTitle}
        description={modalDescription}
        handleOnClose={setModalShow}
        open={modalShow}
        buttons={modalButtons}
      />
    </>
  )
})
