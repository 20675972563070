import {
  OreCheckbox,
  OreInput,
  OreSelect,
  OreTableCell,
  OreTableRow,
  OreText,
} from '@edenredespana/oreneta'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { cardRequestTypeId } from 'src/domain/enum'
import { duplicatesConfigurationTranslations, forms } from 'src/domain/translations'
import { UseFormReturn } from 'react-hook-form'
import { EmployeesTTOrderFormModel } from '../hooks/useTTOrderConfigurationEmployees'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { DeliverySiteModel } from 'src/domain/models'
import { ComboBoxOptionModel } from 'src/domain/customComponents'
import { useParams } from 'react-router-dom'

interface Props {
  employeeTT: TTOrderModel
  onSelectEmployee(event: ChangeEvent<HTMLInputElement>, employee: TTOrderModel): void
  isSelected(employee: TTOrderModel): boolean
  form: UseFormReturn<EmployeesTTOrderFormModel, any>
  index: number
  updateDataRowToTTOrderEmployee: (
    userId: number,
    email?: string | undefined,
    reason?: number | undefined,
    deliverySiteId?: number | undefined,
    deliverySite?: DeliverySiteModel | undefined
  ) => void
  deliverySitesOptions: ComboBoxOptionModel[]
  deliverySites: DeliverySiteModel[]
}

export const TTOrderConfigurationEmployeesRow = ({
  employeeTT,
  updateDataRowToTTOrderEmployee,
  onSelectEmployee,
  isSelected,
  form,
  index,
  deliverySitesOptions,
  deliverySites,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { id: isEdit } = useParams()

  const [email, setEmail] = useState<string>(
    employeeTT.corporativeEmail ? employeeTT.corporativeEmail : ''
  )
  const [reason, setReason] = useState<number>(
    employeeTT.cardData.cardRequestTypeId ? employeeTT.cardData.cardRequestTypeId : 0
  )
  const [deliverySiteId, setDeliverySiteId] = useState<number>(
    employeeTT.deliverySiteId ? employeeTT.deliverySiteId : 0
  )
  const [deliverySite, setDeliverySite] = useState<DeliverySiteModel>(
    employeeTT.deliverySiteData ? employeeTT.deliverySiteData : ({} as DeliverySiteModel)
  )

  const setEmailValue = (email: string) => {
    setEmail(email)
  }
  const setReasonValue = (reason: number) => {
    setReason(reason)
  }
  const setDeliverySiteValue = (
    deliverySiteId: number,
    deliverySite: DeliverySiteModel
  ) => {
    setDeliverySiteId(deliverySiteId)
    setDeliverySite(deliverySite)
  }

  const onDeliverySiteChange = (event: ChangeEvent<HTMLSelectElement>) => {
    const deliverySite = deliverySites.find(
      (ds: DeliverySiteModel) => ds.deliveryPointId === +event.target.value
    )
    if (deliverySite) {
      setDeliverySiteValue(+event.target.value, deliverySite)
      form.setValue(`employeesTT.${index}.deliverySiteId`, +event.target.value)
    }
  }

  const onReasonChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setReasonValue(+event.target.value)
  }

  useEffect(() => {
    updateDataRowToTTOrderEmployee(
      employeeTT.userId,
      email,
      reason,
      deliverySiteId,
      deliverySite
    )
  }, [email, reason, deliverySiteId])

  useEffect(() => {
    if (deliverySites) {
      form.setValue(`employeesTT.${index}.deliverySiteId`, employeeTT.deliverySiteId)
      const deliverySite = deliverySites.find(
        (ds: DeliverySiteModel) => ds.deliveryPointId === employeeTT.deliverySiteId
      )
      setDeliverySiteValue(
        employeeTT.deliverySiteId,
        deliverySite ? deliverySite : ({} as DeliverySiteModel)
      )
    }
  }, [deliverySites])

  useEffect(() => {
    form.setValue(`employeesTT.${index}.cardData.cardRequestTypeId`, 0)
  }, [])

  return (
    <>
      <OreTableRow valign="top">
        {!isEdit && (
          <OreTableCell align="left" as="td" size="auto">
            <OreCheckbox
              name="check"
              onChange={event => onSelectEmployee(event, employeeTT)}
              role="checkbox"
              aria-checked={isSelected(employeeTT)}
              checked={isSelected(employeeTT)}
            />
          </OreTableCell>
        )}
        <OreTableCell align="left" as="td" size="auto">
          <OreText bold size="text-sm" tone="neutral-900">
            {employeeTT.name}
          </OreText>
          <OreText size="text-sm" tone="neutral-500">
            {employeeTT.document}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreInput
            {...form.register(`employeesTT.${index}.corporativeEmail`)}
            placeholder={t(forms.placeholders.corporativeEmail)}
            required
            type="text"
            autoComplete="off"
            hasError={!!form.formState.errors?.employeesTT?.[index]?.corporativeEmail}
            errorMessage={
              form.formState.errors?.employeesTT?.[index]?.corporativeEmail?.message
            }
            value={employeeTT.corporativeEmail}
            onChange={e => setEmailValue(e.target.value)}
          />
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreSelect
            {...form.register(`employeesTT.${index}.cardData.cardRequestTypeId`)}
            required={true}
            hasError={
              !!form.formState.errors?.employeesTT?.[index]?.cardData?.cardRequestTypeId
            }
            errorMessage={
              form.formState.errors?.employeesTT?.[index]?.cardData?.cardRequestTypeId
                ?.message
            }
            onInput={onReasonChange}
            value={employeeTT.cardData.cardRequestTypeId}>
            <option label={t(forms.placeholders.select)} value={0} />
            ,
            <option
              label={t(
                duplicatesConfigurationTranslations.table.comboBoxFields.reson.optionOne
              )}
              value={cardRequestTypeId.lostStolenPlastic}
            />
            ,
            <option
              label={t(
                duplicatesConfigurationTranslations.table.comboBoxFields.reson.optionTwo
              )}
              value={cardRequestTypeId.damagePlastic}
            />
          </OreSelect>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreSelect
            {...form.register(`employeesTT.${index}.deliverySiteId`)}
            required={true}
            onInput={onDeliverySiteChange}
            hasError={!!form.formState.errors.employeesTT?.[index]?.deliverySiteId}
            errorMessage={
              form.formState.errors.employeesTT?.[index]?.deliverySiteId?.message
            }
            value={employeeTT.deliverySiteId}>
            {deliverySitesOptions.map((option: ComboBoxOptionModel, index: number) => (
              <optgroup
                key={'optgroup_' + index}
                label={t(option.groupName ? option.groupName : '')}>
                {option.options.map((opt: [string, number], index: number) => (
                  <option key={'option_' + index} label={opt[0]} value={opt[1]} />
                ))}
              </optgroup>
            ))}
          </OreSelect>
        </OreTableCell>
      </OreTableRow>
    </>
  )
}
