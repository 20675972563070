import { SvgArrowWidget } from '@edenredespana/oreneta'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import type { FooterModel } from '../../../../../domain/customComponents/table'
import { myOrdersTabs } from '../../../../../domain/enum'
import { orderHomeTranslation } from '../../../../../domain/translations/cardOrder/home'

interface ContainerState {
  footer: FooterModel
}

export const useIncompleteOrderClientHome = (): ContainerState => {
  const navigate = useNavigate()

  const footer: FooterModel = {
    actionButtons: [
      {
        icon: <SvgArrowWidget style={{ transform: 'rotate(180deg)' }} />,
        text: t(orderHomeTranslation.incompleteTable.action),
        onClick: () =>
          navigate(navigationRoutes.myOrdersHistory, {
            state: {
              navigateValue: myOrdersTabs.incompleteOrders,
            },
          }),
        category: 'highlight',
        size: 'large',
      },
    ],
  }

  return { footer }
}
