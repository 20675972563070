import { OreNavBarUser, SvgLogout } from '@edenredespana/oreneta'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { getAcronym } from '../../../../../../core/services'
import { modals, navigationTranslation } from '../../../../../../domain/translations'
import { useAuth } from '../../../../../context/auth/AuthProvider'
import { NavbarUserListItem } from '../atoms'
import { useHeaderNavigationData } from '../../data'
import { useCardOrder } from 'src/presentation/context/cardOrder/CardOrderProvider'
import { loginSiteUrl } from 'src/config/constants/endpoints'

export interface NavbarUserListItemModel {
  title: string
  icon?: JSX.Element
  link?: string
  handleClick?: () => void
  size?: 'text-sm' | 'text-xs'
  divider?: boolean
  protectedBy?: string
}

const NavbarUser = (): JSX.Element => {
  const [openUser, setOpenUser] = useState(false)
  const { startLoading, stopLoading } = useLoader()
  const { user, logOut } = useAuth()
  const { t } = useTranslation()
  const { navbarUserMenu } = useHeaderNavigationData()
  const { orders, orderExcel, setLeaveOrderEnabled } = useCardOrder()
  const { permissionTags } = useAuth()

  const handleLogout = async (): Promise<void> => {
    startLoading()
    // if order process on going?
    if (orders.length > 0 || orderExcel.abstractCardOrder.resumeData.length > 0) {
      if (window.confirm(t(modals.leaveOrder.title))) {
        startLoading()
        setLeaveOrderEnabled(false)
        await logOut()
        window.location.href = loginSiteUrl
      }
    } else {
      await logOut()
      window.location.href = loginSiteUrl
    }
    stopLoading()
  }

  return (
    <div
      onMouseEnter={() => setOpenUser(true)}
      onMouseLeave={() => setOpenUser(false)}
      style={{ padding: '15px 0' }}>
      <OreNavBarUser
        userName={t(navigationTranslation.header.user.userName)}
        userPlaceholderText={getAcronym(user?.name)}
        open={openUser}>
        {navbarUserMenu.map(
          (listItem: NavbarUserListItemModel, index: number): JSX.Element => {
            const { protectedBy } = listItem

            if (protectedBy && !permissionTags.includes(protectedBy)) {
              return <Fragment key={index}></Fragment>
            }

            return (
              <NavbarUserListItem
                title={listItem.title}
                icon={listItem.icon}
                size={listItem.size}
                divider={listItem.divider}
                link={listItem.link}
                onClick={listItem.handleClick}
                key={index}
              />
            )
          }
        )}
        <NavbarUserListItem
          title={navigationTranslation.header.user.menu.logout}
          icon={<SvgLogout />}
          size="text-xs"
          divider={true}
          onClick={handleLogout}
        />
      </OreNavBarUser>
    </div>
  )
}

export default NavbarUser
