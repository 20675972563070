import { OreButton, OreDivider, OreStack, SvgSave } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { forms } from 'src/domain/translations'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { useTGDOrder } from '../../context/TGDOrderProvider'
import { TGDOrderSummaryState } from '../hooks/useTGDOrderSummaryContainer'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'

interface Props {
  tgdProps: TGDOrderSummaryState
  isExcel: boolean
}

export const TGDOrderSummaryButtons = ({ tgdProps, isExcel }: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { tgdOrders, tgdOrderCheckout, addTDGOrderCheckout } = useTGDOrder()
  const { form, onClickCancel, onCheckoutOrder, setShowSaveCloseModal } = tgdProps
  const onCheckoutExcelOrder = () => {
    if (!tgdOrderCheckout.data) return
    addTDGOrderCheckout({
      ...tgdOrderCheckout,
      data: {
        ...tgdOrderCheckout.data,
        detail: {
          ...tgdOrderCheckout.data.detail,
          initialChargeDate: form.getValues('initialChargeDate'),
          billReference: form.getValues('billReference'),
        },
      },
    })
    navigate(navigationRoutes.tgdOrderCheckout, {
      state: {
        isExcel: isExcel,
      },
    })
  }

  return (
    <div style={{ width: '100%' }}>
      <OreDivider space="medium" />

      <OreStack direction="row" placeContent="space-between" placeItems="normal">
        {!isExcel && tgdOrders && tgdOrders.length > 0 ? (
          <OreButton
            category="tertiary"
            icon={<SvgSave />}
            onClick={() => setShowSaveCloseModal(true)}>
            {t(forms.buttons.saveAndClose)}
          </OreButton>
        ) : (
          <div></div>
        )}
        <OreStack direction="row" space="medium">
          <OreButton onClick={() => onClickCancel()}>{t(forms.buttons.cancel)}</OreButton>
          {!isExcel ? (
            <OreButton
              category="primary"
              onClick={form.handleSubmit(onCheckoutOrder)}
              disabled={tgdOrders && tgdOrders.length <= 0}>
              {t(TGDOrderTranslation.form.checkoutButton)}
            </OreButton>
          ) : (
            <OreButton
              category="primary"
              onClick={form.handleSubmit(onCheckoutExcelOrder)}
              disabled={!form.formState.isValid}>
              {t(TGDOrderTranslation.form.checkoutButton)}
            </OreButton>
          )}
        </OreStack>
      </OreStack>
    </div>
  )
}
