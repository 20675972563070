import { OreTab, OreTabs } from '@edenredespana/oreneta'
import { t } from 'i18next'
import { invoicesTabs } from '../../../../domain/enum'
import { useContainerController } from '../hooks/useContainerController'
import { invoiceTabLabels } from '../../../../domain/constants/InvoiceTabLabels'
import { InvoicesContainer } from '../invoices/organism/InvoicesContainer'
import { CreditNotesContainer } from '../creditNotes/organism/CreditNotesContainer'

interface ContainerProps {
  navigateValue?: invoicesTabs
}

export const Container = (props: ContainerProps): JSX.Element => {
  const { handleTabClick, selectedTab } = useContainerController(props.navigateValue)
  return (
    <>
      <OreTabs>
        {invoiceTabLabels.map((label: string, index: number) => {
          return (
            <OreTab
              key={'tab_' + index}
              handleClick={() => handleTabClick(index)}
              active={selectedTab === index && true}
              label={t(label)}
            />
          )
        })}
      </OreTabs>
      {selectedTab === invoicesTabs.invoices && <InvoicesContainer />}
      {selectedTab === invoicesTabs.creditNotes && <CreditNotesContainer />}
    </>
  )
}
