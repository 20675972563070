import { OreButton, OreHeading, OreStack, OreText } from '@edenredespana/oreneta'
import { orderImputationsTranslations } from '../orderImputationsTranslations'
import { useTranslation } from 'react-i18next'
export const ForceUpdateInputation = (props: {
  refresh: () => Promise<void>
  date: string
}): JSX.Element => {
  const { t } = useTranslation()
  return (
    <OreStack>
      <OreHeading size="headline-md">
        {t(orderImputationsTranslations.forceUpdate.title)}
      </OreHeading>
      <OreText>{t(orderImputationsTranslations.forceUpdate.description)}</OreText>
      {props.date && (
        <OreText tone="neutral-400">
          {t(orderImputationsTranslations.forceUpdate.updateDate)} {props.date}
        </OreText>
      )}
      <OreStack placeContent="start">
        <OreButton category="primary" onClick={props.refresh}>
          {t(orderImputationsTranslations.forceUpdate.button)}
        </OreButton>
      </OreStack>
    </OreStack>
  )
}
