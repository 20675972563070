import type { FieldValues } from 'react-hook-form'
import { OreSelect } from '@edenredespana/oreneta'
import { Seq } from 'immutable'
import { get } from 'lodash'
import type { ComboBoxModel, ComboBoxOptionModel } from '../../../domain/customComponents'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

export const ComboBox = <TFormValues extends FieldValues>(
  props: ComboBoxModel<TFormValues>
): JSX.Element => {
  const errorMessages = get(props.errors, props.name)
  const hasError = props.errors && errorMessages
  const { t } = useTranslation()
  return (
    <Fragment key={'comboBox'}>
      <OreSelect
        {...(props.register && props.register(props.name))}
        name={props.name}
        id={props.id}
        label={props.label}
        required={props.required}
        hasError={hasError}
        errorMessage={errorMessages?.message}
        disabled={props.disabled}
        defaultValue={props.defaultValue}
        onChange={props.onChange}
        legend={props.legend}>
        {Seq(props.options).map((option: ComboBoxOptionModel, index: number) =>
          props.group ? (
            <optgroup
              key={'optgroup_' + index}
              label={t(option.groupName ? option.groupName : '')}>
              {Seq(option.options).map((opt: [string, number], index: number) => (
                <option key={'option_' + index} label={opt[0]} value={opt[1]} />
              ))}
            </optgroup>
          ) : (
            Seq(option.options).map((opt: [string, number], index: number) => (
              <option key={'option_' + index} label={opt[0]} value={opt[1]} />
            ))
          )
        )}
      </OreSelect>
    </Fragment>
  )
}

//     {
//         Seq(props.options)
//         .map((option: [string, number], index: number) =>(
//                 <option
//                     key={"option_" + index}
//                     label={option[0]}
//                     value={option[1]}
//                 />
//         ))
//     }
// </OreSelect>
// </Fragment>
