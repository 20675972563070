import {
  createCardOrderNewDuplicated,
  createCardOrderRecharge,
  createCardOrderUnload,
  createOrderExcel,
  downloadTTExcel,
  saveCardOrder,
  validateCardOrder,
} from 'src/config/constants/endpoints'
import { SetTTRequestCheckoutBody } from 'src/domain/adapters/ttOrderAdapter'
import { CardOrderRequestBody } from 'src/domain/bodyRequest/CardOrderRequestBody'
import { LoadExcelOrder } from 'src/domain/bodyRequest/ExcelOrders'
import {
  CardOrderResumeDTO,
  CardOrderValidateDTO,
  SaveCardOrderValidateDTO,
} from 'src/domain/dto'
import { edenredProducts, excelRequestType, orderType } from 'src/domain/enum'
import { HttpModel } from 'src/domain/models'
import { TGDOrderCompleteDTO } from 'src/domain/models/TGDOrder/TGDOrderModel'
import { TTOrderModel, TTOrderSummaryForm } from 'src/domain/models/TTOrder/TTOrderModel'
import { HttpMethod, HttpRequest, SaveCardOrderProtocol } from 'src/domain/protocols'
import { axiosFetch } from 'src/infrastructure/axios'

export class TTOrderRepository {
  async validate(
    TTCardOrderValidateProtocol: CardOrderRequestBody
  ): Promise<CardOrderValidateDTO> {
    const { url, method, body }: HttpRequest = {
      url: validateCardOrder,
      method: HttpMethod.POST,
      body: TTCardOrderValidateProtocol,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }
  async checkout(
    endpointUrl: string,
    summary: TTOrderSummaryForm,
    ttOrders: TTOrderModel[]
  ): Promise<CardOrderResumeDTO> {
    const { url, method, body }: HttpRequest = {
      url: endpointUrl,
      method: HttpMethod.POST,
      body: SetTTRequestCheckoutBody(summary, ttOrders),
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async saveTTOrder(
    saveTTOrderProtocol: SaveCardOrderProtocol
  ): Promise<SaveCardOrderValidateDTO> {
    const { url, method, body }: HttpRequest = {
      url: saveCardOrder,
      method: HttpMethod.POST,
      body: saveTTOrderProtocol,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async complete(
    summary: TTOrderSummaryForm,
    ttOrders: TTOrderModel[]
  ): Promise<TGDOrderCompleteDTO> {
    let newUrl = ''
    switch (summary.orderTypeId) {
      case orderType.Card:
        newUrl = createCardOrderNewDuplicated
        break
      case orderType.Recharge:
        newUrl = createCardOrderRecharge
        break
      case orderType.Unload:
        newUrl = createCardOrderUnload
        break
      default:
        newUrl = createCardOrderNewDuplicated
        break
    }
    const { url, method, body }: HttpRequest = {
      url: newUrl,
      method: HttpMethod.POST,
      body: SetTTRequestCheckoutBody(summary, ttOrders),
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async downloadTTExcelTemplate(
    orderContext: number,
    empty: boolean
  ): Promise<HttpModel<string>> {
    let excelType = orderType.Card
    switch (orderContext) {
      case orderType.Card:
        excelType = excelRequestType.TTOrderCard
        break
      case orderType.Recharge:
        excelType = excelRequestType.Recharge
        break
      case orderType.Unload:
        excelType = excelRequestType.Unload
        break
    }
    const { url, method, body }: HttpRequest = {
      url: downloadTTExcel,
      method: HttpMethod.POST,
      body: {
        orderExcelType: excelType,
        productType: edenredProducts.ticketTransporte,
        orderId: 0,
        empty: empty,
      },
    }
    const response = await axiosFetch(url, method, body)
    return response
  }

  async completeExcelOrder(loadExcelOrder: LoadExcelOrder): Promise<HttpModel<any>> {
    const { url, method, body }: HttpRequest = {
      url: createOrderExcel,
      method: HttpMethod.POST,
      body: loadExcelOrder,
    }
    const response = await axiosFetch(url, method, body)
    return response
  }
}
