import {
  OreIconButton,
  OrePill,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
  SvgBin,
  SvgEdit,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { UsersAndLicensesGridModel } from 'src/domain/models/myAccount/UsersAndLicenses'
import { OrePillTones } from 'src/domain/runrom/IOrePillTones'
import { accountUsersLicensesTranslations } from 'src/domain/translations/myAccount/accountUsersLicenses'
import { Grid } from 'src/presentation/components/Edenred/layout'

interface Props {
  userAndLicense: UsersAndLicensesGridModel
  onClickDelete(userName: string): void
  onClickEdit(userModel: UsersAndLicensesGridModel): void
}

export const AccountUsersAndLicensesRow = ({
  userAndLicense,
  onClickDelete,
  onClickEdit,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const fullName =
    userAndLicense.name +
    ' ' +
    userAndLicense.surname +
    ' ' +
    (userAndLicense.surname2 ? userAndLicense.surname2 : '')
  return (
    <OreTableRow valign="middle">
      <OreTableCell align="left" as="td" size="auto">
        <OreText bold size="text-sm" tone="neutral-900">
          {fullName}
        </OreText>
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {userAndLicense.email}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {userAndLicense.enabled ? (
          <OrePill tone={OrePillTones.success}>
            {t(accountUsersLicensesTranslations.add.statusActive)}
          </OrePill>
        ) : (
          <OrePill tone={OrePillTones.warning}>
            {t(accountUsersLicensesTranslations.add.statusPending)}
          </OrePill>
        )}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        {userAndLicense.rolName}
      </OreTableCell>
      <OreTableCell align="left" as="td" size="auto">
        <Grid gap=".5rem">
          <OreTooltip
            text={t(accountUsersLicensesTranslations.table.actions.deleteTooltip)}
            placement="top"
            separation="small">
            <OreIconButton
              onClick={() => onClickDelete(userAndLicense.userName)}
              icon={<SvgBin />}
            />
          </OreTooltip>
          <OreTooltip
            text={t(accountUsersLicensesTranslations.table.actions.editTooltip)}
            placement="top"
            separation="small">
            <OreIconButton
              onClick={() => onClickEdit(userAndLicense)}
              icon={<SvgEdit />}
            />
          </OreTooltip>
        </Grid>
      </OreTableCell>
    </OreTableRow>
  )
}
