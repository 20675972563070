import {
  OreIcon,
  OrePill,
  OreTooltip,
  SvgBin,
  SvgCompany,
  SvgDeliver,
  SvgEdit,
  SvgInfo,
  SvgMessengerService,
  SvgParticular,
  SvgPostalMail,
} from '@edenredespana/oreneta'
import { Seq } from 'immutable'
import type { Dispatch, SetStateAction } from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  addComma,
  deliverySiteService,
  getUndefinedDashString,
} from '../../../../core/services'
import type {
  CaptionModel,
  FilterControlModel,
  FilterFieldModel,
  FilterModel,
  FooterModel,
  HeaderModel,
  RowModel,
  TableEmptyModel,
} from '../../../../domain/customComponents/table'
import {
  MetaStatusCodes,
  NotificationSeverity,
  contactType,
  deliveryType,
  edenredProducts,
} from '../../../../domain/enum'
import type { DeliverySiteModel } from '../../../../domain/models'
import {
  deliverySiteFormTranslation,
  deliverySiteTranslation,
  forms,
} from '../../../../domain/translations'
import { useLoader } from '../../../context/loader/LoaderProvider'
import type {
  DeliverySiteDeleteProtocol,
  DeliverySiteProtocol,
  DeliverySiteUpdateFavoriteProtocol,
} from 'src/domain/protocols'
import { useNavigate } from 'react-router'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { iconButtonAction } from 'src/domain/constants'
import { useModalController } from '../../Edenred'
import type { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { OrePillTones } from 'src/domain/runrom/IOrePillTones'

interface ControllerState {
  header: HeaderModel
  caption: CaptionModel
  rows: RowModel[]
  footer: FooterModel
  setRows: Dispatch<SetStateAction<RowModel[]>>
  filter: FilterModel
  setFilter: Dispatch<SetStateAction<FilterModel>>
  onClickFilter: () => void
  setShowPopup: Dispatch<SetStateAction<boolean>>
  popupShow: boolean
  popupTitle: string
  popupDescription: string
  popupButtons: PopupButtonModel[]
  tableEmpty: TableEmptyModel
}

interface Filters {
  multiFilter?: string
  companyAdress?: boolean
  particularAdress?: boolean
  otherAdress?: boolean
  zipCode?: string
  city?: string
  contactType?: number
}

export const useDeliverySiteController = (productCode: number): ControllerState => {
  const { t } = useTranslation()
  const { addNotification } = useNotification()
  const [rows, setRows] = useState<RowModel[]>([])
  const [totalRows, setTotalRows] = useState<number>(0)
  const [deliverySites, setDeliverySites] = useState<DeliverySiteModel[]>([])
  const [caption, setCaption] = useState<CaptionModel>({
    title: '',
    description: t(deliverySiteTranslation.table.caption.deliveryPoints),
  })
  const [footer, setFooter] = useState<FooterModel>({})
  const [page, setPage] = useState(1)
  const pageSize = 10
  const { startLoading, stopLoading } = useLoader()
  const [sortByCodeAsc, setSortByCodeAsc] = useState<boolean>(true)
  const [query, setQuery] = useState<DeliverySiteProtocol>({
    productCode: productCode,
    companyAdress: true,
    particularAdress: true,
    otherAdress: true,
    paginationModel: {
      orderByField: 'alias',
      sortDescending: !sortByCodeAsc,
      numberOfRecordsPerPage: pageSize,
      pageNumber: page - 1,
    },
  })
  const [filter, setFilter] = useState<FilterModel>({})
  const navigate = useNavigate()

  const header: HeaderModel = {
    headerLabel: [
      { label: t(deliverySiteTranslation.table.head.shippingType) },
      {
        label: t(deliverySiteTranslation.table.head.code),
        isSortable: true,
        sortDirection: sortByCodeAsc ? 'asc' : 'desc',
        onClick: () => {
          sortRowsByCode()
        },
      },
      { label: t(deliverySiteTranslation.table.head.name) },
      { label: t(deliverySiteTranslation.table.head.address) },
      { label: t(deliverySiteTranslation.table.head.contact) },
      { label: t(deliverySiteTranslation.table.head.addressType) },
      { label: t(deliverySiteTranslation.table.head.actions), align: 'center' },
    ],
  }

  const sortRowsByCode = () => {
    setSortByCodeAsc(!sortByCodeAsc)
  }

  const tableEmpty: TableEmptyModel = {
    colSpan: 7,
    firstText: t(forms.table.emptyMessage),
  }

  const {
    show: popupShow,
    title: popupTitle,
    description: popupDescription,
    buttons: popupButtons,
    setShow: setShowPopup,
    setDescription: setDescriptionPopup,
    setTitle: setTitlePopup,
    setButtons: setButtonsPopup,
  } = useModalController()

  const getDeliverySites = (): void => {
    startLoading()
    deliverySiteService()
      .getAllQuery(query)
      .then(response => {
        setDeliverySites(response.data.rows)
        setTotalRows(response.data.totalRows)
      })
      .finally(() => stopLoading())
  }

  const GetRows = (): void => {
    const deliverySiteRows: RowModel[] = []
    Seq(deliverySites).forEach((site: DeliverySiteModel) => {
      const deliverySiteRow: RowModel = {
        rowId: site.deliveryPointId,
        cells: [
          {
            type: 'element',
            cellElement:
              site.deliveryType === deliveryType.messenger ? (
                <OreTooltip
                  text={t(deliverySiteTranslation.table.head.messagingTooltip)}
                  placement="top">
                  <OreIcon
                    icon={<SvgMessengerService />}
                    size="extra-small"
                    tone="generic-dark"
                  />
                </OreTooltip>
              ) : (
                <OreTooltip
                  text={t(deliverySiteTranslation.table.head.postOfficeTooltip)}
                  placement="top">
                  <OreIcon
                    icon={<SvgPostalMail />}
                    size="extra-small"
                    tone="postal-service"
                  />
                </OreTooltip>
              ),
          },
          {
            type: 'text',
            cellText: [{ text: site.alias, name: 'alias', size: 'text-sm' }],
          },
          {
            type: 'text',
            cellText: [
              {
                text: getUndefinedDashString(site.deliveryPointName),
                name: 'deliveryPointName',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'element',
            cellElement: getFullAddress(site),
          },
          {
            type: 'text',
            cellText: [
              {
                text: getUndefinedDashString(site.contactName),
                name: 'contactName',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'element',
            cellElement: GetPill(site.contactType, getContactType(site.contactType)),
          },
          {
            type: 'actions',
            cellActions: [
              {
                name: 'deleteRow',
                icon: <SvgBin />,
                type: iconButtonAction,
                disabled: !site.isRemovable,
                tooltip: getDeleteActionTooltip(site.isRemovable, site.isMain),
                onClick: onClickDelete,
              },
              {
                name: 'download',
                icon: <SvgEdit />,
                type: iconButtonAction,
                onClick: onClickEdit,
                tooltip: t(deliverySiteTranslation.table.head.editTooltip),
              },
              {
                name: 'favs',
                type: 'favs',
                checked: site.isFavorite,
                disabled: site.contactType !== contactType.company,
                onChange: () =>
                  onChangeFavDeliverySite(site.deliveryPointId, site.isFavorite),
                tooltip:
                  site.contactType !== contactType.company
                    ? ''
                    : !site.isFavorite
                    ? t(deliverySiteTranslation.table.head.favCheckedTooltip)
                    : t(deliverySiteTranslation.table.head.favUncheckedTooltip),
              },
            ],
          },
        ],
      }
      deliverySiteRows.push(deliverySiteRow)
    })
    setRows(deliverySiteRows)
  }

  const onChangeFavDeliverySite = async (
    siteId: number,
    isDSFavorite: boolean
  ): Promise<void> => {
    startLoading()
    const deliverySiteUpdateFav: DeliverySiteUpdateFavoriteProtocol = {
      deliveryPointId: siteId,
      isFavorite: !isDSFavorite,
    }
    const response = await deliverySiteService().updateFavorite(deliverySiteUpdateFav)
    if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
      const updatedSites = deliverySites.map((site: DeliverySiteModel) => {
        if (site.deliveryPointId === siteId) {
          return {
            ...site,
            isFavorite: !isDSFavorite,
          }
        }
        return site
      })
      setDeliverySites(updatedSites)
      addNotification(t(forms.success.message), NotificationSeverity.success)
    } else {
      if (response?.meta?.messages[0]?.description) {
        addNotification(
          `${response.meta.messages[0].description}: ${response.meta.messages[0].value}`,
          NotificationSeverity.error
        )
      } else {
        addNotification(t(forms.errors.genericError), NotificationSeverity.error)
      }
    }
    stopLoading()
  }

  const getDeleteActionTooltip = (
    isRemovable: boolean | undefined,
    isMain: boolean
  ): string => {
    if (!isRemovable) {
      return isMain
        ? t(deliverySiteTranslation.table.body.actions.delete.mainCantBeDeleted)
        : t(deliverySiteTranslation.table.body.actions.delete.activeUserLinked)
    }
    return t(deliverySiteTranslation.table.head.deleteTooltip)
  }

  const deleteDeliverySite = async (siteId: number): Promise<void> => {
    setShowPopup(false)
    startLoading()
    const deliveryPointDelete: DeliverySiteDeleteProtocol = {
      deliveryPointId: siteId,
      productTypeId: productCode,
    }
    const response = await deliverySiteService().delete(deliveryPointDelete)
    if (response?.meta?.status === MetaStatusCodes.SUCCESS) {
      const cleanSites = deliverySites.filter(site => site.deliveryPointId !== siteId)
      setDeliverySites(cleanSites)
      addNotification(t(forms.success.message), NotificationSeverity.success)
    } else {
      addNotification(t(forms.errors.genericError), NotificationSeverity.error)
    }
    stopLoading()
  }

  const onClickDelete = (rowId: number): void => {
    setShowPopup(true)
    setButtonsPopup(SetPopupButtons(rowId))
  }

  const SetPopupButtons = (siteId: number): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowPopup(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => deleteDeliverySite(siteId),
        size: 'large',
      },
    ]
  }

  const getFullAddress = (site: DeliverySiteModel): JSX.Element => {
    return (
      <>
        {addComma(site.streetName) +
          addComma(site.streetNumber) +
          addComma(site.streetDescription)}
        <br />
        {addComma(site.zipCode) + site.province}
      </>
    )
  }

  const getContactType = (ctype: number): string => {
    switch (ctype) {
      case contactType.company:
        return t(deliverySiteTranslation.table.body.addressType.business)
      case contactType.personal:
        return t(deliverySiteTranslation.table.body.addressType.personal)
      default:
        return t(deliverySiteTranslation.table.body.addressType.others)
    }
  }

  const onClickEdit = (rowId: number): void => {
    const deliverySite = deliverySites.find(
      (site: DeliverySiteModel) => site.deliveryPointId === rowId
    )
    navigate(
      productCode === edenredProducts.ticketRestaurant
        ? navigationRoutes.deliverySiteEditTR
        : navigationRoutes.deliverySiteEditTT,
      {
        state: {
          editMode: true,
          entity: deliverySite,
        },
      }
    )
  }

  const onPageChange = (page: number): void => {
    setQuery({
      ...query,
      paginationModel: {
        orderByField: 'alias',
        sortDescending: !sortByCodeAsc,
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
    setPage(page)
  }

  const getFooter = (): void => {
    setFooter({
      pagination: {
        onPageChange: onPageChange,
        labelNextPage: t(forms.pagination.next),
        labelPreviousPage: t(forms.pagination.back),
        rowsPerPage: pageSize,
        text: t(forms.pagination.of),
        page: page,
        isServerSide: true,
        totalRows: totalRows,
      },
    })
  }

  const getFilters = (): void => {
    setFilter({
      search: {
        textBox: {
          fields: ['zipCode', 'deliveryPointName', 'city'],
          placeHolder: t(deliverySiteTranslation.table.filter.searchPlaceholder),
          value: '',
        },
        searchButton: t(forms.buttons.search),
      },
      resetButton: t(deliverySiteTranslation.table.filter.resetButton),
      filterButtonLabel: t(forms.filter.label),
      filterFields: [
        {
          formClass: 'form-atom',
          filterFields: [
            {
              filterType: 'radio',
              name: 'contactType',
              label: 'Tipo de envío',
              radioButtons: [
                {
                  label: t(deliverySiteFormTranslation.address.company),
                  value: contactType.company.toString(),
                  tooltip: false,
                },

                {
                  label: t(deliverySiteFormTranslation.address.particular),
                  value: contactType.personal.toString(),
                  tooltip: false,
                },
                {
                  label: t(deliverySiteFormTranslation.address.others),
                  value: contactType.other.toString(),
                  tooltip: true,
                  tooltipIcon: <SvgInfo aria-label="info" />,
                  tooltipText: t(deliverySiteFormTranslation.address.othersInfo),
                },
              ],
            },
          ],
        },
        {
          formClass: 'form-atom form-atom--half',
          filterFields: [
            {
              filterType: 'input',
              name: 'zipCode',
              label: t(deliverySiteTranslation.table.filter.zipCode),
              placeHolder: t(deliverySiteTranslation.table.filter.zipCodePlaceholder),
              value: '',
            },
            {
              filterType: 'input',
              name: 'city',
              label: t(deliverySiteTranslation.table.filter.location),
              placeHolder: t(deliverySiteTranslation.table.filter.locationPlaceholder),
              value: '',
            },
          ],
        },
      ],
    })
  }

  const onClickFilter = (): void => {
    // event.preventDefault()
    const tableFilters: Filters = SetFilterValues()

    setQuery({
      productCode: productCode,
      multiFilter: tableFilters.multiFilter,
      city: tableFilters.city,
      zipCode: tableFilters.zipCode,
      contactType: tableFilters.contactType,
      companyAdress: tableFilters.contactType
        ? tableFilters.contactType === contactType.company
        : true,
      particularAdress: tableFilters.contactType
        ? tableFilters.contactType === contactType.personal
        : true,
      otherAdress: tableFilters.contactType
        ? tableFilters.contactType === contactType.other
        : true,
      paginationModel: {
        orderByField: 'alias',
        sortDescending: !sortByCodeAsc,
        numberOfRecordsPerPage: pageSize,
        pageNumber: 0,
      },
    })
    setPage(1)
  }

  const SetFilterValues = (): Filters => {
    const tableFilters: Filters = {}
    if (filter.search && filter.search.textBox.value !== '')
      tableFilters.multiFilter = filter.search.textBox.value
    if (filter.filterFields && filter.filterFields.length > 0) {
      Seq(filter.filterFields).forEach((fields: FilterFieldModel) => {
        Seq(fields.filterFields).forEach((field: FilterControlModel) => {
          if (field.name === 'zipCode' && field.value !== '')
            tableFilters.zipCode = field.value
          if (field.name === 'city' && field.value !== '') tableFilters.city = field.value
          if (field.name === 'contactType' && field.value)
            tableFilters.contactType = +field.value
        })
      })
    }
    return tableFilters
  }

  const GetPillIcon = (contactTypeId: number): JSX.Element => {
    switch (contactTypeId) {
      case contactType.company:
        return <SvgCompany />
      case contactType.personal:
        return <SvgParticular />
      case contactType.other:
        return <SvgDeliver />
      default:
        return <></>
    }
  }

  const GetPill = (contactType: number, statusLabel: string): JSX.Element => {
    return (
      <OrePill tone={OrePillTones.cobalt} icon={GetPillIcon(contactType)}>
        {statusLabel}
      </OrePill>
    )
  }

  useEffect(() => {
    if (rows.length > 0) {
      setCaption(prevCaption => {
        return {
          ...prevCaption,
          rowCount: totalRows,
        }
      })
    }
  }, [rows])

  useEffect(() => {
    GetRows()
    getFooter()
  }, [deliverySites])

  useEffect(() => {
    getDeliverySites()
  }, [query])

  useEffect(() => {
    setQuery({
      ...query,
      paginationModel: {
        orderByField: 'alias',
        sortDescending: !sortByCodeAsc,
        numberOfRecordsPerPage: pageSize,
        pageNumber: page - 1,
      },
    })
  }, [sortByCodeAsc])

  useEffect(() => {
    setTitlePopup(t(deliverySiteTranslation.modal.title))
    setDescriptionPopup(t(deliverySiteTranslation.modal.description))
    getFilters()
  }, [])

  return {
    header,
    caption,
    rows,
    setRows,
    footer,
    filter,
    setFilter,
    onClickFilter,
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    tableEmpty,
  }
}
