import {
  OrePagination,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreTooltip,
} from '@edenredespana/oreneta'
import { DietSpendingsState } from '../hooks/useDietSpendings'
import TableEmpty from 'src/presentation/components/Edenred/table/molecules/TableEmpty'
import { duplicatedTranslation, historyTranslation } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { DietSpendingsCaption } from './DietSpendingsCaption'
import { DietSpendingsRow } from './DietSpendingsRow'
import { DietSpendingModel } from 'src/domain/models/historySpendings'

interface Props {
  useDietSpendingsProps: DietSpendingsState
}

export const DietSpendingsTable = ({ useDietSpendingsProps }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { totalRows, header, dietSpendings, onPageChange, page, pageSize } =
    useDietSpendingsProps

  return (
    <OreTableContainer>
      <div className="bg-white">
        <DietSpendingsCaption dietSpendingsProps={useDietSpendingsProps} />
      </div>
      <OreTable>
        <OreTableHead>
          <OreTableRow>
            {header.headerElement && (
              <OreTableCell as="th">{header.headerElement}</OreTableCell>
            )}
            {header.headerLabel.map((header, index) =>
              !header.tooltip ? (
                <OreTableCell as="th" key={'lbl' + index}>
                  {header.label}
                </OreTableCell>
              ) : (
                <OreTableCell as="th" key={'lbl' + index}>
                  {header.label}&nbsp;
                  <OreTooltip placement="top" separation="small" text={header.tooltip}>
                    <span>{header.tooltipIcon}</span>
                  </OreTooltip>
                </OreTableCell>
              )
            )}
          </OreTableRow>
        </OreTableHead>
        <OreTableBody as="tbody" valign="top">
          {dietSpendings && dietSpendings.length <= 0 ? (
            <TableEmpty
              colSpan={7}
              firstText={t(historyTranslation.table.emptyMessage)}
            />
          ) : (
            dietSpendings.map((dietSpending: DietSpendingModel, index: number) => {
              return <DietSpendingsRow dietSpending={dietSpending} key={'rows' + index} />
            })
          )}
        </OreTableBody>
      </OreTable>
      <OreTableActions>
        <OrePagination
          count={totalRows}
          labelNextPage={t(duplicatedTranslation.nextPageLabel)}
          labelPreviousPage={t(duplicatedTranslation.previousPageLabel)}
          onPageChange={onPageChange}
          page={page}
          rowsPerPage={pageSize}
          text={t(duplicatedTranslation.pageOf)}
        />
      </OreTableActions>
    </OreTableContainer>
  )
}
