import { PageWrapper } from 'src/presentation/layout'
import {
  OreHeading,
  OreDivider,
  OreButton,
  SvgPlus,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'

import { Trans, useTranslation } from 'react-i18next'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { useNavigate } from 'react-router'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'

import { FlexCollectivesList } from 'src/Flex/Collectives/ui/collective-list/organisms'
import { useUserData } from 'src/Flex/User/ui/context'
import { VideoTutorial } from 'src/Flex/Shared/ui/VideoTutorial/VideoTutorial'

export const FlexCollectivesPage = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { flexData } = useUserData()

  return (
    <PageWrapper>
      <OreStack placeContent="space-between" direction="row">
        <OreHeading as="h1" size="title-md">
          {t(flexCollectivesTranslations.title)}
        </OreHeading>

        <OreButton
          category="primary"
          onClick={(): void => navigate(flexNavigationRoutes.flexCollectivesCreate)}
          icon={<SvgPlus />}
          size="large">
          {t(flexCollectivesTranslations.cta_create)}
        </OreButton>
      </OreStack>

      <OreDivider space="larger-bottom" />
      <OreStack space="large" placeContent="stretch">
        {!flexData?.isOnboardingDone ? (
          <OreText>
            <Trans t={t}>{t(flexCollectivesTranslations.onboarding)}</Trans>
          </OreText>
        ) : null}
        <FlexCollectivesList />
        <OreStack placeContent="stretch">
          <VideoTutorial
            title={t(flexCollectivesTranslations.videotutorial.configure)}
            youtubeUrl="https://www.youtube.com/embed/Sqzai4MpOfQ?si=9WVl8npobv3weiu1"
          />
          <VideoTutorial
            title={t(flexCollectivesTranslations.videotutorial.edit)}
            youtubeUrl="https://www.youtube.com/embed/mY_CwVzWqnA?si=FypGDjUch7K3v9QH"
          />
        </OreStack>
      </OreStack>
    </PageWrapper>
  )
}
