import { OreCheckbox } from '@edenredespana/oreneta'
import type { Dispatch } from 'react'
import { memo } from 'react'
import type { RowModel } from '../../../../../domain/customComponents/table/TableModel'
import { useCellSelection } from '../../hooks/table/useCellSelection'

export const CellSelection = memo(
  (props: {
    rowIndex: number
    rowId: number
    selectedRows: number[] | undefined
    setRows: React.Dispatch<React.SetStateAction<RowModel[]>>
    setSelectedRows: Dispatch<React.SetStateAction<number[]>>
  }): JSX.Element => {
    const { onChangeCellSelection } = useCellSelection(
      props.rowIndex,
      props.rowId,
      props.setSelectedRows,
      props.setRows
    )
    return (
      <OreCheckbox
        name="rowSelection"
        role="checkbox"
        checked={props.selectedRows && props.selectedRows.indexOf(props.rowId) !== -1}
        onChange={event => onChangeCellSelection(event)}
      />
    )
  }
)
