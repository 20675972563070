import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { FlexCollectiveRow } from 'src/Flex/Collectives/ui/collective-list/molecules'
import { useCollectiveController } from 'src/Flex/Collectives/ui/collective-list/hooks'
import { CollectiveModel } from 'src/Flex/Collectives/domain'
import {
  OrePagination,
  OreTable,
  OreTableActions,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
} from '@edenredespana/oreneta'
import { HeadersModel } from 'src/domain/models/tables/Headers'
import { tableSelector } from 'src/domain/translations'
import {
  DeleteCollectiveModal,
  DeleteModalActions,
} from 'src/Flex/Collectives/ui/collective-list'
import {
  SendEmailModal,
  SendEmailModalActions,
} from 'src/Flex/CommunicationsTemplates/ui/templates-send'
import { TemplateTargetEnum } from 'src/Flex/CommunicationsTemplates/domain'

export const FlexCollectivesList = (): JSX.Element => {
  const ref = useRef<DeleteModalActions>(null)
  const sendEmailRef = useRef<SendEmailModalActions>(null)
  const { t } = useTranslation()
  const {
    validateDeleteCollective,
    DeleteCollectiveExcel,
    numberOfRecordsPerPage,
    collectives,
    allCollectives,
    totalRows,
    page,
    changePage,
    loadAllCollectives,
    employeesAffected,
    showSSIAlert,
    deleteCollective,
    setEmployeesAffected,
  } = useCollectiveController()

  const handleDeleteCollective = (
    id: string,
    hasActiveHires: boolean,
    isalud: boolean
  ): void => {
    ref?.current?.open(id, hasActiveHires, isalud)
    loadAllCollectives()
  }

  const handlePageChange = (page: number): void => changePage(page - 1)

  const collectivesHeaders: HeadersModel[] = [
    {
      key: 'name',
      label: t(flexCollectivesTranslations.table.header.name),
      size: 'third',
    },
    {
      key: 'products',
      label: t(flexCollectivesTranslations.table.header.products),
      size: 'third',
    },
    {
      key: 'employees',
      label: t(flexCollectivesTranslations.table.header.employees),
      size: 'quarter',
    },
    {
      key: 'actions',
      label: t(flexCollectivesTranslations.table.header.actions),
      size: 'quarter',
    },
  ]

  const handleSendEmail = (id: string): void => {
    sendEmailRef?.current?.open(id, TemplateTargetEnum.employee)
  }

  return (
    <>
      <OreTableContainer>
        <OreTable>
          <OreTableHead valign="middle">
            <OreTableRow hover={false}>
              {collectivesHeaders.map(
                (header: HeadersModel): JSX.Element => (
                  <OreTableCell as="th" key={header.key} size={header.size}>
                    <span style={{ whiteSpace: 'pre-line' }}>{header.label}</span>
                  </OreTableCell>
                )
              )}
            </OreTableRow>
          </OreTableHead>
          <OreTableBody valign="top">
            {collectives.length > 0
              ? collectives.map(
                  (collective: CollectiveModel): JSX.Element => (
                    <FlexCollectiveRow
                      key={collective.id}
                      collective={collective}
                      deleteCollective={handleDeleteCollective}
                      hasOnlyOneCollective={allCollectives.length === 1}
                      sendEmail={handleSendEmail}
                    />
                  )
                )
              : null}
          </OreTableBody>
        </OreTable>
        {collectives.length === 0 && (
          <OreTableActions>
            <OreText size="text-sm" tone="neutral-900">
              {t(flexCollectivesTranslations.table.noCollectives)}
            </OreText>
          </OreTableActions>
        )}
        {collectives.length > 0 && (
          <OreTableActions>
            <OrePagination
              displayPageButtons
              count={totalRows}
              labelNextPage={t(tableSelector.nextPageLabel)}
              labelPreviousPage={t(tableSelector.previousPageLabel)}
              onPageChange={handlePageChange}
              page={page}
              rowsPerPage={numberOfRecordsPerPage}
              text={t(tableSelector.pageOf)}
              legend={t(tableSelector.rowsShowing)}
            />
          </OreTableActions>
        )}
      </OreTableContainer>
      <DeleteCollectiveModal
        onValidation={validateDeleteCollective}
        collectives={allCollectives}
        employeesAffected={employeesAffected}
        showSSIAlert={showSSIAlert}
        getExcel={DeleteCollectiveExcel}
        onConfirmation={deleteCollective}
        setEmployeesAffected={setEmployeesAffected}
        ref={ref}
      />
      <SendEmailModal ref={sendEmailRef} target={TemplateTargetEnum.collective} />
    </>
  )
}
