import {
  OreButton,
  OreHeading,
  OreInput,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreText,
} from '@edenredespana/oreneta'
import { useForm } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTranslation } from 'react-i18next'
import { forms } from '../../../../domain/translations'
import { dateValueIsLowerThanTomorrow } from '../../../../core/helpers'
import type { SaveCardOrder } from '../../../../domain/protocols/CardOrderProtocol'
import { MessageBar } from '../../Edenred'
import { cardOrderTranslation } from '../../../../domain/translations/cardOrder/cardOrder'
import { useCardOrderSaveAndClose } from './hooks'

interface Props {
  open: boolean
  handleOnClose: React.Dispatch<React.SetStateAction<boolean>>
}

export const CardOrderSaveAndClose = ({ open, handleOnClose }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { errorMessage, setStateErrorMessage, saveAndCloseCardOrder } =
    useCardOrderSaveAndClose()

  const formSchema = Yup.object({
    orderName: Yup.string().required(t(forms.errors.fieldRequired)),
    reminderDate: Yup.string().test(
      'testReminderDate',
      t(cardOrderTranslation.form.errors.dateBeforeToday),
      value => {
        const error = value ? dateValueIsLowerThanTomorrow(value) : null
        return !error
      }
    ),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SaveCardOrder>({
    resolver: yupResolver(formSchema),
    mode: 'all',
    defaultValues: {
      orderName: '',
      reminderDate: '',
    },
  })

  return (
    <OreModal
      open={open}
      handleOnClose={() => {
        handleOnClose(false)
        setStateErrorMessage('')
      }}>
      <OreModalBox
        handleOnClose={() => {
          handleOnClose(false)
          setStateErrorMessage('')
        }}>
        <form autoComplete="off">
          <OreModalBoxBody>
            <OreHeading size="headline-lg">
              {t(cardOrderTranslation.cardOrderHome.modalSaveTitle)}
            </OreHeading>
            <OreText>{t(cardOrderTranslation.cardOrderHome.modalSaveBody)}</OreText>
            <OreInput
              {...register('orderName')}
              id="orderName"
              label={t(cardOrderTranslation.cardOrderHome.modalSaveInputDescription)}
              name="orderName"
              hasError={!!errors?.orderName}
              errorMessage={errors?.orderName?.message}
              placeholder="Ej: FC98567"
              required
            />
            {/* <OreDatePicker
              {...register('reminderDate')}
              id="reminderDate"
              label={t(cardOrderTranslation.cardOrderHome.modalSaveInputReminder)}
              legend={t(cardOrderTranslation.cardOrderResume.reminderDatePlaceholder)}
              name="reminderDate"
              min={getTomorrow()}
              hasError={!!errors?.reminderDate}
              errorMessage={errors?.reminderDate?.message}
            /> */}
          </OreModalBoxBody>
          <MessageBar color="error" message={errorMessage} />
          <OreModalBoxFooter>
            <div className="ml-auto">
              <OreButton
                category="primary"
                size="small"
                onClick={handleSubmit(saveAndCloseCardOrder)}>
                {t(forms.buttons.saveAndClose)}
              </OreButton>
            </div>
          </OreModalBoxFooter>
        </form>
      </OreModalBox>
    </OreModal>
  )
}
