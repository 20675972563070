import { OreButton, OreDivider, SvgDownload } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { EmployeesTGDState } from '../hooks/useEmployeesListTableTGDController'
import { forms } from 'src/domain/translations'
import { Grid } from 'src/presentation/components/Edenred/layout'

interface Props {
  employeesTGDProps: EmployeesTGDState
}

export const EmployeeListTableTGDFooterButtons = ({
  employeesTGDProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { downloadExcel, employeesTGD } = employeesTGDProps

  return (
    <div className="py-2">
      <OreDivider />
      <div className="py-1">
        <Grid justify="flex-end">
          <OreButton
            as="button"
            category="primary"
            size="large"
            icon={<SvgDownload />}
            // disabled={!employeesTGD || employeesTGD.length <= 0}
            onClick={downloadExcel}>
            {t(forms.buttons.downloadExcel)}
          </OreButton>
        </Grid>
      </div>
    </div>
  )
}
