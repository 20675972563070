import { ComponentType, RefAttributes } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  OreButton,
  OreDivider,
  OreHeading,
  OreText,
  OreStack,
  SvgArrowWidget,
} from '@edenredespana/oreneta'
import {
  ConfigureCollectivesBasicForm,
  CollectivesConfigureExternalHealthInsuranceModalForm,
  CollectivesConfigureFormationModalForm,
} from 'src/Flex/Collectives/ui/collective-detail'
import { useConfigureCollectiveController } from 'src/Flex/Collectives/ui/collective-detail'
import { flexCollectivesTranslations } from 'src/Flex/Collectives/ui/translations'
import { forms } from 'src/domain/translations'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { edenredProducts } from 'src/domain/enum'
import { ConfigureModalActions } from 'src/Flex/Products/ui/product-configuration'
import { FlexProductsList, ProductsProvider } from 'src/Flex/Products/ui/product-list'
import { productConfigurations as defaultProductConfigurations } from 'src/Flex/Products/ui/product-list'

const productConfigurations = (): {
  [key: number]: {
    modal: ComponentType<RefAttributes<ConfigureModalActions>>
    modalIcon?: ComponentType
  }
} => {
  const productConfigurations = defaultProductConfigurations()
  // productConfigurations[edenredProducts.formacion] = {
  //   modal: CollectivesConfigureFormationModalForm,
  // }

  productConfigurations[edenredProducts.saludExterno] = {
    modal: CollectivesConfigureExternalHealthInsuranceModalForm,
  }

  return productConfigurations
}

interface IProps {
  id: string | undefined
}

export const CollectivesDetail = ({ id }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { collective, form, save } = useConfigureCollectiveController(id)

  return (
    <>
      <OreStack space="2xlarge" placeContent="stretch">
        <ConfigureCollectivesBasicForm form={form} save={save} hasId={!!id} />
        {collective ? (
          <ProductsProvider
            collective={collective}
            productConfigurations={productConfigurations}>
            <OreStack space="medium">
              <OreHeading size="headline-md">
                {t(flexCollectivesTranslations.configure.edit.products.title)}
              </OreHeading>
              <OreText>
                {t(flexCollectivesTranslations.configure.edit.products.subtitle)}
              </OreText>
              <FlexProductsList
                noProducts={t(
                  flexCollectivesTranslations.configure.edit.products.noProducts
                )}
                isGlobal={false}
              />
            </OreStack>
          </ProductsProvider>
        ) : null}
      </OreStack>
      {collective ? (
        <>
          <OreDivider space="larger-top" />
          <OreButton
            onClick={(): void => navigate(flexNavigationRoutes.flexCollectives)}
            category="tertiary"
            icon={<SvgArrowWidget />}>
            {t(forms.buttons.goBack)}
          </OreButton>
        </>
      ) : null}
    </>
  )
}
