import {
  OreButton,
  OreCardContainer,
  OreDatePicker,
  OreInput,
  OreTimePicker,
  OreToggleButton,
  SvgFilter,
} from '@edenredespana/oreneta'
import { FormProvider } from 'react-hook-form'
import { forms } from 'src/domain/translations'
import { DietSpendingsState } from '../hooks/useDietSpendings'
import { useTranslation } from 'react-i18next'
import { Grid } from 'src/presentation/components/Edenred/layout'
import { dietSpendingsTranslations } from 'src/domain/translations/history/dietSpendings'
import { getFirstDayOfMonthOneYearBefore, getToday } from 'src/core/helpers'

interface Props {
  dietSpendingsProps: DietSpendingsState
}

export const DietSpendingsCaption = ({ dietSpendingsProps }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { form, onEnter, onSearch, clearFilters, showMoreFilters, setShowMoreFilters } =
    dietSpendingsProps

  return (
    <FormProvider {...form}>
      <form autoComplete="off" onSubmit={e => e.preventDefault()}>
        <div className="bg-white px-2 py-2">
          <Grid justify="space-between" items="center" gap="1rem">
            <Grid items="center" gap="1.5rem" className="width-60">
              <OreInput
                {...form.register('multiFilter')}
                id="multiFilter"
                placeholder={t(dietSpendingsTranslations.table.filters.inputPlaceHolder)}
                name="multiFilter"
                type="search"
                onKeyUp={e => onEnter(e)}
              />
              {!showMoreFilters && (
                <OreButton as="button" category="primary" onClick={onSearch} size="small">
                  {t(forms.buttons.search)}
                </OreButton>
              )}
            </Grid>
            <Grid>
              <OreToggleButton
                icon={<SvgFilter aria-label="Filtros" />}
                onClick={() => setShowMoreFilters(!showMoreFilters)}>
                {t(dietSpendingsTranslations.table.filters.addFilter)}
              </OreToggleButton>
            </Grid>
          </Grid>
          {showMoreFilters && (
            <Grid className="pt-2">
              <OreCardContainer tone="neutral-100" hasShadow={false}>
                <Grid columns={2} className="form-atom px-1 pt-105" columnGap="1rem">
                  <div>
                    <OreDatePicker
                      {...form.register('beginDate')}
                      id="beginDate"
                      label={t(dietSpendingsTranslations.table.filters.dateFrom)}
                      min={getFirstDayOfMonthOneYearBefore()}
                      max={getToday()}
                      required
                      hasError={!!form.formState.errors.beginDate}
                      errorMessage={
                        form.formState.errors.beginDate &&
                        form.formState.errors.beginDate?.message
                      }
                    />
                  </div>
                  <div>
                    <OreDatePicker
                      {...form.register('endDate')}
                      id="endDate"
                      label={t(dietSpendingsTranslations.table.filters.dateTo)}
                      min={getFirstDayOfMonthOneYearBefore()}
                      max={getToday()}
                      required
                      hasError={!!form.formState.errors.endDate}
                      errorMessage={
                        form.formState.errors.endDate &&
                        form.formState.errors.endDate?.message
                      }
                    />
                  </div>
                </Grid>
                <Grid columns={2} className="form-atom px-1 pt-1" columnGap="1rem">
                  <div>
                    <OreTimePicker
                      {...form.register('beginHour')}
                      id="beginHour"
                      label={t(dietSpendingsTranslations.table.filters.hsFrom)}
                      required
                      hasError={!!form.formState.errors.beginHour}
                      errorMessage={
                        form.formState.errors.beginHour &&
                        form.formState.errors.beginHour?.message
                      }
                    />
                  </div>
                  <div>
                    <OreTimePicker
                      {...form.register('endHour')}
                      id="endHour"
                      label={t(dietSpendingsTranslations.table.filters.hsTo)}
                      required
                      hasError={!!form.formState.errors.endHour}
                      errorMessage={
                        form.formState.errors.endHour &&
                        form.formState.errors.endHour?.message
                      }
                    />
                  </div>
                </Grid>
                <Grid columns={2} className="form-atom px-1 pt-1" columnGap="1rem">
                  <div>
                    <OreInput
                      {...form.register('zipCode')}
                      id="zipCode"
                      label={t(dietSpendingsTranslations.table.filters.postalCode)}
                      placeholder={t(
                        dietSpendingsTranslations.table.filters.postalCodePlaceholder
                      )}
                    />
                  </div>
                  <div>
                    <OreInput
                      {...form.register('city')}
                      id="city"
                      label={t(dietSpendingsTranslations.table.filters.city)}
                      placeholder={t(
                        dietSpendingsTranslations.table.filters.cityPlaceholder
                      )}
                    />
                  </div>
                </Grid>
                {showMoreFilters && (
                  <Grid gap="1.5rem" className="px-1 py-1">
                    <OreButton
                      as="button"
                      category="primary"
                      onClick={onSearch}
                      size="small">
                      {t(forms.buttons.search)}
                    </OreButton>
                    <OreButton
                      as="button"
                      category="secondary"
                      onClick={() => clearFilters()}
                      size="small">
                      {t(dietSpendingsTranslations.table.filters.clearFilter)}
                    </OreButton>
                  </Grid>
                )}
              </OreCardContainer>
            </Grid>
          )}
        </div>
      </form>
    </FormProvider>
  )
}
