import { OreButton } from '@edenredespana/oreneta'
import { Seq } from 'immutable'
import { Fragment } from 'react'
import type { PopupButtonModel } from '../../../domain/customComponents/Popup'

export const ModalActionButtons = (props: {
  buttons: PopupButtonModel[]
}): JSX.Element => {
  return (
    <div className="flex flex--row ml-auto">
      {Seq(props.buttons).map((button: PopupButtonModel, index: number) => (
        <Fragment key={'button_' + index}>
          <OreButton
            category={button.category}
            size={button.size}
            onClick={button.onClick}
            disabled={button.disabled}>
            {button.title}
          </OreButton>
        </Fragment>
      ))}
    </div>
  )
}
