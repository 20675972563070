import { OreText } from '@edenredespana/oreneta'
import { Seq } from 'immutable'
import { Fragment, memo } from 'react'
import type { CellTextModel } from '../../../../../domain/customComponents/table/TableModel'

export const CellText = memo(
  (props: { text: CellTextModel[]; rowIndex: number }): JSX.Element => {
    return (
      <>
        {Seq(props.text).map((text: CellTextModel, index: number) => (
          <Fragment key={text.name + '_' + index}>
            <OreText size={text.size} tone={text.tone} align={text.align || 'left'}>
              {text.isBold ? <b>{text.text}</b> : text.text}
            </OreText>
          </Fragment>
        ))}
      </>
    )
  }
)
