import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm, UseFormReturn } from 'react-hook-form'
import { Dispatch, SetStateAction, useEffect, useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { HeaderLabelModel, HeaderModel } from 'src/domain/customComponents/table'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { employeesTranslation } from 'src/domain/translations/cardOrder/employees'
import { useNavigate, useParams } from 'react-router-dom'
import { OreCheckbox, SvgInfo } from '@edenredespana/oreneta'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { forms } from 'src/domain/translations'
import { useModalController } from 'src/presentation/components/Edenred'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { useTTOrder } from '../../context/TTOrderProvider'
import { DeliverySiteModel } from 'src/domain/models'
import { ComboBoxOptionModel } from 'src/domain/customComponents'
import { deliverySiteService, getDeliverySitesByGroup } from 'src/core/services'
import { GetTTDeliverySitesRequestBody } from 'src/core/controllers/cardOrder/services'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'

export interface EmployeesTTOrderFormModel {
  employeesTT: TTOrderModel[]
}

export interface TTOrderConfigurationEmployeeState {
  header: HeaderModel
  totalRows: number
  employeesTT: TTOrderModel[]
  page: number
  setPage: Dispatch<SetStateAction<number>>
  pageSize: number
  onPageChange(page: number): void
  form: UseFormReturn<EmployeesTTOrderFormModel, any>
  addConfigToOrder(): void
  selectedEmployees: TTOrderModel[]
  setSelectedEmployees: Dispatch<SetStateAction<TTOrderModel[]>>
  onSelectEmployee(event: ChangeEvent<HTMLInputElement>, employee: TTOrderModel): void
  isSelected(employee: TTOrderModel): boolean
  showSelected: boolean
  setShowSelected: Dispatch<SetStateAction<boolean>>
  onAssignMultipleValues: (
    reason: number,
    deliverySiteId: number,
    deliverySite: DeliverySiteModel
  ) => void
  showCancelModal: boolean
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  errorMessage: string
  updateDataRowToTTOrderEmployee: (
    userId: number,
    email?: string,
    reason?: number,
    deliverySiteId?: number,
    deliverySite?: DeliverySiteModel
  ) => void
  deliverySitesOptions: ComboBoxOptionModel[]
  deliverySites: DeliverySiteModel[]
}

export const useTTOrderConfigurationEmployees = (): TTOrderConfigurationEmployeeState => {
  const { t } = useTranslation()
  const {
    setTTSelectedEmployees,
    setTTEmployeesToConfig,
    getTTEmployeesToConfig,
    addOrUpdateTTOrders,
    ttOrders,
    updateTTOrder,
  } = useTTOrder()
  const [page, setPage] = useState(1)
  const pageSize = 8
  const [employeesTT, setEmployeesTT] = useState<TTOrderModel[]>(getTTEmployeesToConfig())
  const [totalRows, setTotalRows] = useState<number>(0)
  const navigate = useNavigate()
  const [selectedEmployees, setSelectedEmployees] = useState<TTOrderModel[]>([])
  const [showSelected, setShowSelected] = useState<boolean>(false)
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const { addNotification } = useNotification()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [deliverySitesOptions, setDeliverySitesOptions] = useState<ComboBoxOptionModel[]>(
    []
  )
  const [deliverySites, setDeliverySites] = useState<DeliverySiteModel[]>([])

  const { startLoading, stopLoading } = useLoader()

  const { id: idTTEditOrder } = useParams()
  useEffect(() => {
    if (idTTEditOrder) {
      const orderToEdit = ttOrders.find(order => order.id === idTTEditOrder)
      if (orderToEdit) {
        setTTEmployeesToConfig([{ ...orderToEdit }])
        setEmployeesTT([{ ...orderToEdit }])
      }
    }
  }, [idTTEditOrder])

  const GetDeliverySiteOptions = (): void => {
    startLoading()
    deliverySiteService()
      .getAll(GetTTDeliverySitesRequestBody(), false)
      .then(response => {
        if (response.meta.status === MetaStatusCodes.SUCCESS) {
          setDeliverySites(response.data.rows)
          const options: ComboBoxOptionModel[] = [
            {
              options: [
                [t(TTOrderTranslation.form.shipping.deliverySiteSelect.placeholder), 0],
              ],
            },
          ]
          const groupedOptions = getDeliverySitesByGroup(response.data.rows)
          groupedOptions.map(option => {
            options.push(option)
          })
          setDeliverySitesOptions(options)
        }
      })
      .finally(() => stopLoading())
  }

  const {
    show: showCancelModal,
    buttons: cancelPopupButtons,
    setShow: setShowCancelModal,
    setButtons: setCancelModalButtons,
  } = useModalController()

  const getDiffBetweenEmployeeArrays = (
    array1: TTOrderModel[],
    array2: TTOrderModel[]
  ): TTOrderModel[] => {
    let result: TTOrderModel[] = []
    result = array1.filter(emp1 => {
      return !array2.some(emp2 => {
        return emp1.userId === emp2.userId
      })
    })
    return result
  }

  const headerLabel: HeaderLabelModel[] = [
    { label: t(employeesTranslation.table.header.titular) },
    { label: t(employeesTranslation.table.header.email) },
    {
      label: t(employeesTranslation.table.header.reason),
      tooltip: t(employeesTranslation.table.header.reasonTooltip),
      tooltipIcon: <SvgInfo />,
    },
    { label: t(employeesTranslation.table.header.deliverySite) },
  ]

  const headerElement: JSX.Element = (
    <OreCheckbox
      name="check"
      checked={
        selectAll ||
        getDiffBetweenEmployeeArrays(employeesTT, selectedEmployees).length <= 0
      }
      onChange={event => {
        onSelectAllEmployees(event)
      }}
      disabled={showSelected}
    />
  )

  const header: HeaderModel = !idTTEditOrder
    ? {
        headerElement: headerElement,
        headerLabel: headerLabel,
      }
    : { headerLabel: headerLabel }

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const schema = Yup.object({
    employeesTT: Yup.array().of(
      Yup.object().shape({
        deliverySiteId: Yup.number()
          .transform(value => (isNaN(value) ? null : value))
          .nullable()
          .min(1, t(forms.errors.fieldRequired))
          .required(t(forms.errors.fieldRequired)),
        corporativeEmail: Yup.string()
          .email(t(forms.errors.invalidFormat))
          .required(t(forms.errors.fieldRequired)),
        cardData: Yup.object().shape({
          cardRequestTypeId: Yup.number()
            .transform(value => (isNaN(value) ? null : value))
            .nullable()
            .min(1, t(forms.errors.fieldRequired))
            .required(t(forms.errors.fieldRequired)),
        }),
      })
    ),
  })

  const form = useForm<EmployeesTTOrderFormModel>({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      employeesTT: employeesTT,
    },
  })

  const addConfigToOrder = (): void => {
    addOrUpdateTTOrders(employeesTT)
    setTTSelectedEmployees([])
    setTTEmployeesToConfig([])
    navigate(navigationRoutes.ttOrderSummary)
  }

  const onSelectEmployee = (
    event: ChangeEvent<HTMLInputElement>,
    employee: TTOrderModel
  ): void => {
    if (event.target.checked) {
      const exist = selectedEmployees.find(emp => emp.userId == employee.userId)
      if (!exist) {
        setSelectedEmployees(prev => [...prev, employee])
      }
    } else {
      setSelectedEmployees(
        selectedEmployees.filter(emp => emp.userId !== employee.userId)
      )
    }
  }

  const onSelectAllEmployees = (event: ChangeEvent<HTMLInputElement>): void => {
    const selectedArray: TTOrderModel[] = []
    setSelectAll(event.target.checked)
    if (event.target.checked) {
      const difference = getDiffBetweenEmployeeArrays(employeesTT, selectedEmployees)
      selectedArray.push(...difference)
      if (selectedArray.length > 0)
        setSelectedEmployees(prev => [...prev, ...selectedArray])
    } else {
      selectedArray.push(
        ...selectedEmployees.filter((emp: TTOrderModel) => {
          return !employeesTT.some((e: TTOrderModel) => {
            return emp.userId === e.userId
          })
        })
      )
      setSelectedEmployees([...selectedArray])
    }
  }

  const isSelected = (employee: TTOrderModel): boolean => {
    const exist = selectedEmployees.find(emp => emp.userId == employee.userId)
    return !!exist
  }

  const onAssignMultipleValues = (
    reason: number,
    deliverySiteId: number,
    deliverySite: DeliverySiteModel
  ): void => {
    const copyList = [...employeesTT]
    copyList.map((employee, index) => {
      if (selectedEmployees.includes(employee)) {
        if (reason) {
          employee.cardData.cardRequestTypeId = reason
          form.setValue(`employeesTT.${index}.cardData.cardRequestTypeId`, reason)
        }
        if (deliverySiteId) {
          employee.deliverySiteId = deliverySiteId
          form.setValue(`employeesTT.${index}.deliverySiteId`, deliverySiteId)
        }
        if (deliverySite) {
          employee.deliverySiteData = deliverySite
          form.setValue(`employeesTT.${index}.deliverySiteData`, deliverySite)
        }
        form.trigger()
        return employee
      }
    })
    setEmployeesTT(copyList)
  }

  const onClickCancel = (): void => {
    setShowCancelModal(true)
    setCancelModalButtons(getCancelModalButtons())
  }

  const getCancelModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.discard),
        category: 'primary',
        onClick: () => {
          setTTEmployeesToConfig([])
          setTTSelectedEmployees([])
          setShowCancelModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
          idTTEditOrder ? navigate(-1) : navigate(-2)
        },
        size: 'large',
      },
    ]
  }

  const updateDataRowToTTOrderEmployee = (
    userId: number,
    email?: string,
    reason?: number,
    deliverySiteId?: number,
    deliverySite?: DeliverySiteModel
  ): void => {
    const indexEmployeeToConfig: number = employeesTT.findIndex(
      (o: TTOrderModel) => o.userId === userId
    )
    const employeeOnOrder = ttOrders.find((o: TTOrderModel) => o.userId === userId)

    if (indexEmployeeToConfig >= 0) {
      const updatedOrders: TTOrderModel[] = [...employeesTT]
      const employee = { ...updatedOrders[indexEmployeeToConfig] }
      if (email) employee.corporativeEmail = email
      if (reason) employee.cardData.cardRequestTypeId = reason
      if (deliverySiteId && deliverySite) {
        employee.deliverySiteId = deliverySiteId
        employee.deliverySiteData = deliverySite
      }
      updatedOrders[indexEmployeeToConfig] = employee
      setEmployeesTT(updatedOrders)
    }

    if (employeeOnOrder) {
      if (email) employeeOnOrder.corporativeEmail = email
      if (reason) employeeOnOrder.cardData.cardRequestTypeId = reason
      if (deliverySiteId && deliverySite) {
        employeeOnOrder.deliverySiteId = deliverySiteId
        employeeOnOrder.deliverySiteData = deliverySite
      }
      updateTTOrder(employeeOnOrder)
    }
  }

  const setDefaultValues = () => {
    employeesTT.forEach((employee, index) => {
      if (employee.corporativeEmail)
        form.setValue(`employeesTT.${index}.corporativeEmail`, employee.corporativeEmail)
      if (employee.cardData.cardRequestTypeId)
        form.setValue(
          `employeesTT.${index}.cardData.cardRequestTypeId`,
          employee.cardData.cardRequestTypeId
        )
      if (employee.deliverySiteId)
        form.setValue(`employeesTT.${index}.deliverySiteId`, employee.deliverySiteId)
    })
  }

  useEffect(() => {
    GetDeliverySiteOptions()
  }, [])

  useEffect(() => {
    if (selectedEmployees.length === 0) setShowSelected(false)
  }, [selectedEmployees])

  useEffect(() => {
    setTotalRows(employeesTT.length)
    // setDefaultValues()
  }, [employeesTT])

  return {
    header,
    totalRows,
    employeesTT,
    page,
    setPage,
    pageSize,
    onPageChange,
    form,
    selectedEmployees,
    setSelectedEmployees,
    addConfigToOrder,
    onSelectEmployee,
    isSelected,
    showSelected,
    setShowSelected,
    onAssignMultipleValues,
    showCancelModal,
    setShowCancelModal,
    cancelPopupButtons,
    onClickCancel,
    errorMessage,
    updateDataRowToTTOrderEmployee,
    deliverySitesOptions,
    deliverySites,
  }
}
