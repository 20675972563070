import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreTableContainer,
  OreTable,
  OreTableHead,
  OreTableRow,
  OreTableCell,
  OreTableBody,
  OrePagination,
  OreTableActions,
  OreText,
  OreTableCaption,
} from '@edenredespana/oreneta'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { tableSelector } from 'src/domain/translations/tables/tableSelector'
import type { HeadersModel } from 'src/domain/models/tables/Headers'
import {
  active,
  collectiveId,
  type EmployeeModel,
  flexRetributionPlanRegisterStep,
} from 'src/Flex/Employees/domain'
import {
  TableSearcher,
  tableSearcherTranslations,
} from 'src/Flex/Shared/ui/TableSearcher'
import { FilterModel } from 'src/Flex/Shared/domain'
import { useCollectiveController } from 'src/Flex/Collectives/ui/collective-list'
import {
  SendEmailModal,
  SendEmailModalActions,
} from 'src/Flex/CommunicationsTemplates/ui/templates-send/organisms'
import { TemplateTargetEnum } from 'src/Flex/CommunicationsTemplates/domain'
import { EmployeePRFEnum } from 'src/Flex/Employees/domain/EmployeePRFEnum'
import {
  EmployeeStatusModal,
  EmployeeStatusModalActions,
} from '../atoms/EmployeeStatusModal'
import { FlexEmployeesPendingAdherenceRow } from '../molecules/FlexEmployeesPendingAdherenceRow'
import { employeesAdherenceTranslations } from '../employeesAdherenceTranslations'
import { useEmployeesPendingAdherence } from '../contexts/EmployeesPendingAdherenceContext'

export const FlexEmployeesPendingAdherenceList = (): JSX.Element => {
  const { t } = useTranslation()
  const ref = useRef<EmployeeStatusModalActions>(null)
  ref.current?.open
  const sendEmailRef = useRef<SendEmailModalActions>(null)

  const {
    numberOfRecordsPerPage,
    page,
    count,
    onPageChange,
    search,
    searchParameters,
    employees,
    toggleState,
    loadEmployees,
  } = useEmployeesPendingAdherence()

  const { collectives } = useCollectiveController(null)

  const handleSubscribe = (employee: EmployeeModel): void => {
    if (!employee) return

    if (employee?.active) {
      ref?.current?.open(employee)
    } else {
      toggleState(employee)
      loadEmployees()
    }
  }

  const handleSendEmail = (id: string): void => {
    sendEmailRef?.current?.open(id, TemplateTargetEnum.employee)
  }

  const employeesHeaders: HeadersModel[] = [
    {
      key: 'name',
      label: t(flexEmployeesTranslations.table.header.name),
    },
    {
      key: 'collective',
      label: t(flexEmployeesTranslations.table.header.collective),
    },
    {
      key: 'communicationDate',
      label: t(flexEmployeesTranslations.table.header.communicationDate),
    },
    {
      key: 'state',
      label: t(flexEmployeesTranslations.table.header.state),
    },
    {
      key: 'prf',
      label: t(flexEmployeesTranslations.table.header.prfShort),
    },
    {
      key: 'actions',
      label: t(flexEmployeesTranslations.table.header.actions),
    },
  ]

  const filters: FilterModel[] = [
    {
      id: collectiveId,
      label: t(flexEmployeesTranslations.table.header.collective),
      type: 'select',
      placeholder: `${t(tableSearcherTranslations.selectPrefix)} ${t(
        flexEmployeesTranslations.table.header.collective
      ).toLowerCase()}`,
      options: collectives.map(collective => ({
        label: collective.name,
        value: collective.id,
      })),
    },
    {
      id: active,
      label: t(flexEmployeesTranslations.table.header.state),
      type: 'select',
      placeholder: `${t(tableSearcherTranslations.selectPrefix)} ${t(
        flexEmployeesTranslations.table.header.state
      ).toLowerCase()}`,
      options: [
        { label: t(flexEmployeesTranslations.table.body.active), value: 'true' },
        { label: t(flexEmployeesTranslations.table.body.inactive), value: 'false' },
      ],
    },
    {
      id: flexRetributionPlanRegisterStep,
      label: t(flexEmployeesTranslations.table.header.prfLong),
      type: 'select',
      placeholder: t(flexEmployeesTranslations.table.filters.placeholders.prf),
      options: [
        {
          label: t(flexEmployeesTranslations.table.body.notStartedPrf),
          value: EmployeePRFEnum.notStarted.toString(),
        },
        {
          label: t(flexEmployeesTranslations.table.body.pendingPrf),
          value: EmployeePRFEnum.pending.toString(),
        },
        {
          label: t(flexEmployeesTranslations.table.body.cancelledPrf),
          value: EmployeePRFEnum.cancelled.toString(),
        },
        {
          label: t(flexEmployeesTranslations.table.body.donePrf),
          value: EmployeePRFEnum.done.toString(),
        },
      ],
    },
  ]

  return (
    <>
      <OreTableContainer>
        {employees.length > 0 && (
          <TableSearcher
            title={t(employeesAdherenceTranslations.pendingAdherenceTable.title)}
            handleSearch={search}
            // filters={filters}
            subtitle={
              count +
              ' ' +
              t(employeesAdherenceTranslations.pendingAdherenceTable.subtitle)
            }
            searchPlaceholder={t(flexEmployeesTranslations.searchPlaceholder)}
          />
        )}

        <OreTable>
          {employees.length <= 0 && (
            <OreTableCaption
              title={t(employeesAdherenceTranslations.pendingAdherenceTable.title)}
            />
          )}
          {employees.length > 0 && (
            <OreTableHead valign="middle">
              <OreTableRow hover={false}>
                {employeesHeaders.map(
                  (header: HeadersModel, index: number): JSX.Element => (
                    <OreTableCell as="th" key={header.key + index}>
                      <span style={{ whiteSpace: 'pre-line' }}>{header.label}</span>
                    </OreTableCell>
                  )
                )}
              </OreTableRow>
            </OreTableHead>
          )}
          <OreTableBody valign="top">
            {employees.map(
              (employee: EmployeeModel): JSX.Element => (
                <FlexEmployeesPendingAdherenceRow
                  key={employee.id}
                  employee={employee}
                  handleSubscribe={handleSubscribe}
                  sendEmail={handleSendEmail}
                />
              )
            )}
          </OreTableBody>
        </OreTable>
        {employees.length === 0 ? (
          <OreTableActions>
            <OreText size="text-sm" tone="neutral-900">
              {searchParameters.filters.some(sf => sf.value) ||
              searchParameters.search !== ''
                ? t(flexEmployeesTranslations.table.noEmployeesWithFilters)
                : t(employeesAdherenceTranslations.pendingAdherenceTable.empty)}
            </OreText>
          </OreTableActions>
        ) : null}
        {count > 0 ? (
          <OreTableActions>
            <OrePagination
              displayPageButtons
              count={count}
              labelNextPage={t(tableSelector.nextPageLabel)}
              labelPreviousPage={t(tableSelector.previousPageLabel)}
              onPageChange={onPageChange}
              page={page}
              rowsPerPage={numberOfRecordsPerPage}
              text={t(tableSelector.pageOf)}
              legend={t(tableSelector.rowsShowing)}
            />
          </OreTableActions>
        ) : null}
      </OreTableContainer>
      <EmployeeStatusModal ref={ref} />
      <SendEmailModal ref={sendEmailRef} target={TemplateTargetEnum.employee} />
    </>
  )
}
