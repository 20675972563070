import { OreButton, OreStack, OreText } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { edenredProducts } from 'src/domain/enum'
import NoCard from '../../../../../presentation/assets/img/NoCard.svg'
import { forms } from 'src/domain/translations'
import { useNavigate } from 'react-router-dom'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { orderImputationsTranslations } from '../orderImputationsTranslations'

interface Props {
  productType: number
}

const InactiveExternalInsurance = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <OreStack direction="column" space="large">
      <OreStack>
        <OreText bold>
          {t(orderImputationsTranslations.configure.externalInsurance.subtitleInactive)}
        </OreText>
        <OreText>
          {t(orderImputationsTranslations.configure.externalInsurance.inactiveText1_1)}
          <span className="bold">
            {t(orderImputationsTranslations.configure.externalInsurance.inactiveText1_2)}
          </span>
          {t(orderImputationsTranslations.configure.externalInsurance.inactiveText1_3)}
        </OreText>
        <OreText>
          {t(orderImputationsTranslations.configure.externalInsurance.inactiveText2)}
        </OreText>
      </OreStack>
      <OreStack>
        <OreButton
          size="large"
          category="primary"
          onClick={() => navigate(flexNavigationRoutes.flexProducts)}>
          {t(forms.buttons.configure)}
        </OreButton>
      </OreStack>
    </OreStack>
  )
}

const InactiveFormation = (): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  return (
    <OreStack direction="column" space="large">
      <OreStack>
        <OreText bold>
          {t(orderImputationsTranslations.configure.formation.subtitleInactive)}
        </OreText>
        <OreText>
          {t(orderImputationsTranslations.configure.formation.inactiveText1_1)}
          <span className="bold">
            {t(orderImputationsTranslations.configure.formation.inactiveText1_2)}
          </span>
          {t(orderImputationsTranslations.configure.formation.inactiveText1_3)}
        </OreText>
        <OreText>
          {t(orderImputationsTranslations.configure.formation.inactiveText2)}
        </OreText>
      </OreStack>
      <OreStack>
        <OreButton
          size="large"
          category="primary"
          onClick={() => navigate(flexNavigationRoutes.flexProducts)}>
          {t(forms.buttons.configure)}
        </OreButton>
      </OreStack>
    </OreStack>
  )
}

export const FlexOrderImputationInactiveProduct = ({ productType }: Props) => {
  const getModuleByProductType = (productType: number): JSX.Element => {
    switch (productType) {
      case edenredProducts.saludExterno:
        return <InactiveExternalInsurance />
      case edenredProducts.formacion:
        return <InactiveFormation />
      default:
        return <InactiveExternalInsurance />
    }
  }

  return (
    <OreStack direction="row" space="3xlarge">
      {getModuleByProductType(productType)}
      <OreStack>
        <div>
          <figure>
            <img src={NoCard} alt="" />
          </figure>
        </div>
      </OreStack>
    </OreStack>
  )
}
