import { useTranslation } from 'react-i18next'
import { flexEmployeesTranslations } from '../../translations'

export const TooltipList = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <>
      <p style={{ marginBottom: '0.5rem' }}>
        {t(flexEmployeesTranslations.configure.common.form.documentNumber.tooltip.title)}
      </p>
      <ol style={{ paddingLeft: '1rem' }}>
        <li>
          {t(
            flexEmployeesTranslations.configure.common.form.documentNumber.tooltip.item1
          )}
        </li>
        <li>
          {t(
            flexEmployeesTranslations.configure.common.form.documentNumber.tooltip.item2
          )}
        </li>
      </ol>
    </>
  )
}
