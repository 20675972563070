import { OreButton, OreNavBarHeader, OreNavBarWrapper } from '@edenredespana/oreneta'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { locale } from '../i18n/i18n'

const SimplePageApp = ({
  children,
  hasLanguages,
}: {
  children: React.ReactNode
  hasLanguages?: boolean
}): JSX.Element => {
  const { t } = useTranslation()
  const [selectedLanguage, setSelectedLanguage] = useState('es')

  const handleEditLanguage = (event: React.MouseEvent<HTMLButtonElement>): void => {
    localStorage.setItem('i18nextLng', event.currentTarget.value)
    setSelectedLanguage(event.currentTarget.value)

    window.location.reload()
  }

  useEffect(() => {
    const currentLanguage = localStorage.getItem('i18nextLng')
    setSelectedLanguage(currentLanguage || 'es')
  }, [])

  return (
    <div style={{ display: 'flex' }}>
      {hasLanguages ? (
        <OreNavBarHeader>
          {locale.map(lang => (
            <OreButton
              id={lang}
              onClick={(event): void => {
                handleEditLanguage(event)
              }}
              category="tertiary"
              key={lang}
              value={lang}>
              {lang}
            </OreButton>
          ))}
        </OreNavBarHeader>
      ) : (
        <OreNavBarHeader />
      )}
      <OreNavBarWrapper>{children}</OreNavBarWrapper>
    </div>
  )
}

export { SimplePageApp }
