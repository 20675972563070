import { t } from 'i18next'
import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import type {
  FilterControlModel,
  FilterFieldModel,
  FilterModel,
  FooterModel,
  TableEmptyModel,
} from '../../../../../domain/customComponents/table'
import type {
  HeaderModel,
  RowModel,
} from '../../../../../domain/customComponents/table/TableModel'
import { invoiceTranslation } from '../../../../../domain/translations/payments/invoiceTranslation'
import { OrePill, SvgDownload } from '@edenredespana/oreneta'
import { creditNoteStatus, emptyValues, invoicesTabs } from '../../../../../domain/enum'
import {
  getDownloadTooltip,
  InvoicesService,
} from '../../../../../core/services/InvoiceService'
import { GetCreditNotesMap } from '../../../../../domain/adapters/invoiceAdapter'
import type { OrePillTonesTypes } from '../../../../../domain/runrom/IOrePillTones'
import { OrePillTones } from '../../../../../domain/runrom/IOrePillTones'
import type { ICreditNotesMapped } from '../../../../../domain/interfaces/invoice/ICreditNotesApiResponse'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { cardOrderService } from '../../../../../core/services'
import { forms } from '../../../../../domain/translations'
import { Seq } from 'immutable'
import { dash } from '../../../../../domain/constants/symbols'
import { useInvoiceSharedController } from '../../hooks/useInvoiceSharedController'
import { GetEdenredProducts } from 'src/core/services/productsService'

interface IUseCreditNotes {
  header: HeaderModel
  rows: RowModel[]
  setRows: Dispatch<SetStateAction<RowModel[]>>
  footer: FooterModel
  filter: FilterModel
  setFilter: Dispatch<SetStateAction<FilterModel>>
  onClickFilter: () => void
  tableEmpty: TableEmptyModel
}

interface Filters {
  orderNumberOrCreditNoteNumber?: string
  productCode?: number
  status?: number
  dateFrom?: string
  dateTo?: string
}

export const useCreditNotesController = (): IUseCreditNotes => {
  const [creditNotes, setCreditNotes] = useState<ICreditNotesMapped[]>([])
  const [rows, setRows] = useState<RowModel[]>([])
  const [page, setPage] = useState<number>(1)
  const [footer, setFooter] = useState<FooterModel>({})
  const [pageSize, setPageSize] = useState<number>(8)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [filter, setFilter] = useState<FilterModel>({})
  const isServerSide = true
  const { startLoading, stopLoading } = useLoader()
  const { GetProductIconByCode } = useInvoiceSharedController()

  const GetCreditNotes = (page: number): void => {
    startLoading()
    InvoicesService()
      .GetCreditNotes(SetQueryParams(page))
      .then(response => {
        setCreditNotes(GetCreditNotesMap(response.data.rows))
        setTotalRows(response.data.totalRows)
      })
      .finally(() => stopLoading())
  }

  const header: HeaderModel = {
    headerLabel: [
      { label: t(invoiceTranslation.creditNotes.table.header.date) },
      { label: t(invoiceTranslation.creditNotes.table.header.creditNoteNumber) },
      { label: t(invoiceTranslation.creditNotes.table.header.orderNumber) },
      { label: t(invoiceTranslation.creditNotes.table.header.product) },
      { label: t(invoiceTranslation.creditNotes.table.header.expireDate) },
      { label: t(invoiceTranslation.creditNotes.table.header.status) },
      { label: t(invoiceTranslation.creditNotes.table.header.amountTotal) },
      { label: t(invoiceTranslation.creditNotes.table.header.balanceDue) },
      { label: t(invoiceTranslation.creditNotes.table.header.actions) },
    ],
  }

  const tableEmpty: TableEmptyModel = {
    colSpan: 7,
    firstText: t(invoiceTranslation.invoices.table.tableEmpty.message),
  }

  const GetPillInvoiceTone = (status: number): OrePillTonesTypes => {
    switch (status) {
      case creditNoteStatus.available:
        return OrePillTones.cobalt
      case creditNoteStatus.refunded:
        return OrePillTones.success
      case creditNoteStatus.affected:
        return OrePillTones.warning
      case creditNoteStatus.cancelled:
        return OrePillTones.error
      default:
        return OrePillTones.gray
    }
  }

  const GetPill = (status: number, statusLabel: string): JSX.Element => {
    return <OrePill tone={GetPillInvoiceTone(status)}>{statusLabel}</OrePill>
  }

  const GetRows = (): void => {
    const creditNotesRows: RowModel[] = []
    creditNotes.forEach((creditNote: ICreditNotesMapped) => {
      creditNotesRows.push({
        rowId: creditNote.creditNoteId,
        cells: [
          {
            type: 'text',
            cellText: [
              { text: creditNote.creationDate, name: 'creationDate', size: 'text-sm' },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: creditNote.creditNoteNumber,
                name: 'creditNoteNumber',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: creditNote.orderNumber?.toString(),
                name: 'orderNumber',
                size: 'text-sm',
              },
            ],
          },
          { type: 'element', cellElement: GetProductIconByCode(creditNote.productCode) },
          {
            type: 'text',
            cellText: [
              { text: creditNote.limitDate, name: 'limitDate', size: 'text-sm' },
            ],
          },
          {
            type: 'element',
            cellElement: GetPill(creditNote.status, creditNote.statusDescription),
          },
          {
            type: 'text',
            cellText: [
              {
                text: creditNote.totalAmountDescription,
                name: 'totalAmount',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'text',
            cellText: [
              {
                text: creditNote.dueAmountDescription,
                name: 'dueAmount',
                size: 'text-sm',
              },
            ],
          },
          {
            type: 'actions',
            cellActions: [
              {
                name: 'download',
                icon: <SvgDownload />,
                type: 'download',
                tooltip: getDownloadTooltip(
                  creditNote.isInvoiceDownloadable,
                  creditNote.isInvoiceReadyToDownload,
                  invoicesTabs.creditNotes
                ),
                disabled:
                  !creditNote.isInvoiceDownloadable ||
                  !creditNote.isInvoiceReadyToDownload,
                onClick: () =>
                  downloadInvoice(
                    creditNote.creditNoteId,
                    creditNote.creditNoteNumber,
                    creditNote.isInvoiceDownloadable,
                    creditNote.isInvoiceReadyToDownload
                  ),
              },
            ],
          },
        ],
      })
    })
    setRows(creditNotesRows)
  }

  const downloadInvoice = (
    creditNoteId: number,
    creditNoteNumber: string,
    isInvoiceDownloadable: boolean,
    isInvoiceReadyToDownload: boolean
  ): void => {
    if (!isInvoiceDownloadable || !isInvoiceReadyToDownload) return
    startLoading()
    cardOrderService()
      .downloadInvoice(creditNoteId)
      .then(response => {
        const link = document.createElement('a')
        link.href = `data:application/pdf;base64,${response.data.file}`
        link.download = creditNoteNumber ? creditNoteNumber + '.pdf' : 'invoice.pdf'
        link.click()
        link.remove()
      })
      .finally(() => {
        stopLoading()
      })
  }

  const onPageChange = (page: number) => {
    setPage(page)
  }

  const getFooter = (): void => {
    setFooter({
      pagination: {
        onPageChange: onPageChange,
        labelNextPage: t(invoiceTranslation.creditNotes.table.pagination.next),
        labelPreviousPage: t(invoiceTranslation.creditNotes.table.pagination.back),
        rowsPerPage: pageSize,
        text: t(invoiceTranslation.creditNotes.table.pagination.of),
        page: page,
        isServerSide: isServerSide,
        totalRows: totalRows,
      },
    })
  }

  const GetMinDate = (): string => {
    const date: Date = new Date()
    date.setFullYear(date.getFullYear() + -2)
    const splitDate: string[] = date.toLocaleDateString('es-ES').split('/')
    const formatDate =
      splitDate[2] +
      dash +
      splitDate[1].padStart(2, '0') +
      dash +
      splitDate[0].padStart(2, '0')
    return formatDate
  }

  const GetMaxDate = (): string => {
    const date: Date = new Date()
    const splitDate: string[] = date.toLocaleDateString('es-ES').split('/')
    const formatDate =
      splitDate[2] +
      dash +
      splitDate[1].padStart(2, '0') +
      dash +
      splitDate[0].padStart(2, '0')
    return formatDate
  }

  const GetOrderStatus = (): [string, number][] => {
    const options: [string, number][] = []
    Seq(creditNotes).forEach((creditNote: ICreditNotesMapped) => {
      if (!options.find((option: [string, number]) => option[1] === creditNote.status))
        options.push([creditNote.statusDescription, creditNote.status])
    })
    return options
  }

  const GetFilters = (): void => {
    const filterValues = SetFilterValues()
    setFilter({
      search: {
        textBox: {
          fields: ['creditNoteNumber', 'orderNumber'],
          placeHolder: t(invoiceTranslation.creditNotes.filter.textBox),
          value:
            filterValues && filterValues.orderNumberOrCreditNoteNumber
              ? filterValues.orderNumberOrCreditNoteNumber.toString()
              : emptyValues.empty,
        },
        searchButton: t(invoiceTranslation.creditNotes.filter.searchButton),
      },
      resetButton: t(invoiceTranslation.creditNotes.filter.resetButton),
      filterButtonLabel: t(forms.filter.label),
      filterFields: [
        {
          formClass: 'form-atom form-atom--half',
          filterFields: [
            {
              filterType: 'datePicker',
              name: 'orderCreationDateFrom',
              label: t(invoiceTranslation.creditNotes.filter.dateFrom),
              placeHolder: t(
                invoiceTranslation.creditNotes.filter.datePickerPlaceHolder
              ).toUpperCase(),
              value:
                filterValues && filterValues.dateFrom
                  ? filterValues.dateFrom.toString()
                  : emptyValues.empty,
              min: GetMinDate(),
              max: GetMaxDate(),
            },
            {
              filterType: 'datePicker',
              name: 'orderCreationDateTo',
              label: t(invoiceTranslation.creditNotes.filter.dateTo),
              placeHolder: t(
                invoiceTranslation.creditNotes.filter.datePickerPlaceHolder
              ).toUpperCase(),
              value:
                filterValues && filterValues.dateTo
                  ? filterValues.dateTo.toString()
                  : emptyValues.empty,
              min: GetMinDate(),
              max: GetMaxDate(),
            },
          ],
        },
        {
          formClass: 'form-atom form-atom--half',
          filterFields: [
            {
              filterType: 'comboBox',
              name: 'productCode',
              label: t(invoiceTranslation.creditNotes.filter.product),
              placeHolder: t(invoiceTranslation.creditNotes.filter.comboBoxPlaceHolder),
              value:
                filterValues && filterValues.productCode
                  ? filterValues.productCode.toString()
                  : emptyValues.empty,
              options: [{ options: GetEdenredProducts() }],
            },
            {
              filterType: 'comboBox',
              name: 'status',
              label: t(invoiceTranslation.creditNotes.filter.status),
              placeHolder: t(invoiceTranslation.creditNotes.filter.comboBoxPlaceHolder),
              value:
                filterValues && filterValues.status
                  ? filterValues.status.toString()
                  : emptyValues.empty,
              options: [{ options: GetOrderStatus() }],
            },
          ],
        },
      ],
    })
  }

  const onClickFilter = (): void => {
    GetCreditNotes(1)
    setPage(1)
  }

  const SetFilterValues = (): Filters => {
    const tableFilters: Filters = {}
    if (filter.search && filter.search.textBox.value !== emptyValues.empty)
      tableFilters.orderNumberOrCreditNoteNumber = filter.search.textBox.value?.trim()
    if (filter.filterFields && filter.filterFields.length > 0) {
      Seq(filter.filterFields).forEach((fields: FilterFieldModel) => {
        Seq(fields.filterFields).forEach((field: FilterControlModel) => {
          if (field.name === 'productCode' && field.value !== emptyValues.empty)
            tableFilters.productCode = Number(field.value)
          if (field.name === 'status' && field.value !== emptyValues.empty)
            tableFilters.status = Number(field.value)
          if (field.name === 'orderCreationDateFrom' && field.value !== emptyValues.empty)
            tableFilters.dateFrom = field.value
          if (field.name === 'orderCreationDateTo' && field.value !== emptyValues.empty)
            tableFilters.dateTo = field.value
        })
      })
    }
    return tableFilters
  }

  const SetQueryParams = (page: number): string => {
    const tableFilters: Filters = SetFilterValues()
    let query = `?paginationModel.numberOfRecordsPerPage=${pageSize}&paginationModel.pageNumber=${
      page - 1
    }&paginationModel.orderByField=creationDate&paginationModel.sortDescending=true`
    if (tableFilters.orderNumberOrCreditNoteNumber !== undefined && query !== undefined)
      query += '&orderNumberOrCreditNote=' + tableFilters.orderNumberOrCreditNoteNumber
    if (tableFilters.orderNumberOrCreditNoteNumber !== undefined && query === undefined)
      query = '?orderNumberOrCreditNote=' + tableFilters.orderNumberOrCreditNoteNumber

    if (tableFilters.productCode !== undefined && query !== undefined)
      query += '&productCode=' + tableFilters.productCode
    if (tableFilters.productCode !== undefined && query === undefined)
      query = '?productCode=' + tableFilters.productCode

    if (tableFilters.status !== undefined && query !== undefined)
      query += '&status=' + tableFilters.status
    if (tableFilters.status !== undefined && query === undefined)
      query = '?status=' + tableFilters.status

    if (tableFilters.dateFrom !== undefined && query !== undefined)
      query += '&beginDate=' + tableFilters.dateFrom
    if (tableFilters.dateFrom !== undefined && query === undefined)
      query = '?beginDate=' + tableFilters.dateFrom

    if (tableFilters.dateTo !== undefined && query !== undefined)
      query += '&endDate=' + tableFilters.dateTo
    if (tableFilters.dateTo !== undefined && query === undefined)
      query = '?endDate=' + tableFilters.dateTo

    return query
  }

  useEffect(() => {
    GetCreditNotes(page)
  }, [page])

  useEffect(() => {
    GetRows()
    getFooter()
  }, [creditNotes])

  useEffect(() => {
    if (rows.length > 0) GetFilters()
  }, [rows])

  return {
    header,
    rows,
    setRows,
    footer,
    filter,
    setFilter,
    onClickFilter,
    tableEmpty,
  }
}
