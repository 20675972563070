import { OreButton, OreDivider, SvgArrowLeft } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { forms, recharge } from 'src/domain/translations'
import { Modal } from 'src/presentation/components/Edenred'
import { useCardOrder } from 'src/presentation/context/cardOrder/CardOrderProvider'
import { useNavigate } from 'react-router-dom'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { RechargeEmployeesState } from '../hooks/useRechargeEmployees'

interface Props {
  rechargeEmpoyeesProps: RechargeEmployeesState
}

export const RechargeEmployeesFooterButtons = ({
  rechargeEmpoyeesProps,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showDismissModal, setShowDismissModal, employeesSelected } =
    rechargeEmpoyeesProps
  const { clearCardOrderDuplicates, clearCardOrderRecharges, clearAll } = useCardOrder()

  const handleCloseModal = () => {
    setShowDismissModal(false)
    clearCardOrderDuplicates()
    clearCardOrderRecharges()
    clearAll()
    navigate(navigationRoutes.rechargeHome)
  }

  const getButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'tertiary',
        onClick: () => setShowDismissModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.delete),
        category: 'primary',
        onClick: () => handleCloseModal(),
        size: 'large',
      },
    ]
  }

  return (
    <div className="mt-2">
      <OreDivider />
      <div className="column-wrapper-buttons flex space-between">
        <OreButton
          as="button"
          category="highlight"
          onClick={() => navigate(-1)}
          size="large"
          style={{ color: '#0F172A' }}
          icon={<SvgArrowLeft />}>
          {t(forms.buttons.goBack)}
        </OreButton>
        <div style={{ marginLeft: 'auto' }}>
          <div className="flex flex--row float-right">
            <OreButton
              as="button"
              category="secondary"
              onClick={() => setShowDismissModal(true)}
              size="large">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              as="button"
              category="primary"
              onClick={() => navigate(navigationRoutes.cardOrderRechargeConfiguration)}
              size="large"
              disabled={employeesSelected.length <= 0}>
              {t(recharge.NextButtonLabel)}
            </OreButton>
          </div>
        </div>
        <Modal
          handleOnClose={setShowDismissModal}
          open={showDismissModal}
          title={t(recharge.cancelModal.title)}
          description={t(recharge.cancelModal.subtitle)}
          buttons={getButtons()}
          size="small"
        />
      </div>
    </div>
  )
}
