import React from 'react'
import { Divider } from '../../../../layout'
import { Grid } from '../../../Edenred/layout'
import { ChangePassword } from '../molecules/ChangePassword'
import { CompanyData } from '../molecules/CompanyData'
import { DeleteAccount } from '../molecules/DeleteAccount'
import { UserData } from '../molecules/UserData'
import { ChangeLanguage } from '../molecules/ChangeLanguage'
import { UserDataSso } from '../molecules/UserDataSso'
import { useAuth } from 'src/presentation/context/auth/AuthProvider'

export const AccountSettingsContainer = (): JSX.Element => {
  const { permissionTags } = useAuth()
  const isSso = permissionTags.includes('SSO')
  return (
    <>
      <Divider marginTop="1.5rem" marginBottom="3rem" />
      <CompanyData />

      {/* SSO LAYOUT */}
      {isSso && (
        <>
          <Grid columns={2} columnGap="4rem" rowGap="4rem" className="mt-4">
            <UserDataSso />
            <ChangeLanguage />
          </Grid>
          <Grid columns={2} columnGap="4rem" rowGap="4rem" className="mt-4">
            <DeleteAccount />
          </Grid>
        </>
      )}

      {/* REGULAR LAYOUT */}
      {!isSso && (
        <>
          <Grid columns={2} columnGap="4rem" rowGap="4rem" className="mt-4">
            <UserData />
            <ChangePassword />
          </Grid>
          <Grid columns={2} columnGap="4rem" rowGap="4rem" className="mt-4">
            <ChangeLanguage />
            <DeleteAccount />
          </Grid>
        </>
      )}
    </>
  )
}
