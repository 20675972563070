import {
  OreFormGroup,
  OreHeading,
  OreRadioButton,
  OreStack,
} from '@edenredespana/oreneta'

import { useFormContext } from 'react-hook-form'
import { EmployeeForm } from 'src/Flex/Employees/ui/employee-detail'

import { useTranslation } from 'react-i18next'
import { flexEmployeesTranslations } from 'src/Flex/Employees/ui/translations'
import { handleErrors } from 'src/presentation/sharedForms/helpers'

export const ConfigureEmployeesCommunicationEmail = (): JSX.Element => {
  const { t } = useTranslation()
  const { register, formState } = useFormContext<EmployeeForm>()
  const { errors } = handleErrors(formState)
  const sendWelcomeEmailErrors = errors('sendWelcomeEmail')

  return (
    <OreStack placeContent="stretch" space="medium">
      <OreHeading as="h2" size="headline-md">
        {t(flexEmployeesTranslations.configure.common.form.title_communication)}
      </OreHeading>
      <OreFormGroup
        label={t(flexEmployeesTranslations.configure.communication.cta.label)}
        required
        direction="row"
        {...sendWelcomeEmailErrors}>
        <OreRadioButton
          label={t(flexEmployeesTranslations.configure.communication.cta.yes)}
          value={'true'}
          {...register('sendWelcomeEmail')}
          hasError={sendWelcomeEmailErrors.hasError}
        />
        <OreRadioButton
          label={t(flexEmployeesTranslations.configure.communication.cta.no)}
          value={'false'}
          {...register('sendWelcomeEmail')}
          hasError={sendWelcomeEmailErrors.hasError}
        />
      </OreFormGroup>
    </OreStack>
  )
}
