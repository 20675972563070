import { OreButton, SvgArrowLeft } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { memo } from 'react'
import { forms } from '../../../../../domain/translations'
import { Modal, Table } from '../../../Edenred'
import { useEditRechargesConfigurationController } from '../hooks/useEditRechargeConfigurationController'

export const EditContainer = memo((): JSX.Element => {
  const {
    caption,
    header,
    rows,
    validateTable,
    setRows,
    clearCardOrderRecharges,
    navigate,
    ValidateTableFields,
    setShowPopup,
    popupShow,
    popupTitle,
    popupDescription,
    popupButtons,
    configurationParameters,
  } = useEditRechargesConfigurationController()
  const { t } = useTranslation()

  return (
    <>
      <Table
        caption={caption}
        header={header}
        multiSelected={false}
        rows={rows}
        setRows={setRows}
        validateTable={validateTable}>
        <div className="mt-2 flex space-between">
          {!configurationParameters?.repetOrder && (
            <OreButton
              as="button"
              category="highlight"
              onClick={() => {
                clearCardOrderRecharges()
                navigate(-1)
              }}
              size="large"
              style={{ color: '#0F172A' }}
              icon={<SvgArrowLeft />}>
              {t(forms.buttons.goBack)}
            </OreButton>
          )}
          <div style={{ marginLeft: 'auto' }}>
            <div className="flex flex--row float-right">
              <OreButton
                as="button"
                category="secondary"
                onClick={() => setShowPopup(true)}
                size="large">
                {t(forms.buttons.cancel)}
              </OreButton>
              <OreButton
                as="button"
                category="primary"
                onClick={event => ValidateTableFields(event)}
                disabled={rows.length === 0}
                size="large">
                {t(forms.buttons.addToOrder)}
              </OreButton>
            </div>
          </div>
        </div>
      </Table>
      <Modal
        handleOnClose={setShowPopup}
        open={popupShow}
        title={popupTitle}
        description={popupDescription}
        buttons={popupButtons}
      />
    </>
  )
})
