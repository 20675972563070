import { useTranslation } from 'react-i18next'
import {
  OreCheckbox,
  OreDatePicker,
  OreFormGroup,
  OreHeading,
  OreInput,
  OreRadioButton,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'
import {
  useOrderValidation,
  orderValidationsTranslations,
} from 'src/Flex/Orders/ui/order-validation'
import { ChargePayrollMonthOptions, isReadyToValidate } from 'src/Flex/Orders/domain'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { forms } from 'src/domain/translations'
import { GetMonthFormattedWithYear, getDaysAfter } from 'src/core/helpers'
import { edenredProducts } from 'src/domain/enum'

export const OrderValidationBasicForm = (): JSX.Element => {
  const { productType, orderToValidate, form, rows, isRequired } = useOrderValidation()
  const { errors } = handleErrors(form.formState)
  const { t } = useTranslation()
  if (!orderToValidate || !isReadyToValidate(orderToValidate) || rows.length === 0) {
    return <></>
  }

  const payrollMonthErrors = errors('chargePayrollMonth')
  const orderReferenceErrors = errors('orderReference')

  const nextMonth = orderToValidate.month ? new Date(orderToValidate.month) : null

  if (nextMonth) {
    nextMonth.setMonth(nextMonth.getMonth() + 1)
  }

  return (
    <>
      <OreStack space="large">
        <OreHeading size="headline-md">
          {t(orderValidationsTranslations.step1.complete.title)}
        </OreHeading>
        <OreText>
          {productType === edenredProducts.ticketGuarderia
            ? t(
                orderValidationsTranslations.step1.complete.recharge.description_guarderia
              )
            : t(orderValidationsTranslations.step1.complete.recharge.description)}
        </OreText>

        <div style={{ maxWidth: '540px' }}>
          <OreDatePicker
            id="chargeDate"
            {...form.register('chargeDate')}
            {...errors('chargeDate')}
            label={t(orderValidationsTranslations.step1.complete.recharge.date)}
            labelTooltip={t(orderValidationsTranslations.step1.complete.recharge.tooltip)}
            required
            min={getDaysAfter(1)}
          />
        </div>

        <OreFormGroup
          label={t(orderValidationsTranslations.step1.complete.imputate.month)}
          direction="row"
          {...payrollMonthErrors}
          required
          labelTooltip={t(orderValidationsTranslations.step1.complete.imputate.tooltip)}>
          <OreRadioButton
            {...form.register('chargePayrollMonth')}
            hasError={payrollMonthErrors.hasError}
            label={t(orderValidationsTranslations.step1.complete.imputate.current, {
              month: orderToValidate.month
                ? GetMonthFormattedWithYear(orderToValidate.month)
                : '',
            })}
            value={ChargePayrollMonthOptions.Current}
          />
          <OreRadioButton
            {...form.register('chargePayrollMonth')}
            hasError={payrollMonthErrors.hasError}
            label={t(orderValidationsTranslations.step1.complete.imputate.next, {
              month: nextMonth ? GetMonthFormattedWithYear(nextMonth) : null,
            })}
            value={ChargePayrollMonthOptions.Next}
          />
        </OreFormGroup>

        <OreText>
          {t(orderValidationsTranslations.step1.complete.number.description)}
        </OreText>

        <div style={{ maxWidth: '540px' }}>
          <OreInput
            id="orderReference"
            {...form.register('orderReference')}
            {...errors('orderReference')}
            label={t(orderValidationsTranslations.step1.complete.number.label)}
            required={isRequired}
            hasError={orderReferenceErrors.hasError}
            placeholder={t(
              orderValidationsTranslations.step1.complete.number.placeholder
            )}
          />
        </div>

        <OreText> {t(forms.errors.fieldsRequired)}</OreText>
      </OreStack>
    </>
  )
}
