import { UnloadConfigurationEmployeesList } from '../molecules/UnloadConfigurationEmployeesList'
import { UnloadConfigurationEmployeesFooterButtons } from '../molecules/UnloadConfigurationEmployeesFooterButtons'
import { UnloadConfigurationToolbar } from '../molecules/UnloadConfigurationToolbar'
import { useUnloadConfigurationEmployees } from '../hooks/useUnloadConfigurationEmployees'
import { OreMessageBar } from '@edenredespana/oreneta'
import { unloadsConfigurationTranslations } from 'src/domain/translations'
import { useTranslation } from 'react-i18next'
import { LeaveOrderPrompt } from 'src/presentation/components/Edenred/navigation'

export const UnloadConfigurationEmployeeContainer = () => {
  const props = useUnloadConfigurationEmployees()
  const { t } = useTranslation()

  return (
    <div>
      <div className="mb-2">
        <OreMessageBar color="warning" icon={false}>
          {t(unloadsConfigurationTranslations.warningMessage)}
        </OreMessageBar>
      </div>
      <UnloadConfigurationEmployeesList employeesTRProps={props} />
      <UnloadConfigurationEmployeesFooterButtons
        tableIsEmpty={props.employeesTR && props.employeesTR.length <= 0}
        addConfigToOrder={props.addConfigToOrder}
        form={props.form}
        cancelPopupButtons={props.cancelPopupButtons}
        onClickCancel={props.onClickCancel}
        setShowCancelModal={props.setShowCancelModal}
        showCancelModal={props.showCancelModal}
        errorMessage={props.errorMessage}
      />
      {props.selectedEmployees.length > 1 && (
        <UnloadConfigurationToolbar
          selectedEmployees={props.selectedEmployees.length}
          onAssignMultipleValues={props.onAssignMultipleValues}
        />
      )}
      <LeaveOrderPrompt />
    </div>
  )
}
