export const recharge = {
  title: 'recharge.title',
  excel: {
    textHtml1: 'recharge.excel.textHtml1',
    textHtml2: 'recharge.excel.textHtml2',
  },
  captionTitle: 'recharge.captionTitle',
  rowsTotalDescription: 'recharge.rowsTotalDescription',
  searchTitle: 'recharge.searchTitle',
  showSelected: 'recharge.showSelected',
  selectedRowsDescription: 'recharge.selectedRowsDescription',
  selectedRowsDescriptionOne: 'recharge.selectedRowsDescriptionOne',
  selectedRowsDescriptionOthers: 'recharge.selectedRowsDescriptionOthers',
  userUnavailable: 'recharge.userUnavailable',
  nextPageLabel: 'recharge.nextPageLabel',
  previousPageLabel: 'recharge.previousPageLabel',
  pageOf: 'recharge.pageOf',
  NextButtonLabel: 'recharge.NextButtonLabel',
  backButtonLabel: 'recharge.backButtonLabel',
  searchNoFound: {
    first: 'recharge.searchNoFound.first',
    link: 'recharge.searchNoFound.link',
    end: 'recharge.searchNoFound.end',
  },
  tableHeader: {
    owner: 'recharge.tableHeader.owner',
    employeeNumber: 'recharge.tableHeader.employeeNumber',
    balance: 'recharge.tableHeader.balance',
    balanceTooltip: 'recharge.tableHeader.balanceTooltip',
    center: 'recharge.tableHeader.center',
    type: 'recharge.tableHeader.type',
    initialBalance: 'recharge.tableHeader.initialBalance',
    initialBalanceTooltip: 'recharge.tableHeader.initialBalanceTooltip',
    finalBalance: 'recharge.tableHeader.finalBalance',
    finalBalanceTooltip: 'recharge.tableHeader.finalBalanceTooltip',
    rechargeAmount: 'recharge.tableHeader.rechargeAmount',
    actions: 'recharge.tableHeader.actions',
    cardStatus: 'recharge.tableHeader.cardStatus',
  },
  tableFooter: {
    addRecharge: 'recharge.tableFooter.addRecharge',
    editAll: 'recharge.tableFooter.editAll',
  },
  tableEmpty: {
    message: 'recharge.tableEmpty.message',
  },
  tableCaption: {
    title: 'recharge.tableCaption.title',
    description: 'recharge.tableCaption.description',
  },
  tableAction: {
    delete: {
      title: 'recharge.tableAction.delete.title',
      description: 'recharge.tableAction.delete.description',
    },
  },
  rechargeCards: 'recharge.rechargeCards',
  cancelModal: {
    title: 'recharge.cancelModal.title',
    subtitle: 'recharge.cancelModal.subtitle',
  },
}
