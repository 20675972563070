import {
  OreCheckbox,
  OreDatePicker,
  OreHeading,
  OreInput,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { TGDBasicOrderSummaryState } from '../hooks/useTGDBasicOrderSummaryContainer'
import { FormProvider } from 'react-hook-form'
import {
  getLastDayOfMonthOneYearLater,
  getLastMilesimaDate,
  getTomorrow,
} from 'src/core/helpers'
import { useUser } from 'src/presentation/context/user/UserProvider'
import { edenredProducts } from 'src/domain/enum'
import { cardOrderTranslation } from 'src/domain/translations'
import { useTGDBasicOrder } from '../../context/TGDBasicOrderProvider'
import { TGDBasicOrderTranslation } from 'src/domain/translations/tgdOrders/tgdBasicOrderTranslate'
import { TGDBasicOrderSummaryNoOrderDescription } from './TGDBasicOrderSummaryNoOrderDescription'

interface Props {
  tgdProps: TGDBasicOrderSummaryState
}

export const TGDBasicOrderSummaryForm = ({ tgdProps }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { form } = tgdProps
  const { contracts } = useUser()
  const currentContract = contracts?.find(
    contract => contract.productCode === edenredProducts.ticketGuarderia
  )
  const { tgdOrders } = useTGDBasicOrder()
  return (
    <FormProvider {...form}>
      <div style={{ width: '100%' }}>
        {tgdOrders && tgdOrders.length > 0 && (
          <>
            <OreStack direction="column" placeContent="stretch">
              <OreStack>
                <OreHeading size="headline-md">
                  {t(TGDBasicOrderTranslation.summary.completeOrder.title)}
                </OreHeading>
              </OreStack>
              <OreStack className="width-70 pt-05">
                <OreText>
                  {t(TGDBasicOrderTranslation.summary.completeOrder.dateDescription)}
                </OreText>
              </OreStack>
              <div className="form-row form-row-nopadding">
                <div className="form-atom form-atom--three">
                  <OreDatePicker
                    {...form.register('initialChargeDate')}
                    name="initialChargeDate"
                    label={t(
                      TGDBasicOrderTranslation.summary.completeOrder.dateAssignment
                    )}
                    placeholder={t(
                      TGDBasicOrderTranslation.summary.completeOrder
                        .dateAssignmentPlaceholder
                    )}
                    labelTooltip={t(
                      TGDBasicOrderTranslation.summary.completeOrder.dateAssignment
                    )}
                    id="initialChargeDate"
                    min={getTomorrow()}
                    max={
                      currentContract?.allowMilesima
                        ? getLastMilesimaDate()
                        : getLastDayOfMonthOneYearLater()
                    }
                    hasError={!!form.formState.errors?.initialChargeDate}
                    errorMessage={form.formState.errors?.initialChargeDate?.message}
                    required
                  />
                </div>
              </div>
              <OreStack className="width-70 pt-05">
                <OreText>
                  {t(
                    TGDBasicOrderTranslation.summary.completeOrder
                      .billReferenceDescription
                  )}
                </OreText>
              </OreStack>
              <OreStack placeContent="normal" space="2xsmall">
                <OreText tone="neutral-500">
                  {t(TGDBasicOrderTranslation.summary.completeOrder.billReference)}
                </OreText>
                <div className="form-row form-row-nopadding">
                  <div className="form-atom form-atom--three">
                    <OreInput
                      {...form.register('billReference')}
                      name="billReference"
                      id="billReference"
                      placeholder="Ej: FC98568"
                      maxLength={400}
                      required={currentContract?.isCommentRequiredFromClient}
                      hasError={!!form.formState.errors?.billReference}
                      errorMessage={form.formState.errors?.billReference?.message}
                    />
                  </div>
                </div>
              </OreStack>
            </OreStack>
            <div className="pt-1">
              <OreText tone="neutral-600">
                {t(TGDBasicOrderTranslation.summary.completeOrder.fieldsRequired)}
              </OreText>
            </div>
            <div className="form-section float-left mt-2 inline-flex">
              <OreCheckbox
                {...form.register('acceptConditions')}
                label={<CheckboxLabel />}
                name="acceptConditions"
                hasError={!!form.formState.errors?.acceptConditions}
                errorMessage={form.formState.errors?.acceptConditions?.message}
              />
            </div>
          </>
        )}
        {tgdOrders && tgdOrders.length <= 0 && <TGDBasicOrderSummaryNoOrderDescription />}
      </div>
    </FormProvider>
  )
}

const CheckboxLabel = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <div>
      <OreText size="text-md">
        {t(cardOrderTranslation.cardOrderHome.termsAcceptanceExcel1)}
      </OreText>
      <br />
      <OreText size="text-md">
        {t(cardOrderTranslation.cardOrderHome.termsAcceptanceExcel2)}
      </OreText>
    </div>
  )
}
