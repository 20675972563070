import {
  OreTab,
  OreTabs,
  SvgKindergarten,
  SvgRestaurant,
  SvgTransport,
} from '@edenredespana/oreneta'
import { useEmployeeProfileContainerController } from '../hooks'
import { edenredProducts } from 'src/domain/enum'
import { EmployeeProfileProductCardContainerTT } from '../employeesTT/employeeProfile/organism'
import { EmployeeProfileProductCardContainerTR } from '../employeesTR/employeeProfile/organism'
import { EmployeeProfileProductCardContainerTGD } from '../employeesTGD/employeeProfile/organism'
import { useTranslation } from 'react-i18next'
import { productsTranslate } from 'src/domain/translations'
import { DeleteClientEmployeeResponse } from 'src/domain/models/cardOrder/DeleteClientEmployees'

interface Props {
  addSuccessNotification: (
    response: DeleteClientEmployeeResponse,
    isDeletingProduct: boolean
  ) => void
}

export const EmployeeProfileContainer = ({
  addSuccessNotification,
}: Props): JSX.Element => {
  const { selectedTab, handleTabClick, employeeProducts, productCodes, setProductCodes } =
    useEmployeeProfileContainerController()
  const { t } = useTranslation()

  if (!employeeProducts) {
    return <></>
  }

  return (
    <div className="mt-3">
      <OreTabs>
        {productCodes?.includes(edenredProducts.ticketRestaurant) && (
          <OreTab
            key="tab-1"
            handleClick={() => handleTabClick(edenredProducts.ticketRestaurant)}
            active={selectedTab === edenredProducts.ticketRestaurant}
            label={t(productsTranslate.ticketRestaurant)}
            icon={<SvgRestaurant />}
          />
        )}
        {productCodes?.includes(edenredProducts.ticketTransporte) && (
          <OreTab
            key="tab-2"
            handleClick={() => handleTabClick(edenredProducts.ticketTransporte)}
            active={selectedTab === edenredProducts.ticketTransporte}
            label={t(productsTranslate.ticketTransporte)}
            icon={<SvgTransport />}
          />
        )}
        {productCodes?.includes(edenredProducts.ticketGuarderia) && (
          <OreTab
            key="tab-3"
            handleClick={() => handleTabClick(edenredProducts.ticketGuarderia)}
            active={selectedTab === edenredProducts.ticketGuarderia}
            label={t(productsTranslate.ticketGuarderia)}
            icon={<SvgKindergarten />}
          />
        )}
      </OreTabs>

      {selectedTab === edenredProducts.ticketRestaurant && (
        <EmployeeProfileProductCardContainerTR
          addSuccessNotification={addSuccessNotification}
          productCodes={productCodes}
          setProductCodes={setProductCodes}
        />
      )}
      {selectedTab === edenredProducts.ticketTransporte && (
        <EmployeeProfileProductCardContainerTT
          addSuccessNotification={addSuccessNotification}
          productCodes={productCodes}
          setProductCodes={setProductCodes}
        />
      )}
      {selectedTab === edenredProducts.ticketGuarderia && (
        <EmployeeProfileProductCardContainerTGD
          addSuccessNotification={addSuccessNotification}
          productCodes={productCodes}
          setProductCodes={setProductCodes}
        />
      )}
    </div>
  )
}
