import { OreButton, OreHeading, OreStack, OreWysiwyg } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import SuccessLogo from 'src/presentation/assets/img/Success.svg'
import { orderImputationsTranslations } from '../orderImputationsTranslations'
import {
  OrderValidationError,
  orderValidationsTranslations,
} from '../../order-validation'
import { useOrders } from '../contexts'

export const FlexOrderImputationSuccess = (): JSX.Element => {
  const { backToHome, backToPayroll, errorData, backToStart } = useOrders()
  const { t } = useTranslation()
  return errorData ? (
    <OrderValidationError
      errors={errorData}
      back={backToStart}
      backToHome={backToHome}
      excelError={''}
    />
  ) : (
    <div style={{ maxWidth: '600px', margin: '0 auto' }}>
      <OreStack placeContent="center" placeItems="center" space="large">
        <img src={SuccessLogo} alt="" />
        <OreHeading as="h1" size="title-sm" align="center">
          {t(orderImputationsTranslations.stepThree.success.title)}
        </OreHeading>
        <OreWysiwyg>
          <p style={{ textAlign: 'center' }}>
            {t(orderImputationsTranslations.stepThree.success.description)}
          </p>
        </OreWysiwyg>
        <OreStack
          direction="column"
          space="small"
          placeContent="space-between"
          placeItems="center">
          <div className="mt-1">
            <OreButton
              as="button"
              category="primary"
              size="large"
              onClick={backToPayroll}>
              {t(orderImputationsTranslations.stepThree.success.check_payroll)}
            </OreButton>
          </div>
          <OreButton
            as="button"
            category="highlight"
            size="large"
            style={{ color: '#0F172A' }}
            onClick={backToHome}>
            {t(orderValidationsTranslations.step3.error.goHome)}
          </OreButton>
        </OreStack>
      </OreStack>
    </div>
  )
}
