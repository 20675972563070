import { OreButton, SvgChevronDown, SvgChevronUp } from '@edenredespana/oreneta'
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
  useEffect,
  ReactNode,
} from 'react'

interface DropdownProps {
  buttonLabel: string
  buttonIcon?: JSX.Element
  onSelect?: (value: string) => void // Make onSelect optional
  children: ReactNode // Accept ButtonDropdownItem as dropdown items
}

export const ButtonDropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ buttonLabel, buttonIcon, onSelect, children }, ref) => {
    const [isOpen, setIsOpen] = useState(false)
    const [highlightedIndex, setHighlightedIndex] = useState(0)
    const dropdownRef = useRef<HTMLDivElement>(null)

    // Forwarding the ref to the dropdown element
    useImperativeHandle(ref, () => dropdownRef.current as HTMLDivElement)

    const toggleDropdown = () => {
      setIsOpen(prev => !prev)
    }

    const handleOptionClick = (value: string) => {
      if (onSelect) {
        onSelect(value)
      }
      setIsOpen(false)
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowDown':
          setHighlightedIndex(prev => (prev + 1) % React.Children.count(children))
          break
        case 'ArrowUp':
          setHighlightedIndex(
            prev =>
              (prev - 1 + React.Children.count(children)) % React.Children.count(children)
          )
          break
        case 'Enter':
          if (isOpen) {
            const selectedChild = React.Children.toArray(children)[
              highlightedIndex
            ] as React.ReactElement
            handleOptionClick(selectedChild.props.value) // Assuming each child has a 'value' prop
            setIsOpen(false)
          } else {
            toggleDropdown()
          }
          break
        case 'Escape':
          setIsOpen(false)
          break
        default:
          break
      }
    }

    // Close dropdown when clicking outside
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsOpen(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [])

    return (
      <div
        ref={dropdownRef}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        style={{ position: 'relative', display: 'inline-block', zIndex: 1 }}>
        <OreButton
          icon={buttonIcon}
          aria-haspopup="true"
          aria-expanded={isOpen}
          onClick={toggleDropdown}>
          {buttonLabel} {isOpen ? <SvgChevronUp /> : <SvgChevronDown />}
        </OreButton>
        {isOpen && (
          <ul
            role="menu"
            className={`ore-distributor-list ${
              isOpen ? 'ore-distributor-list--open' : ''
            }`}
            style={{ width: '100%' }}>
            {React.Children.map(children, (child, index) => (
              <li
                key={index}
                role="menuitem"
                onClick={() =>
                  handleOptionClick((child as React.ReactElement).props.value)
                } // Assuming each child has a 'value' prop
                className="ore-distributor-list-item"
                onMouseEnter={() => setHighlightedIndex(index)}>
                {child}
              </li>
            ))}
          </ul>
        )}
      </div>
    )
  }
)

export default ButtonDropdown
