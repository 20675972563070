import { OreButton, OreHeading, SvgArrowLeft, SvgSave } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  cardOrderService,
  getUrlCompleteByCardOrderType,
} from '../../../../../core/services/cardOrderService'
import { edenredProducts, Repositories } from '../../../../../domain/enum'
import {
  cardRequestType,
  currency,
  orderType,
} from '../../../../../domain/enum/cardOrder'
import { forms } from '../../../../../domain/translations'
import { cardOrderTranslation } from '../../../../../domain/translations/cardOrder/cardOrder'
import { useCardOrder } from '../../../../context/cardOrder/CardOrderProvider'
import { useLoader } from '../../../../context/loader/LoaderProvider'
import { useModal } from '../../../../hooks'
import { Divider } from '../../../../layout'
import { CardOrderCheckout } from '../molecules/CardOrderCheckout'
import { CardOrderCheckoutHeader } from '../molecules/CardOrderCheckoutHeader'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import type { CardOrderCompleteModel, MetaModel } from '../../../../../domain/models'
import { RepositoryFactory } from '../../../../../core/factories'
import type { LoadExcelOrder } from '../../../../../domain/bodyRequest/ExcelOrders'
import { useEffect } from 'react'
import { EmptySaveCardOrder } from '../../../../../domain/protocols'
import { GetExcelOrderTypeRequest } from '../../../../../core/controllers/cardOrder/services'
import { CardOrderSaveAndClose } from '../../cardOrderSaveAndClose'

export const Checkout = (): JSX.Element => {
  const { t } = useTranslation()
  const {
    orders,
    orderExcel,
    cardOrderData,
    setCardOrderData,
    cardOrderCheckout,
    isOrderExcel,
    base64File,
    orderContext,
  } = useCardOrder()
  const { showModal: showSaveCloseModal, setShowModal: setShowSaveCloseModal } =
    useModal()
  const { startLoading, stopLoading } = useLoader()

  const navigate = useNavigate()

  async function confirmFormOrder() {
    if (cardOrderData) {
      startLoading()
      const url = getUrlCompleteByCardOrderType(orders[0].orderTypeId)
      const { data, meta } = await cardOrderService()
        .createCardOrder(
          {
            ...cardOrderData,
            cardOrders: orders,
            productCode: edenredProducts.ticketRestaurant,
          },
          url
        )
        .finally(() => stopLoading())
      // TODO: Pendiente de que backend cambie el modelo de la respuesta, la respuesta trata recarga y descarga en la misma propiedad
      if (data && orderContext.orderType === orderType.Unload)
        data.unloadOrderId = data.chargeOrderId
      RedirectToResultPage(meta, data)
    }
  }

  async function confirmExcel() {
    startLoading()
    const loadExcelOrder: LoadExcelOrder = {
      orderExcelType: GetExcelOrderTypeRequest(orderContext.orderType),
      productCode: edenredProducts.ticketRestaurant,
      excel: base64File,
      orderDate: orderExcel.detail.initialChargeDate,
      expirationDate: orderExcel.detail.expiredDate,
      invoiceComment: orderExcel.detail.billReference || '', // invoice reference?
      stopOnWarnings: false,
    }
    const repository = RepositoryFactory(Repositories.excel)

    try {
      const { data, meta } = await repository.confirmOrder(loadExcelOrder)
      // TODO: Pendiente de que backend cambie el modelo de la respuesta, la respuesta trata recarga y descarga en la misma propiedad
      if (data && orderContext.orderType === orderType.Unload)
        data.unloadOrderId = data.chargeOrderId
      RedirectToResultPage(meta, data)
    } catch {
      // await error fallback for loading modal
      stopLoading()
    }
  }

  function RedirectToResultPage(meta: MetaModel, data: CardOrderCompleteModel | null) {
    stopLoading()
    meta.status === 'ERROR'
      ? navigate(navigationRoutes.cardOrderCompleteError, {
          state: {
            success: false,
            errorMessage: meta.messages,
            excel: data?.additionalData || '',
          },
        })
      : navigate(navigationRoutes.cardOrderCompleteSuccess, {
          state: { success: true, data: { ...data }, excel: '' },
        })
  }

  const confirmOrder = async () => {
    if (isOrderExcel()) await confirmExcel()
    else await confirmFormOrder()
  }

  useEffect(() => {
    if (isOrderExcel()) {
      const currentOrder = { ...EmptySaveCardOrder }
      currentOrder.initialChargeDate = cardOrderCheckout?.detail.initialChargeDate || ''
      currentOrder.expiredDate = cardOrderCheckout?.detail.expiredDate || ''
      currentOrder.billReference = cardOrderCheckout?.detail.billReference || ''
      setCardOrderData(currentOrder)
    }
  }, [])

  return cardOrderCheckout && cardOrderData ? (
    <>
      <CardOrderCheckoutHeader
        cardDetail={{ ...cardOrderCheckout.detail, ...cardOrderData }}
      />
      <CardOrderCheckout
        data={cardOrderCheckout.card}
        cardRequestTypeId={cardRequestType.newCard}
        quantity={cardOrderCheckout.card?.quantity}
      />
      <CardOrderCheckout
        data={cardOrderCheckout.charge}
        cardRequestTypeId={cardRequestType.charge}
        quantity={cardOrderCheckout.charge?.quantity}
      />
      <section
        className="width-max"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <OreHeading as="h4" size="headline-md">
          {t(cardOrderTranslation.cardOrderResume.totalAmount)}
        </OreHeading>
        <OreHeading as="h4" size="headline-md">
          {cardOrderCheckout.totalAmount + currency.euro}
        </OreHeading>
      </section>

      <section className="width-max">
        <Divider />
        <div className="flex flex--row mt-2 space-between">
          <div className="flex flex--row">
            <>
              <OreButton
                as="button"
                category="highlight"
                onClick={() => navigate(-1)}
                size="large"
                style={{ color: '#0F172A' }}
                icon={<SvgArrowLeft />}>
                {t(forms.buttons.goBack)}
              </OreButton>

              {
                // save button only with forms
                !isOrderExcel() && (
                  <OreButton
                    as="button"
                    category="highlight"
                    onClick={() => setShowSaveCloseModal(true)}
                    size="large"
                    style={{ color: '#0F172A' }}
                    icon={<SvgSave />}>
                    {t(forms.buttons.saveAndClose)}
                  </OreButton>
                )
              }
            </>
          </div>

          <div className="flex flex--row float-right">
            <OreButton
              as="button"
              category="secondary"
              onClick={() =>
                navigate(navigationRoutes.myOrders, {
                  state: {
                    cancelModal: true,
                  },
                })
              }
              size="large">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton
              as="button"
              category="primary"
              onClick={() => confirmOrder()}
              size="large">
              {t(cardOrderTranslation.cardOrderResume.confirmOrder)}
            </OreButton>
          </div>
        </div>
      </section>

      {
        // save button only with forms
        !isOrderExcel() && (
          <CardOrderSaveAndClose
            open={showSaveCloseModal}
            handleOnClose={setShowSaveCloseModal}
          />
        )
      }
    </>
  ) : (
    <></>
  )
}
