import { OreText } from '@edenredespana/oreneta'
import { AlignPosition } from '../../../../../domain/enum'
import { useDistributorController } from '../../../Edenred/distributor/hook/useDistributorController'
import { DistributorsContainer } from '../../../Edenred/distributor/organism/DistributorsContainer'
import { clientHomeTranslation } from '../../../../../domain/translations/client/home'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { navigationRoutes } from '../../../../../config/constants/navigationRoutes'
import { Grid, GridItem } from '../../../Edenred/layout'
import KindergartenHomeImg from '../../../../assets/img/KindergartenHome.png'
import RedCircleImg from '../../../../assets/img/RedCircle.svg'

export const FrequentOperationsContainerTGD = (): JSX.Element => {
  const { t } = useTranslation()
  const { getTGDPlusDistributor } = useDistributorController()

  return (
    <Grid gap="2rem" columns={2}>
      <Grid direction="column" gap="2rem">
        <GridItem>
          <p className="mt-1">
            <OreText size="text-sm" as="span">
              {t(clientHomeTranslation.kindergarten.frequentDescription1)}
            </OreText>
            <Link to={navigationRoutes.myOrdersHistory}>
              <OreText size="text-sm" as="span">
                {t(clientHomeTranslation.kindergarten.frequentDescription2)}
              </OreText>
            </Link>
            <OreText size="text-sm" as="span">
              {t(clientHomeTranslation.kindergarten.frequentDescription3)}
            </OreText>
          </p>
        </GridItem>
        <GridItem>
          <DistributorsContainer
            position={AlignPosition.horizontal}
            distributors={getTGDPlusDistributor()}
          />
        </GridItem>
      </Grid>
      <Grid className="kindergarten-home__container">
        <GridItem className="kindergarten-home__wrapper">
          <img src={KindergartenHomeImg} className="kindergarten-home__hero-image" />
          <img src={RedCircleImg} className="kindergarten-home__hero-circle" />
        </GridItem>
      </Grid>
    </Grid>
  )
}
