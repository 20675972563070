import {
  OreCheckbox,
  OreInput,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
} from '@edenredespana/oreneta'
import { OrderValidationLine } from 'src/Flex/Orders/domain'
import { currency } from 'src/domain/enum'
import { useOrderValidation } from 'src/Flex/Orders/ui/order-validation'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { orderImputationsTranslations } from '../../order-imputation'
import { useTranslation } from 'react-i18next'

type OrderValidationRowProps = {
  orderLine: OrderValidationLine
}

export const OrderValidationRowGuarderia = ({
  orderLine,
}: OrderValidationRowProps): JSX.Element => {
  const { form } = useOrderValidation()
  const { errors } = handleErrors(form.formState, form.getFieldState)
  const { t } = useTranslation()
  return (
    <OreTableRow>
      <OreTableCell>
        <input
          type="hidden"
          {...form.register(`orderLines.${orderLine.position}.id`)}
          value={orderLine.id}
        />
        <OreTooltip
          text={
            form.getValues(`orderLines.${orderLine.position}.active`)
              ? t(orderImputationsTranslations.configure.delete.checkImputationTooltip)
              : t(orderImputationsTranslations.configure.delete.uncheckImputationTooltip)
          }
          placement="top">
          <div>
            <OreCheckbox
              {...form.register(`orderLines.${orderLine.position}.active`)}
              {...errors(`orderLines.${orderLine.position}.active`)}
            />
          </div>
        </OreTooltip>
      </OreTableCell>
      <OreTableCell size="third">
        <OreText bold tone="neutral-800" size="text-sm">
          {orderLine.employee.name}
        </OreText>
        <OreText size="text-sm">{orderLine.employee.document}</OreText>
      </OreTableCell>
      <OreTableCell size="quarter">
        <OreText bold tone="neutral-800" size="text-sm">
          {orderLine.deliverySite}
        </OreText>
        <OreText size="text-sm">{orderLine.beneficiary?.name}</OreText>
      </OreTableCell>
      <OreTableCell size="quarter">
        <OreInput
          {...form.register(`orderLines.${orderLine.position}.amount`)}
          {...errors(`orderLines.${orderLine.position}.amount`)}
          type="number"
          startAdornment={currency.euro}
        />
      </OreTableCell>
      <OreTableCell size="quarter">{orderLine.costCenter}</OreTableCell>
    </OreTableRow>
  )
}
