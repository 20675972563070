import {
  OreCheckbox,
  OreTableCell,
  OreTableRow,
  OreText,
  OreTooltip,
} from '@edenredespana/oreneta'
import { ChangeEvent, Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { getCardOrderStatusLabelById, getUndefinedDashString } from 'src/core/services'
import { EmployeesWithLastCard } from 'src/domain/models'
import { OreTooltipCustom } from 'src/presentation/components/Edenred/OreTooltipCustom'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { lastCardStatus } from 'src/domain/enum'
import { duplicatedTranslation } from 'src/domain/translations'

interface Props {
  employeeTT: EmployeesWithLastCard
  selectedEmployees: EmployeesWithLastCard[]
  setSelectedEmployees: Dispatch<SetStateAction<EmployeesWithLastCard[]>>
  onSelectEmployee(
    event: ChangeEvent<HTMLInputElement>,
    employee: EmployeesWithLastCard
  ): void
  isSelected(employee: EmployeesWithLastCard): boolean
  isInContext: (employee: EmployeesWithLastCard) => boolean
}

export const TTOrderSelectMultipleEmployeesRow = ({
  employeeTT,
  onSelectEmployee,
  isSelected,
  isInContext,
}: Props): JSX.Element => {
  const { t } = useTranslation()

  const getRowWithTooltip = (): JSX.Element => {
    return (
      <OreTooltipCustom
        text={t(TTOrderTranslation.selectMultipleEmployees.table.duplicatedTooltip)}
        placement="left"
        separation="xsmall">
        {getRow()}
      </OreTooltipCustom>
    )
  }

  const getRow = (): JSX.Element => {
    return (
      <OreTableRow valign="middle">
        <OreTableCell align="left" as="td" size="auto">
          {employeeTT.cardStatusId < +lastCardStatus.confirmed ? (
            <OreTooltip
              placement="bottom"
              separation="small"
              text={t(duplicatedTranslation.userUnavailable)}>
              <div>
                <OreCheckbox
                  name="check"
                  onChange={() => null}
                  role="checkbox"
                  aria-checked={false}
                  disabled={true}
                />
              </div>
            </OreTooltip>
          ) : (
            <OreCheckbox
              name="check"
              onChange={event => onSelectEmployee(event, employeeTT)}
              role="checkbox"
              aria-checked={isSelected(employeeTT)}
              checked={isSelected(employeeTT)}
              disabled={isInContext(employeeTT)}
            />
          )}
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText bold size="text-sm" tone="neutral-900">
            {employeeTT.userName}
          </OreText>
          <OreText size="text-sm" tone="neutral-500">
            {employeeTT.document}
          </OreText>
          <OreText size="text-sm" tone="neutral-500">
            {employeeTT.email}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          {getUndefinedDashString(employeeTT.employeeNumber)}
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          {employeeTT.balance}€
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          {t(getCardOrderStatusLabelById(employeeTT.cardStatusId))}
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          {employeeTT.expiredDate.toString()}
        </OreTableCell>
      </OreTableRow>
    )
  }

  return isInContext(employeeTT) ? getRowWithTooltip() : getRow()
}
