import { Modal } from 'src/presentation/components/Edenred'
import { AccountUsersAndLicensesTable } from '../molecules/AccountUsersAndLicensesTable'
import { AccountUsersAndLicensesState } from '../hooks/useAccountUsersAndLicences'
import { AccountUsersAndLicensesForm } from '../molecules/AccountUsersAndLicensesForm'
import { OreText } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { accountUsersLicensesTranslations } from 'src/domain/translations/myAccount/accountUsersLicenses'

interface Props {
  props: AccountUsersAndLicensesState
}

export const AccountUsersAndLicensesContainer = ({ props }: Props): JSX.Element => {
  const {
    showAdd,
    setShowAdd,
    showAddButtons,
    showAddTitle,
    showAddDescription,
    errorMessage,
  } = props
  const { t } = useTranslation()

  return (
    <>
      <div className="mb-2 width-75">
        <OreText>{t(accountUsersLicensesTranslations.description)}</OreText>
      </div>
      <AccountUsersAndLicensesTable props={props} />
      <Modal
        handleOnClose={setShowAdd}
        open={showAdd}
        title={showAddTitle}
        showDivider={true}
        description={showAddDescription}
        buttons={showAddButtons}
        size={'large'}>
        <AccountUsersAndLicensesForm
          form={props.form}
          getUserDataForm={props.getUserDataForm}
          errorMessage={errorMessage}
        />
      </Modal>
    </>
  )
}
