import { edenredProducts, MetaStatusCodes } from 'src/domain/enum'
import { HttpModel } from 'src/domain/models'
import { productsService } from 'src/Flex/Products/application'
import {
  ExcelFile,
  PolicyModel,
  ProductConfigurationModels,
  ProductModel,
} from 'src/Flex/Products/domain'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'

interface UseEmployeContractAlert<K extends keyof ProductConfigurationModels> {
  getExcel: (
    productType: edenredProducts,
    product: ProductConfigurationModels[K],
    collectiveId?: string
  ) => Promise<string>
  getExcelHealth: (policy: PolicyModel) => Promise<string>
  getExcelDeleteHealth: (policyId: string) => Promise<string>
}

export const useEmployeContractAlert = <
  K extends keyof ProductConfigurationModels
>(): UseEmployeContractAlert<K> => {
  const { handleMetaResponse } = useMetaResponseHandler()
  const { startLoading, stopLoading } = useLoader()

  const getExcel = async (
    productType: edenredProducts,
    product: ProductConfigurationModels[K],
    collectiveId?: string
  ): Promise<string> => {
    let result = ''
    startLoading()
    const response = await productsService().GetEmployeesAffectedExcel(
      productType,
      product,
      collectiveId
    )
    stopLoading()
    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      result = response?.data.file
    }
    return result
  }

  const getExcelHealth = async (policy: PolicyModel): Promise<string> => {
    let result = ''
    startLoading()
    const response = await productsService().GetEmployeesAffectedExcelHealth(policy)
    stopLoading()
    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      result = response?.data.file
    }
    return result
  }

  const getExcelDeleteHealth = async (policyId: string): Promise<string> => {
    let result = ''
    startLoading()
    const response = await productsService().GetEmployeesAffectedExcelDeleteHealth(
      policyId
    )
    stopLoading()
    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      result = response?.data.file
    }
    return result
  }

  return {
    getExcel,
    getExcelHealth,
    getExcelDeleteHealth,
  }
}
