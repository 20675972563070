import {
  OreIconButton,
  OreTableCell,
  OreTableRow,
  OreText,
  OreStack,
  OreInput,
  OreCheckbox,
  SvgBin,
  OreTooltip,
} from '@edenredespana/oreneta'
import { useFormContext } from 'react-hook-form'
import {
  ImputationForm,
  orderImputationsTranslations,
} from 'src/Flex/Orders/ui/order-imputation'

import { OrderImputationModel } from 'src/Flex/Orders/domain'
import { useTranslation } from 'react-i18next'
import { currency } from 'src/core/helpers'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
interface IProps {
  order: OrderImputationModel
  deleteOrder: (id: string) => void
}

export const FlexOrderImputationRow = ({ order, deleteOrder }: IProps): JSX.Element => {
  const { t } = useTranslation()
  const {
    id,
    position,
    owner,
    ownerId,
    totalImport,
    remaining,
    isEditable,
    company,
    name,
  } = order
  const { register, formState, getFieldState, getValues } =
    useFormContext<ImputationForm>()
  const { errors } = handleErrors(formState, getFieldState)

  return (
    <>
      <OreTableRow valign="middle">
        <OreTableCell>
          <OreTooltip
            text={
              getValues(`orders.${position}.active`)
                ? t(orderImputationsTranslations.configure.delete.checkImputationTooltip)
                : t(
                    orderImputationsTranslations.configure.delete.uncheckImputationTooltip
                  )
            }
            placement="top">
            <div>
              <OreCheckbox {...register(`orders.${position}.active`)} />
            </div>
          </OreTooltip>
        </OreTableCell>
        <OreTableCell size="quarter">
          <div className="flex">
            <OreStack space="2xsmall">
              <OreText size="text-sm" tone="neutral-900" bold={true}>
                {owner}
              </OreText>
              <OreText size="text-sm" tone="neutral-600">
                {ownerId}
              </OreText>
            </OreStack>
          </div>
        </OreTableCell>
        <OreTableCell>
          <>
            <OreText bold>{company}</OreText>
            <OreText>{name}</OreText>
          </>
        </OreTableCell>
        <OreTableCell>
          <OreInput
            disabled={!isEditable}
            id="import"
            type="number"
            startAdornment={'€'}
            {...register(`orders.${position}.value`)}
            {...errors(`orders.${position}.value`)}
          />
        </OreTableCell>
        <OreTableCell>{currency(remaining)}</OreTableCell>
        <OreTableCell>{currency(totalImport)}</OreTableCell>
        <OreTableCell>
          <OreTooltip
            text={t(
              orderImputationsTranslations.configure.delete.removeImputationTooltip
            )}
            placement="top">
            <OreIconButton
              icon={<SvgBin />}
              title={t(orderImputationsTranslations.configure.delete.title)}
              onClick={() => deleteOrder(id)}
            />
          </OreTooltip>
        </OreTableCell>
      </OreTableRow>
    </>
  )
}
