import { forwardRef, useImperativeHandle, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  OreButton,
  OreHeading,
  OreModal,
  OreModalBox,
  OreModalBoxBody,
  OreModalBoxFooter,
  OreStack,
  OreText,
} from '@edenredespana/oreneta'
import { forms } from 'src/domain/translations'
import { TemplateModel } from 'src/Flex/CommunicationsTemplates/domain'
import { flexCommunicationsTemplatesTranslations } from '../../translations'
import { useTemplates } from '../contexts'

export type DeleteModalActions = {
  open: (template: TemplateModel) => void
}

interface IProps {
  ref: React.Ref<DeleteModalActions>
}

export const DeleteTemplateModal = forwardRef<DeleteModalActions, IProps>(
  (_, ref): JSX.Element => {
    const [open, setOpen] = useState<boolean>(false)
    const [template, setTemplate] = useState<TemplateModel>()
    const { t } = useTranslation()
    const { deleteTemplate } = useTemplates()

    useImperativeHandle(ref, () => ({
      open: async (template: TemplateModel): Promise<void> => {
        setTemplate(template)
        setOpen(true)
      },
    }))

    const saveAndClose = async (): Promise<void> => {
      if (!template) return

      deleteTemplate(template).then(response => {
        if (response) {
          setOpen(false)
        }
      })
    }

    return (
      <OreModal open={open} handleOnClose={() => setOpen(false)}>
        <OreModalBox size="medium" handleOnClose={() => setOpen(false)}>
          <OreModalBoxBody>
            <OreHeading align="left" size="headline-md">
              {t(flexCommunicationsTemplatesTranslations.deleteModal.title)}
            </OreHeading>
            <OreStack space="large">
              <OreText align="left">
                {t(flexCommunicationsTemplatesTranslations.deleteModal.subtitle)}
              </OreText>
            </OreStack>
          </OreModalBoxBody>
          <OreModalBoxFooter>
            <OreButton onClick={() => setOpen(false)} size="small" category="secondary">
              {t(forms.buttons.cancel)}
            </OreButton>
            <OreButton onClick={saveAndClose} size="small" category="danger">
              {t(forms.buttons.delete)}
            </OreButton>
          </OreModalBoxFooter>
        </OreModalBox>
      </OreModal>
    )
  }
)
