import {
  OreButton,
  OreInput,
  OreSwitch,
  OreToggleButton,
  SvgFilter,
} from '@edenredespana/oreneta'
import type { Dispatch, KeyboardEvent, SetStateAction } from 'react'
import { memo } from 'react'
import type { FilterModel } from '../../../../../domain/customComponents/table'
import { FilterField, useFilter } from '../..'
import { Seq } from 'immutable'

interface FilterProps {
  filter: FilterModel
  setFilter: Dispatch<SetStateAction<FilterModel>>
  onClick: () => void
}

export const Filter = memo((props: { filterTable: FilterProps }): JSX.Element => {
  const {
    filter,
    openFilters,
    onClickOpenFilters,
    onChangeTextFilter,
    onClickResetFilter,
    setFilter,
    onChangeOptions,
    ResetOptionsValue,
  } = useFilter(
    props.filterTable.filter,
    props.filterTable.setFilter,
    props.filterTable.filter && props.filterTable.filter.showOpen
      ? props.filterTable.filter.showOpen
      : false
  )

  const onEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      props.filterTable.onClick()
      ResetOptionsValue()
    }
  }
  return (
    <div style={{ backgroundColor: '#FFF', padding: '24px 16px' }}>
      <div
        className="flex space-between items-center"
        style={{ gap: '1rem', paddingBottom: '24px' }}>
        <div className="flex items-center" style={{ gap: '0.5rem', width: '50%' }}>
          {filter && filter.search && filter.search.textBox.fields.length > 0 && (
            <OreInput
              placeholder={filter.search.textBox.placeHolder}
              onChange={event => onChangeTextFilter(event)}
              value={filter.search.textBox.value}
              type="search"
              onKeyUp={e => onEnter(e)}
            />
          )}
          {filter && filter.search && !openFilters && (
            <OreButton
              as="button"
              category="primary"
              onClick={() => {
                props.filterTable.onClick()
                ResetOptionsValue()
              }}
              size="small">
              {filter.search.searchButton}
            </OreButton>
          )}
        </div>
        <div className="flex items-center" style={{ gap: '1rem' }}>
          {filter && filter.filterFields && (
            <OreToggleButton
              icon={<SvgFilter aria-label="Filtros" />}
              onClick={event => onClickOpenFilters(event)}
              title={filter.filterButtonLabel}>
              {filter.filterButtonLabel}
            </OreToggleButton>
          )}
          {filter &&
            filter.filerOptions &&
            filter.filerOptions.length > 0 &&
            Seq(filter.filerOptions).map((options, index) => (
              <OreSwitch
                label={options.label}
                name={options.name}
                key={'option_' + index}
                role="checkbox"
                onChange={event => onChangeOptions(event, options.name)}
                checked={options.checked}
              />
            ))}
        </div>
      </div>
      {filter &&
        filter.search &&
        filter.filterFields &&
        filter.filterFields.length > 0 &&
        openFilters && (
          <>
            <div
              style={{
                borderRadius: '16px',
                background: '#E2E8F0',
                padding: '24px 28px',
                marginBottom: '24px',
              }}>
              <section className="form-molecule">
                <FilterField fields={filter.filterFields} setFilter={setFilter} />
              </section>
            </div>
            <div className="flex" style={{ gap: '1rem' }}>
              <OreButton
                as="button"
                category="primary"
                onClick={event => {
                  props.filterTable.onClick()
                  ResetOptionsValue()
                }}
                size="small">
                {filter.search?.searchButton}
              </OreButton>
              <OreButton
                as="button"
                category="secondary"
                size="small"
                onClick={event => onClickResetFilter(event)}>
                {filter.resetButton}
              </OreButton>
            </div>
          </>
        )}
    </div>
  )
})
