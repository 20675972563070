import { OreTab, OreTabs } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { edenredProducts } from 'src/domain/enum'
import { productsTranslate } from 'src/domain/translations'
import { useProductTabsController } from 'src/presentation/components/hooks/useProductTabsController'
import cx from 'classnames'
import { Divider } from 'src/presentation/layout'
import { ClientHomeFrequentActionsContainer } from './ClientHomeFrequentActionsContainer'
import { FrequentOperationsContainerTR } from './FrequentOperationsContainerTR'
import { FrequentOperationsContainerTGD } from './FrequentOperationsContainerTGD'
import { FrequentOperationsContainerTT } from './FrequentOperationsContainerTT'
import { RoleStandarizationMessageBar } from 'src/presentation/components/roleStandarization/molecules/RoleStandarizationMessageBar'

interface ContainerProps {
  navigateValue?: edenredProducts
}
export const ClientHomeContainer = (props: ContainerProps) => {
  const { t } = useTranslation()

  const { handleTabClick, selectedTab, getIconByTab, hasTR, hasTT, hasTGD } =
    useProductTabsController(props.navigateValue)

  return (
    <>
      <div className="pt-1">
        <RoleStandarizationMessageBar />
      </div>
      <div className="pt-3">
        <OreTabs>
          {hasTR && (
            <OreTab
              handleClick={() => handleTabClick(edenredProducts.ticketRestaurant)}
              active={selectedTab === edenredProducts.ticketRestaurant && true}
              label={t(productsTranslate.ticketRestaurant)}
              icon={getIconByTab(edenredProducts.ticketRestaurant)}
            />
          )}
          {hasTT && (
            <OreTab
              handleClick={() => handleTabClick(edenredProducts.ticketTransporte)}
              active={selectedTab === edenredProducts.ticketTransporte && true}
              label={t(productsTranslate.ticketTransporte)}
              icon={getIconByTab(edenredProducts.ticketTransporte)}
            />
          )}
          {hasTGD && (
            <OreTab
              handleClick={() => handleTabClick(edenredProducts.ticketGuarderia)}
              active={selectedTab === edenredProducts.ticketGuarderia && true}
              label={t(productsTranslate.ticketGuarderia)}
              icon={getIconByTab(edenredProducts.ticketGuarderia)}
            />
          )}
        </OreTabs>
        <div className={cx({ 'pt-3': true }, { 'hero-pt-reduced': false })}>
          {selectedTab === edenredProducts.ticketRestaurant && (
            <FrequentOperationsContainerTR />
          )}
          {selectedTab === edenredProducts.ticketTransporte && (
            <FrequentOperationsContainerTT />
          )}
          {selectedTab === edenredProducts.ticketGuarderia && (
            <FrequentOperationsContainerTGD />
          )}
          <Divider marginTop="4rem" marginBottom="4rem" />
          <ClientHomeFrequentActionsContainer />
        </div>
      </div>
    </>
  )
}
