import { OreHeading, OreStack, OreText } from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'

export const TGDOrderSummaryNoOrderDescription = (): JSX.Element => {
  const { t } = useTranslation()
  return (
    <OreStack direction="column">
      <OreStack>
        <OreHeading size="headline-md">
          {t(TGDOrderTranslation.summaryAssignment.table.emptyTable.createNewOrderTitle)}
        </OreHeading>
      </OreStack>
      <OreStack className="width-70 pt-05">
        <OreText>
          {t(
            TGDOrderTranslation.summaryAssignment.table.emptyTable
              .createNewOrderDescription
          )}
        </OreText>
      </OreStack>
      <OreStack className="pt-05">
        <OreText>
          {t(
            TGDOrderTranslation.summaryAssignment.table.emptyTable.createNewOrderOption1
          )}
        </OreText>
        <OreText>
          {t(
            TGDOrderTranslation.summaryAssignment.table.emptyTable.createNewOrderOption2
          )}
        </OreText>
        <OreText>
          {t(
            TGDOrderTranslation.summaryAssignment.table.emptyTable.createNewOrderOption3
          )}
        </OreText>
      </OreStack>
    </OreStack>
  )
}
