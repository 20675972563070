import { useTranslation } from 'react-i18next'
import { OreInput, OreStack } from '@edenredespana/oreneta'
import { flexCommunicationsTemplatesTranslations } from 'src/Flex/CommunicationsTemplates/ui/translations'
import { useTemplatesEdit } from 'src/Flex/CommunicationsTemplates/ui/templates-detail'
import { handleErrors } from 'src/presentation/sharedForms/helpers'
import { templateTypes } from 'src/Flex/CommunicationsTemplates/domain'

interface TemplateEditProps {
  id: string | undefined
}

export const TemplatesEditBasic = ({ id }: TemplateEditProps): JSX.Element => {
  const { t } = useTranslation()
  const {
    form: { register, formState },
    model,
  } = useTemplatesEdit()

  const { errors } = handleErrors(formState)

  return (
    <OreStack space="medium" placeContent="stretch">
      {id === undefined ? (
        <OreInput
          {...register('name')}
          {...errors('name')}
          id="name"
          label={t(
            flexCommunicationsTemplatesTranslations.configure.form.title_template.label
          )}
          placeholder={t(
            flexCommunicationsTemplatesTranslations.configure.form.title_template
              .placeholder
          )}
          required
        />
      ) : null}
      {id === undefined ? (
        <OreInput
          {...register('description')}
          {...errors('description')}
          id="description"
          label={t(
            flexCommunicationsTemplatesTranslations.configure.form.description_template
              .label
          )}
          placeholder={t(
            flexCommunicationsTemplatesTranslations.configure.form.description_template
              .placeholder
          )}
        />
      ) : null}
      <OreInput
        {...register('subject')}
        id="subject"
        label={
          model?.type === templateTypes.email
            ? t(flexCommunicationsTemplatesTranslations.configure.form.subject.label)
            : t(flexCommunicationsTemplatesTranslations.configure.form.name.label)
        }
        placeholder={
          model?.type === templateTypes.email
            ? t(
                flexCommunicationsTemplatesTranslations.configure.form.subject.placeholder
              )
            : t(flexCommunicationsTemplatesTranslations.configure.form.name.placeholder)
        }
        {...errors('subject')}
        required
      />
    </OreStack>
  )
}
