import {
  OreCardContainer,
  OreCardWrapper,
  OreHeading,
  OreStack,
  OreTable,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableHead,
  OreTableRow,
  OreText,
} from '@edenredespana/oreneta'
import { useTranslation } from 'react-i18next'
import { flexEmployeesTranslations } from '../../translations'
import {
  ConfigureEmployeeExternalHealthRow,
  ConfigureEmployeeInternalHealthRow,
  ConfigureEmployeeTGDRow,
  ConfigureEmployeeTrainingRow,
  ConfigureEmployeeTRRow,
  ConfigureEmployeeTTRow,
} from '../molecules'
import { useConfigureEmployeeProducts } from '../hooks'
import { useEffect } from 'react'

export const ConfigureEmployeeProductList = ({ employeeId }: { employeeId: string }) => {
  const { t } = useTranslation()

  const { productList, getProductsByEmployee, deleteProductEmployee } =
    useConfigureEmployeeProducts()

  useEffect(() => {
    employeeId && getProductsByEmployee(employeeId)
  }, [])

  useEffect(() => {
    // console.log(productList)
  }, [productList])

  if (!productList) return null

  if (
    productList &&
    !productList.ticketRestaurant &&
    !productList.ticketTransporte &&
    productList.ticketGuarderia?.length === 0 &&
    productList.formation?.length === 0 &&
    productList.healthInsurance?.length === 0 &&
    productList.externalHealthInsurance?.length === 0
  )
    return (
      <OreCardContainer>
        <OreCardWrapper>
          <OreStack space="medium">
            <OreHeading as="h2" size="headline-md">
              {t(flexEmployeesTranslations.configure.products.title)}
            </OreHeading>
            <OreText>
              {t(flexEmployeesTranslations.configure.products.noProducts)}
            </OreText>
          </OreStack>
        </OreCardWrapper>
      </OreCardContainer>
    )

  return (
    <>
      <OreCardContainer>
        <OreCardWrapper>
          <OreStack space="medium">
            <OreHeading as="h2" size="headline-md">
              {t(flexEmployeesTranslations.configure.products.title)}
            </OreHeading>
            <OreText>
              {t(flexEmployeesTranslations.configure.products.description)}
            </OreText>
            <OreTableContainer>
              <OreTable>
                <OreTableHead valign="middle">
                  <OreTableRow hover={false}>
                    <OreTableCell as="th">
                      {t(flexEmployeesTranslations.configure.products.table.head.name)}
                    </OreTableCell>
                    <OreTableCell as="th" colSpan={2}>
                      <OreStack direction="row" placeContent="space-between">
                        <div>
                          {t(
                            flexEmployeesTranslations.configure.products.table.head.detail
                          )}
                        </div>
                        {/* <div className="custom-row--action">
                          {t(
                            flexEmployeesTranslations.configure.products.table.head
                              .actions
                          )}
                        </div> */}
                      </OreStack>
                    </OreTableCell>
                  </OreTableRow>
                </OreTableHead>
                <OreTableBody valign="top">
                  {productList.ticketRestaurant && (
                    <ConfigureEmployeeTRRow
                      product={productList.ticketRestaurant}
                      employeeId={employeeId}
                      deleteProductEmployee={deleteProductEmployee}
                    />
                  )}
                  {productList.ticketTransporte && (
                    <ConfigureEmployeeTTRow
                      product={productList.ticketTransporte}
                      employeeId={employeeId}
                      deleteProductEmployee={deleteProductEmployee}
                    />
                  )}
                  {productList.ticketGuarderia &&
                    productList.ticketGuarderia?.length > 0 && (
                      <ConfigureEmployeeTGDRow
                        products={productList.ticketGuarderia}
                        employeeId={employeeId}
                        deleteProductEmployee={deleteProductEmployee}
                      />
                    )}
                  {productList.formation && productList.formation?.length > 0 && (
                    <ConfigureEmployeeTrainingRow
                      products={productList.formation}
                      employeeId={employeeId}
                      deleteProductEmployee={deleteProductEmployee}
                    />
                  )}
                  {productList.healthInsurance &&
                    productList.healthInsurance?.length > 0 && (
                      <ConfigureEmployeeInternalHealthRow
                        products={productList.healthInsurance}
                        employeeId={employeeId}
                        deleteProductEmployee={deleteProductEmployee}
                      />
                    )}
                  {productList.externalHealthInsurance &&
                    productList.externalHealthInsurance?.length > 0 && (
                      <ConfigureEmployeeExternalHealthRow
                        products={productList.externalHealthInsurance}
                        employeeId={employeeId}
                        deleteProductEmployee={deleteProductEmployee}
                      />
                    )}
                </OreTableBody>
              </OreTable>
            </OreTableContainer>
          </OreStack>
        </OreCardWrapper>
      </OreCardContainer>
    </>
  )
}
