import { OreCardTitle, SvgKindergarten } from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { employeesTranslation } from '../../../../../../domain/translations/cardOrder/employees'
import { Grid } from '../../../../Edenred/layout'
import { useEmployeeProfileController } from '../hooks'
import { EmployeesNurseryModel } from 'src/domain/models/employeesNursery'

interface EmployeeProfileCardDataTGDProps {
  employeeData: EmployeesNurseryModel
}

export const EmployeeProfileCardDataTGD = ({
  employeeData,
}: EmployeeProfileCardDataTGDProps): JSX.Element => {
  const { t } = useTranslation()

  if (!employeeData) {
    return <></>
  }

  return (
    <Grid justify="space-between" items="center">
      <OreCardTitle
        icon={<SvgKindergarten />}
        title={t(employeesTranslation.profile.card.kindergartenTicked)}
        tone="kindergarten"
      />
    </Grid>
  )
}
