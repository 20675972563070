import React from 'react'
import {
  OreButton,
  OreDropZone,
  OreHeading,
  OreMessageBar,
  OreSpinner,
  OreText,
  OreWysiwyg,
  SvgExcel,
} from '@edenredespana/oreneta'
import { Download } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import {
  cardOrderFormTranslation,
  cardOrderTranslation,
  duplicatedTranslation,
  forms,
} from '../../../../../../domain/translations'
import { Modal } from '../../../../Edenred'
import { useExcelOrder } from '../hooks/useExcelOrder'
import { OrderFooter } from '../../../cardOrderForm/molecules'
import {
  ButtonDropdown,
  ButtonDropdownItem,
} from 'src/presentation/components/Edenred/ButtonDropdown'
import { orderType } from 'src/domain/enum'

export interface UploadExcelStepProps {
  product: number
  additionalHelp?: React.ReactNode
}

export const UploadExcelStep = (uploadExcelStepProps: UploadExcelStepProps) => {
  const {
    Continue,
    register,
    errors,
    getCancelButtons,
    showCancelModal,
    setShowCancelModal,
    validationWaitModal,
    uploadError,
    downloadTemplate,
    getDescriptionHtmlText,
    orderContext,
  } = useExcelOrder(uploadExcelStepProps)
  const { t } = useTranslation()

  return (
    <>
      {/*{"MAIN CONTENT"}*/}
      <div>
        <div className="mb-2">
          <OreWysiwyg>
            <OreText
              dangerouslySetInnerHTML={{
                __html: getDescriptionHtmlText('text1'),
              }}
            />
          </OreWysiwyg>
          <br />
          <div className="list-items-space-narrow">
            <OreWysiwyg>
              <OreText
                dangerouslySetInnerHTML={{
                  __html: getDescriptionHtmlText('text2'),
                }}
              />
            </OreWysiwyg>
          </div>
        </div>
        <div className="vertical-double-stack big-wrapper">
          <div className="horizontal-stack mt-1">
            {orderContext.orderType === orderType.Card ? (
              <OreButton
                icon={<Download />}
                type="button"
                onClick={() => downloadTemplate(true)}>
                {t(forms.buttons.downloadTemplate)}
              </OreButton>
            ) : (
              <ButtonDropdown
                buttonLabel={t(forms.buttons.downloadTemplate)}
                buttonIcon={<Download />}>
                <ButtonDropdownItem
                  title={t(forms.buttons.downloadEmptyTemplate)}
                  icon={<SvgExcel />}
                  handleClick={() => downloadTemplate(true)}
                />
                <ButtonDropdownItem
                  title={t(forms.buttons.downloadPreLoadedTemplate)}
                  icon={<SvgExcel />}
                  handleClick={() => downloadTemplate(false)}
                />
              </ButtonDropdown>
            )}
            {uploadExcelStepProps.additionalHelp}
          </div>

          <div className="mt-3">
            <OreDropZone
              {...register('file')}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              dropText={
                <>
                  {t(cardOrderTranslation.excel.dropFileOr)}{' '}
                  <OreText as="span" tone="cobalt">
                    {t(cardOrderTranslation.excel.browseFile)}
                  </OreText>
                </>
              }
              hasError={errors.file?.message !== undefined}
              errorMessage={errors.file?.message}
              deleteFileText={t(cardOrderTranslation.excel.deleteFileText)}
              errorUploadMessage={t(cardOrderTranslation.excel.errorUploadMessage)}
              supportsText={t(cardOrderTranslation.excel.supportsText)}
              validMessage={t(cardOrderTranslation.excel.validMessage)}
            />
          </div>
          <hr className="line" />
        </div>
      </div>
      {/*{"MAIN CONTENT END"}*/}

      {uploadError && (
        <OreMessageBar color="error">
          {t(cardOrderTranslation.excel.errorUploadMessage)}
        </OreMessageBar>
      )}

      <OrderFooter
        continueButtonLabel={t(forms.buttons.validateFile)}
        onContinueClick={Continue}
      />

      {/*MODALS*/}
      <>
        {/*{"EXIT MODAL"}*/}
        <Modal
          handleOnClose={setShowCancelModal}
          open={showCancelModal}
          title={t(duplicatedTranslation.cancelModal.title)}
          description={t(duplicatedTranslation.cancelModal.subtitle)}
          buttons={getCancelButtons()}
          size="small"
        />

        {/*{"VALIDATION MODAL"}*/}
        <Modal
          handleOnClose={() => true}
          closeIcon={false}
          open={validationWaitModal}
          topChildren={
            <div className="flex justify-center">
              <OreSpinner />
            </div>
          }>
          <>
            <OreHeading size="headline-lg" align="center">
              {t(cardOrderTranslation.excel.validationTitle)}
            </OreHeading>
            <OreText align="center">
              {t(cardOrderTranslation.excel.validationBody)}
            </OreText>
          </>
        </Modal>
      </>
      {/*MODALS END*/}
    </>
  )
}
