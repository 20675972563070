import {
  OreNavBarContent,
  OreNavBarTab,
  OreNavBarTabs,
  OreNavBarVertical,
} from '@edenredespana/oreneta'
import { useUI } from '../../../../context/ui/UIProvider'
import { useManagedNavigation, useSidebar } from '../hooks'
import { SidebarBackdrop, SidebarItem } from './molecules'

export interface SidebarItemModel {
  title?: string
  icon?: JSX.Element
  pillText?: string | JSX.Element
  link?: string
  childs?: SidebarItemModel[] // Means is Parent
  protectedBy?: string
  activeLinks?: string[]
}

export interface SidebarSectionModel {
  title?: string
  items?: SidebarItemModel[]
  protectedBy?: string
}

export type SidebarMenuItemModel = SidebarSectionModel & SidebarItemModel

const Sidebar = (): JSX.Element => {
  const { sidebarCollapsed } = useUI()
  const {
    hover,
    handleMouseEnter,
    handleMouseLeave,
    openBackdrop,
    sidebarTab,
    changeTab,
  } = useSidebar()
  const { getSidebarMenu, isFlex } = useManagedNavigation()

  return (
    <aside className="sidebar">
      {openBackdrop && <SidebarBackdrop />}
      <OreNavBarVertical
        handleMouseEnter={handleMouseEnter}
        handleMouseLeave={handleMouseLeave}
        hover={hover}
        height="calc(100vh - 64px)"
        collapse={sidebarCollapsed}>
        {isFlex ? (
          <OreNavBarTabs hover={hover} collapse={sidebarCollapsed}>
            <OreNavBarTab
              collapse={sidebarCollapsed}
              onChange={() => changeTab(0)}
              checked={sidebarTab === 0}
              id="tab-1"
              label="General"
              hover={hover}
            />
            <OreNavBarTab
              collapse={sidebarCollapsed}
              onChange={() => changeTab(1)}
              id="tab-2"
              checked={sidebarTab === 1}
              label="Flex"
              hover={hover}
            />
          </OreNavBarTabs>
        ) : null}
        <OreNavBarContent tabs={isFlex} hidden={sidebarTab === 1}>
          {getSidebarMenu(0).map((menuItem: SidebarMenuItemModel, index: number) => (
            <SidebarItem
              menuItem={menuItem}
              index={index}
              hover={hover}
              key={`tab_0_${index}`}
              isFlex={false}
            />
          ))}
        </OreNavBarContent>
        {isFlex ? (
          <OreNavBarContent tabs={isFlex} hidden={sidebarTab === 0}>
            {getSidebarMenu(1).map((menuItem: SidebarMenuItemModel, index: number) => (
              <SidebarItem
                menuItem={menuItem}
                index={index}
                hover={hover}
                key={`tab_1_${index}`}
                isFlex={true}
              />
            ))}
          </OreNavBarContent>
        ) : null}
      </OreNavBarVertical>
    </aside>
  )
}

export default Sidebar
