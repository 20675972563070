import { OreText } from '@edenredespana/oreneta'
import React from 'react'

interface ButtonDropdownItemProps {
  handleClick: () => void
  title: React.ReactNode
  icon?: React.ReactNode
}

export const ButtonDropdownItem: React.FC<ButtonDropdownItemProps> = ({
  handleClick,
  title,
  icon,
}) => {
  return (
    <button className="ore-distributor-list-item__link" onClick={handleClick}>
      {icon} <OreText tone="neutral-600">{title}</OreText>
    </button>
  )
}

export default ButtonDropdownItem
