import {
  OreButton,
  OreCheckbox,
  OreInput,
  OreTableCell,
  OreTableRow,
  OreText,
  SvgOut,
} from '@edenredespana/oreneta'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { currency } from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { UseFormReturn } from 'react-hook-form'
import { EmployeesTTOrderFormModel } from '../hooks/useTTUnloadConfigurationEmployees'
import { TTOrderModel } from 'src/domain/models/TTOrder/TTOrderModel'
import { useTTOrder } from '../../context/TTOrderProvider'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'

interface Props {
  employeeTT: TTOrderModel
  onSelectEmployee(event: ChangeEvent<HTMLInputElement>, employee: TTOrderModel): void
  isSelected(employee: TTOrderModel): boolean
  form: UseFormReturn<EmployeesTTOrderFormModel, any>
  rowId: number
  page: number
  pageSize: number
  updateDataRowToTTOrderEmployee: (
    userId: number,
    initialAmount: string,
    costCenter: string
  ) => void
  unloadAllBalance: (userId?: number) => void
}

export const TTUnloadConfigurationEmployeesRow = ({
  employeeTT,
  updateDataRowToTTOrderEmployee,
  onSelectEmployee,
  isSelected,
  form,
  rowId,
  page,
  pageSize,
  unloadAllBalance,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { getTTEmployeesToConfig } = useTTOrder()

  const GetCurrentIndex = (index: number): number => {
    return index + (page - 1) * pageSize
  }

  const setInitialAmountValue = (index: number, amount: number) => {
    form.setValue(`employeesTT.${index}.cardData.initialAmount`, amount)
    employeeTT.cardData.initialAmount = amount
  }
  const setCostCenterValue = (index: number, costCenter: string) => {
    form.setValue(`employeesTT.${index}.costCenter`, costCenter)
    employeeTT.costCenter = costCenter
  }

  const index = GetCurrentIndex(rowId)

  return (
    <>
      <OreTableRow valign="top">
        {getTTEmployeesToConfig().length > 1 && (
          <OreTableCell align="left" as="td" size="auto">
            <OreCheckbox
              name="check"
              onChange={event => onSelectEmployee(event, employeeTT)}
              role="checkbox"
              aria-checked={isSelected(employeeTT)}
              checked={isSelected(employeeTT)}
            />
          </OreTableCell>
        )}
        <OreTableCell align="left" as="td" size="auto">
          <OreText bold size="text-sm" tone="neutral-900">
            {employeeTT.name}
          </OreText>
          <OreText size="text-sm" tone="neutral-500">
            {employeeTT.document}
          </OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreText>{currency.euro + employeeTT.cardData.balance}</OreText>
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreInput
            {...form.register(`employeesTT.${index}.cardData.initialAmount`)}
            placeholder={'200.00'}
            required
            startAdornment={currency.euro}
            type="number"
            autoComplete="off"
            hasError={
              !!form.formState.errors?.employeesTT?.[index]?.cardData?.initialAmount
            }
            errorMessage={
              form.formState.errors?.employeesTT?.[index]?.cardData?.initialAmount
                ?.message
            }
            onChange={e => setInitialAmountValue(index, +e.target.value)}
          />
        </OreTableCell>
        <OreTableCell align="left" as="td" size="auto">
          <OreInput
            {...form.register(`employeesTT.${index}.costCenter`)}
            placeholder={t(forms.placeholders.employee.costCenter)}
            required
            maxLength={50}
            type="text"
            autoComplete="off"
            hasError={!!form.formState.errors?.employeesTT?.[index]?.costCenter}
            errorMessage={
              form.formState.errors?.employeesTT?.[index]?.costCenter?.message
            }
            onChange={e => setCostCenterValue(index, e.target.value)}
          />
        </OreTableCell>
        <OreTableCell
          align="left"
          as="td"
          size="auto"
          style={{ verticalAlign: 'middle' }}>
          <OreButton
            category="tertiary"
            size="small"
            icon={<SvgOut />}
            onClick={() => unloadAllBalance(employeeTT.userId)}>
            {t(TTOrderTranslation.unload.employeesConfiguration.unloadBalance)}
          </OreButton>
        </OreTableCell>
      </OreTableRow>
    </>
  )
}
