import { OreMessageBar } from '@edenredespana/oreneta'
import type { MessageBarModel } from '../../../domain/customComponents/MessageBarModel'

export const MessageBar = (props: MessageBarModel): JSX.Element => {
  if (props.message && props.message !== '')
    return (
      <div className={props.className}>
        <OreMessageBar color="error">{props.message}</OreMessageBar>
      </div>
    )
  else return <></>
}
