import {
  OreCardContainer,
  OreCardWrapper,
  OreIcon,
  OreSpinner,
  OreStack,
  OreText,
  SvgBaby,
  SvgDestinationDirection,
} from '@edenredespana/oreneta'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GetDateFormatted } from 'src/core/helpers'
import { KindergartenService } from 'src/core/services/kindergartenService'
import { edenredProducts } from 'src/domain/enum'
import { KindergartenModel } from 'src/domain/models/TGDOrder'
import { TGDBasicChildModel } from 'src/domain/models/TGDOrder/TGDBasicOrderModel'
import { forms } from 'src/domain/translations'

interface Props {
  child: TGDBasicChildModel
}

export const TGDOrderChildLabel = ({ child }: Props): JSX.Element => {
  const [kindergarten, setKindergarten] = useState<KindergartenModel>()
  const [showSpinner, setShowSpinner] = useState<boolean>(false)
  const { t } = useTranslation()

  const getKindergartenById = (): void => {
    setShowSpinner(true)
    KindergartenService()
      .GetSingleKindergarten({
        productCode: edenredProducts.ticketGuarderia,
        nurseryId: child.kindergartenId,
      })
      .then(response => {
        if (response.data) {
          setKindergarten(response.data.rows[0])
        }
      })
      .finally(() => setShowSpinner(false))
  }

  useEffect(() => {
    getKindergartenById()
  }, [])

  if (showSpinner) return <OreSpinner />

  return (
    <OreCardContainer radius="all" tone="neutral-100">
      <OreCardWrapper space="small">
        <OreStack direction="row" space="medium">
          <OreStack direction="row" placeItems="center" space="xsmall">
            <OreIcon icon={<SvgBaby aria-hidden />} size="small" tone="neutral" />
            <div>
              <OreText bold tone="neutral-800">
                {child?.firstName}
              </OreText>
              {child?.birthDate && (
                <OreText>{GetDateFormatted(child?.birthDate)}</OreText>
              )}
            </div>
          </OreStack>{' '}
          <OreStack direction="row" placeItems="center" space="xsmall">
            <OreIcon
              icon={<SvgDestinationDirection aria-hidden />}
              size="small"
              tone="neutral"
            />
            <div>
              {kindergarten ? (
                <>
                  <OreText bold tone="neutral-800">
                    {kindergarten.name}
                  </OreText>
                  <OreText>
                    {kindergarten.streetName +
                      ' ' +
                      kindergarten.streetNumber +
                      ', ' +
                      kindergarten.zipCode +
                      ' ' +
                      kindergarten.city}
                  </OreText>
                </>
              ) : (
                <OreText tone="neutral-800">{t(forms.labels.unassigned)}</OreText>
              )}
            </div>
          </OreStack>{' '}
        </OreStack>
      </OreCardWrapper>
    </OreCardContainer>
  )
}
