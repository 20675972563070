import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { NumberSchema, TestContext, array, boolean, object } from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import { yupResolver } from '@hookform/resolvers/yup'
import { UseFormReturn, useForm } from 'react-hook-form'
import { MetaStatusCodes, NotificationSeverity } from 'src/domain/enum'
import { flexNavigationRoutes } from 'src/config/constants/navigationRoutes'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import {
  ProductTypeSlugs,
  Steps,
  orderValidationsTranslations,
} from 'src/Flex/Orders/ui/order-validation'
import {
  OrderValidationCompleteModel,
  OrderValidationData,
  OrderValidationLine,
  OrderValidationModel,
  OrderValidationResumeModel,
} from 'src/Flex/Orders/domain'
import { orderValidationService } from 'src/Flex/Orders/application'
import { edenredProducts } from 'src/domain/enum'
import {
  dateSchema,
  nonEmptyPositiveNumberSchema,
  nonEmptyStringSchema,
  numberSchema,
  stringSchema,
  useMetaResponseHandler,
} from 'src/Flex/Shared/ui/Form'
import { chargePayrollSchema } from 'src/Flex/Orders/ui/order-validation'
import { useUser } from 'src/presentation/context/user/UserProvider'
import { MessagesModel } from 'src/domain/models'
import { forms } from 'src/domain/translations'
import { PENDINGVALIDATIONSAMOUNTEQUALZERO } from 'src/domain/constants'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { GetDateFormattedLong, GetFullDateFormatted } from 'src/core/helpers'
import { useUserData } from 'src/Flex/User/ui/context'

export interface IUseOrderValidationController {
  productType: edenredProducts
  orderToValidate: OrderValidationModel | undefined
  orderSummary: OrderValidationResumeModel | undefined
  successData: OrderValidationCompleteModel | undefined
  errorData: MessagesModel[] | undefined
  form: UseFormReturn<OrderValidationData>
  refresh: () => Promise<void>
  calculate: (data: OrderValidationData) => Promise<void>
  back: () => void
  confirm: () => Promise<void>
  rows: OrderValidationLine[]
  search: (value: string) => void
  searchParam: string
  numberOfRecordsPerPage: number
  onPageChange: (page: number) => void
  page: number
  backToStartFromError: () => void
  backToHome: () => void
  isRequired: boolean
  calculateMetaError: MessagesModel | undefined
  cutoffDate: string | undefined
  backToPayroll: () => void
  getProductIsActive: (productType: number) => boolean
  updateDate: string | undefined
  emptyRows: boolean
  excelError: string
  showSpinner: boolean
}

export type IUseOrderValidationProps = {
  productType: edenredProducts
}

type CustomTestContext = {
  options: {
    index: number
    context: OrderValidationModel | undefined
  }
}

const schema = (t: TFunction<'translation', undefined>) =>
  object({
    chargeDate: dateSchema(t),
    chargePayrollMonth: chargePayrollSchema(t),
    orderReference: stringSchema(t).test({
      name: 'referenceRequired',
      test: (value, schema) => {
        const { options } = schema as TestContext & CustomTestContext
        return options.context?.isCommentRequiredFromClient &&
          (value === undefined || value === '')
          ? schema.createError({
              message: t(forms.errors.fieldRequired),
            })
          : true
      },
    }),
    orderLines: array().of(
      object({
        id: nonEmptyStringSchema(t),
        active: boolean(),
        amount: numberSchema(t).when(
          'active',
          (active: boolean, schema: NumberSchema<number | undefined>) =>
            active
              ? nonEmptyPositiveNumberSchema(t, undefined, false, 0).test({
                  name: 'max',
                  test: (value, schema) => {
                    const { options } = schema as TestContext & CustomTestContext
                    return (
                      (options.context?.orderLines[options.index].maxAmount ?? 0) >=
                        (value ?? 0) ||
                      schema.createError({
                        message: t(
                          orderValidationsTranslations.errors.maxAmountCollective,
                          {
                            maxAmount:
                              options.context?.orderLines[options.index].maxAmount,
                          }
                        ),
                      })
                    )
                  },
                })
              : schema
        ),
      })
    ),
  })

export const useOrderValidationController = ({
  productType,
}: IUseOrderValidationProps): IUseOrderValidationController => {
  const { step, productType: productTypeSlug } = useParams<{
    step: Steps
    productType: ProductTypeSlugs
  }>()
  const { t } = useTranslation()
  const { contracts } = useUser()
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const navigate = useNavigate()
  const { addNotification } = useNotification()
  const [orderToValidate, setOrderToValidate] = useState<OrderValidationModel>()
  const [cutoffDate, setCutoffDate] = useState<string>()
  const [updateDate, setUpdateDate] = useState<string>()
  const [orderSummary, setOrderSummary] = useState<OrderValidationResumeModel>()
  const [successData, setSuccessData] = useState<OrderValidationCompleteModel>()
  const [errorData, setErrorData] = useState<MessagesModel[]>()
  const [excelError, setExcelError] = useState<string>('')
  const [validatingProductType, setValidatingProductType] = useState<edenredProducts>()
  const [calculateMetaError, setCalculateMetaError] = useState<
    MessagesModel | undefined
  >()
  const numberOfRecordsPerPage = 40
  const [page, setPage] = useState<number>(1)
  const [searchParam, setSearchParam] = useState<string>('')
  const [isRequired, setIsRequired] = useState<boolean>(false)
  const [emptyRows, setEmptyRows] = useState<boolean>(true)
  const [showSpinner, setShowSpinner] = useState<boolean>(false)

  const { getAllProducts } = useUserData()

  const form = useForm<OrderValidationData>({
    resolver: yupResolver(schema(t)),
    mode: 'onChange',
    context: orderToValidate,
  })

  const onPageChange = (page: number): void => {
    setPage(page)
  }

  const load = async (afterCutoffDate?: boolean): Promise<void> => {
    setCalculateMetaError(undefined)
    startLoading()

    const response = await orderValidationService().GetOrderToValidate(
      productType,
      afterCutoffDate
    )

    if (response.meta.status === MetaStatusCodes.SUCCESS && afterCutoffDate) {
      addNotification(
        t(orderValidationsTranslations.forceUpdate.success),
        NotificationSeverity.success
      )
    }

    const currentContract = contracts?.find(
      contract => contract.productCode === productType
    )

    if (currentContract?.isCommentRequiredFromClient) {
      setIsRequired(true)
    } else {
      setIsRequired(false)
    }
    stopLoading()
    setValidatingProductType(productType)

    if (handleMetaResponse(response?.meta, undefined, { notifySuccess: false })) {
      response.data.isCommentRequiredFromClient =
        currentContract?.isCommentRequiredFromClient
      setOrderToValidate(response.data)
      setEmptyRows(response.data.orderLines.length > 0)
      setCutoffDate(
        GetDateFormattedLong(
          response.data?.cutoffDate ? response.data?.cutoffDate : new Date()
        )
      )
      setUpdateDate(
        GetFullDateFormatted(
          response.data?.updateDate ? response.data?.updateDate : new Date()
        )
      )

      form.reset({
        chargeDate: '',
        chargePayrollMonth: response.data.chargePayrollMonth,
        terms: false,
        orderReference: currentContract?.invoiceComment
          ? currentContract.invoiceComment
          : '',
        orderLines: response.data.orderLines.map(orderLine => ({
          id: orderLine.id,
          active: true,
          amount: orderLine.defaultAmount,
        })),
      })
    } else if (response.meta.status === MetaStatusCodes.ERROR && afterCutoffDate) {
      addNotification(
        t(orderValidationsTranslations.forceUpdate.fail),
        NotificationSeverity.error
      )
    } else {
      setOrderToValidate(undefined)
      setCutoffDate(undefined)
      setUpdateDate(undefined)
    }
  }

  const refresh = (): Promise<void> => load(true)

  const calculate = async (data: OrderValidationData): Promise<void> => {
    data.terms = true
    let error = false
    setShowSpinner(true)

    const response = await orderValidationService().CalculateValidationOrder(
      productType,
      data
    )

    if (
      response.meta.status === MetaStatusCodes.ERROR &&
      response.meta.messages[0].code === PENDINGVALIDATIONSAMOUNTEQUALZERO
    ) {
      error = true
    } else if (response.meta.status === MetaStatusCodes.SUCCESS) {
      error = false
    } else {
      error = true
    }
    if (error) {
      setCalculateMetaError(response.meta.messages[0])
      setOrderSummary(undefined)
      setErrorData(response.meta.messages)
      setExcelError(response.data.additionalData)
    } else {
      setOrderSummary(response.data)
      setCalculateMetaError(undefined)
      setErrorData(undefined)
      setExcelError('')
      navigate(
        flexNavigationRoutes.flexOrdersValidate
          .replace(':productType', productTypeSlug as string)
          .replace(':step', Steps.StepTwo)
      )
    }
  }

  const back = (): void => {
    navigate(
      flexNavigationRoutes.flexOrdersValidate
        .replace(':productType', productTypeSlug as string)
        .replace(':step', Steps.StepOne)
    )

    setOrderSummary(undefined)
  }

  const confirm = async (): Promise<void> => {
    startLoading()

    const response = await orderValidationService().ConfirmOrder(productType, {
      ...form.getValues(),
      terms: true,
    })

    stopLoading()

    if (response?.meta.status === MetaStatusCodes.SUCCESS) {
      setSuccessData(response.data)
    } else {
      setErrorData(response.meta.messages)
      setExcelError(response.data.additionalData)
    }

    navigate(
      flexNavigationRoutes.flexOrdersValidate
        .replace(':productType', productTypeSlug as string)
        .replace(':step', Steps.StepThree)
    )
    setOrderSummary(undefined)
  }

  const initialize = async (): Promise<void> => {
    if (
      (step === Steps.StepTwo && !orderSummary) ||
      (step === Steps.StepThree && !successData && !errorData)
    ) {
      backToStart()

      return
    }

    if (
      step !== Steps.StepOne ||
      (orderToValidate &&
        !successData &&
        !errorData &&
        productType === validatingProductType)
    ) {
      return
    }
    await executeLoad()
  }

  const backToStart = (): void => {
    navigate(
      flexNavigationRoutes.flexOrdersValidate
        .replace(':productType', productTypeSlug as string)
        .replace(':step', Steps.StepOne)
    )
  }
  const backToStartFromError = (): void => {
    executeLoad()
    backToStart()
  }
  const backToHome = (): void => {
    navigate(flexNavigationRoutes.flex)
  }

  const executeLoad = async (): Promise<void> => {
    search('')
    setCutoffDate(undefined)
    setUpdateDate(undefined)
    await load()
    setOrderSummary(undefined)
    setSuccessData(undefined)
    setErrorData(undefined)
    setExcelError('')
  }

  const search = (s: string): void => {
    setPage(1)
    setSearchParam(s)
  }

  const backToPayroll = (): void => {
    navigate(flexNavigationRoutes.flexPayrolls)
  }

  const getProductIsActive = (productType: number): boolean => {
    const prod = getAllProducts.find(x => x.productType === productType)
    if (prod) {
      return !!prod.active
    }
    return false
  }

  useEffect(() => {
    if (getProductIsActive(productType)) {
      initialize()
    }
  }, [step, productType])

  useEffect(() => {
    if (orderSummary !== undefined || calculateMetaError !== undefined) {
      setShowSpinner(false)
    }
  }, [orderSummary, calculateMetaError])

  const rows = useMemo(() => {
    if (!orderToValidate) return []

    if (searchParam === '') return orderToValidate.orderLines

    return orderToValidate.orderLines.filter(
      orderLine =>
        orderLine.employee?.name?.toLowerCase().includes(searchParam.toLowerCase()) ||
        orderLine.employee?.document?.toLowerCase().includes(searchParam.toLowerCase()) ||
        orderLine.employee?.number?.toLowerCase().includes(searchParam.toLowerCase())
    )
  }, [page, orderToValidate?.orderLines, searchParam])

  return {
    productType,
    orderToValidate,
    orderSummary,
    successData,
    errorData,
    form,
    refresh,
    calculate,
    back,
    confirm,
    rows,
    search,
    searchParam,
    numberOfRecordsPerPage,
    onPageChange,
    page,
    backToStartFromError,
    backToHome,
    isRequired,
    calculateMetaError,
    cutoffDate,
    backToPayroll,
    getProductIsActive,
    updateDate,
    emptyRows,
    excelError,
    showSpinner,
  }
}
