import {
  OreButton,
  OreHeading,
  OreIconButton,
  OreMessageBar,
  OreStack,
  OreTable,
  OreTableBody,
  OreTableCell,
  OreTableContainer,
  OreTableRow,
  OreText,
  SvgDuplicate,
} from '@edenredespana/oreneta'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import SuccessLogo from '../../../../assets/img/Success.svg'
import { cardOrderTranslation, forms } from 'src/domain/translations'
import { TGDOrderTranslation } from 'src/domain/translations/tgdOrders/tgdOrderForm'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { GetDateFormatted } from 'src/core/helpers'
import { TGDOrderCompleteModel } from 'src/domain/models/TGDOrder/TGDOrderModel'
import { PaymentModes, currency } from 'src/domain/enum'

interface Props {
  orderCompleteData: TGDOrderCompleteModel
}

export const TGDBasicOrderCompleteSuccess = ({ orderCompleteData }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const initialChargeDate = orderCompleteData.initialChargeDate
    ? GetDateFormatted(orderCompleteData.initialChargeDate)
    : ''
  return (
    <div style={{ margin: '3rem 17% 0 17%' }}>
      <OreStack
        direction="column"
        space="large"
        placeContent="space-between"
        placeItems="center">
        <div>
          <figure>
            <img src={SuccessLogo} alt="" />
          </figure>
        </div>
        <OreStack direction="column" space="medium">
          <header className="width-max">
            <OreHeading as="h1" size="title-sm" align="center">
              {t(cardOrderTranslation.cardOrderComplete.titleSuccess)}
            </OreHeading>
          </header>
          <div>
            <OreText align="center">
              {t(TGDOrderTranslation.complete.successMessage1)}
              <span style={{ fontWeight: 'bold' }}>{orderCompleteData.email}</span>
              {t(TGDOrderTranslation.complete.successMessage2)}
            </OreText>
          </div>
          <div>
            <OreText align="center">
              {t(TGDOrderTranslation.complete.assignmentDate)}
              <span style={{ fontWeight: 'bold' }}>{initialChargeDate}</span>
            </OreText>
            <OreText align="center">
              {t(TGDOrderTranslation.complete.totalAmount)}
              <span style={{ fontWeight: 'bold' }}>{orderCompleteData.totalAmount}€</span>
            </OreText>
            {orderCompleteData.chargeOrderId && (
              <OreText align="center">
                {t(TGDOrderTranslation.complete.assignmentOrderID)}
                <b>{`#${orderCompleteData.chargeOrderId}`}</b>
              </OreText>
            )}
          </div>
          <OreMessageBar color="warning" icon>
            {t(TGDOrderTranslation.complete.important)}
          </OreMessageBar>
          {orderCompleteData.paymentMode === +PaymentModes.wireTransfer && (
            <div>
              <OreText align="center">
                {t(TGDOrderTranslation.complete.successMessage3)}
              </OreText>
            </div>
          )}
        </OreStack>
        {orderCompleteData.paymentMode === +PaymentModes.wireTransfer && (
          <>
            <OreStack>
              <OreTableContainer>
                <OreTable>
                  <OreTableBody>
                    <OreTableRow hover={false} noBorder={true}>
                      <OreTableCell>
                        <OreStack
                          placeContent="space-between"
                          space="large"
                          direction="row"
                          placeItems="center">
                          <OreText>{t(TGDOrderTranslation.complete.iban)}</OreText>
                          <OreStack
                            placeContent="space-between"
                            space="xsmall"
                            placeItems="center"
                            direction="row">
                            <OreText bold={true}>{orderCompleteData.iban}</OreText>
                            <OreIconButton
                              as="a"
                              icon={<SvgDuplicate />}
                              onClick={() =>
                                navigator.clipboard.writeText(orderCompleteData.iban)
                              }
                            />
                          </OreStack>
                        </OreStack>
                      </OreTableCell>
                    </OreTableRow>
                    <OreTableRow hover={false} valign="middle">
                      <OreTableCell>
                        <OreStack
                          placeContent="space-between"
                          space="large"
                          direction="row"
                          placeItems="center">
                          <OreText>{t(TGDOrderTranslation.complete.concept)}</OreText>
                          <OreStack
                            placeContent="space-between"
                            space="xsmall"
                            placeItems="center"
                            direction="row">
                            <OreText bold={true}>{orderCompleteData.concept}</OreText>
                            <OreIconButton
                              as="a"
                              icon={<SvgDuplicate />}
                              onClick={() =>
                                navigator.clipboard.writeText(orderCompleteData.concept)
                              }
                            />
                          </OreStack>
                        </OreStack>
                      </OreTableCell>
                    </OreTableRow>
                    <OreTableRow hover={false}>
                      <OreTableCell>
                        <OreStack
                          placeContent="space-between"
                          space="large"
                          direction="row">
                          <OreText>{t(TGDOrderTranslation.complete.totalAmount)}</OreText>
                          <OreText bold>
                            {orderCompleteData.totalAmount} {currency.euro}
                          </OreText>
                        </OreStack>
                      </OreTableCell>
                    </OreTableRow>
                  </OreTableBody>
                </OreTable>
              </OreTableContainer>
            </OreStack>
          </>
        )}

        <OreStack
          direction="column"
          space="small"
          placeContent="space-between"
          placeItems="center">
          <div className="mt-1">
            <OreButton
              as="button"
              category="primary"
              onClick={() => navigate(navigationRoutes.myOrdersHistory)}
              size="large">
              {t(cardOrderTranslation.cardOrderComplete.checkStatus)}
            </OreButton>
          </div>
          <OreButton
            as="button"
            category="highlight"
            onClick={() => navigate(navigationRoutes.clientsHome)}
            size="large"
            style={{ color: '#0F172A' }}>
            {t(forms.buttons.goHome)}
          </OreButton>
        </OreStack>
      </OreStack>
    </div>
  )
}
