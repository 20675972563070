import { yupResolver } from '@hookform/resolvers/yup'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { NotificationSeverity, OSCCode, orderType } from 'src/domain/enum'
import { forms } from 'src/domain/translations'
import { UseFormReturn, useForm } from 'react-hook-form'
import { PopupButtonModel } from 'src/domain/customComponents/Popup'
import { useModalController } from 'src/presentation/components/Edenred'
import { navigationRoutes } from 'src/config/constants/navigationRoutes'
import { useNavigate } from 'react-router-dom'
import { CardOrderResumeDataModel } from 'src/domain/models'
import { useNotification } from 'src/presentation/context/notification/NotificationProvider'
import { useTTOrderExcelSummarySchema } from '../validations/useTTOrderExcelSummarySchema'
import { TTOrderTranslation } from 'src/domain/translations/ttOrder/ttOrderTranslate'
import { TTOrderSummaryForm } from 'src/domain/models/TTOrder/TTOrderModel'
import { useTTOrder } from '../../../context/TTOrderProvider'

export interface TTOrderExcelSummaryState {
  form: UseFormReturn<TTOrderSummaryForm, any>
  setShowCancelModal: Dispatch<SetStateAction<boolean>>
  showCancelModal: boolean
  cancelModalTitle: string
  cancelModalDescription: string
  cancelPopupButtons: PopupButtonModel[]
  onClickCancel(): void
  onCheckoutOrder: (summary: TTOrderSummaryForm) => Promise<void>
  getCardOrderResumeData: (
    resumeData: CardOrderResumeDataModel[]
  ) => CardOrderResumeDataModel[]
  getRechargeResumeData: (
    resumeData: CardOrderResumeDataModel[]
  ) => CardOrderResumeDataModel[]
  getUnloadResumeData: (
    resumeData: CardOrderResumeDataModel[]
  ) => CardOrderResumeDataModel[]
}

export const useTTOrderExcelSummaryController = (): TTOrderExcelSummaryState => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    clearTTOrders,
    addTTSummaryForm,
    orderContext,
    setTTEmployeesToConfig,
    setTTSelectedEmployees,
  } = useTTOrder()
  const { TTOrderExcelSummarySchema, TTUnloadExcelSummarySchema } =
    useTTOrderExcelSummarySchema()
  const { addNotification } = useNotification()

  const form = useForm<TTOrderSummaryForm>({
    resolver: yupResolver(
      orderContext === orderType.Unload
        ? TTUnloadExcelSummarySchema
        : TTOrderExcelSummarySchema
    ),
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      initialChargeDate: '',
      billReference: '',
      acceptConditions: false,
      orderTypeId: orderContext,
    },
  })

  const {
    show: showCancelModal,
    title: cancelModalTitle,
    description: cancelModalDescription,
    buttons: cancelPopupButtons,
    setShow: setShowCancelModal,
    setDescription: setCancelModalDescription,
    setTitle: setCancelModalTitle,
    setButtons: setCancelModalButtons,
  } = useModalController()

  const onClickCancel = (): void => {
    setShowCancelModal(true)
    setCancelModalButtons(getCancelModalButtons())
  }

  const getCancelModalButtons = (): PopupButtonModel[] => {
    return [
      {
        title: t(forms.buttons.cancel),
        category: 'secondary',
        onClick: () => setShowCancelModal(false),
        size: 'large',
      },
      {
        title: t(forms.buttons.discard),
        category: 'primary',
        onClick: () => {
          clearTTOrders()
          setTTEmployeesToConfig([])
          setTTSelectedEmployees([])
          setShowCancelModal(false)
          addNotification(t(forms.success.actionMessage), NotificationSeverity.success)
          navigate(navigationRoutes.myOrdersTtTab)
        },
        size: 'large',
      },
    ]
  }

  const getCardOrderResumeData = (
    resumeData: CardOrderResumeDataModel[]
  ): CardOrderResumeDataModel[] => {
    return resumeData.filter(
      (item: CardOrderResumeDataModel) =>
        item.resumeCode !== OSCCode.recharge_1 && item.resumeCode !== OSCCode.recharge_2
    )
  }

  const getRechargeResumeData = (
    resumeData: CardOrderResumeDataModel[]
  ): CardOrderResumeDataModel[] => {
    return resumeData.filter(
      (item: CardOrderResumeDataModel) =>
        item.resumeCode === OSCCode.recharge_1 || item.resumeCode === OSCCode.recharge_2
    )
  }

  const getUnloadResumeData = (
    resumeData: CardOrderResumeDataModel[]
  ): CardOrderResumeDataModel[] => {
    return resumeData.filter(
      (item: CardOrderResumeDataModel) =>
        item.resumeCode === OSCCode.unload_1 || item.resumeCode === OSCCode.unload_2
    )
  }

  const getExcelCheckoutURL = (): string => {
    switch (orderContext) {
      case orderType.Card:
        return navigationRoutes.ttOrderExcelCheckout
      case orderType.Recharge:
        return navigationRoutes.ttRechargeExcelCheckout
      case orderType.Unload:
        return navigationRoutes.ttUnloadExcelCheckout
      default:
        return navigationRoutes.ttOrderExcelCheckout
    }
  }

  const onCheckoutOrder = async (summary: TTOrderSummaryForm): Promise<void> => {
    addTTSummaryForm(summary)
    addNotification(t(forms.success.message), NotificationSeverity.success)
    navigate(getExcelCheckoutURL(), { state: { isExcel: true } })
  }

  useEffect(() => {
    setCancelModalTitle(t(TTOrderTranslation.summary.cancelModal.title))
    setCancelModalDescription(t(TTOrderTranslation.summary.cancelModal.description))
  }, [])

  return {
    form,
    onCheckoutOrder,
    showCancelModal,
    setShowCancelModal,
    cancelModalTitle,
    cancelModalDescription,
    cancelPopupButtons,
    onClickCancel,
    getCardOrderResumeData,
    getRechargeResumeData,
    getUnloadResumeData,
  }
}
