import { productsService } from 'src/Flex/Products/application'
import type { edenredProducts } from 'src/domain/enum'
import { useProducts } from 'src/Flex/Products/ui/product-list'
import { useLoader } from 'src/presentation/context/loader/LoaderProvider'
import { useMetaResponseHandler } from 'src/Flex/Shared/ui/Form'
import { publishEvent } from 'src/Flex/Shared/ui/Events/helpers'
import { Events as productEvents } from 'src/Flex/Products/domain'
import { useTranslation } from 'react-i18next'
import { flexProductsTranslations } from '../../translations'
import { downloadExcel } from 'src/core/helpers'

export const useStatusToggleProduct = () => {
  const { startLoading, stopLoading } = useLoader()
  const { handleMetaResponse } = useMetaResponseHandler()
  const { collective, loadProductsCollectives } = useProducts()
  const { t } = useTranslation()

  const save = async (productId: edenredProducts): Promise<boolean> => {
    startLoading()

    const response = await productsService().SaveProductStatus(productId, collective?.id)

    stopLoading()

    if (handleMetaResponse(response?.meta)) {
      publishEvent(productEvents.SAVE_PRODUCT_STATE)

      return true
    }

    return false
  }

  const enableDisable = async (
    productId: edenredProducts,
    enable: boolean
  ): Promise<boolean> => {
    startLoading()

    const response = await productsService().EnableDisableProductStatus(
      productId,
      enable,
      collective?.id
    )

    stopLoading()

    if (handleMetaResponse(response?.meta)) {
      publishEvent(productEvents.SAVE_PRODUCT_STATE)

      await loadProductsCollectives(collective && collective.id ? collective.id : '')
      return true
    }

    return false
  }

  const getEmployeesAffectedExcel = async (productId: edenredProducts): Promise<void> => {
    if (collective === undefined || collective.id === undefined) return
    startLoading()
    const response = await productsService().GetEmployeesAffectedExcelDisabledProduct(
      productId,
      collective.id
    )

    stopLoading()

    if (
      handleMetaResponse(response?.meta, undefined, {
        notifySuccess: false,
      }) &&
      response.data.file
    ) {
      downloadExcel(
        response.data.file,
        t(flexProductsTranslations.employeeContractAlert.excel)
      )
    }
  }

  return {
    save,
    getEmployeesAffectedExcel,
    enableDisable,
  }
}
